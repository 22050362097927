<style lang="scss" scoped>

.supplier-appointment-options {
  --supplier-border: 1px solid #{$gray-light-2};

  border: var(--supplier-border);
  border-radius: 8px;

  .option {
    padding: 24px;
    grid-template-columns: 24px 1fr;

    &:not(:last-child) {
      border-bottom: var(--supplier-border);
    }
  }
}

</style>


<template lang="pug">

.supplier-appointment-options
  template(v-for="{ supplier, distance, at } of suppliersAvailable")
    .option(:class="hideRadio ? [] : ['grid', 'gap-big']")
      radio-field.vertical-start(
        v-if="!hideRadio"
        hide-value-text,
        name="supplier",
        v-model="model",
        :value="String(supplier.id)",
        :id="`supplier-${supplier.id}`",
        :disabled="disabled"
      )

      supplier-appointment-options-item(:supplier="supplier", :distance="distance")
        template(#bottom, v-if="supplier.id == model")
          slot(
            name="bottom",
            v-bind="{ supplier, availableBy: at, disabled }"
          )

</template>


<script>

// Components
import RadioField from "@/components/radio-field/radio-field.vue"
import SupplierAppointmentOptionsItem from "./item"

export default {
  name: "SupplierAppointmentOptions",

  components: {
    RadioField,
    SupplierAppointmentOptionsItem
  },

  props: {
    suppliersAvailable: { type: Array, default: () => [] },
    modelValue:         { type: [String, Number] },
    disabled:           { type: Boolean, default: false },
    hideRadio:          { type: Boolean, default: false }
  },

  emits: ["update:modelValue"],

  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit("update:modelValue", val)
      }
    }
  },

  data() {
    return {
      i18nScope: "component.supplier-appointment-options"
    }
  }
}

</script>
