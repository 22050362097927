// Base
import Base from "./Base"

// Models
import SupplierAvailable from "@/models/supplier/supplier-available"

class SupplierAvailableRequests extends Base {
  static get selector() {
    return "suppliers.available"
  }

  index({ ticketId, params = {}, options = {} } = {}) {
    this.verifyData("index", { ticketId })

    return this.action({
      method:  "post",
      url:     `/tickets/${ticketId}/available_suppliers/search`,
      params,
      options: {
        model: SupplierAvailable,

        ...options
      }
    })
  }
}

export default SupplierAvailableRequests
