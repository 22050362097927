import Base from "../base/base"

class Service extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "type",
      "name",
      "description"
    ])
  }
}

export default Service
