<style lang="scss" scoped>

.toggle-tag {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid $gray-light;
  border-radius: var(--toggle-tag-radius);
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 400;
  color: $gray-dark;
  background-color: transparent;
  cursor: pointer;
  transition: color 80ms ease-in,
    background-color 80ms ease-in,
    border-color 80ms ease-in;

  &.disabled {
    color: $gray-2;
    border-color: $gray-light-2;
    cursor: not-allowed;
    background-color: $gray-light-2;
  }

  &:not(.disabled).strike-through {
    color: $gray;
    text-decoration: line-through;
    cursor: not-allowed;
  }

  &:not(.disabled, .strike-through).selected {
    color: $white;
    background-color: $orange;
    border-color: $orange;

    &:hover {
      background-color: $orange-dark;
      border-color: $orange-dark;
    }
  }

  &:not(.selected, .disabled, .strike-through):hover {
    color: $orange;
    border-color: $orange;
  }

  &::selection, *::selection {
    color: inherit;
    background-color: transparent;
  }
}

</style>


<template lang="pug">

span.toggle-tag(:class="{ selected, disabled, 'strike-through': strikeThrough }", :style="cssVars")
  slot

</template>


<script>

export default {
  name: "ToggleTag",

  props: {
    disabled:      { type: Boolean, default: false },
    selected:      { type: Boolean, default: false },
    strikeThrough: { type: Boolean, default: false },
    radius:        { type: String, default: "8px" }
  },

  computed: {
    cssVars() {
      return {
        "--toggle-tag-radius": this.radius
      }
    }
  }
}

</script>
