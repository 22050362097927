<style lang="scss" scoped>

.customer-popover {
  width: 328px;

  .cell {
    font-size: 0.875rem;
    color: $gray-dark;
    font-family: $secondary-font;
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-light-2;
    }

    &.with-icon {
      grid-template-columns: 24px 1fr 16px;
    }

    .icon {
      font-size: 1rem;
      color: $gray-2;
    }

    .unavailable {
      font-style: italic;
      color: $gray;
      font-family: $primary-font;
    }

    button {
      appearance: none;
      background-color: transparent;
      border: none;
      color: $orange;
      position: relative;

      &:hover {
        cursor: pointer;
        color: $orange-dark;
      }

      i {
        --icon-transform-center: translate(-50%, -50%);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: var(--icon-transform-center);
      }

      .fa-check-circle {
        opacity: 0;
      }

      &.animate {
        i {
          animation-direction: alternate;
          animation-fill-mode: forwards;
          animation-duration: 0.6s;
          animation-iteration-count: 2;
          animation-timing-function: linear;
        }

        .fa-copy {
          animation-name: rotate-from-0;
        }

        .fa-check-circle {
          animation-name: rotate-from-180;
        }
      }


      @keyframes rotate-from-0 {
        0% {
          transform: var(--icon-transform-center) rotateY(0);
          opacity:1;
        }

        24% { opacity:1; }
        25% { opacity:0; }

        50%, 100% {
          transform: var(--icon-transform-center) rotateY(0.5turn);
          opacity:0;
        }
      }

      @keyframes rotate-from-180 {
        0% {
          transform: var(--icon-transform-center) rotateY(-0.5turn);
          opacity:0;
        }

        24% { opacity:0; }
        25% { opacity:1; }

        50%, 100% {
          transform: var(--icon-transform-center) rotateY(0);
          opacity:1;
        }
      }
    }
  }
}

</style>


<template lang="pug">

.customer-popover
  .cell(
    v-for="detail of details"
    :class="detail.copy ? ['grid', 'with-icon', 'gap-small'] : []"
  )
    template(v-if="detail.copy")
      .icon.flex.center.vertical-baseline
        i.fas(:class="detail.iconClass")

      span(v-if="detail.value", v-mask="detail.mask || '' ") {{ detail.value }}
      span.unavailable(v-else) {{ $t(".unavailable")}}

      button(
        v-if="copyEnable && detail.value",
        @click="copy(detail.value, $event)",
        @animationend="removeClass"
      )
        i.far.fa-copy
        i.fas.fa-check-circle

    span(v-else-if="detail.value") {{ detail.value }}
    span.unavailable(v-else) {{ $t(".unavailable")}}

</template>


<script>

export default {
  name: "DetailsPopover",

  props: {
    copyEnable: { type: Boolean, default: true },
    details:    { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "components.details-popover"
    }
  },

  methods: {
    copy(value, event) {
      const el = event.target.tagName === "BUTTON"
        ? event.target
        : event.target.parentElement

      el.classList.add("animate")
      navigator.clipboard.writeText(value)
    },

    removeClass(event) {
      event.target.parentElement.classList.remove("animate")
    }
  }
}

</script>
