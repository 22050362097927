<style lang="scss" scoped>

.issues-summary-row {
  grid-template-columns: var(--issues-summary-template-columns);

  .cell {
    padding: 12px 8px;
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    color: $gray-3;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .description {
    font-size: 12px;
  }

  .part,
  .target {
    gap: 6px;
    font-family: $primary-font;
  }

  .part {
    font-size: 14px;
  }

  .targets {
    font-size: 12px;
    margin-left: 2.5px;
    padding-left: 8.5px;
    border-left: 1px solid $gray-3;
  }

  .gray {
    color: $gray-3;
  }

  .red {
    color: #ff0000;
  }

  .purchase-intent-container {
    display: flex;
    align-items: center;
  }
  .purchase-intention-icon {
    margin-right: 4px;
    font-size: 10px;
  }

  .fa-circle {
    font-size: 6px;
  }

  .fa-diamond {
    font-size: 4px;
  }

  p {
    margin: 0;
  }

  .empty {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
  }
}

</style>


<template lang="pug">

.issues-summary-row.grid.vertical-center(:style="cssStyles")
  .cell
    .icon.flex.center.vertical-center
      i.far.fa-wrench

  .cell
    p.name {{ issue?.issueName }}
    p.description(v-if="issue?.description") {{ issue.description }}
      span(v-if="issue?.purchaseIntent == 1")
        .purchase-intent-container
          i.fas.fa-circle.red.purchase-intention-icon
          span Há intenção de compra


  .cell(v-if="hasPartAndSymptom && !issue.isMaintenance")
    .part.flex.vertical-center
      i.fas.fa-circle.gray
      span {{ issue.part?.name }}
      i.fas.fa-diamond.gray
      span {{ issue.symptom?.name }}

    .targets
      .target.flex.vertical-center(v-for="target of issue.serviceTargets")
        span {{ translatePartTarget(target.position) }}
        i.fas.fa-diamond.gray
        span {{ translatePartTarget(target.location) }}

  .cell(v-else)
    p.empty -

</template>


<script>

// Models
import Part from "@/models/part/part"

export default {
  name: "IssuesSummaryRow",

  props: {
    issue:       { type: Object },
    columnSizes: { type: String, default: "1fr" }
  },

  computed: {
    cssStyles() {
      return {
        "--issues-summary-template-columns": this.columnSizes
      }
    },

    hasPartAndSymptom() {
      return [this.issue.part, this.issue.symptom].every(Boolean)
    }
  },

  methods: {
    translatePartTarget: Part.translateTarget
  }
}

</script>
