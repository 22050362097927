<style lang="scss" scoped>

.requester-contact {
  padding: 16px;
  border: 1px solid $gray-light;
  border-radius: 8px;

  .name {
    font-weight: 500;
    font-family: $secondary-font;
    line-height: 1.5;
  }

  p {
    margin-block: 0;
  }

  .detail {
    font-size: 14px;
  }

  .reset-button {
    appearance: none;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    width: 24px;
    aspect-ratio: 1;
    padding: 0;
    cursor: pointer;

    &:hover:not(:disabled, [disabled]) {
      background-color: $gray-light-3;

      &:active {
        background-color: $gray-light-2;
      }
    }
  }

  .edit {
    color: $orange;

    &:disabled,
    &[disabled] {
      color: $gray-light;
      cursor: not-allowed;
    }
  }

  .fold {
    transition: transform 0.3s ease;

    &.retracted {
      transform: rotate(-0.5turn);
    }
  }
}

</style>


<template lang="pug">

.requester-contact
  .grid.gap-small(v-if="skeleton")
    loading-lines(
      :height="22"
    )

    loading-lines(
      :lines="2",
      :height="16"
    )

  template(v-else)
    .top
      slot(name="top")

    .header.flex.gap-regular.vertical-center

      p.name.grow {{ requester?.name }}

      button.reset-button.action.edit(
        v-if="!withoutEdit"
        type="button",
        :disabled="disabled"
        @click="emitEdit"
      )
        i.fas.fa-pencil

      button.reset-button.action.fold(
        v-if="!withoutRetraction"
        type="button",
        :class="{ retracted }",
        @click="toggleOpening",
      )
        i.fas.fa-angle-up

    template(v-if="!retracted")
      .details
        .detail.flex.vertical-center.gap-small
          i.fal.fa-phone

          p(v-mask="phoneMask") {{ requester?.phone }}

        .detail.flex.vertical-center.gap-small
          i.fal.fa-envelope

          p {{ requester?.email }}

      .footer
        slot(name="footer")

</template>


<script>

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

export default {
  name: "RequesterContact",

  components: {
    LoadingLines
  },

  props: {
    requester:         { type: Object },
    skeleton:          { type: Boolean, default: false },
    disabled:          { type: Boolean, default: false },
    withoutRetraction: { type: Boolean, default: false },
    withoutEdit:       { type: Boolean, default: false }
  },

  emits: ["show-edit"],

  data() {
    return {
      retracted: false
    }
  },

  computed: {
    phoneMask() {
      if (!this.requester?.phone) return ""

      const cleanPhone = this.requester.phone.replace(/\D/g, "")

      switch (cleanPhone.length) {
        case 10:
          return "(##) ####-####"
        case 11:
          return "(##) #####-####"
        default:
          return ""
      }
    }
  },

  methods: {
    emitEdit() {
      if (this.disabled) return

      this.$emit("show-edit", { requester: this.requester })
    },

    toggleOpening(bool) {
      if (this.withoutRetraction) return

      if (typeof bool === "boolean") {
        this.retracted = bool
        return
      }

      this.retracted = !this.retracted
    }
  }
}

</script>
