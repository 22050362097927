<style lang="scss" scoped>

.ticket-timeline {
  color: $gray-dark;
  font-size: 14px;

  p {
    margin: 0;
  }

  .skeleton {
    grid-template-columns: 82px 1fr;
    column-gap: 16px;
    row-gap: 24px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 500;
  }

  .table {
    --timeline-table-padding-block: 12px;
    --timeline-table-line-gap: 5px;

    grid-template-columns: 82px auto 1fr;
    column-gap: 16px;

    .left {
      .accent {
        width: 16px;

        &.translate {
          transform: translateY(-5px);
        }
      }
    }

    .middle {
      gap: var(--timeline-table-line-gap);
      grid-column-start: 2;
    }

    .right {
      grid-column-start: 3;
    }

    .vertical-line {
      width: 2px;
      background-color: $gray-light-2;

      &.top {
        flex-basis: calc(
          var(--timeline-table-padding-block) - var(--timeline-table-line-gap)
        );
      }
    }
  }

  .reason {
    font-weight: 400;
    font-size: 12px;
  }

  .padding-block {
    padding-block: 12px;
  }

  .fs-tiny {
    font-size: 12px;
  }

  .ff-secondary {
    font-family: $secondary-font;
    font-weight: 400;
  }

  $local-colors: (
    "purple": $purple,
    "gray": $gray,
    "gray-3": $gray-3,
    "green": $green,
    "yellow": $yellow,
    "red": $red,
  );

  @each $name, $color in $local-colors {
    .color-#{$name} {
      color: $color;
    }
  }
}

</style>


<template lang="pug">

.ticket-timeline.grid.gap-regular
  p.title {{ $t(".title") }}

  .skeleton.grid(v-if="!initialized")
    template(v-for="i of 3")
      loading-lines(:min="90", :max="100")
      loading-lines( icon, :min="90", :max="100")

  .table.grid(v-else)
    template(v-for="(timepoint, i) of timeline")
      .left.flex.padding-block
        .accent.no-shrink(:class="{ translate: i > 0 }")
          i.fal.fa-arrow-up.fs-tiny.color-gray-3(v-if="i > 0")
          i.fas.fa-caret-right.color-purple(v-else)

        .created
            p.ff-secondary {{ $fromNow(timepoint.createdAt)}}
            p.fs-tiny {{ formatTimeShort(timepoint.createdAt) }}
            p.fs-tiny {{ getFirstName(timepoint.authorName) }}

      .middle.flex.column-direction.vertical-center
        .vertical-line.top.no-shrink
        i(:class="getEventIcon(timepoint.status)")
        .vertical-line.grow

      .right.padding-block
        p.ff-secondary {{ timepointTitle(timepoint.status) }}
        p {{ timepoint.supplier?.presentName }}
        p {{ formatTimeShort(timepoint.at) }}

    .middle.flex.center.vertical-center
      i.fas.fa-arrow-up.color-gray-3

    .right
      span.color-gray.fs-tiny {{ $t(".footer") }}

</template>


<script>

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import BookingEvent from "@/models/booking/booking-event"

const eventIconMap = new Map([
  ["booked", "fas fa-calendar-check color-green"],
  ["rebooked", "fas fa-calendar-clock color-yellow"],
  ["pre_booked", "fas fa-calendar-clock color-yellow"],
  ["confirmed", "fas fa-check color-green"],
  ["done", "fas fa-check color-green"],
  ["canceled", "fas fa-calendar-xmark color-red"]
])

export default {
  name: "TicketTimeline",

  components: {
    LoadingLines
  },

  mixins: [FetchMixin],

  props: {
    ticketId: { type: [String, Number] }
  },

  emits: ["index"],

  data() {
    return {
      i18nScope: "components.ticket-timeline",
      //
      timeline:  [],
      autofetch: false
    }
  },

  watch: {
    ticketId() {
      this.fetch()
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.bookings.events.index({ ticketId: this.ticketId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.timeline = data
      this.$emit("index", data)
    },

    getFirstName(authorName) {
      if (!authorName) return ""

      return authorName
        ? authorName.split(" ")[0]
        : ""
    },

    getEventIcon(eventStatus) {
      return eventIconMap.get(eventStatus) || ""
    },

    formatTimeShort(date) {
      return this.$l("time.formats.short", date)
    },

    timepointTitle(eventStatus) {
      const translatedTypes = BookingEvent.$enums.status.values

      if (!eventStatus || !translatedTypes.includes(eventStatus)) return ""

      return BookingEvent.$tEnum("status", eventStatus)
    }
  }
}

</script>
