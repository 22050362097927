<style lang="scss" scoped>

.license-plate {
  width: 75px;
  height: 24px;
  background-color: $gray-light-4;
  border-radius: 4px;
  border: 1px solid $gray-light;
  overflow: hidden;

  .top {
    background-color: $blue-dark;
    height: 4px;
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
    padding-inline: 8px;
  }

  &.small {
    width: auto;
    height: 20px;

    .top {
      height: 3px;
      flex-shrink: 0;
    }

    .value {
      padding-inline: 4px;
      font-size: 12px;
    }
  }
}

</style>


<template lang="pug">

.license-plate.flex.column-direction(:class="{ small }")
  .top.full-width
  span.value {{ value }}

</template>


<script>

export default {
  name: "LicensePlate",

  emits: [],

  props: {
    value: { type: String, required: true },
    small: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.license-plate"
    }
  }
}

</script>
