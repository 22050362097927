<!--
<doc lang="markdown">

Container de notificações (alertas) para feedback visual ao usuário.
Integra-se ao módulo `notifications.js`, respondendo aos eventos de notificação.

</doc>
-->

<style lang="scss" scoped>

.alerts {
  position: fixed;
  display: flex;
  top: 14px;
  right: 15px;
  z-index: 9999;

  .alert {
    display: flex;
    width: 330px;
    padding: 14px 26px 14px 13px;
    border-radius: 8px;
    border: 1px solid $gray-light-2;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    margin-bottom: 12px;

    .icon {
      font-size: 26px;

      &.success {
        color: $green;
      }
      &.warn {
        color: $yellow;
      }
      &.error {
        color: $red;
      }
    }

    .group {
      margin-left: 13px;
      margin-right: 8px;

      .content {
        font-size: 14px;
        line-height: 21px;
        color: $gray-dark;
      }

      .close-btn {
        position: absolute;
        top: 9px;
        right: 15px;
        cursor: pointer;
        color: $gray-2;
        font-size: 24px;

        &:hover {
          color: $gray-dark;
        }
      }
    }
  }
}

</style>


<template lang="pug">

.alerts(v-if="hasNotifications")
  transition-group(data-test="notification" name="fade", tag="div", mode="out-in")
    .alert.flex.vertical-center(
      v-for="{ message, key } in notifications.error",
      :key="key",
      role="alert"
    )
      .icon.error
        i.fas.fa-times-circle
      .group
        .content {{ message }}
        .close-btn(:aria-label="$t('close')", @click="dismissError(message)") ×

    .alert(
      v-for="{ message, key } in notifications.info",
      :key="key",
      role="alert"
    )
      .icon.success
        i.fas.fa-check-circle
      .group
        .content {{ message }}
        .close-btn(:aria-label="$t('close')", @click="dismissInfo(message)") ×

    .alert(
      v-for="{ message, key } in notifications.warning",
      :key="key",
      role="alert"
    )
      .icon.warn
        i.fas.fa-exclamation-circle
      .group
        .content {{ message }}
        .close-btn(:aria-label="$t('close')", @click="dismissWarning(message)") ×

</template>


<script>

// Vue
import { nextTick } from "vue"

const ERROR_DISMISS_TIMEOUT = 10000
const WARNING_DISMISS_TIMEOUT = 8000
const INFO_DISMISS_TIMEOUT = 5000

function dismissTimeoutFor(level) {
  switch (level) {
    case "error": {
      return ERROR_DISMISS_TIMEOUT
    }

    case "warning": {
      return WARNING_DISMISS_TIMEOUT
    }

    case "info":
    default: {
      return INFO_DISMISS_TIMEOUT
    }
  }
}

export default {
  name: "Notifications",

  emits: [],

  data() {
    return {
      i18nScope: "components.notifications",

      notifications: {
        error:   [],
        info:    [],
        warning: []
      }
    }
  },

  computed: {
    hasNotifications() {
      let { info, warning, error } = this.notifications
      return info.length > 0 || warning.length > 0 || error.length > 0
    }
  },

  created() {
    this.$events.on("notifications:clear", this.clear)
    this.$events.on("notifications:info", this.appendInfo)
    this.$events.on("notifications:error", this.appendError)
    this.$events.on("notifications:warning", this.appendWarning)
  },

  beforeUnmount() {
    this.$events.off("notifications:clear", this.clear)
    this.$events.off("notifications:info", this.appendInfo)
    this.$events.off("notifications:error", this.appendError)
    this.$events.off("notifications:warning", this.appendWarning)
  },

  methods: {
    append(level, { message, dismiss = true }) {
      let notifications = this.notifications[level]

      // Caso a notificação já exista, remova-a antes de adicionar uma "réplica"
      if (notifications.findIndex(notification => notification.message === message) !== -1) {
        this.dismiss(level, message)
      }

      // Para que seja "removível" corretamente, precisamos criar uma 'key'
      // baseada no tempo, para que o `v-for` use-a corretamente!
      let notification = {
        message,
        key:     `${message}-${Date.now()}`,
        timeout: null
      }

      if (dismiss) {
        notification.timeout = setTimeout(() => {
          this.dismiss(level, message)
        }, dismissTimeoutFor(level))
      }

      nextTick(() => {
        notifications.push(notification)
      })
    },

    appendError(...args) {
      this.append("error", ...args)
    },

    appendInfo(...args) {
      this.append("info", ...args)
    },

    appendWarning(...args) {
      this.append("warning", ...args)
    },

    clear() {
      Object.keys(this.notifications).forEach(level => {
        this.notifications[level] = []
      })
    },

    dismiss(level, message) {
      let notifications = this.notifications[level]
      let idx = notifications.findIndex(notification => notification.message === message)

      if (idx !== -1) {
        let notification = notifications[idx]
        if (notification.timeout) clearTimeout(notification.timeout)

        notifications.splice(idx, 1)
      }
    },

    dismissError(...args) {
      this.dismiss("error", ...args)
    },

    dismissInfo(...args) {
      this.dismiss("info", ...args)
    },

    dismissWarning(...args) {
      this.dismiss("warning", ...args)
    }
  }
}

</script>
