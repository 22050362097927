<style lang="scss" scoped>

.tickets-show {
  margin-block: 24px;
  grid-template-columns: 1fr 330px;

  p {
    margin: 0;
  }

  .two-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light-2;
  }

  .contract {
    padding: 24px;
    border-radius: 8px;
    background-color: $gray-light-4;
  }

  .drivers-outside {
    .icon {
      width: 16px;
      aspect-ratio: 1;
      font-size: 12px;
      color: $gray;
    }

    .drivers-title {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
    }

    .drivers-count {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }
}

.content-start {
  align-content: flex-start;
}

</style>


<template lang="pug">

.tickets-show.grid.gap-big.content-start(ref="page")
  .main.grid.gap-big
    ticket-show-header(:skeleton="initializing", :ticket="ticket")

    .divider

    .grid.gap-big.two-columns
      data-box-supplier(
        :skeleton="initializing",
        :supplier="supplier",
        :ticket-id="ticket.id",
        :hide-notifications="!ticket.canBeNotified"
      )

      .grid.gap-big
        appointment-data(
          :skeleton="initializing",
          :ticket="ticket"
        )

        data-box-requester(
          :skeleton="initializing",
          :requester="requester",
          :ticket-id="ticket.id",
          :contract-id="contract.id",
          :hide-notifications="!ticket.canBeNotified"
        )

    .contract.grid.gap-big
      ticket-contract-header(:skeleton="initializing", :contract="contract")

      .grid.gap-big.two-columns
        data-box-vehicle(:skeleton="initializing", :vehicle="vehicle")

        data-box-customer(:skeleton="initializing", :customer="customer")

      drivers-table(
        v-if="contract.id",
        :contract-id="contract.id",
        hide-create-button
      )
        template(#outside="{ count }")
          .drivers-outside.flex.vertical-center.gap-tiny
            .icon.flex.vertical-center.center
              i.fas.fa-steering-wheel

            p.drivers-title {{ $t(".drivers-outside.title") }}

            template(v-if="count")
              i.fas.fa-diamond.decoration
              p.drivers-count {{ $t(".drivers-outside.count", { count }) }}

    .grid.gap-regular
      tabs-selector(:tabs="tabs", v-model="selectedTab")

      transition-fade-slide(:reverse="selectedTab === tabTypes[1]")
        keep-alive
          issues-summary(
            v-if="selectedTab === tabTypes[0]"
            hide-outside,
            :ticket-id="ticketId"
          )

          messages-history(
            v-else-if="selectedTab === tabTypes[1]"
            :ticket-id="ticketId",
            :ticket-remote-id="ticket.remoteId"
          )

  .aside.grid.gap-big.content-start
    ticket-timeline(
      v-if="!timelineEmpty"
      ref="ticketTimeline"
      :ticket-id="ticket.id",
      @index="handleTimelineIndex"
    )

    .divider(v-if="!timelineEmpty && ticket.processedState !== 'canceled'")

    side-buttons(
      v-if="ticket.processedState && ticket.processedState !== 'canceled'",
      :disabled="fetching",
      :ticket="ticket",
      :show-booking-reschedule="ticket.canBeRescheduled",
      :show-booking-confirmation="ticket.isPrebooked",
      @cancellation="refreshPageData"
      @booking-confirmation="refreshPageData"
    )

</template>


<script>

// Components
import TicketShowHeader from "./_components/ticket-show-header"
import DataBoxSupplier from "./_components/data-box-supplier"
import DataBoxRequester from "./_components/data-box-requester"
import TicketContractHeader from "./_components/ticket-contract-header"
import TicketTimeline from "./_components/ticket-timeline"
import SideButtons from "./_components/side-buttons"
import MessagesHistory from "./_components/messages-history"
import TabsSelector from "../_components/tabs-selector"

import AppointmentData from "@/components/appointment-data"
import DataBoxCustomer from "@/components/data-box/customer"
import DataBoxVehicle from "@/components/data-box/vehicle"
import DriversTable from "@/components/drivers-table"
import IssuesSummary from "@/components/issues-summary"
import AppButton from "@/components/app-button/app-button.vue"

// Transitions
import TransitionFadeSlide from "@/transitions/transition-fade-slide"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Views
import LoadedView from "@/views/loaded-view.vue"

const tabTypes = ["services", "messages"]

export default {
  name: "TicketsShow",

  components: {
    TicketShowHeader,
    DataBoxSupplier,
    DataBoxRequester,
    TicketContractHeader,
    TicketTimeline,
    SideButtons,
    MessagesHistory,
    TabsSelector,

    AppointmentData,
    DataBoxCustomer,
    DataBoxVehicle,
    DriversTable,
    IssuesSummary,
    AppButton,

    TransitionFadeSlide
  },

  mixins: [FetchMixin],

  extends: LoadedView,

  data() {
    return {
      i18nScope:     "tickets-show",
      routeName:     "tickets-show",
      ticketId:      "",
      //
      ticket:        {},
      timelineEmpty: false,
      //
      selectedTab:   tabTypes[0],
      tabTypes
    }
  },

  computed: {
    contract() {
      return this.ticket?.contract || {}
    },

    customer() {
      return this.contract?.customer || {}
    },

    vehicle() {
      return this.contract?.vehicle || {}
    },

    supplier() {
      return this.ticket?.supplier || {}
    },

    requester() {
      return this.ticket?.requester || {}
    },

    tabs() {
      return tabTypes.map(type => ({
        value: type,
        label: this.$t(`.tabs.${type}`)
      }))
    }
  },

  mounted() {
    this.scrollToTop()
  },

  methods: {
    // @override LoadedView
    parseRoute() {
      this.ticketId = this.$route.params.id
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.show({ ticketId: this.ticketId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.ticket = data
    },

    scrollToTop() {
      this.$emit("request-scroll-top")
    },

    handleTimelineIndex(timeline) {
      this.timelineEmpty = timeline.length === 0
    },

    async refreshPageData() {
      this.timelineEmpty = false
      await this.$nextTick()

      this.fetch()

      if (this.$refs.ticketTimeline?.fetch) this.$refs.ticketTimeline.fetch()
    }
  }
}

</script>
