<style lang="scss" scoped>

.tickets-view {
  padding-block: 24px;

  .title {
    --tickets-title-vertical-spacing: 16px;

    border-bottom: 1px solid $gray-light-2;
    padding-bottom: var(--tickets-title-vertical-spacing);
    margin-bottom: var(--tickets-title-vertical-spacing);
    font-size: 1.5rem;

    span {
      font-size: 1.875rem;
      font-family: $secondary-font;
      font-weight: 500;
    }
  }

  .empty-list {
    padding: 16px;
    display: grid;
    justify-items: center;
    align-content: center;
    gap: 48px;
    border-top: 1px solid $gray-light-2;
    min-height: calc(100vh - 270px);

    p {
      margin: 0;
      text-align: center;
      color: $gray;
      font-size: 18px;
      max-width: 48ch;
    }
  }
}

</style>


<template lang="pug">

.tickets-view
  h1.title.flex.vertical-center.gap-regular
    i.fas.fa-list
    span {{ $t('.heading') }}

  tickets-search(
    :disabled="submitting",
    :query-data="$route.query"
    @search="handleSearch"
  )

  tickets-table(
    :tickets="tickets",
    :skeleton="!submitted",
    :loading="submitting && submitted",
    :pagination="pagination",
    :disabled-pagination="submitting || singlePage",
    show-pagination,
    enable-sorting,
    :header-sorting="sortQueryObject",
    @page-change="handlePageChange",
    @sort="handleSort",
    @cancellation="handleTicketCancellation"
    @booking-confirmation="handleBookingConfirmation"
  )
    template(#customEmpty)
      .empty-list
        img(:src="emptySearch", :alt="$t('.empty-list.alt')")
        p {{ $t('.empty-list.text') }}


</template>


<script>

// Assets
import emptySearch from "@/assets/images/list-illustrations/empty-search.svg"

// Components
import TicketsTable from "@/components/tickets-table"

import TicketsSearch from "./_components/tickets-search"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "TicketsView",

  components: {
    TicketsTable,
    TicketsSearch
  },

  mixins: [FormMixin],

  data() {
    return {
      i18nScope:  "tickets",
      emptySearch,
      tickets:    [],
      pagination: null
    }
  },

  computed: {
    resultEmpty() {
      return this.submitted && !this.tickets.length
    },

    singlePage() {
      if (!this.pagination) return false
      return this.pagination.total <= this.pagination.perPage
    },

    sortQueryObject() {
      const { sort_by: sort } = this.$route.query

      if (!sort) return {}

      const sortArray = sort.split(",").map(order => order.split(":"))

      return Object.fromEntries(sortArray)
    }
  },

  beforeRouteUpdate(to, from, next) {
    const query = _.cloneDeep(to.query)

    if (!query.timeframe) {
      this.$router.replace({ query: { ...query, timeframe: 30 } })
    }

    next()
  },

  watch: {
    $route(to) {
      if (to.name !== "tickets") return

      this.handleRouting(to.query)
    }
  },

  mounted() {
    this.handleRouting(this.$route.query)
  },

  methods: {
    handleRouting(query) {
      if (!Object.keys(query).length) {
        this.tickets = []
        this.submitted = true
        return
      }

      this.submit()
    },

    // @overide FormMixin
    submitRequest() {
      const { query } = this.$route
      return this.$sdk.tickets.index({ params: query })
    },

    // @overide FormMixin
    onSubmitSuccess({ data, headers }) {
      const { "x-per-page": perPage, "x-page": page, "x-total": total } = headers

      this.pagination = {
        perPage: Number(perPage),
        page:    Number(page),
        total:   Number(total)
      }

      this.tickets = data
    },

    handleSearch(query) {
      this.$router.push({ name: "tickets", query })
    },

    handlePageChange(page) {
      const query = _.cloneDeep(this.$route.query)

      if (page <= 1) {
        delete query.page
      }
      else {
        query.page = page
      }

      this.$router.push({ name: "tickets", query })
    },

    handleSort(property) {
      const query = _.cloneDeep(this.$route.query)

      const { sort_by: sort } = query

      if (!sort) {
        this.$router.push({
          name:  "tickets",
          query: {
            ...query,
            sort_by: [property, "asc"].join(":")
          }
        })

        return
      }

      const sortArray = sort.split(",")
        .map(order => order.split(":"))

      const propertyIndex = sortArray.findIndex(([category]) => category === property)

      if (propertyIndex < 0) {
        sortArray.push([property, "asc"])
      }
      else if (sortArray[propertyIndex][1] === "asc") {
        sortArray[propertyIndex][1] = "desc"
      }
      else if (sortArray[propertyIndex][1] === "desc") {
        sortArray.splice(propertyIndex, 1)
      }

      const newSort = sortArray
        .map(order => order.join(":"))
        .join(",")

      const newQuery = { ...query }

      if (newSort) newQuery.sort_by = newSort
      else delete newQuery.sort_by

      this.$router.push({
        name:  "tickets",
        query: newQuery
      })
    },

    async handleTicketCancellation({ successMessage }) {
      await this.submit()
      this.$notifications.info(successMessage)
    },

    async handleBookingConfirmation({ successMessage }) {
      await this.submit()
      this.$notifications.info(successMessage)
    }
  }
}

</script>
