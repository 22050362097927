// Base
import Base from "./Base"

// Models
import Ticket from "@/models/ticket/ticket"

class VehicleTicketRequests extends Base {
  static get selector() {
    return "vehicles.tickets"
  }

  index({ vehicleId, params = {}, options = {} } = {}) {
    this.verifyData("index", { vehicleId })

    return this.action({
      method:  "get",
      url:     `/vehicles/${vehicleId}/tickets`,
      params,
      options: {
        model: Ticket,

        ...options
      }
    })
  }
}

export default VehicleTicketRequests
