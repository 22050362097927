import Base from "../base/base"

class User extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "username",
      "name",
      "email"
    ])
  }

  get firstName() {
    if (_.blank(this.name)) return this.name
    return this.name.split(" ")[0]
  }
}

export default User
