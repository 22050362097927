// 3rd Party
import FloatingVue from "floating-vue"
import VueClickAway from "vue3-click-away"

// Stylesheets
import "floating-vue/dist/style.css"

// Internal
import { VueMaskDirective } from "@/directives/v-mask"
import { VueObserveVisibility } from "@/directives/v-observe-visibility"

const FloatingVueOptions = {
  distance: 0,
  skidding: 0,

  themes: {
    tooltip: {
      distance:  4,
      placement: "bottom"
    },

    dropdown: {
      disposeTimeout: null,
      placement:      "bottom"
    }
  }
}

const plugins = [
  { plugin: FloatingVue, options: FloatingVueOptions },
  { plugin: VueClickAway }
]

const directives = {
  mask: VueMaskDirective,

  "observe-visibility": VueObserveVisibility
}

export default function initialize(app) {
  plugins.forEach(({ plugin, options = {} }) => app.use(plugin, options))

  Object.keys(directives).forEach(key => { app.directive(key, directives[key]) })
}

export { plugins, directives }
