// Libs
import conf from "@/lib/conf"

// Modules
import { auth } from "@/modules/auth"
import { i18n } from "@/modules/i18n"
import { loading } from "@/modules/loading"
import { sdkPlugin, SdkClient } from "@/modules/sdk"

function getPluginOptions() {
  const { clientCredentials } = auth

  const sdkClient = new SdkClient({
    baseURL:             _.get(conf, "api.url"),
    locale:              i18n.locale,
    authorizationHeader: "Authorization",
    clientCredentials
  })

  const data = { count: 0 }

  sdkClient.addInterceptorRequest(
    config => {
      loading.start()
      data.count++

      return config
    },

    error => {
      loading.stop()
      data.count--

      return Promise.reject(error)
    }
  )

  sdkClient.addInterceptorResponse(
    response => {
      loading.stop()
      data.count--

      return response
    },

    error => {
      loading.stop()
      data.count--

      return Promise.reject(error)
    }
  )

  sdkClient.addInterceptorResponse(
    response => response, // success handler (empty)

    error => {
      const errorStatus = _.get(error, "response.status")
      const errorMessage = _.get(error, "response.data.message")

      const hasUnauthorizedAuthenticationGuardMessage = (
        errorMessage === "AuthenticationGuard::NotFoundError"
        || errorMessage === "AuthenticationGuard::UnauthorizedError"
      )

      const isUnauthorized = errorStatus === 401
      const hasInvalidToken = errorStatus === 404 && hasUnauthorizedAuthenticationGuardMessage

      if (isUnauthorized || hasInvalidToken) {
        // logout, emitting app event
        auth.logout()
      }

      return Promise.reject(error)
    }
  )

  return { sdkClient }
}

export default function initialize(app) {
  const pluginOptions = getPluginOptions()

  app.use(sdkPlugin, pluginOptions)
}

export { sdkPlugin }
