<style lang="scss" scoped>

.supplier-appointment-options-item {
  .name {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 400;
  }

  .address {
    font-style: normal;
    color: $gray-dark;
    font-size: 14px;
  }

  .details {
    margin-top: 8px;
    column-gap: 16px;
    row-gap: 8px;
  }

  .detail {
    gap: 4px;
    font-size: 14px;
    color: $gray;
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
  }

  .golden {
    display: inline-block;
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 500;
    color: $gray-dark;
    background-color: $yellow-light;
    border: 1px solid $yellow;
    border-radius: 24px;
    padding: 4px 8px;
  }

  .monospace {
    font-family: $primary-monospace-font;
    font-size: 14px;
    font-weight: 400;
  }
}

</style>


<template lang="pug">

.supplier-appointment-options-item
  .content
    .summary.flex.gap-small
      h3.name {{ supplier?.presentName }}
      img.grade(
        v-if="supplier.gradeIcon"
        :src="supplier.gradeIcon.src",
        :alt="supplier.gradeIcon.alt"
      )
      img.grade(
        v-if="supplier.movidaApp"
        :src="supplier.movidaAppLink.src",
        :alt="supplier.movidaAppLink.alt"
      )

    address.address(v-if="supplier?.address") {{ supplier.address.fullFormat }}

    .details.flex.vertical-center.wrap
      .detail.flex.vertical-center(v-if="distance")
        .icon.flex.vertical-center.center
          i.far.fa-location-dot
        span {{ distanceFormatted }}

      .detail.flex.vertical-center(v-if="supplier.cnpj")
        .icon.flex.vertical-center.center
          i.far.fa-building
        span.monospace(v-mask="'##.###.###/####-##'") {{ supplier.cnpj }}

      .detail.flex.vertical-center(v-if="sectorsText", v-tooltip.right="sectorsTooltip")
        .icon.flex.vertical-center.center
          i.far.fa-briefcase
        span {{ sectorsText }}

      .detail(v-if="supplier.dealership")
        span.golden {{ $t(".pre-booked") }}

    slot(name="bottom" v-bind="{ supplier }")

</template>


<script>

export default {
  name: "SupplierAppointmentOptionsItem",

  props: {
    supplier: { type: Object, default: () => ({}) },
    distance: { type: Number }
  },

  data() {
    return {
      i18nScope: "component.supplier-appointment-options-item"
    }
  },

  computed: {
    sectorsText() {
      if (!this.supplier?.sectors?.length) return ""

      let text = this.supplier.sectors[0].name

      const surplus = this.supplier.sectors.length - 1
      if (surplus > 0) text += ` +${surplus}`

      return text
    },

    sectorsTooltip() {
      if (this.supplier?.sectors?.length < 2) return ""

      return this.supplier.sectors
        .map(sector => sector.name)
        .join(", ")
    },

    distanceFormatted() {
      const numberOptions = { precision: 0 }
      let { distance } = this
      let unit = "meter"

      const KILOMETER = 1000
      const HUNDRED_KILOMETERS = 100000

      if (this.distance < KILOMETER) numberOptions.precision = 0

      if (this.distance >= KILOMETER) {
        distance = this.distance / KILOMETER
        unit = "kilometer"

        if (this.distance < HUNDRED_KILOMETERS) numberOptions.precision = 1
      }

      const numberFormatted = this.$asNumber(distance, numberOptions)

      return this.$t(`.distance.${unit}`, { number: numberFormatted })
    }
  }
}

</script>
