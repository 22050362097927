import Base from "../base/base"

class SupplierBusinessHour extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "weekday",
      "startAt",
      "endAt",
      "supplierId"
    ])
  }
}

export default SupplierBusinessHour
