<style lang="scss" scoped>

button {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: start;
  padding: 0;
  //
  display: inline-flex;
  gap: 9px;
  cursor: pointer;

  i {
    display: none;
    color: $orange;
  }

  &:hover {
    background-color: $gray-light-4;

    i{
      display: inline;
    }

    &:active {
      background-color: $orange-light-3;
    }
  }
}

</style>


<template lang="pug">

button(
  v-if="text",
  @click="copyText",
  v-tooltip="compoundTooltipOptions"
)
  slot
    span(v-mask="mask") {{ text }}

  i.far.fa-copy

</template>


<script>

export default {
  name: "AppCopyWrapper",

  props: {
    text:    { type: String },
    mask:    { type: String, default: "" },
    tooltip: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope:             "components.app-copy-wrapper",
      tooltipOpen:           false,
      timeout:               null,
      defaultTooltipOptions: {
        triggers:  [],
        placement: "right",
        delay:     0
      }
    }
  },

  computed: {
    compoundTooltipOptions() {
      return {
        content: this.$t(".tooltip"),
        ...this.defaultTooltipOptions,
        ...this.tooltip,
        shown:   this.tooltipOpen
      }
    }
  },

  methods: {
    copyText() {
      navigator.clipboard.writeText(this.text)

      this.showAndHideTooltip()
    },

    showAndHideTooltip() {
      this.resetTimeout()

      this.tooltipOpen = true
      this.timeout = setTimeout(() => {
        this.resetTimeout()
      }, 2000)
    },

    resetTimeout() {
      clearTimeout(this.timeout)
      this.tooltipOpen = false
    }
  }
}

</script>
