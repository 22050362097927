import nationalHolidays from "./national-holidays"

const nationalHolidaysSet = new Set(nationalHolidays)

/**
 *
 * @param {string} dateString
 * @returns {boolean}
 */
export function isDateStringHoliday(dateString) {
  return nationalHolidaysSet.has(dateString)
}
