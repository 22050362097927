<template lang="pug">

.service-targets-fields.grid.gap-big
  service-targets-fields-item(
    v-for="(target, i) of targets",
    :target="target",
    :key="i",
    :index="i",
    :parent-list="targets",
    :options="getOptions(i)"
    :can-add="i === targets.length - 1 && getOptions(i).length > 1"
    :disabled="disabled || i !== targets.length - 1"
    :errors="i === targets.length - 1 ? errorObject : {}"
    @add="addTarget"
    @remove="removeCurrentTarget"
  )

</template>


<script>

// Components
import ServiceTargetsFieldsItem from "./item"

// Models
import ServiceTarget from "@/models/service/service-target"

export default {
  name: "ServiceTargetsFields",

  components: {
    ServiceTargetsFieldsItem
  },

  props: {
    targets:  { type: Array },
    disabled: { type: Boolean, default: false },
    part:     { type: Object },
    errors:   { type: Object }
  },

  emits: ["update:targets"],

  data() {
    return {
      i18nScope: "components.service-targets-fields"
    }
  },

  computed: {
    targetsOptions() {
      return this.part.positionsAndLocations
    },

    errorObject() {
      return this.errors
        ? Object.values(this.errors)[0]
        : {}
    }
  },

  methods: {
    addTarget() {
      const newTargetsArray = [
        ...this.targets,
        new ServiceTarget({ position: "", location: "" })
      ]

      this.$emit("update:targets", newTargetsArray)
    },

    removeCurrentTarget(index) {
      const newTargetsArray = this.targets.filter((_, idx) => idx !== index)
      this.$emit("update:targets", newTargetsArray)
    },

    getOptions(index) {
      const selectedTargets = this.targets.filter((target, idx) => idx !== index)

      return this.targetsOptions.filter(option => {
        const targetNotSelected = !selectedTargets.some(({ position, location }) => option.position.value === position && option.location.value === location)
        return targetNotSelected
      })
    }
  }
}

</script>
