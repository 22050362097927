// Base
import Base from "./Base"

// Models
import Booking from "@/models/booking/booking"

class TicketBookingRequests extends Base {
  static get selector() {
    return "tickets.booking"
  }

  show({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("show", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/booking`,
      params,
      options: {
        model: Booking,

        ...options
      }
    })
  }

  create({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("create", { ticketId })

    return this.action({
      method:  "post",
      url:     `/tickets/${ticketId}/booking`,
      params,
      options: {
        model: Booking,

        ...options
      }
    })
  }

  delete({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("delete", { ticketId })

    return this.action({
      method:  "delete",
      url:     `/tickets/${ticketId}/booking`,
      params,
      options: {
        model: Booking,

        ...options
      }
    })
  }
}

export default TicketBookingRequests
