import Base from "../base/base"

class Params extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "description",
      "keyValue",
      "validationRules",
      "content"
    ])
  }
}

export default Params
