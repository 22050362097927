const TIMEOUT = 5000 // tempo máximo para o carregamento da biblioteca
const VERIFY_INTERVAL = 100

const GoogleMapsLoader = {
  loadPromise: null,
  google:      null,
  key:         null,

  load({ language = "en", libraries = "places" } = {}) {
    // loaded?
    if (this.google) {
      return Promise.resolve(this.google)
    }

    // loading?
    if (this.loadPromise) {
      return this.loadPromise
    }

    this.loadPromise = new Promise((resolve, reject) => {

      // A maneira de "carregar" a lib do Google Maps é via <script> tag.
      // Então vamos carregá-la com `async` e `defer`, para maior fluidez no
      // carregamento da app.
      // Ainda, definimos um callback global `__onGoogleMapsLoaded`, que será
      // definido ainda neste módulo.
      let scriptTag = document.createElement("script")
      scriptTag.setAttribute("async", "true")
      scriptTag.setAttribute("defer", "true")

      scriptTag.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=${this.key}&language=${language}&libraries=${libraries}&callback=__onGoogleMapsLoaded`
      )

      document.body.appendChild(scriptTag)


      let failureTimeout = setTimeout(() => {
        console.warn("[google-maps-loader] Não foi possível carregar a lib 'google' (timeout 5s)")
        reject(new Error(`[google-maps-loader] Could not load 'google' library (timeout 5s)`))
      }, TIMEOUT)

      let loadInterval = setInterval(() => {
        if (this.google) {
          clearInterval(loadInterval)
          clearTimeout(failureTimeout)

          resolve(this.google)
        }
      }, VERIFY_INTERVAL)

    })

    return this.loadPromise
  }
}


// Definindo o callback global `__onGoogleMapsLoaded` para o carregamento do
// Google Maps
/* eslint-disable-next-line func-names */
window.__onGoogleMapsLoaded = function () {
  /* eslint-disable-next-line no-undef */
  GoogleMapsLoader.google = google
}


export default GoogleMapsLoader
