<style lang="scss" scoped>

.tag-inline-ticket-state{
  .icon {
    width: 20px;
    aspect-ratio: 1;
  }

  .status {
    color: $gray-dark;
  }
}

</style>


<template lang="pug">

tag-inline(
  v-if="isStatusMapped"
  weight="bold",
  text-colored,
  :color="statusColor"
)
  .tag-inline-ticket-state.flex.gap-tiny.vertical-center
    .icon.flex.center.vertical-center
      i(:class="statusIconClasses")
    span.status {{ ticket.processedStateTranslated || "" }}

</template>


<script>

// Components
import TagInline from ".."

const statusConfigMap = new Map([
  [
    "draft",
    {
      color:       "gray",
      iconClasses: ["fas fa-pencil"]
    }
  ],
  [
    "pre_booked",
    {
      color:       "yellow",
      iconClasses: ["fas fa-calendar-clock"]
    }
  ],
  [
    "booked",
    {
      color:       "green",
      iconClasses: ["fas fa-calendar-check"]
    }
  ],
  [
    "ongoing",
    {
      color:       "green",
      iconClasses: ["fas fa-screwdriver-wrench"]
    }
  ],
  [
    "done",
    {
      color:       "green",
      iconClasses: ["fas fa-check"]
    }
  ],
  [
    "canceled",
    {
      color:       "red",
      iconClasses: ["fas fa-calendar-xmark"]
    }
  ]
])

export default {
  name: "TagInlineTicketStatus",

  props: {
    ticket: { type: Object }
  },

  components: {
    TagInline
  },

  data() {
    return {
      i18nScope: "components.tag-inline-ticket-state"
    }
  },

  computed: {
    isStatusMapped() {
      return statusConfigMap.has(this.ticket.processedState)
    },

    statusIconClasses() {
      if (!this.isStatusMapped) return ""

      return statusConfigMap.get(this.ticket.processedState).iconClasses
    },

    statusColor() {
      if (!this.isStatusMapped) return ""

      return statusConfigMap.get(this.ticket.processedState).color
    }
  }
}

</script>
