<template lang="pug">

select-field(
  :mandatory="mandatory",
  v-model="model",
  :name="name",
  :options="optionsFormatted",
  :placeholder="placeholder",
  :disabled="disabled || emptyDependency",
  :errors="errors",
  :fetching="searched && fetching"
  searchable,
  :default-filter="false"
  :hide-selected="hideSelected"
  @search="handleSearch"
  @close="clearQuery",
)

</template>


<script>

// Components
import SelectField from "@/components/select-field/select-field.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {

  name: "ServiceFetchSelect",

  components: {
    SelectField
  },

  emits: [
    "update:modelValue",
    "fetching",
    "fetched",
    "select-content"
  ],

  mixins: [FetchMixin],

  props: {
    name:        { type: String },
    placeholder: { type: String },
    modelValue:  { type: String },
    errors:      { type: Array },
    dependent:   { type: Boolean, default: false },
    dependency:  { type: String },
    disabled:    { type: Boolean, default: false },
    mandatory:   { type: Boolean, default: false },
    fetchParams: { type: Object, default: () => ({}) },
    ticketId:    { type: [String, Number] },
    kind:        {
      type:     String,
      required: true,
      validator(val) {
        return ["parts", "services", "symptoms"].includes(val)
      }
    }
  },

  data() {
    return {
      i18nScope:         "components.service-fetch-select",
      options:           [],
      searchedOptions:   [],
      //
      searched:          false,
      query:             "",
      //
      // @override FetchMixin
      autofetch:         false,
      tempCurrentOption: null
    }
  },

  computed: {
    model: {
      get() {
        return this.modelValue
      },

      set(val) {
        this.$emit("update:modelValue", val)
        this.emitContent(val)
      }
    },

    currentlySelectedIsWithinResults() {
      if (!this.tempCurrentOption) return false

      return this.searchedOptions.some(({ id }) => id === this.tempCurrentOption.id)
    },

    currentlySelectedIsWithinInitialList() {
      if (!this.tempCurrentOption) return false

      return this.options.some(({ id }) => id === this.tempCurrentOption.id)
    },

    hideSelected() {
      if (!this.query) return false
      return !this.currentlySelectedIsWithinResults || (this.searched && this.searchedOptions.length === 0)
    },

    relevantOptions() {
      if (!this.searched || !this.query) {
        if (!this.tempCurrentOption) return this.options

        const resultingOptions = [
          this.tempCurrentOption,
          ...this.options.filter(({ id }) => id !== this.tempCurrentOption?.id)
        ]

        return resultingOptions
      }

      const searched = [...this.searchedOptions]

      if (!this.currentlySelectedIsWithinResults && this.tempCurrentOption) {
        searched.push(this.tempCurrentOption)
      }

      return searched
    },

    optionsFormatted() {
      return this.relevantOptions.map(this.formatOptions)
    },

    params() {
      const result = { ...this.fetchParams }

      if (this.query) result.q = this.query

      return result
    },

    emptyDependency() {
      return this.dependent && !this.dependency
    }
  },

  mounted() {
    if (!this.dependent) this.fetch()
  },

  watch: {
    async dependency(to, from) {
      if (!this.dependent) return

      this.$emit("update:modelValue", null)

      await this.$nextTick()
      this.emitContent()

      this.options = []

      if (to) this.fetch()
    },

    fetching(to) {
      if (to) this.$emit("fetching")
      else this.$emit("fetched")
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.available.index({
        ticketId: this.ticketId,
        kind:     this.kind,
        params:   this.params
      })
    },

    // @override FetchMixin
    onFetchSuccess({ data, headers }) {
      this.searchedOptions = data
      if (this.searched) return
      this.options = data
    },

    checkRelevantProps() {
      const relevantProps = [this.ticketId, this.kind]

      if (!relevantProps.every(Boolean)) throw new Error(`'service-fetch-select': missing relevant props`)
    },

    formatOptions({ id, name }) {
      return ({
        value: id,
        label: name
      })
    },

    handleSearch({ term, el }) {
      this.searched = true
      this.debouncedSearchHandling(term, el)
    },

    debouncedSearchHandling: _.debounce(
      /* eslint-disable-next-line func-names */
      async function (query, el) {
        await this.applyQueryToParamsAndFetch(query)

        /* eslint-disable-next-line no-unused-expressions */
        el?.focus()
      },
      1000
    ),

    async applyQueryToParamsAndFetch(query) {
      this.query = query

      if (!this.query) {
        this.searchedOptions = this.options
      }
      else {
        await this.fetch()
      }
    },

    clearQuery() {
      this.searched = false
      this.query = ""
      this.searchedOptions = []
    },

    emitContent(value) {
      if (!value) {
        this.$emit("select-content", null)
        this.tempCurrentOption = null
        return
      }

      const content = this.searched
        ? this.searchedOptions.find(({ id }) => id === value)
        : this.options.find(({ id }) => id === value)

      this.$emit("select-content", content)
      this.tempCurrentOption = content
    }
  }
}

</script>
