<style scoped lang="scss">
</style>


<template lang="pug">

.blank-layout
  router-view

</template>


<script>

export default {
  name: "BlankLayout"
}

</script>
