<style lang="scss" scoped>

.fake-input {
  span {
    display: inline-block;
    margin-bottom: 8px;
    color: $gray-dark;
    line-height: 17px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
  }

  p {
    cursor: not-allowed;
    background-color: $gray-light-2;
    color: $gray-2;
    border: 1px solid $gray-light;
    border-radius: 4px;
    padding-block: 1px;
    padding-inline: 13px;
    margin: 0;
    height: 40px;
    line-height:38px;
    font-weight: 400;
  }
}

</style>


<template lang="pug">

.fake-input
  span.label {{ label }}

  p(v-mask="mask") {{ value }}

</template>


<script>

export default {
  name: "FakeInput",

  props: {
    label: { type: String },
    value: { type: String },
    mask:  { type: String, default: "" }
  }
}

</script>
