<style lang="scss" scoped>

.tickets-draft-confirmation.grid {
  grid-template-columns: 1fr 330px;
  gap: 56px;

  p {
    margin: 0;
  }

  .main {
    align-content: flex-start;
  }

  .title {
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: 400;
  }

  .divider {
    min-height: 1px;
    min-width: 1px;
    background-color: $gray-light-2;
  }

  .aside {
    align-content: flex-start;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }

  .checkbox-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline-start: 8px;
  }
}

</style>


<template lang="pug">

.tickets-draft-confirmation.grid
  .main.grid.gap-big
    h2.title {{ $t('.title') }}

    appointment-summary(
      :ticket-id="ticketId",
      :disabled="isLoading",
      @edit-schedule="goToSupplierSelection",
      @edit-supplier="goToSupplierSelection",
      @show="handleAppointmentSummaryShow"
    )

    issues-summary(
      :ticket-id="ticketId",
      :disabled="isLoading",
      @edit="goToServiceSelection"
    )

    .field.grid.gap-small
      .field-header.flex.gap-tiny.vertical-center
        .field-icon.flex.center.vertical-center
          i.fas.fa-message

        span.grow {{ $t(".fields.chatDescription.label") }}

      textarea-field(
        v-model="resource.chatDescription",
        hide-label,
        name="draftConfirmation[chatDescription]",
        id="draftConfirmation[chatDescription]"
        :placeholder="$t('.fields.chatDescription.placeholder')",
        :errors="errors.chatDescription",
        :disabled="ticketConfirmationLoading",
        :maxlength="1_000_000_000"
      )

      checkbox-field(
        name="draftConfirmation[customerInteraction]",
        v-model="resource.customerInteraction",
        :value-text="$t('.fields.customerInteraction.label')"
        :errors="errors.customerInteraction",
        :disabled="ticketConfirmationLoading",
      )
        template(#optionText="{ props: { inputId } }")
          label(:for="inputId")
            span.checkbox-text {{ $t('.fields.customerInteraction.label') }}

  .aside.grid.gap-big
    service-cart(
      without-delete,
      :ticket-id="ticketId",
      :disabled="leaving || ticketConfirmationLoading"
    )
      template(#footer="{ disabled: cartDisabled }")
        .cart-complement.grid.gap-big
          requester-contact(
            ref="requesterContact"
            :skeleton="parentLoading",
            :requester="requester",
            without-edit,
            @show-edit="openModal('edit-requester')"
          )
            //- template(#footer)
            //-   .select-channels.grid.gap-tiny
            //-     channels-selector(
            //-       v-model="resource.channels",
            //-       :disabled="cartDisabled"
            //-     )

            //-     p.error-message(v-if="errors.channels?.length") {{ errors.channels[0] }}

          .divider

          app-button(
            full-width,
            :disabled="cartDisabled",
            @click="openModal('checklist')"
          )
            .flex.center.vertical-center.gap-small
              span {{ $t('.cart-button') }}
              i.fas.fa-check

    .divider

    auxiliar-buttons(
      :ticket="ticket",
      @cancellation="handleCancellation",
      @leaving-start="leaving = true",
      @leaving-end="leaving = false"
    )

  app-modal(
    close-button,
    avoid-close-on-click-outside,
    :heading="$t(`.modal.title.${selectedModal}`)",
    :width="modalWidth",
    :show="modalShown",
    @close="hideModal"
  )
    template(#default)
      requester-form-edit(
        v-if="selectedModal === 'edit-requester'"
        :requester="requester",
        @cancel="hideModal",
        @update="handleRequesterUpdate"
      )

      checklist-modal(
        v-if="selectedModal === 'checklist'",
        :ticket="ticket",
        :booking="supplierBooking"
        @close="hideModal",
        @complete="handleChecklistComplete"
      )

</template>


<script>

// Components
import ServiceCart from "@/components/service-cart"
import AppButton from "@/components/app-button/app-button.vue"
import AppModal from "@/components/app-modal"
import RequesterFormEdit from "@/components/requester-form-edit"
import IssuesSummary from "@/components/issues-summary"
import TextareaField from "@/components/textarea-field/textarea-field.vue"
import CheckboxField from "@/components/checkbox-field/checkbox-field.vue"

import RequesterContact from "../../_components/requester-contact"
import ChannelsSelector from "../../_components/channels-selector"
import AuxiliarButtons from "../../_components/auxiliar-buttons"
import AppointmentSummary from "./_components/appointment-summary"
import ChecklistModal from "./_components/checklist-modal"

export default {
  name: "TicketsDraftConfirmation",

  components: {
    ServiceCart,
    AppButton,
    AppModal,
    RequesterFormEdit,
    IssuesSummary,
    TextareaField,
    CheckboxField,

    RequesterContact,
    ChannelsSelector,
    AuxiliarButtons,
    AppointmentSummary,
    ChecklistModal
  },

  props: {
    ticketId:      { type: [String, Number] },
    vehicle:       { type: Object, default: () => ({}) },
    ticket:        { type: Object, default: () => ({}) },
    parentLoading: { type: Boolean, default: false }
  },

  emits: ["update-ticket"],

  data() {
    return {
      i18nScope:                 "tickets-draft-confirmation",
      modalShown:                false,
      selectedModal:             "",
      leaving:                   false,
      ticketConfirmationLoading: false,
      resource:                  {
        channels:            ["email"],
        chatDescription:     "",
        customerInteraction: false
      },
      errors:          {},
      supplierBooking: null
    }
  },

  computed: {
    requester() {
      return this.ticket?.requester ?? {}
    },

    hasErrors() {
      return Object.entries(this.errors).length > 0
    },

    modalWidth() {
      return this.selectedModal === "checklist"
        ? 914
        : 690
    },

    isLoading() {
      return [
        this.parentLoading,
        this.leaving,
        this.ticketConfirmationLoading
      ].some(Boolean)
    }
  },

  methods: {
    handleRequesterUpdate({ requester }) {
      this.$emit("update-ticket")
      this.hideModal()

      this.$notifications.info(
        this.$t(".requester.update", { requester: requester.name })
      )
    },

    validate() {
      this.errors = {}

      if (!this.resource.channels.length) {
        this.errors.channels = [this.$t(".fields.channels.validation.presence")]
      }
    },

    getRecipientsParams() {
      const { channels, chatDescription, customerInteraction } = _.cloneDeep(this.resource)

      return _.snakeizeKeys({
        chatDescription,
        customerInteraction,
        recipients: [
          {
            personId: this.requester.personId,
            channels
          }
        ]
      })
    },

    async submitConfirmation() {
      this.validate()

      if (this.hasErrors) {
        if (this.$refs.requesterContact?.toggleOpening) {
          this.$refs.requesterContact.toggleOpening(false)
        }
        return
      }

      const params = this.getRecipientsParams()

      try {
        this.ticketConfirmationLoading = true

        await this.$sdk.tickets.publication.create({ ticketId: this.ticketId, params })

        this.$router.push({
          name:   "tickets-end",
          params: { id: this.ticket.id }
        })
      }
      catch (err) {
        console.error(err)

        const error = err.error || err

        this.chooseErrorToastMessage(error)
      }
      finally {
        this.ticketConfirmationLoading = false
      }
    },

    chooseErrorToastMessage(error) {
      let errorTranslationTitle = "generic"

      if (this.isErrorFromMissingSapId(error)) {
        errorTranslationTitle = "missing-sap"
      }

      this.$notifications.error(this.$t(`.notifications.submit.error.${errorTranslationTitle}`))
    },

    isErrorFromMissingSapId(error) {
      //
      // XXX: Capturar caso de erro apresentado em https://github.com/caiena/movida-gac/issues/424
      //
      const isThisErrorCase = _.chain(error)
        .get("originalError.response.data.remote_ticket")
        .map(remoteTicket => _.get(remoteTicket, "errors.errors"))
        .find(errorType => errorType.remote_service_order)
        .get("remote_service_order")
        .map(remoteServiceError => remoteServiceError.errors)
        .some(errorItem => typeof errorItem.message === "string" && errorItem.message.includes("`CodigoSAP` = ?"))
        .value()

      return isThisErrorCase
    },

    openModal(type) {
      if (!["edit-requester", "checklist"].includes(type)) throw new Error("Modal type not found")

      if (type === "checklist") {
        this.validate()

        if (this.hasErrors) return
      }

      this.selectedModal = type
      this.modalShown = true
    },

    hideModal() {
      this.modalShown = false
      this.selectedModal = ""
    },

    handleChecklistComplete() {
      this.hideModal()
      this.submitConfirmation()
    },

    goToSupplierSelection() {
      this.$router.push({ name: "tickets-draft.supplier", params: { id: this.ticketId } })
    },

    goToServiceSelection() {
      this.$router.push({ name: "tickets-draft.service", params: { id: this.ticketId } })
    },

    async handleCancellation({ successMessage }) {
      await this.$router.push({ name: "tickets-show", param: { id: this.ticketId } })
      this.$notifications.info(successMessage)
    },

    handleAppointmentSummaryShow({ booking }) {
      this.supplierBooking = booking
    }
  }
}

</script>
