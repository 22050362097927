<style lang="scss" scoped>

.app-table {
  .skeleton-table {
    margin-top: 16px;
  }

  .empty {
    margin: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .footer {
    background-color: $gray-light-4;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 24px;
    min-height: 8px;
  }
}

</style>


<template lang="pug">

.app-table
  template(v-if="initializing")
    .skeleton-table
      slot(name="skeleton")
        app-table-skeleton(
          :cols-count="colsCount",
          :column-sizes="columnSizes"
          :grid-gap="skeletonGap"
        )

  template(v-else)
    slot(name="header")

    template(v-if="empty")
      slot(name="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

    template(v-else)
      .rows
        slot(name="rows")

    .footer(v-if="footer")
      slot(name="footer")

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/list-illustrations/no-results.svg"

// Components
import AppTableSkeleton from "./app-table-skeleton/app-table-skeleton.vue"

export default {
  name: "AppTable",

  emits: [],

  components: {
    AppTableSkeleton
  },

  props: {
    empty:        { type: Boolean, required: true },
    initializing: { type: Boolean, default: false },
    colsCount:    { type: Number, required: true },
    columnSizes:  { type: String, required: true },
    footer:       { type: Boolean, default: true },
    skeletonGap:  { type: String }
  },

  data() {
    return {
      i18nScope: "components.app-table",
      emptyIcon
    }
  }
}

</script>
