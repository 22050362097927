<style lang="scss" scoped>

.draft-existence-warning {
  outline: 1ox solid red;

  p {
    margin: 0;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .message {
    padding: 16px;
    border: 1px solid $gray-light-2;
  }

  .details {
    font-size: 12px;
    font-weight: 400;
  }

  .button-text {
    font-size: 14px;
    font-weight: 400;
  }

  .alert {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid $orange;
    background-color: $orange-light-3;
    font-size: 12px;
  }

  .icon {
    width: 32px;
    aspect-ratio: 1;
    color: $orange;
    flex-shrink: 0;
    font-size: 20px;
  }
}

</style>


<template lang="pug">

.draft-existence-warning.grid.gap-regular
  .message.grid.gap-regular
    p.title {{ $t('.title') }}

    .details
      p
        span {{ $t(".ticket") }}
        id-tag(:value="ticket.remoteId")
      p {{ $t(".started-at", { dateTime }) }}
      p  {{ $t(".user", { username: ticket.user.name }) }}

    app-button(
      theme="link",
      @click="$emit('continue', ticket)"
    )
      p.button-text.text-center {{ $t('.button') }}

  .alert.flex.gap-regular.vertical-center
    .icon.flex.center.vertical-center
      i.fal.fa-triangle-exclamation
    p {{ $t('.alert') }}

</template>


<script>

import AppButton from "@/components/app-button/app-button.vue"
import IdTag from "@/components/id-tag/id-tag.vue"

export default {
  name: "DraftExistenceWarning",

  components: {
    AppButton,
    IdTag
  },

  props: {
    ticket: { type: Object }
  },

  emits: ["continue"],

  data() {
    return  {
      i18nScope: "components.draft-existence-warning"
    }
  },

  computed: {
    dateTime() {
      return [
        this.$l("date.formats.default", this.ticket.openingDate),
        this.$l("time.formats.hourAndMinutes", this.ticket.openingDate)
      ].join(" ")
    }
  }
}

</script>
