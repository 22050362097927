<style lang="scss" scoped>

.modal-body {
  padding: 16px 24px;
}

#viewport {
  width: 100%;
  height: 100vh;
  position: relative;
}

.layout {
  height:100%;
}

</style>


<template lang="pug">

loading
notifications

transition(name="outdated-down")
  outdated-indicator(v-if="outdated", @refresh="refresh")

app-modal(
  close-button,
  footer,
  :show="showUpdateModal",
  :heading="$t('updateVersion.title')",
  :width="540",
  :confirm-label="$t('btn.update.label')",
  @close="cancelUpdate",
  @cancel="cancelUpdate",
  @confirm="refresh"
)
  .update-modal.modal-body
    span.text {{ $t('updateVersion.text') }}

#viewport
  component.layout(
    :is="layoutTemplate"
  )

</template>


<script>

// Layouts
import DefaultLayout from "@/views/_layouts/default"
import BlankLayout from "@/views/_layouts/blank"

// Components
import Notifications from "@/components/notifications/notifications.vue"
import Loading from "@/components/loading/loading.vue"
import OutdatedIndicator from "@/components/outdated-indicator/outdated-indicator.vue"
import AppModal from "@/components/app-modal"

export default {
  name: "App",

  emits: [],

  components: {
    DefaultLayout,
    BlankLayout,
    Notifications,
    Loading,
    OutdatedIndicator,
    AppModal
  },

  data() {
    return {
      showUpdateModal: false,
      outdated:        false
    }
  },

  computed: {
    layoutTemplate() {
      const layout = _.get(this.$route, "meta.layout") || "default"

      return `${layout}-layout`
    }
  },

  watch: {
    $route(to, from) {
      this.$notifications.clear()
    }
  },

  created() {
    this.$events.on("app:update-version", () => {
      this.showUpdateModal = true
    })
  },

  beforeUnmount() {
    this.$events.off("app:update-version")
  },

  methods: {
    refresh() {
      window.location.reload(true)
    },

    cancelUpdate() {
      this.showUpdateModal = false
      this.outdated = true
    }
  }
}

</script>
