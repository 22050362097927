// Base
import Base from "./Base"

// Models
import Part from "@/models/part/part"
import Service from "@/models/service/service"
import Symptom from "@/models/symptom/symptom"

class TicketAvailableRequests extends Base {
  static get selector() {
    return "tickets.available"
  }

  index({ ticketId, kind, params = {}, options = {} } = {}) {
    this.verifyData("index", { ticketId, kind })

    const modelMap = new Map([
      ["parts", Part],
      ["services", Service],
      ["symptoms", Symptom]
    ])

    if (!modelMap.has(kind)) throw new Error(`SDK ${this.constructor.name} - #index() - "kind" value is invalid`)

    return this.action({
      method:  "post",
      url:     `/tickets/${ticketId}/available_${kind}/search`,
      params,
      options: {
        model: modelMap.get(kind),

        ...options
      }
    })
  }
}

export default TicketAvailableRequests
