// Base
import Base from "./Base"

// Models
import BaseModel from "@/models/base/base"

class TickeSinistertRequests extends Base {
  static get selector() {
    return "tickets.sinister"
  }

  create({ ticketId, params = {}, options = {} } = {}) {
    this.verifyData("create", { ticketId })

    return this.action({
      method:  "post",
      url:     `/tickets/${ticketId}/sinister`,
      params,
      options: {
        model: BaseModel,

        ...options
      }
    }).then(response => {
      response.data = {}

      return response
    }).catch(err => {
      err.data = {}

      return err
    })
  }
}

export default TickeSinistertRequests
