<template lang="pug">

transition(
  :duration="duration"
  @before-enter="onBeforeEnter",
  @enter="onEnter",
  @after-enter="onAfterEnter",
  @before-leave="onBeforeLeave",
  @leave="onLeave",
  @after-leave="onAfterLeave",
)
  slot

</template>


<script>

export default {
  name: "TransitionHorizontalFold",

  props: {
    customWidth:   { type: String },
    layoutSpacing: { type: String }
  },

  data() {
    return {
      duration:     300,
      enterTimeout: null,
      leaveTimeout: null
    }
  },

  computed: {

    animationMap() {
      const map = new Map([
        ["overflowX", "hidden"],
        ["whiteSpace", "nowrap"],
        ["transition", `width ${this.duration}ms ease`],
        ["width", "0px"]
      ])

      if (this.layoutSpacing) {
        const transition = map.get("transition")

        map.set("margin-left", `-${this.layoutSpacing}`)
        map.set("transition", [
          transition,
          `margin-left ${this.duration}ms ease`
        ].join(", "))
      }

      return map
    }
  },

  methods: {
    setCommonStyles(el) {
      [...this.animationMap.entries()].forEach(([key, value]) => {
        el.style[key] = value
      })
    },

    clearCommonStyles(el) {
      [...this.animationMap.keys()].forEach(key => {
        el.style[key] = null
      })
    },

    setLeftStyles(el) {
      el.style.width = "0px"
      if (this.layoutSpacing) el.style.marginLeft = `-${this.layoutSpacing}`
    },

    setEnteredStyles(el) {
      el.style.width = this.customWidth || `${el.scrollWidth}px`
      if (this.layoutSpacing)  el.style.marginLeft = "0px"
    },

    onBeforeEnter(el) {
      this.setLeftStyles(el)

      this.setCommonStyles(el)
    },

    onEnter(el, done) {
      clearTimeout(this.leaveTimeout)

      setTimeout(() => {
        this.setEnteredStyles(el)
      }, 10)

      this.enterTimeout = setTimeout(() => done(), this.duration + 1)
    },

    onAfterEnter(el) {
      clearTimeout(this.leaveTimeout)
      this.clearCommonStyles(el)
    },

    onBeforeLeave(el) {
      this.setEnteredStyles(el)

      this.setCommonStyles(el)
    },

    onLeave(el, done) {
      clearTimeout(this.leaveTimeout)

      this.setLeftStyles(el)

      this.leaveTimeout = setTimeout(() => done(), this.duration + 1)
    },

    onAfterLeave(el) {
      clearTimeout(this.leaveTimeout)
      this.clearCommonStyles(el)
    }
  }
}

</script>
