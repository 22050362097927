<style lang="scss" scoped>

.messages-history-create-modal {
  p {
    margin: 0;
  }

  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-block-start: 1px solid $gray-light-2;
    }
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }

  .checkbox-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline-start: 8px;
  }
}

</style>


<template lang="pug">

.messages-history-create-modal
  .section
    p
      span {{ $t('.description') }}
      id-tag(:value="ticketRemoteId")

  .section.grid.gap-small
    .field-header.flex.gap-tiny.vertical-center
      .field-icon.flex.center.vertical-center
        i.fas.fa-message

      span.grow {{ $t(".fields.message.label") }}

    textarea-field(
      v-model="resource.message",
      hide-label,
      name="draftMessage[message]",
      id="draftMessage[message]",
      :placeholder="$t('.fields.message.placeholder')",
      :errors="errors.message"
      :disabled="submitting",
    )

    checkbox-field(
      name="draftMessage[customerInteraction]",
      v-model="resource.customerInteraction",
      :value-text="$t('.fields.customerInteraction.label')"
      :errors="errors.customerInteraction",
      :disabled="submitting",
    )
      template(#optionText="{ props: { inputId } }")
        label(:for="inputId")
          span.checkbox-text {{ $t('.fields.customerInteraction.label') }}

  .section.flex.space-between
    app-button(
      outline,
      theme="gray",
      @click="$emit('close')"
    ) {{ $t(".button.cancel") }}

    app-button(
      :disabled="submitting",
      @click="submit",
    ) {{ $t(".button.confirm") }}

</template>


<script>

// Components
import TextareaField from "@/components/textarea-field/textarea-field.vue"
import CheckboxField from "@/components/checkbox-field/checkbox-field.vue"
import AppButton from "@/components/app-button/app-button.vue"
import IdTag from "@/components/id-tag/id-tag.vue"
// Mixins
import FormMixin from "@/mixins/form-mixin"

// Models
import Base from "@/models/base/base"

class DraftMessage extends Base {
  static get attrs() {
    return ["message", "customerInteraction"]
  }

  static get constraints() {
    return {
      message: { presence: true }
    }
  }
}

export default {
  name: "MessagesHistoryCreateModal",

  components: {
    TextareaField,
    CheckboxField,
    AppButton,
    IdTag
  },

  mixins: [FormMixin],

  props: {
    ticketId:       { type: [String, Number] },
    ticketRemoteId: { type: [String, Number] }
  },

  data() {
    return {
      i18nScope: "components.messages-history-create-modal",
      resource:  new DraftMessage()
    }
  },

  emits: ["create", "close"],

  methods: {
    // @override DirtyableMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override FormMixin
    submitRequest() {
      const params = this.serializeAttributes()

      return this.$sdk.tickets.messages.create({
        ticketId: this.ticketId,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("create", { ...this.resource })

      this.$notifications.info(this.$t(`.notifications.submit.success`))
    }
  }
}

</script>
