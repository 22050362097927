<style lang="scss" scoped>

.multiple-select-field {
  &.selected {
    ::v-deep .placeholder {
      color: $gray-dark;
    }
  }

  .selected-items {
    padding: 8px;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid $gray-light-3;

    .link {
      font-size: 14px;
      font-weight: 400;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
      margin-left: 8px;
    }
  }

  .option {
    min-height: 40px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-light-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $gray-light-2;
    }

    &.focus {
      background: $gray-light-2;
      color: $primary-dark;

      .remove-icon-content {
        background: $gray-light-3;
        color: $primary;
      }
    }
  }
}

</style>


<template lang="pug">

.multiple-select-field(:class="{ selected: hasSelected }")
  select-field(
    :close-on-select="false",
    :disabled="disabled",
    :errors="errors",
    :hide-label="hideLabel",
    :label="label",
    :loading="loading",
    :name="name",
    :optional="optional",
    :options="options",
    :placeholder="placeholderText",
    @update:modelValue="onSelect"
  )
    template(#placeholder, v-if="hasSelected")
      app-span(crop,:value="placeholderText")

    template(#menuContentHeader)
      .selected-items.flex.vertical-center(v-if="hasSelected")
        app-span(crop, :value="selectedItemsText", :data-select-id="inputId")
        app-button.link(theme="link", @click="onClear", :data-select-id="inputId")
          i.icon.far.fa-times(:data-select-id="inputId")
          span(:data-select-id="inputId") {{ $t('.clear.label') }}

    template(#option="{ props: { option, index, focus } }")
      .option(:class="{ focus, active: isActiveOption(option) }")
        span {{ option.label }}
        .remove-icon-content(v-if="isActiveOption(option)", :data-select-id="inputId")
          i.fal.fa-times(:data-select-id="inputId")

</template>


<script>

// Components
import AppSpan from "@/components/app-span/app-span.vue"
import AppButton from "@/components/app-button/app-button.vue"
import SelectField from "@/components/select-field/select-field.vue"

// Mixins
import FieldMixin from "@/mixins/field-mixin"

export default {
  name: "MultipleSelectField",

  components: {
    AppSpan,
    AppButton,
    SelectField
  },

  emits: ["update:modelValue"],

  mixins: [FieldMixin],

  props: {
    modelValue: { type: Array, default: () => [] },
    optional:   { type: Boolean, default: false    },
    hideLabel:  { type: Boolean, default: false    },
    options:    { type: Array,   default: () => [] }
  },

  data() {
    return {
      i18nScope: "components.multiple-select-field"
    }
  },

  computed: {
    // XXX: se tiver valor selecionado, sobrepõe o placeholder!
    placeholderText() {
      return this.hasSelected
        ? this.selectedItemsText
        : this.placeholder
    },

    selectedItemsText() {
      return this.modelValue
        .map(val => this.options.find(opt => opt.value === val).label)
        .join(", ")
    },

    hasSelected() {
      return _.present(this.modelValue)
    }
  },

  methods: {
    onSelect(optionValue) {
      if (!optionValue) return

      let selectedOptions = this.modelValue
      const optionIndex = selectedOptions.findIndex(value => value === optionValue)

      if (optionIndex < 0) selectedOptions = [...selectedOptions, optionValue]
      else selectedOptions.splice(optionIndex, 1)

      this.$emit("update:modelValue", selectedOptions)
    },

    isActiveOption(option) {
      return this.modelValue.length && this.modelValue.some(val => val === option.value)
    },

    onClear() {
      this.$emit("update:modelValue", [])
    }
  }
}

</script>
