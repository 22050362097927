import Base from "../base/base"

class Booking extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "supplierId",
      "at",
      "distance",
      "score",
      "approved",
      "ticketId"
    ])
  }

  static get relations() {
    return {
      supplier: { type: "hasOne", model: "Supplier" }
    }
  }
}

export default Booking
