<style lang="scss" scoped>

.modal-requester-notifications {
  --requester-notifications-border: 1px solid #{$gray-light-2};

  p {
    margin: 0px;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .points {
    border-block: var(--requester-notifications-border);
    max-height: min(474px, 55vh);
    overflow-x: scroll;
  }

  .point {
    padding: 16px;

    &:not(:last-child) {

      border-block-end: var(--requester-notifications-border);
    }
  }

  .sentBy {
    font-size: 14px;
  }

  .empty {
    padding: 32px;
    background-color: $gray-light-3;
    color: $gray;
    font-style: italic;
  }
}

</style>


<template lang="pug">

.modal-requester-notifications
  .wrapper
    p {{ $t('.description') }}

  .points(v-if="skeleton")
    .point.grid.gap-regular(v-for="point of 2")
      loading-lines(:height="17")

      modal-requester-notifications-contact(skeleton)

  .empty.text-center(v-else-if="!points.length")
    span {{ $t(".empty") }}

  .points(v-else)
    .point.grid.gap-regular(v-for="point of points")
      p.sentBy(v-if="point.sentBy") {{ $t(".sent", { time: $i18n.fromNow(point.sentBy) }) }}

      modal-requester-notifications-contact(
        v-for="contact of point.contacts",
        :contact="contact"
      )

  .wrapper.flex.end
    app-button(@click="$emit('next')") {{ $t('.resend-button') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import ModalRequesterNotificationsContact from "./contact"

export default {
  name: "ModalRequesterNotifications",

  components: {
    AppButton,
    LoadingLines,
    ModalRequesterNotificationsContact
  },

  emits: ["next"],

  data() {
    return {
      i18nScope: "components.modal-requester-notifications",
      points:    [],
      skeleton:  false
    }
  }
}

</script>
