<style lang="scss" scoped>

.expended-padding {
  padding: 2px 8px;;
}

</style>


<template lang="pug">

tag-inline.expended-padding(
  weight="bold",
  :color="categoryColor"
) {{ categoryTranslated }}

</template>


<script>

// Components
import TagInline from ".."

export default {
  name: "TagInlineVehicleCategory",

  props: {
    category: { type: String }
  },

  components: {
    TagInline
  },

  data() {
    return {
      i18nScope: "components.tag-inline-vehicle-category"
    }
  },

  computed: {
    categoryColor() {
      switch (this.category) {
        case "Operacional":
          return "gray-shadow"
        case "Premium":
          return "gold-gradient-shadow"
        case "Vip":
          return "bronze-gradient-shadow"
        case "Super Vip":
          return "silver-gradient-shadow"
        default:
          return "gray-gradient"
      }
    },

    categoryTranslated() {
      return this.category
        ? this.$t(`.${this.category}`)
        : "-"
    }
  }
}

</script>
