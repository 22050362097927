<style lang="scss" scoped>

.supplier-appointment-search-form {
  .form-grid {
    grid-template-columns: 1fr 225px;
    align-items: flex-start;
  }

  .submit {
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid currentColor;
    color: $orange;
    padding: 0;
    width: 40px;
    aspect-ratio: 1;

    &:not([disabled], :disabled):hover {
      color: $orange-dark;
      cursor: pointer;
    }

    &[disabled],
    &:disabled {
      cursor: not-allowed;
      color: $gray-light;
    }
  }
}

</style>


<template lang="pug">

form.supplier-appointment-search-form.grid.gap-small(@submit.prevent="handleSubmit")
  .form-grid.grid.gap-regular
    address-place-field(
      name="supplier[address]",
      mandatory,
      v-model="form.address",
      v-model:lat="form.lat"
      v-model:lng="form.lng"
      :label="$t('.fields.address.label')",
      :placeholder="$t('.fields.address.placeholder')"
      :disabled="fieldsDisabled"
      :errors="errors.address"
    )

    date-field.date(
      name="supplier[at]"
      v-model="form.at",
      :min-date="today",
      :label="$t('.fields.at.label')",
      :disabled="fieldsDisabled"
    )

  .flex.gap-regular.vertical-bottom
    input-field.grow(
      name="supplier[q]"
      v-model="form.q",
      :label="$t('.fields.q.label')",
      :placeholder="$t('.fields.q.placeholder')",
      :disabled="fieldsDisabled"
    )

    select-field.select(
      name="supplier[dealership]"
      v-model="form.dealership",
      :label="$t('.fields.dealership.label')",
      :placeholder="$t('.fields.dealership.placeholder')",
      :disabled="fieldsDisabled",
      :options="selectFieldOptions"
    )

    checkbox-field(v-if="mostrarCheckbox",
        name="supplier[all]",
        v-model="form.all",
        :value-text="$t('.fields.all.placeholder')"
        :disabled="fieldsDisabled",
      )

    button.submit.flex.center.vertical-center(:disabled="fieldsDisabled")
      i.fas.fa-magnifying-glass

</template>


<script>

// Modules
import { i18n } from "@/modules/i18n"

// Components
import InputField from "@/components/input-field/input-field.vue"
import SelectField from "@/components/select-field/select-field.vue"
import DateField from "@/components/date-field/date-field.vue"
import AddressPlaceField from "@/views/tickets/_components/address-place-field"
import CheckboxField from "@/components/checkbox-field/checkbox-field.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Models
import SupplierRecommendation from "@/models/supplier/supplier-recommendation"

const rawDealershipOptions = ["only", "except"]

export default {
  name: "SupplierAppointmentSearchForm",

  components: {
    InputField,
    SelectField,
    DateField,
    AddressPlaceField,
    CheckboxField
  },

  mixins: [FormMixin],

  props: {
    ticketId:   { type: [Number, String] },
    disabled:   { type: Boolean, default: false },
    suggestion: { type: String }
  },

  data() {
    return {
      i18nScope: "component.supplier-appointment-search-form",
      today:     moment().toDate().toISOString(),
      form:      {
        address:    null,
        lat:        null,
        lng:        null,
        at:         "",
        q:          "",
        dealership: "",
        all:        false
      },
      mostrarCheckbox: false,
      selectFieldOptions: rawDealershipOptions.map(option => ({
        value: option,
        label: i18n.t(`component.supplier-appointment-search-form.fields.dealership.option.${option}`)
      })),
      errors: {}
    }
  },

  computed: {
    fieldsDisabled() {
      return [this.disabled, this.submitting].some(Boolean)
    },

    hasErrors() {
      return Object.entries(this.errors).length
    },

    showCheckbox() {
      return false
    }
  },

  watch: {
    suggestion(to) {
      if (!to) return

      this.setDealershipOptionFromSuggestion(to)
    }
  },

  methods: {
    setDealershipOptionFromSuggestion(suggestion) {
      const { dealership, multiservice } = SupplierRecommendation.$enums.supplierType.all
      const [onlyDealership, exceptDealership] = rawDealershipOptions
      const teste = false

      if (suggestion === dealership) {
        this.form.dealership = onlyDealership
      }
      else if (suggestion === multiservice) {
        this.form.dealership = exceptDealership
      }
    },

    validate() {
      this.errors = {}

      if ([this.form.lat, this.form.lng].some(geodata => geodata === null)) {
        this.errors.address = [this.$t(".fields.address.validation.presence")]
      }
    },

    handleSubmit() {
      this.validate()

      if (this.hasErrors) {
        return
      }

      this.submit()
      this.$emit("submitting")
    },

    getParamsFromForm() {
      const { lat, lng, q, at, dealership, all } = this.form

      const atWithCurrentTime = this.addCurrentTimeToDate(at)
      const params = { lat, lng, q, at: atWithCurrentTime, all }

      if (dealership) {
        params.dealership = this.form.dealership === "only"
      }

      return params
    },
     
    addCurrentTimeToDate(isoString) {
      const dateSelected = moment(isoString)
      const now = moment()

      if(!dateSelected.isSame(now, "day")) return isoString

      dateSelected.hour(now.hour())
      dateSelected.minute(now.minute())
      dateSelected.second(now.second())

      return dateSelected.toISOString()
    },

   
    // @override FormMixin
    submitRequest() {
      const params = this.getParamsFromForm()

      return this.$sdk.suppliers.available.index({ ticketId: this.ticketId,  params })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("index", data)
      //ver se eh vazio
      if(!this.mostrarCheckbox){
        this.mostrarCheckbox = data.length == 0 ? true : false 
      }
    },

    // @override FormMixin
    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true
      this.mostrarCheckbox = true

      console.error(this.submitError)

      this.chooseErrorToastMessage(error)

      if (error.originalError) this.setFormErrors(error.originalError)
    },

    chooseErrorToastMessage(error) {
      const vehicleModelGroupsErrors = _.get(error, "originalError.response.data.errors.vehicle_model_groups")

      const modelGroupIsBlank = Array.isArray(vehicleModelGroupsErrors)
        && vehicleModelGroupsErrors.length > 0
        && vehicleModelGroupsErrors.find(errorType => errorType.error === "blank")

      if (modelGroupIsBlank) this.$notifications.error(this.$t(".notifications.submit.error.group-model"))
      else this.$notifications.error(this.$t(".notifications.submit.error.generic"))
    },

    afterSubmitError() {
      this.$emit("error")
    }
  }
}

</script>
