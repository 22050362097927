<style lang="scss" scoped>

.tag-inline {
  border-radius: 4px;
  padding: 2px 4px;
  color: $gray-dark;
  background-color: $gray-light-2;

  &.pill {
    border-radius: 80px;
    padding: 5px 8px;
  }

  &.small-pill {
    border-radius: 80px;
    padding: 2px 6px;
  }

  &.inline-block{
    display: inline-block;
    width: min-content;
  }

  $font-weights: (
    'regular': 400,
    'bold': 500,
    'bolder': 600,
  );

  @each $name, $value in $font-weights {
    &.weight-#{$name} {
      font-weight: $value;
    }
  }

  $tag-colors: (
    /* 'name': (background, color, box-shadow), */
    'gold-gradient-shadow': (linear-gradient(180deg, #FFF4D1 40.1%, #ECD172 100%), null, #AB8C50),
    'silver-gradient-shadow': (linear-gradient(180deg, #F6F6F6 0%, #C4CFD9 100%), null, #1A4F7D),
    'bronze-gradient-shadow': (linear-gradient(180deg, #FFEED9 0%, #FFC690 100%), null, #AE4D34),
    'gray-gradient': (linear-gradient(180deg, #F6F6F6 0%, #D4D4D4 100%), null, null),
    'gray-shadow': (null, null, $gray-light),
    'gray': ($gray-light-2, $gray, null),
    'blue': ($blue-light-2, $blue-dark, null),
    'green': ($green-light-2, $green, null),
    'yellow': ($yellow-light-2, $yellow-dark, null),
    'red': ($red-light-2, $red, null),
    'orange': ($orange-light-0, $orange-dark, null),
    'purple': ($purple-light-2, $purple, null),
    'orange-dark': ($orange, $white, null),
  );

  @each $name, $value in $tag-colors {
    &.color-#{$name},
    &.color-#{$name}-text {
      background: nth($value, 1);

      @if nth($value,3) {
        box-shadow: 0 1px nth($value,3);
      }
    }

    &.color-#{$name}-text {
      color: nth($value, 2)
    }
  }
}

</style>


<template lang="pug">

span.tag-inline(:class="classes")
  slot

</template>


<script>

const weightOptions = ["regular", "bold", "bolder"]
const shapeOptions = ["default", "pill", "small-pill"]
const colorOptions = [
  "default",
  "gold-gradient-shadow",
  "silver-gradient-shadow",
  "bronze-gradient-shadow",
  "gray-gradient",
  "gray-shadow",
  "gray",
  "blue",
  "green",
  "yellow",
  "red",
  "orange",
  "purple",
  "orange-dark"
]

export default {
  name: "TagInline",

  props: {
    pill:        { type: Boolean, default: false },
    textColored: { type: Boolean, default: false },
    inlineBlock: { type: Boolean, default: false },
    shape:       {
      type:      String,
      default:   shapeOptions[0],
      validator: val => shapeOptions.includes(val)
    },
    weight: {
      type:      String,
      default:   weightOptions[0],
      validator: val => weightOptions.includes(val)
    },
    color: {
      type:      String,
      default:   colorOptions[0],
      validator: val => colorOptions.includes(val)
    }
  },

  computed: {
    classes() {
      return {
        "inline-block":               this.inlineBlock,
        [this.shape]:                 true,
        [`weight-${this.weight}`]:    true,
        [`color-${this.color}`]:      !this.textColored,
        [`color-${this.color}-text`]: this.textColored
      }
    }
  }
}

</script>
