import { reactive } from "vue"

const viewStore = reactive({
  appError: null,

  get hasError() {
    return !!this.appError
  }
})

export default viewStore
