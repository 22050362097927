<style lang="scss" scoped>

.search {
  main {
    padding-top: 144px;
    padding-top: min(calc((var(--view-height) - 200px) / 2), 144px);
  }

  main {
    .form {
      width: min(690px, calc(100% - 20px));
    }

    .logo {
      position: relative;

      .stamp {
        position: absolute;
        top: 4px;
        left: 80px;
      }

      .img {
        width: 141px;
        aspect-ratio: 1.66;

        & > * {
          width: 100%;
          height: 100%;
        }
      }
    }

    .welcome {
      position: relative;
      font-family: $secondary-font;
      width: 100%;
      padding-bottom: 30px;

      .description {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .not-found {
    padding-block: 20px;
    gap: 72px;

    .form {
      width: min(520px, calc(100% - 20px));
    }

    .content {
      gap: 8px;
    }

    .text {
      font-style: italic;
      font-weight: 300;
      color: $gray-dark;
    }
  }

  :deep(.col-primary) {
    color: $primary;
  }
}

</style>


<template lang="pug">

.search
  .not-found.grid.center(v-if="withSearchQuery", key="2")
    contract-search.form(
      icon-right="far fa-magnifying-glass col-primary"
      with-clear-icon
      ref="searchComponent",
      :initial-value="searchQuery",
      @error="handleNotFound",
      @success="handleContractFound"
    )

    .content.grid.center
      img(:src="emptyBox" :alt="$t('.no-result.img-alt')")
      h2.text {{ vehicleMovida ? $t('.no-result.text-not-found-with-vehicle') : $t('.no-result.text-not-found') }}

  main(v-else, key="1")
    .flex.column-direction.vertical-center
      .logo
        stage-stamp.stamp

        .img
          gac-icon

      .welcome.text-center
        p.description {{ $t('.welcome.description') }}

      contract-search.form(
        icon-left="far fa-magnifying-glass"
        with-button,
        @error="handleNotFound",
        @success="handleContractFound"
      )

</template>


<script>

// Assets
import logo from "@/assets/images/logo-movida.svg"
import emptyBox from "@/assets/images/list-illustrations/empty-box.svg"

// Components
import StageStamp from "@/components/stage-stamp/stage-stamp.vue"
import ContractSearch from "@/components/contract-search"
import GacIcon from "@/components/icons/gac-icon/gac-icon.vue"

export default {
  name: "Search",

  components: {
    StageStamp,
    ContractSearch,
    GacIcon
  },

  data() {
    return {
      i18nScope: "search",
      logo,
      emptyBox,
      vehicleMovida: false
    }
  },

  computed: {
    withSearchQuery() {
      return _.present(this.$route.query.q)
    },

    searchQuery() {
      return this.$route.query?.q || ""
    }
  },

  mounted() {
    if (this.withSearchQuery) {
      this.$refs.searchComponent.submit()
    }
  },

  methods: {
    handleNotFound({ term, vehicle = false }) {
      this.vehicleMovida = vehicle
      this.$router.push({ name: "search", query: { q: term } })
    },

    handleContractFound({ contract }) {
      this.$router.push({ name: "contract", params: { rid: contract.rid } })
    }
  }
}

</script>
