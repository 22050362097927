// Modules
import router from "@/modules/router"

const GoBackPlugin = {
  install({ config }, { defaultRoute }) {
    function goBack({ fallback = null } = {}) {
      const { referrer } = router

      if (referrer) return router.go(-1)

      const route = _.present(fallback) ? fallback : defaultRoute
      return router.push(route)
    }

    config.globalProperties.$goBack = goBack
  }
}

export default GoBackPlugin
