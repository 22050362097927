<template lang="pug">

tag-inline(
  v-if="isBusinessUnitMapped"
  weight="bold",
  :color="businessUnitColor"
) {{ businessUnitTranslated }}

</template>


<script>

// Components
import TagInline from ".."

// Models
import Contract from "@/models/contract/contract"

export default {
  name: "TagInlineBusinessUnit",

  props: {
    unit: { type: String }
  },

  components: {
    TagInline
  },

  data() {
    return {
      i18nScope: "components.tag-inline-business-unit"
    }
  },

  computed: {
    possibleBusinessUnits() {
      return Contract.$enums.businessUnit.values
    },

    isBusinessUnitMapped() {
      return this.possibleBusinessUnits.includes(this.unit)
    },

    businessUnitColor() {
      if (!this.isBusinessUnitMapped) return "default"

      switch (this.unit) {
        case "gtf":
          return "blue"
        case "zkm":
          return "purple"
        default:
          return "orange"
      }
    },

    businessUnitTranslated() {
      if (!this.isBusinessUnitMapped) return ""

      return Contract.$tEnum("business-unit", this.unit)
    }
  }
}

</script>
