// Requests
import Auth from "./Auth"
import User from "./User"
import Contract from "./Contract"
import ContractRequester from "./ContractRequester"
import ContractDriver from "./ContractDriver"
import Ticket from "./Ticket"
import TicketAvailable from "./TicketAvailable"
import TicketReason from "./TicketReason"
import TicketIssue from "./TicketIssue"
import TicketBooking from "./TicketBooking"
import TicketBookingEvent from "./TicketBookingEvent"
import TicketRebooking from "./TicketRebooking"
import TicketPrebooking from "./TicketPrebooking"
import TicketCancellation from "./TicketCancellation"
import TicketPublication from "./TicketPublication"
import TicketRequesterNotification from "./TicketRequesterNotification"
import TicketSupplierNotification from "./TicketSupplierNotification"
import TicketSupplierRecommendation from "./TicketSupplierRecommendation"
import TicketSupplierInstruction from "./TicketSupplierInstruction"
import TicketSinister from "./TicketSinister"
import TicketMessage from "./TicketMessage"
import People from "./People"
import Requester from "./Requester"
import VehicleKm from "./VehicleKm"
import VehicleTicket from "./VehicleTicket"
import Issue from "./Issue"
import SupplierAvailable from "./SupplierAvailable"
import SupplierBusinessHour from "./SupplierBusinessHour"
import Params from "./Params"

export default {
  Auth,
  User,
  Contract,
  ContractRequester,
  ContractDriver,
  Ticket,
  TicketAvailable,
  TicketReason,
  TicketIssue,
  TicketBooking,
  TicketBookingEvent,
  TicketRebooking,
  TicketPrebooking,
  TicketCancellation,
  TicketPublication,
  TicketRequesterNotification,
  TicketSupplierNotification,
  TicketSupplierRecommendation,
  TicketSupplierInstruction,
  TicketSinister,
  TicketMessage,
  People,
  Requester,
  VehicleKm,
  VehicleTicket,
  SupplierAvailable,
  SupplierBusinessHour,
  Issue,
  Params
}
