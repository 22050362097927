<style lang="scss" scoped>

.auxiliar-buttons {
  .action-contact {
    width: 100%;
    font-size: 14px;

    &.dark {
      color: $gray;

      &.disabled {
        color: $gray-light;
      }

      &:not(.disabled):hover {
        color: $orange;
      }
    }

    i {
      width: 16px;
      font-size: 12px;
    }
  }
}

</style>


<template lang="pug">

.auxiliar-buttons
  .buttons.grid.gap-regular
    //- template(v-if="!hideSinister")
    //-   sinister-attached-indicator(
    //-     v-if="withSinister",
    //-     @edit="showSinisterModal",
    //-   )

    //-   app-button(
    //-     v-else,
    //-     theme="link",
    //-     :disabled="disabled",
    //-     @click="showSinisterModal",
    //-   )
    //-     .action-contact.flex.vertical-center.gap-tiny
    //-       i.fas.fa-car-crash
    //-       span {{ $t('.sinister') }}

    app-button(
      theme="link",
      :disabled="disabled",
      @click="showTicketsModal",
    )
      .action-contact.flex.vertical-center.gap-tiny
        i.fas.fa-headset
        span {{ $t('.history') }}

    app-button(
      v-if="!hideSave"
      theme="link",
      @click="handleSaveTicketAndExit",
      :disabled="disabled"
    )
      .action-contact.flex.vertical-center.gap-tiny
        i.fas.fa-helmet-safety
        span {{ $t('.save') }}

    app-button(
      theme="link",
      :disabled="disabled",
      @click="showCancellationModal"
    )
      .action-contact.dark.flex.vertical-center.gap-tiny(:class="{ disabled }")
        span {{ $t('.cancel') }}

  app-modal(
    close-button,
    :heading="$t(`.modal.title.${selectedModal}`)",
    :width="modalWidth",
    :show="modalShown",
    @close="closeModal"
  )
    template(#default)
      modal-tickets-table(
        v-if="selectedModal === 'tickets'",
        :vehicle="vehicle"
      )

      modal-sinister-attacher(
        v-if="selectedModal === 'sinister'",
        :sinister="sinister",
        :ticket-id="ticket.id",
        @close="closeModal",
        @create="handleSiniterCreate"
      )

      modal-ticket-cancellation(
        v-if="selectedModal === 'cancellation'",
        :ticket="ticket",
        @cancel="handleCancel"
      )

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import AppModal from "@/components/app-modal"
import ModalTicketsTable from "@/components/modal-tickets-table"
import ModalTicketCancellation from "@/components/modal-ticket-cancellation"

import ModalSinisterAttacher from "../modal-sinister-attacher"
import SinisterAttachedIndicator from "../sinister-attached-indicator"

// Modules
import { loading } from "@/modules/loading"

export default {
  name: "AuxiliarButtons",

  components: {
    AppButton,
    AppModal,
    ModalTicketsTable,
    ModalTicketCancellation,

    ModalSinisterAttacher,
    SinisterAttachedIndicator
  },

  props: {
    ticket:       { type: Object },
    hideSave:     { type: Boolean, default: false },
    hideSinister: { type: Boolean, default: false }
  },

  emits: ["leaving-start", "leaving-end"],

  data() {
    return {
      i18nScope:     "components.auxiliar-buttons",
      modalShown:    false,
      selectedModal: "",
      leaving:       false,
      sinister:      null
    }
  },

  computed: {
    vehicle() {
      return this.ticket?.contract?.vehicle
    },

    modalWidth() {
      return this.selectedModal === "tickets"
        ? 1156
        : 566
    },

    disabled() {
      return !this.vehicle?.id || this.leaving
    },

    withSinister() {
      return this.sinister
    }
  },

  methods: {
    showTicketsModal() {
      this.selectedModal = "tickets"
      this.modalShown = true
    },

    showSinisterModal() {
      this.selectedModal = "sinister"
      this.modalShown = true
    },

    showCancellationModal() {
      this.selectedModal = "cancellation"
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
      this.selectedModal = ""
    },

    handleSaveTicketAndExit() {
      loading.start()
      this.leaving = true
      this.$emit("leaving-start")

      setTimeout(() => {
        loading.stop()
        this.leaving = false
        this.$emit("leaving-end")

        this.$router.push({ name: "root" }).then(() => {
          this.$notifications.info(
            this.$t(".save-and-exit", { id: this.ticket.remoteId })
          )
        })
      }, 800)
    },

    handleCancel(event) {
      this.$emit("cancellation", event)
    },

    handleSiniterCreate(sinister) {
      this.sinister = sinister
      this.closeModal()
    }
  }
}

</script>
