<style lang="scss" scoped>

.tickets-end {
  margin-block: 24px;

  .container {
    width: min(565px, 100%);
    gap: 48px;
  }

  .box {
    padding: 16px;
    border: 1px solid $gray-light;
    border-radius: 4px;
    gap: 32px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 38px;
    font-weight: 500;
  }

  .category {
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 500;
  }

  .icon {
    color: $purple;
    width: 20px;
    aspect-ratio: 1;
  }

  .big-icon {
    font-size: 38px;
  }

  .tag {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #e7e7e7;
    font-size: 16px;
  }

  .strong {
    font-family: $secondary-font;
    font-weight: 400;
  }

  $icon-colors: (
    'green': $green,
    'yellow': $yellow,
  );

  @each $name, $color in $icon-colors {
    .text-#{$name} {
      color: $color;
    }
  }
}

</style>


<template lang="pug">

.tickets-end.grid.center.vertical-center
  .container.grid
    .header.grid.center.gap-big(v-if="!initializing")
      i.fas.big-icon(
        :class="ticket.isPrebooked ? ['fa-calendar-circle-exclamation', 'text-yellow'] : ['fa-calendar-check', 'text-green']"
      )

      h1.title.text-center {{ $t(`.title.${ ticket.isPrebooked ? 'pre-booked': 'booked' }`) }}

    .box.grid.skeleton(v-if="initializing")
      loading-lines(:lines="2")
      loading-lines(:lines="2")
      loading-lines(:lines="2")
      loading-lines(:lines="2")

    .box.grid(v-else)
      .detail.grid.gap-small
        h2.category.flex.gap-tiny.vertical-center {{ $t('.ticket') }}
          .tag
            id-tag(:value="ticket.remoteId")

        .icon-text.flex.vertical-center.gap-small
          .icon.flex.center.vertical-center
            i.fas.fa-user-headset

          div
            span.strong {{ $t(".user") }}
            span  {{ ticket.user.name }}

      .detail.grid.gap-small(v-if="ticket.supplier?.presentName")
        h2.category {{ $t('.supplier') }}

        .icon-text.flex.vertical-center.gap-small
          .icon.flex.center.vertical-center
            i.fas.fa-user-hard-hat

          span {{ ticket.supplier.presentName }}

        .icon-text.flex.vertical-center.gap-small
          .icon.flex.center.vertical-center
            i.fas.fa-envelope

          span {{ ticket.supplier.email }}

      .detail.grid.gap-small
        h2.category {{ $t('.requester') }}

        .icon-text.flex.vertical-center.gap-small
          .icon.flex.center.vertical-center
            i.fas.fa-user

          span {{ ticket.requester.name }}

        .icon-text.flex.vertical-center.gap-small
          .icon.flex.center.vertical-center
            i.fas.fa-envelope

          span {{ ticket.requester.email }}

    .footer.flex.space-between
      app-button(
        v-if="showGoToTicketButton"
        outline,
        :disabled="initializing || creatingTicket"
        :to="{ name: 'tickets-show', params: { id: $route.params.id } }"
      ) {{ $t(".footer.show-ticket") }}

      app-button(
        v-else,
        outline,
        :disabled="initializing || creatingTicket"
        @click="newTicket"
      ) {{ $t(".footer.new-ticket-same-vehicle") }}

      app-button(
        :disabled="initializing || creatingTicket"
        :to="{ name: 'root' }"
      ) {{ $t(".footer.new-ticket") }}

</template>


<script>

// Components
import IdTag from "@/components/id-tag/id-tag.vue"
import AppButton from "@/components/app-button/app-button.vue"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Views
import LoadedView from "@/views/loaded-view.vue"

export default {
  name: "TicketsEnd",

  components: {
    IdTag,
    AppButton,
    LoadingLines
  },

  mixins: [FetchMixin],

  extends: LoadedView,

  data() {
    return {
      i18nScope:            "tickets-end",
      routeName:            "tickets-end",
      ticketId:             "",
      //
      ticket:               {},
      creatingTicket:       false,
      showGoToTicketButton: false
    }
  },

  mounted() {
    this.readAndCleanQuery()
  },

  methods: {
    // @override LoadedView
    parseRoute() {
      this.ticketId = this.$route.params.id
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.show({ ticketId: this.$route.params.id })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.ticket = data
    },

    async newTicket() {
      try {
        this.creatingTicket = true

        const params = {
          channel:      this.ticket.channel,
          current_km:   this.ticket.currentKm,
          requester_id: this.ticket.requesterId,
          user_id:      this.ticket.userId,
          contract_id:  this.ticket.contractId
        }

        const { data } = await this.$sdk.tickets.create({ params })

        await this.$router.push({ name: "tickets-draft", params: { id: data.id } })
      }
      catch (e) {
        console.error(e)
      }
      finally {
        this.creatingTicket = false
      }
    },

    readAndCleanQuery() {
      const { query } = this.$route

      if (query.kind === "reschedule") this.showGoToTicketButton = true

      this.$router.replace({ query: null })
    }
  }
}

</script>
