import Base from "../base/base"

class Address extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "zipCode",
      "streetName",
      "number",
      "neighborhood",
      "complement",
      "city",
      "stateAbbr",
      "state",
      "addressLine1"
    ])
  }

  get fullFormat() {
    if (this.addressLine1) return this.addressLine1

    const format = _.firstLettersCapital

    const [streetName, number, neighborhood, city] = [
      this.streetName,
      this.number,
      this.neighborhood,
      this.city
    ].map(info => {
      if (typeof info !== "string") return ""

      return info ? format(info) : ""
    })

    const dataArray = [
      [streetName],
      [number],
      [neighborhood, " - "],
      [city],
      [this.stateAbbr, " - "]
    ]

    return dataArray.reduce((result, [data, separator]) => {
      if (data) {
        const newInfo = result
          ? (separator || ", ") + data
          : data

        // eslint-disable-next-line no-param-reassign
        result += newInfo
      }

      return result
    }, "")
  }

  get cityAndState() {
    const addressArray = []

    if (this.city && typeof this.city === "string") {
      addressArray.push(_.firstLettersCapital(this.city))
    }

    if (this.stateAbbr) addressArray.push(this.stateAbbr)

    return addressArray.join(" - ")
  }
}

export default Address
