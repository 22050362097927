import Base from "../base/base"

class BookingEvent extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "status",
      "authorName",
      "at"
    ])
  }

  static get enums() {
    return {
      status: {
        booked:     "booked",
        rebooked:   "rebooked",
        pre_booked: "pre_booked",
        confirmed:  "confirmed",
        canceled:   "canceled",
        done:       "done"
      }
    }
  }

  static get relations() {
    return {
      supplier: { type: "hasOne", model: "Supplier" }
    }
  }
}

export default BookingEvent
