<style lang="scss" scoped>

.driver-row {
  grid-template-columns: var(--driver-template-columns);
  gap: var(--driver-gap);
  //
  border-top: 1px solid $gray-light-2;
  font-family: $secondary-font;
  font-weight: 400;

  &:last-of-type {
    border-bottom: 1px solid $gray-light-2;
  }

  p {
    margin: 0;
  }

  .cell {
    padding-block: 12px;
    padding-inline: 8px;
    font-size: 14px;
  }

  .primary-font {
    font-family: $primary-font;
  }

  .monospace-font {
    font-family: $primary-monospace-font;
  }
}

</style>


<template lang="pug">

.driver-row.grid.vertical-center(:style="cssVars")
  .cell
    app-span(crop, :value="driver.name")

  .cell.monospace-font
    p(v-if="maskedValuesShown", v-mask="'###.###.###-##'") {{ driver.cpf }}

  .cell.primary-font
    p(v-if="maskedValuesShown", v-mask="phoneMask") {{ driver.phone }}

  .cell.primary-font
    app-span(crop, :value="driver.email")

  .cell(v-if="!hideEdit")
    app-icon-button(
      icon-classes="fal fa-pencil"
      :disabled="!editable",
      @click="handleEdit"
    )

</template>


<script>

// Components
import AppSpan from "@/components/app-span/app-span.vue"
import AppIconButton from "@/components/app-icon-button"

export default {
  name: "DriversTableRow",

  components: {
    AppSpan,
    AppIconButton
  },

  props: {
    driver:      { type: Object, default: () => ({}) },
    columnSizes: { type: String, default: "1fr" },
    gap:         { type: String, default: "0px" },
    editable:    { type: Boolean, default: false },
    hideEdit:    { type: Boolean, default: false }
  },

  emits: ["edit"],

  data() {
    return {
      i18nScope: "components.drivers-table-row",

      maskedValuesShown: true
    }
  },

  computed: {
    cssVars() {
      return {
        "--driver-template-columns": this.columnSizes,
        "--driver-gap":              this.gap
      }
    },

    phoneMask() {
      if (!this.driver?.phone) return ""

      switch (this.driver.phone.replace(/\D/g, "").length) {
        case 10:
          return "(##) ####-####"
        case 11:
          return "(##) #####-####"
        default:
          return ""
      }
    }

  },

  watch: {
    async driver() {
      this.resetMaskedValues()
    }
  },

  methods: {
    handleEdit() {
      if (!this.editable || this.hideEdit) return

      this.$emit("edit", { driver: this.driver })
    },

    // XXX: Rerenderiza valores mascarados, para atualizá-los
    async resetMaskedValues() {
      this.maskedValuesShown = false
      await this.$nextTick()
      this.maskedValuesShown = true
    }
  }
}

</script>
