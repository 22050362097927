<style lang="scss" scoped>

.modal-booking-confirmation {
  p {
    margin: 0px;
  }

  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $gray-light-2;
    }
  }

  .small-padding {
    display: inline-block;
    padding-inline-end: 0.5ch;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }
}

</style>


<template lang="pug">

.modal-booking-confirmation
  .section
    p.text
      span.small-padding {{ $t('.text.first') }}
      id-tag(v-if="ticket?.remoteId", :value="ticket.remoteId")
      span {{ $t('.text.second') }}

  .section
    appointment-data(:ticket="ticket")

  .section.grid.gap-small
    p.text {{ $t(".send-to") }}

    requester-contact(,
      without-edit,
      without-retraction,
      :requester="requester"
    )
      //- template(#footer)
      //-   .select-channels
      //-     channels-selector(v-model="resource.channels")

  .section.grid.gap-small
    .field-header.flex.gap-tiny.vertical-center
      .field-icon.flex.center.vertical-center
        i.fas.fa-message-text

      span.grow {{ $t(".fields.noteForSupplier.label") }}

    textarea-field(
      v-model="resource.noteForSupplier",
      hide-label,
      name="draftConfirmation[noteForSupplier]",
      id="draftConfirmation[noteForSupplier]"
      :placeholder="$t('.fields.noteForSupplier.placeholder')"
      :disabled="bookingConfirmationLoading || fetching",
    )

  .section.flex.space-between.vertical-center
    app-button(
      theme="link",
      :disabled="bookingConfirmationLoading || fetching",
      @click="$emit('start-reschedule', { ticket })"
    ) {{ $t(".button.reschedule") }}

    app-button(
      :disabled="disabledConfirm",
      @click="handleBookingConfirmation"
    ) {{ $t(".button.confirm") }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import IdTag from "@/components/id-tag/id-tag.vue"
import AppointmentData from "@/components/appointment-data"
import RequesterContact from "@/views/tickets/_components/requester-contact"
import ChannelsSelector from "@/views/tickets/_components/channels-selector"
import TextareaField from "@/components/textarea-field/textarea-field.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "ModalBookingConfirmation",

  components: {
    AppButton,
    IdTag,
    AppointmentData,
    RequesterContact,
    ChannelsSelector,
    TextareaField
  },

  props: {
    ticket: { type: Object }
  },

  emits: ["start-reschedule", "confirm"],

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "components.modal-booking-confirmation",
      resource:  {
        channels:        ["email"],
        noteForSupplier: ""
      },
      bookingConfirmationLoading: false
    }
  },

  computed: {
    requester() {
      return this.ticket?.requester || {}
    },

    disabledConfirm() {
      return !this.resource.channels.length
        || this.bookingConfirmationLoading
        || this.fetching
    }
  },

  methods: {
    // @overide FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.supplierInstruction.show({
        ticketId: this.ticket.id
      })
    },

    // @overide FetchMixin
    onFetchSuccess({ data }) {
      if (data?.note) {
        this.resource.noteForSupplier = data.note

        this.$notifications.info(this.$t(".notifications.fetch.success"))
      }
    },

    getRecipientsParams() {
      const {
        noteForSupplier,
        channels
      } = _.cloneDeep(this.resource)

      return _.snakeizeKeys({
        noteForSupplier,
        recipients: [
          {
            personId: this.requester.personId,
            channels
          }
        ]
      })
    },

    handleBookingConfirmation() {
      if (this.disabledConfirm) return

      this.submit()
    },

    async submit() {
      const params = this.getRecipientsParams()

      try {
        this.bookingConfirmationLoading = true

        await this.$sdk.tickets.prebooking.create({ ticketId: this.ticket.id, params })

        const successMessage = this.$t(
          ".notifications.submit.success",
          { ticketId: this.ticket.remoteId }
        )

        this.$notifications.info(successMessage)

        this.$emit("confirm", { successMessage })
      }
      catch (err) {
        console.error(err)

        const error = err.error || err

        this.chooseErrorToastMessage(error)
      }
      finally {
        this.bookingConfirmationLoading = false
      }
    },

    chooseErrorToastMessage(error) {
      let errorTranslationTitle = "generic"

      if (this.isErrorFromMissingSapId(error)) {
        errorTranslationTitle = "missing-sap"
      }

      this.$notifications.error(this.$t(`.notifications.submit.error.${errorTranslationTitle}`))
    },

    isErrorFromMissingSapId(error) {
      //
      // XXX: Capturar caso de erro apresentado em https://github.com/caiena/movida-gac/issues/424
      //
      const isThisErrorCase = _.chain(error)
        .get("originalError.response.data.remote_ticket")
        .map(remoteTicket => _.get(remoteTicket, "errors.errors"))
        .find(errorType => errorType.remote_service_order)
        .get("remote_service_order")
        .map(remoteServiceError => remoteServiceError.errors)
        .some(errorItem => typeof errorItem.message === "string" && errorItem.message.includes("`CodigoSAP` = ?"))
        .value()

      return isThisErrorCase
    }
  }
}

</script>
