<!--
<doc lang="markdown">

Exibe um banner lembrando ao usuário que a versão carregada está desatualizada.

</doc>
-->

<style lang="scss" scoped>

.outdated-indicator {
  height: $outdated-height;
  background-color: $orange;
  color: $white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $gray-3;
  cursor: pointer;
  position: relative;
  z-index: 10000;
}

</style>


<template lang="pug">

.outdated-indicator(@click="$emit('refresh')", data-testid="indicator")
  span(data-testid="message") {{ $t('.message') }}

</template>


<script>

export default {
  name: "OutdatedIndicator",

  emits: ["refresh"],

  data() {
    return {
      i18nScope: "components.outdated-indicator"
    }
  }
}

</script>
