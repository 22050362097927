<style lang="scss" scoped>

.driver-new {
  .footer {
    border-top: 1px solid $gray-light;
  }
}

.padding-regular {
  padding: 16px
}

.go-back {
  color: $gray-dark !important;

  &:hover {
    color: $orange !important;

    &:active {
      color: $orange-dark !important;
    }
  }
}

</style>


<template lang="pug">

.driver-new
  person-search.padding-regular(
    v-if="!cpfSearched",
    @show="handlePersonShow"
    @no-show="handleNoShow"
  )

  .body.padding-regular.grid.gap-regular(v-else)
    fake-input(
      :label="cpfTranslatedLabel",
      :value="resource.cpf"
      mask="000.000.000-00"
    )

    input-field(
      name="driver[name]",
      v-model="resource.name",
      :placeholder="$t('.fields.name.placeholder')",
      :errors="errors.name",
      :disabled="submitting",
      :maxlength="100"
    )

    input-field(
      ref="phoneInput"
      name="driver[phone]",
      v-model="resource.phone",
      :mask="phoneMask",
      :placeholder="$t('.fields.phone.placeholder')",,
      :errors="errors.phone",
      :disabled="submitting",
    )

    input-field(
      name="driver[email]",
      v-model="resource.email",
      :placeholder="$t('.fields.email.placeholder')",
      :errors="errors.email",
      :disabled="submitting",
      :maxlength="100"
    )

  .footer.padding-regular(v-if="cpfSearched")
    .flex.space-between
      app-button.go-back(
        theme="link",
        :disabled="submitting"
        @click="resetCpf"
      )
        .flex.gap-small
          span
            i.fas.fa-arrow-left
          span {{ $t('.btn.go-back') }}

      app-button(
        @click="submit",
        :disabled="!canSubmit || submitting",
      ) {{ $t('.btn.add') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import InputField from "@/components/input-field/input-field.vue"
import PersonSearch from "@/components/person-search"
import FakeInput from "@/components/fake-input"

// Models
import Driver from "@/models/driver/driver"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "DriverFormNew",

  components: {
    AppButton,
    InputField,
    PersonSearch,
    FakeInput
  },

  mixins: [FormMixin],

  props: {
    contractRid: { type: String }
  },

  emits: ["close", "create"],

  data() {
    return {
      i18nScope:   "components.driver-form-new",
      resource:    new Driver(),
      cpfSearched: false
    }
  },

  computed: {
    phoneMask() {
      return this.resource.phone?.length === 15
        ? "(00) 00000-0000"
        : "(00) 0000-00009"
    },

    canSubmit() {
      const { cpf, name, phone, email } = this.resource

      return [cpf, name, phone, email].every(field => field && field.length)
    },

    cpfTranslatedLabel() {
      return Driver.$tAttr("cpf")
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    handlePersonShow(person) {
      Object.assign(this.resource, person.driverDetails)
      this.cpfSearched = true
    },

    handleNoShow({ cpf }) {
      this.resource.cpf = cpf
      this.cpfSearched = true
    },

    resetCpf() {
      this.resetData()
      this.cpfSearched = false
    },

    emitClose() {
      this.$emit("close")
    },

    afterCloseModal() {
      this.cpfSearched = false
    },

    // @override DirtyableMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    async resetData() {
      this.resource = new Driver()

      // XXX: isso força a limpeza do campo de telefone, que se mantém por causa da máscara
      if (this.$refs?.phoneInput?.$refs?.input) {
        this.$refs.phoneInput.$refs.input.value = ""
      }

      this.errors = {}
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    formatData(formData) {
      let phone = formData.phone.replace(/\D/g, "")
      phone = `${phone.substring(0, 2)}-${phone.substring(2)}`

      const cpf = formData.cpf.replace(/\D/g, "")

      formData.phone = phone
      formData.cpf = cpf

      return formData
    },

    // @override FormMixin
    submitRequest() {
      const params = this.formatData(this.serializeAttributes())

      return this.$sdk.contracts.drivers.create({
        contractRid: this.contractRid,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("create", { driver: data })
      this.resetData()
    }
  }
}

</script>
