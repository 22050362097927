<style lang="scss">

.modal-tickets-table-vehicle {
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
  row-gap: 2px;

  .second-column {
    grid-column-start: 2;
  }

  h2 {
    font-size: 24px;
    font-family: $secondary-font;
  }

  p {
    margin: 0;
    font-family: $secondary-font;
    color: $gray;
    font-weight: 500;
    text-transform: uppercase;
  }
}

</style>


<template lang="pug">

.modal-tickets-table-vehicle.grid
  vehicle-manufacturer-icon(:size="24" :value="vehicle.manufacturerName")

  h2 {{ vehicle.name }}

  p.second-column {{ vehicle.name }}

</template>


<script>

// Components
import VehicleManufacturerIcon from "@/components/icons/vehicle-manufacturer-icon/vehicle-manufacturer-icon.vue"

export default {
  name: "ModalTicketsTableVehicle",

  components: {
    VehicleManufacturerIcon
  },

  props: {
    vehicle: { type: Object, default: () => ({}) }
  },


  data() {
    return {
      i18nScope: "components.modal-tickets-table-vehicle"
    }
  }
}

</script>
