// Models
import Base from "../base/base"

class ContactEmail extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "value",
      "kind"
    ])
  }
}

export default ContactEmail
