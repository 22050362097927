<style lang="scss" scoped>
  .font-small {
    font-size: 14px;
  }

  .font-smaller {
    font-size: 12px;
  }
</style>


<template lang="pug">

data-box(
  :skeleton="skeleton",
  category-icon-classes="fas fa-user-hard-hat",
  :category-name="$t('.category-name')",
  :title="supplier?.presentName",
  :details="supplierDetails",
  :information-missing="!supplier?.id"
)
  template(#titleDecoration)
    img.title-decoration(
      v-if="supplier.gradeIcon",
      :src="supplier.gradeIcon.src",
      :alt="supplier.gradeIcon.alt"
    )

  template(#headerExtra, v-if="!hideNotifications")
    app-button(theme="link", @click="openModal")
      .flex.vertical-center.gap-small
        span.font-small {{ $t('.notification') }}
        i.fal.fa-angle-down.font-smaller

app-modal(
  v-if="!hideNotifications",
  avoid-close-on-click-outside,
  close-button,
  :heading="$t(`.modal.title.${step}`)",
  :width="step === 'index' ? 805 : 486",
  :show="modalShown",
  @close="closeModal"
)
  //- modal-supplier-notifications(
  //-   v-if="modalShown && step === 'index'",
  //-   @next="step = 'create'"
  //- )

  modal-supplier-notifications-create(
    v-if="modalShown && step === 'create'",
    :current-email="supplier.email",
    :ticket-id="ticketId",
    @back="closeModal",
    @create="handleCreateSuccess"
  )

</template>


<script>

// Components
import DataBox from "@/components/data-box"
import AppButton from "@/components/app-button/app-button.vue"
import AppModal from "@/components/app-modal"
// import ModalSupplierNotifications from "../modal-supplier-notifications"
import ModalSupplierNotificationsCreate from "../modal-supplier-notifications/create"

export default {
  name: "DataBoxSupplier",

  components: {
    DataBox,
    AppButton,
    AppModal,
    // ModalSupplierNotifications,
    ModalSupplierNotificationsCreate
  },

  props: {
    skeleton:          { type: Boolean, default: false },
    supplier:          { type: Object },
    ticketId:          { type: [Number, String] },
    hideNotifications: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:  "components.data-box-supplier",
      step:       "create",
      modalShown: false
    }
  },

  computed: {
    supplierDetails() {
      if (!this.supplier?.presentName) return []

      const { cnpj, email, address } = this.supplier

      return [
        {
          content:     cnpj,
          iconClasses: `fal fa-building`,
          tooltip:     this.$t(".tooltip.cnpj"),
          mask:        "##.###.###/####-##"
        },
        {
          content:     email,
          iconClasses: "fal fa-envelope",
          tooltip:     this.$t(".tooltip.email")
        },
        {
          content:     address?.fullFormat,
          iconClasses: "fal fa-map-location-dot",
          tooltip:     this.$t(".tooltip.address")
        }
      ]
    }
  },

  methods: {
    generatePhoneMask(phone) {
      if (!phone) return ""

      const cleanPhone = phone.replace(/\D/g, "")

      switch (cleanPhone.length) {
        case 11:
          return "(##) #####-####"
        case 10:
          return "(##) ####-####"
        default:
          return ""
      }
    },

    openModal() {
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
      // this.step = "index"
    },

    handleCreateSuccess() {
      this.closeModal()
    }
  }
}

</script>
