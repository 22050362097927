import Base from "../base/base"

class Customer extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "phone",
      "email",
      "kindPerson",
      "identifier"
    ])
  }

  static get enums() {
    return {
      kindPerson: {
        PF: "PF",
        PJ: "PJ"
      }
    }
  }

  static get relations() {
    return {
      address: { type: "hasOne", model: "Address" }
    }
  }

  get isPerson() {
    return this.kindPerson === "PF"
  }
}

export default Customer
