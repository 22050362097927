<style lang="scss" scoped>

.params-view {
  padding-block: 24px;

  .title {
    --tickets-title-vertical-spacing: 16px;

    border-bottom: 1px solid $gray-light-2;
    padding-bottom: var(--tickets-title-vertical-spacing);
    margin-bottom: var(--tickets-title-vertical-spacing);
    font-size: 1.5rem;

    span {
      font-size: 1.875rem;
      font-family: $secondary-font;
      font-weight: 500;
    }
  }

  .empty-list {
    padding: 16px;
    display: grid;
    justify-items: center;
    align-content: center;
    gap: 48px;
    border-top: 1px solid $gray-light-2;
    min-height: calc(100vh - 270px);

    p {
      margin: 0;
      text-align: center;
      color: $gray;
      font-size: 18px;
      max-width: 48ch;
    }
  }
}

</style>


<template>
  <div class="params-view">
    <h1 class="title flex vertical-center gap-regular">
      <i class="fas fa-circle-parking"></i>
      <span>{{ $t('.heading') }}</span>
    </h1>

    <div>
      <div v-for="(param, index) in resources ">
        <br>
        <input-field v-if="typeVerify(param.validationRules) == 'integer'" :label="param.description" type="number" v-model="resources[index].content" />

        <input-field :mask="'00:00'" v-else-if="typeVerify(param.validationRules) == 'timer'" :label="param.description" type="text" v-model="resources[index].content" />

        <input-field v-else-if="typeVerify(param.validationRules) == 'string'" :label="param.description" type="text" v-model="resources[index].content" />
      </div>
      <br>
      <app-button v-if="user.email == 'leandrosantana@movida.com.br' || user.email == 'luizdlopes@movida.com.br'"
        class="self-vertical-center"
        @click="submit"
      >
        {{ $t('.button') }}
      </app-button>
    </div>
    
  </div>
</template>



<script>

import { auth } from "@/modules/auth"

// Assets
import logo from "@/assets/images/logo-movida.svg"
import emptyBox from "@/assets/images/list-illustrations/empty-box.svg"

// Components
import StageStamp from "@/components/stage-stamp/stage-stamp.vue"
import ContractSearch from "@/components/contract-search"
import GacIcon from "@/components/icons/gac-icon/gac-icon.vue"
import InputField from "@/components/input-field/input-field.vue"
import DateField from "@/components/date-field/date-field.vue"
import SelectField from "@/components/select-field/select-field.vue"

import AppButton from "@/components/app-button/app-button.vue"


export default {
  name: "Params",

  components: {
    StageStamp,
    ContractSearch,
    GacIcon,
    InputField,
    DateField,
    SelectField,
    AppButton
  },

  data() {
    return {
      i18nScope: "params",
      logo,
      emptyBox,
      vehicleMovida: false,
      resources: [],

      user: auth.getUser()
    }
  },

  computed: {
    withSearchQuery() {
      return _.present(this.$route.query.q)
    },

    searchQuery() {
      return this.$route.query?.q || ""
    }
  },

  mounted() {
      this.fetchRequest()
  },

  methods: {

    typeVerify(validationRules) {
      
      return JSON.parse(validationRules).type
    },

    fetchRequest() {
      this.$sdk.params.index().then((response) => {
        this.resources = response.data 
      })
    },
    submit() {

      const params = {
        "params": this.resources.map((resource) => {
          return {
            "key_value": resource.keyValue,
            "content": resource.content
          }
        })
      }

      this.$sdk.params.update({ params }).then((response) => {
        
      })
    },
  }
}

</script>
