<style lang="scss" scoped>

.kilometrage-feedback {
  padding-block: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-family: $secondary-font;
  min-height: 26px;

  &.big {
    padding-block: 8px;
    font-size: 16px;
  }

  p {
    margin: 0;
  }

  &.invalid {
    color: red;
  }

  &[data-status] {

    padding-inline: 8px;
    font-weight: 500;

    i {
      font-size: 14px;
    }

    &.big {
      padding-inline: 16px;

      i {
        font-size: 16px;
      }
    }
  }

  &[data-status="warning"] {
    background-color: $yellow-light-2;

    i {
      color: $yellow-dark;
    }
  }

  &[data-status="ok"] {
    background-color: $green-light-2;

    i {
      color: $green-dark;
    }
  }

  &[data-status="non-existant"] {
    background-color: $gray-light-2;
  }
}

</style>


<template lang="pug">

.kilometrage-feedback.flex.vertical-center(
  :data-status="feedback.status",
  :class="classesArray"
)
  i(v-if="feedback.iconClass", :class="feedback.iconClass")
  p {{ feedback.message }}

</template>


<script>

const feedbackStates = new Set(["loading", "invalid", "non-existant", "required", "not-required"])

export default {
  name: "KilometrageFeedback",

  props: {
    big:   { type: Boolean, default: false },
    state: {
      type: String,
      validator(val) { return feedbackStates.has(val) }
    }
  },

  data() {
    return {
      i18nScope: "components.kilometrage-feedback"
    }
  },

  computed: {
    classesArray() {
      const classes = []

      if (this.state === "invalid") classes.push("invalid")

      if (this.big) {
        ["big", "gap-regular"].forEach(cls => classes.push(cls))
      }
      else {
        classes.push("gap-small")
      }

      return classes
    },

    feedbackMap() {
      return new Map([
        ["loading", { message: this.$t(".message.fetching") }],
        ["invalid", { message: this.$t(".message.invalid") }],
        [
          "non-existant",
          {
            message: this.$t(".message.non-existant"),
            status:  "non-existant"
          }
        ],
        [
          "required",
          {
            message:   this.$t(".message.warning"),
            status:    "warning",
            iconClass: "fas fa-exclamation-triangle"
          }
        ],
        [
          "not-required",
          {
            message:   this.$t(".message.ok"),
            status:    "ok",
            iconClass: "far fa-check"
          }
        ]
      ])
    },

    feedback() {
      return this.feedbackMap.has(this.state)
        ? this.feedbackMap.get(this.state)
        : {}
    }
  }
}

</script>
