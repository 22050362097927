// Base
import Role from "./role"

class UserRole extends Role {
  static build(user, options = {}) {
    return this.define((can, cannot) => {
      can("manage", "all")

      cannot(["edit"], "Quote", quote => !["draft", "changes_requested"].includes(quote.state))
    })
  }
}

export default UserRole
