<style lang="scss" scoped>

.header {

  p {
    margin: 0;
  }

  .top {
    h2 {
      font-size: 1.875rem;
      font-weight: bold;
    }

    .due {
      display: inline-block;
      padding: 4px 12px;
      border-radius: 8px;
      background-color: $green;
      //
      font-family: $secondary-font;
      font-weight: 500;
      color: $white;

      &[data-status="expired"] {
        background-color: $gray-2;
      }
    }
  }

  .bottom {
    font-size: .875rem;
  }

  .fa-diamond {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .col-gray {
    color: $gray;
  }
}

</style>


<template lang="pug">

header.header.grid.gap-small(v-if="skeleton")
  .top
    loading-lines(:height="41" :min="40", :max="65")

  .bottom
    loading-lines(:height="19", :min="60", :max="80")

header.header.grid.gap-small(v-else)
  .top.flex.vertical-center.gap-regular
    h2 {{ $t('.business-unit',{ businessUnit: businessUnitTranslated || '-' }) }}
    span.due(
      :data-status="contract.status === 'open' ? 'valid' : 'expired'"
      v-tooltip="$t('.tooltip.status')"
    ) {{ contract.status === 'open' ? $t('.status.positive') : $t('.status.negative') }}

  .bottom.flex.vertical-center.gap-small.wrap
    app-copy-wrapper(:text="contract.id")
      id-tag(:value="contract.id", v-tooltip="$t('.tooltip.id')")

    p.flex.vertical-center.gap-small(v-if="contract.createdAt")
      i.fas.fa-file-contract.col-gray
      span {{ createdAtFormatted }}
      i.fa.fa-diamond
      span {{ createdAtFromNow }}

    template(v-if="contract.dealEndPresent && contractDurationInMonths > 0")
      i.fa.fa-diamond

      p.flex.vertical-center.gap-smal
        i.fas.fa-calendar-alt.col-gray
        span {{ $t('.duration', { count: contractDurationInMonths }) }}
        i.fa.fa-diamond
        span {{ timeRemaining }}

</template>


<script>

// Lib
import { durationYearsMonths } from "@/lib/duration"

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import IdTag from "@/components/id-tag/id-tag.vue"
import AppCopyWrapper from "@/components/app-copy-wrapper"

// Models
import Contract from "@/models/contract/contract"

export default {
  name: "ContractSummaryHeader",

  components: {
    LoadingLines,
    IdTag,
    AppCopyWrapper
  },

  props: {
    skeleton: { type: Boolean, default: false },
    contract: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "components.contract-summary-header"
    }
  },

  computed: {
    businessUnitTranslated() {
      return this.contract.businessUnit
        ? Contract.$tEnum("business-unit", this.contract.businessUnit)
        : ""
    },

    createdAtFormatted() {
      const formattedDate = this.$l("date.formats.default", this.contract.createdAt)
      return this.$t(".start-format", { date: formattedDate })
    },

    contractDurationInMonths() {
      if (!this.contract.dealEndPresent) return 0

      return Math.round(this.$duration(this.contract.createdAt, this.contract.dealEndAt).asMonths())
    },

    timeRemaining() {
      if (!this.contract.dealEndPresent) return ""
      const remainingMonths = Math.abs(this.$duration(this.contract.dealEndAt).asMonths())

      if (remainingMonths < 0) return this.$t(".remaining.expired")

      return remainingMonths === 1
        ? this.$t(".remaining.valid.one", { count: Math.round(remainingMonths) })
        : this.$t(".remaining.valid.other", { count: Math.round(remainingMonths) })
    },

    createdAtFromNow() {
      return durationYearsMonths(this.contract.createdAt)
    }
  }
}

</script>
