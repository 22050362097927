// Models
import Base from "../base/base"

class Sector extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "name",
      "description"
    ])
  }
}

export default Sector
