<style lang="scss" scoped>

.service-cart-item {
  padding: 16px;
  grid-template-columns: 20px 1fr 24px;
  column-gap: 10px;

  .fa-wrench {
    color: $gray-2;
  }

  .name {
    font-weight: 400;
    font-size: 16px;
    font-family: $secondary-font;
    align-self: center;
  }

  .details {
    grid-column-start: 2;
  }

  .tag-inline {
    font-size: .7rem;
    padding: 5px 0;
    border-radius: 4px;
  }

  .tag-inline-color {
    font-size: .7rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0 10px;
    color: #fff;
    background-color: #F4781F;
  }

  .part,
  .target {
    gap: 6px;
    font-family: $primary-font;
  }

  .part {
    font-size: 14px;
  }

  .targets {
    font-size: 12px;
    margin-left: 2px;
    padding-left: 8.5px;
    border-left: 1px solid $gray-3;
  }

  .gray {
    color: $gray-3;
  }

  .fa-circle {
    font-size: 6px;
  }

  .fa-diamond {
    font-size: 4px;
  }
}

</style>


<template lang="pug">

.service-cart-item.grid
  .icon
    i.fal.fa-wrench

  h3.name {{ issue.issueName }}

  app-icon-button(
    v-if="!withoutDelete",
    v-tooltip="$t('.delete')",
    width="24px",
    icon-classes="fas fa-trash-can",
    :disabled="disabled",
    @click="handleServiceDelete(issue)"
  )

  .details(v-if="issue")
    span(v-if="issue.service")
      span(v-if="issue.service.type == 'Services::Withdrawal'")
        span.tag-inline Há intenção de compra?
        span.tag-inline-color {{ issue.purchaseIntent == 1 ? 'Sim' : 'Não' }}

  .details(v-if="hasPartAndSymptom && !issue.isMaintenance")
    .part.flex.vertical-center
      i.fas.fa-circle.gray
      span {{ issue.part?.name }}
      i.fas.fa-diamond.gray
      span {{ issue.symptom?.name }}

    .targets
      .target.flex.vertical-center(v-for="target of issue.serviceTargets")
        span {{ translatePartTarget(target.position) }}
        i.fas.fa-diamond.gray
        span {{ translatePartTarget(target.location) }}

</template>


<script>

// Components
import AppIconButton from "@/components/app-icon-button"

// Models
import Part from "@/models/part/part"

export default {
  name: "ServiceCartItem",

  components: {
    AppIconButton
  },

  props: {
    issue:         { type: Object, default: () => ({}) },
    withoutDelete: { type: Boolean, default: false },
    disabled:      { type: Boolean, default: false }
  },

  emits: [
    "deleting",        // XXX: início da requisição de remoção
    "not-deleted",     // XXX: erro da requisição de remoção
    "delete"           // XXX: remoção realizada com sucesso
  ],

  data() {
    return {
      i18nScope: "components.service-cart-item"
    }
  },

  computed: {
    hasPartAndSymptom() {
      return [this.issue.part, this.issue.symptom].every(Boolean)
    },

    // tooltipConfig() {
    //   const content = this.issue.isMaintenance ? this.$t('.not-allowed-to-delete') : this.$t('.delete')

    //   return {
    //     content,
    //     placement: this.issue.isMaintenance ? "top" : "bottom",
    //   }
    // }
  },

  methods: {
    async handleServiceDelete(issue) {
      if (this.withoutDelete || this.disabled) return

      try {
        
        if(issue.isMaintenance){
          this.$notifications.error(this.$t(".notifications.submit.bloqueio"))

        }else{

          this.$emit("deleting")

          const { issueName } = this.issue

          await this.$sdk.issues.delete({ issueId: this.issue.id })

          this.$emit("delete")

          this.$notifications.info(this.$t(".notifications.submit.success", { issue: issueName }))
        }
       
      }
      catch (err) {
       
        this.$emit("not-deleted")
        console.error(err)
        this.$notifications.error(this.$t(".notifications.submit.error"))
      }
    },

    translatePartTarget(target) {
      return Part.translateTarget(target)
    }
  }
}

</script>
