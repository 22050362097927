import events from "./events"

const VuePlugin = {
  install({ config }) {
    config.globalProperties.$events = {
      emit: events.emit.bind(events),
      on:   events.on.bind(events),
      off:  events.off.bind(events),
      once: events.once.bind(events),
      all:  events.all
    }
  }
}

export default VuePlugin
