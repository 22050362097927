// Modules
import { i18n } from "@/modules/i18n"

import Base from "../base/base"

class Part extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "type",
      "name",
      "description",
      "rear",
      "front",
      "central",
      "left",
      "right",
      "top",
      "bottom",
      "internal",
      "external"
    ])
  }

  get positions() {
    return ["rear", "front", "central"]
      .filter(pos => this[pos])
      .map(pos => ({
        value: pos,
        label: i18n.t(`models.part.target.${pos}`)
      }))
  }

  get locations() {
    return ["left", "right", "top", "bottom", "internal", "external"]
      .filter(loc => this[loc])
      .map(loc => ({
        value: loc,
        label: i18n.t(`models.part.target.${loc}`)
      }))
  }

  get positionsAndLocations() {
    const allCombinations =  this.positions.map(pos => {
      const posCombinations = this.locations.map(loc => ({ position: pos, location: loc }))

      return posCombinations
    })

    return allCombinations.flat()
  }

  get hasPositionsAndLocations() {
    return [
      this.positions,
      this.locations
    ].every(arr => arr.length > 0)
  }

  static translateTarget(target) {
    return i18n.t(`models.part.target.${target}`)
  }
}

export default Part
