import { i18n } from "@/modules/i18n"

import Base from "../base/base"

class ServiceTarget extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "position",
      "location"
    ])
  }

  static get constraints() {
    return {
      position: {
        presence(value, attrs) {
          const locationEmpty = _.blank(attrs.location)
          if (locationEmpty) return false

          if (!Boolean(value)) return { message: i18n.t("models.serviceTarget.errors.positionAndLocation") }

          return false
        }
      },
      location: {
        presence(value, attrs) {
          const positionEmpty = _.blank(attrs.position)
          if (positionEmpty) return false

          if (!Boolean(value)) return { message: i18n.t("models.serviceTarget.errors.positionAndLocation") }

          return false
        }
      }
    }
  }
}

export default ServiceTarget
