<!--
<doc lang="markdown">

Campo de _input_ de data e hora (opcional)

Como usar:
```pug
date-field(
  v-model="resource.at",
  name="model[at]",
  enable-time,
  :disabled="submitting || fetching",
  :errors="errors.at",
  :label="$t('.fields.at.label')",
  :placeholder="$t('.fields.at.placeholder')",
  data-testid="atInput"
)
```

</doc>
-->

<style lang="scss" scoped>

.date-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

.date-field
  label.label-content.flex.vertical-center(
    v-if="!hideLabel",
    :class="{ error: hasErrors, focus }",
    :for="inputId",
  )
    span {{ labelText }}
    template(v-if="optional")
      span {{ $t('form.optional') }}

    template(v-else-if="mandatory")
      i.mandatory-icon.fas.fa-circle(v-tooltip.top="$t('form.mandatory.tooltip')")

    template(v-if="info")
      i.info-icon.far.fa-info-circle(
        v-tooltip.top="infoTooltip",
        @click="$emit('info-click')"
      )

  app-flatpickr(
    v-model="dateValue",
    :allow-input="!enableTime",
    :date-format="_displayFormat",
    :disabled="disabled",
    :enable-time="enableTime",
    :error="hasErrors",
    :id="inputId",
    :loading="loading",
    :mask="mask",
    :max-date="maxDate",
    :maxlength="10",
    :min-date="minDate",
    :modal="modal",
    :only-future="onlyFuture",
    :placeholder="_placeholder",
    @blur="onBlur",
    @focus="onFocus"
  )

  template(v-if="hasMessageErrors")
    span.error-message {{ errors[0] }}

</template>


<script>

// 3rd Party
import Flatpickr from "flatpickr"

// Mixins
import FieldMixin from "@/mixins/field-mixin"

// Components
import AppFlatpickr from "@/components/app-flatpickr/app-flatpickr.vue"

export default {
  name: "DateField",

  emits: ["info-click", "focus", "blur"],

  components: {
    AppFlatpickr
  },

  mixins: [FieldMixin],

  props: {
    dataFormat:    { type: String, default: null },
    // _displayFormat @ https://flatpickr.js.org/options/ - '%d/%m/%Y às %H:%M'
    displayFormat: { type: String, default: null },
    enableTime:    { type: Boolean, default: false },
    infoTooltip:   { type: String, default: null },
    maxDate:       { type: String, default: null },
    minDate:       { type: String, default: null },
    modal:         { type: Boolean, default: false },
    onlyFuture:    { type: Boolean, default: false },
    placeholder:   { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "components.date-field",

      focus: false
    }
  },

  computed: {
    _displayFormat() {
      if (_.present(this.displayFormat)) return this.displayFormat

      if (this.enableTime) return "d/m/Y à\\s H:i"

      return "d/m/Y"
    },

    _dataFormat() {
      if (_.present(this.dataFormat)) return this.dataFormat

      return null
    },

    _placeholder() {
      if (_.present(this.placeholder)) return this.placeholder

      return this.enableTime
        ? this.$t(".placeholder.date-time")
        : this.$t(".placeholder.date")
    },

    mask() {
      return this.enableTime
        ? "##/##/#### às ##:##"
        : "##/##/####"
    },

    // datetime 'localizado', para exibição no input, mas "retornando" (emit update:modelValue) valor em
    // string ISO8601 (~'YYYY-MM-DDTHH:mmz').
    dateValue: {
      // o get retorna o dado em formato localizado - ex: DD/MM/YYYY às HH:mm para pt-BR - uma
      // vez que é usado para exibição no input
      get() {
        if (_.blank(this.modelValue)) return null

        // Removendo UTC para evitar mudança de dia
        let withoutUtc = moment.utc(this.modelValue, this._dataFormat).format(this._dataFormat)
        let date = moment(withoutUtc).toDate()

        return Flatpickr.formatDate(date, this._displayFormat)
      },

      // o set define o dado em formato localizado - ex: DD/MM/YYYY às HH:mm para pt-BR - mas
      // "retorna" (emit update:modelValue) o dado em formato canônico (iso8601 YYYY-MM-DDTHH:mmZ)
      set(value) {
        // value pode ser `null` caso o usuário "limpe" o <input>
        if (_.blank(value)) return this.$emit("update:modelValue", null)

        let date = Flatpickr.parseDate(value, this._displayFormat)
        let dateAsISO08601 = moment.utc(date, this._dataFormat).format(this._dataFormat)

        this.$emit("update:modelValue", dateAsISO08601)
      }
    }
  },

  methods: {
    onFocus() {
      if (this.loading || this.disabled) return

      this.focus = true
      this.$emit("focus")
    },

    onBlur() {
      this.focus = false
      this.$emit("blur")
    }
  }
}

</script>
