.<style lang="scss" scoped>

.modal-requester-notifications-create {
  --supplier-notifications-border: 1px solid #{$gray-light-2};

  p {
    margin: 0px;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .footer {
    border-top: var(--supplier-notifications-border)
  }

  .requester-indicator {
    color: $gray;
    font-size: 14px;
    margin-bottom: 8px;

    .icon {
      font-size: 12px;
      width: 16px;
      aspect-ratio: 1;
    }
  }
}

</style>


<template lang="pug">

.modal-requester-notifications-create
  .wrapper.grid.gap-regular
    template(v-if="initializing")
      requester-contact(skeleton)
      //- requester-contact(skeleton)

    template(v-else)
      requester-contact(
        v-for="(recipient, i) of reorganizedRecipients",
        without-retraction,
        without-edit,
        :requester="getRecipientsRequester(recipient)",
        :disabled="submitting"
        @show-edit="handleShowEdit"
      )
        //- template(#top, v-if="!i")
        //-   .requester-indicator.flex.vertical-center.gap-tiny
        //-     .icon.flex.center.vertical-center
        //-       i.fas.fa-user
        //-     p {{ $t('.requester') }}

        //- template(#footer)
        //-   .select-channels.grid.gap-tiny
        //-     channels-selector(
        //-       :id="recipient.personId",
        //-       :disabled="submitting"
        //-       v-model="recipient.channels"
        //-     )

      //- .flex.center
      //-   app-button(
      //-     theme="link",
      //-     :disabled="submitting",
      //-     @click="$emit('show-requester-create')"
      //-   )
      //-     .flex.gap-small
      //-       i.fas.fa-user-plus

      //-       span {{ $t(".button.add-requester") }}

  .footer.wrapper.flex.space-between
    app-button(
      outline,
      theme="gray",
      :disabled="initializing || submitting",
      @click="$emit('back')"
    ) {{ $t('.button.cancel') }}

    app-button(
      @click="submitRequestersNotification",
      :disabled="initializing || noOptionSelected || submitting"
    ) {{ $t('.button.resend') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import RequesterContact from "@/views/tickets/_components/requester-contact"
import ChannelsSelector from "@/views/tickets/_components/channels-selector"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "ModalRequesterNotificationsCreate",

  components: {
    AppButton,
    RequesterContact,
    ChannelsSelector
  },

  emits: [
    "back",
    "show-requester-edit", // mostrar formulário de edição de solicitante
    "show-requester-create", // mostrar formulário de criação de solicitante
    "create",
    "error"
  ],

  mixins: [FetchMixin],

  props: {
    contractRid:      { type: String },
    ticketId:         { type: [String, Number] },
    currentRequester: { type: Object }
  },

  data() {
    return {
      i18nScope:       "components.modal-requester-notifications-create",
      requesters:      [],
      recipients:      [],
      //
      selectedForEdit: null,
      submitting:      false,
      autofetch:       false
    }
  },

  computed: {
    noOptionSelected() {
      return this.recipients.every(({ channels }) => channels.length < 1)
    },

    reorganizedRecipients() {
      const registeredRequesterRecipient = this.recipients
        .find(recipient => recipient.personId === this.currentRequester.personId)

      // const otherRecipients = this.recipients
      //   .filter(recipient => recipient.personId !== this.currentRequester.personId)

      return [
        registeredRequesterRecipient
        // ...otherRecipients
      ]
    }
  },

  mounted() {
    this.requesters = [
      this.currentRequester
    ]

    this.recipients = [{
      personId: this.currentRequester.personId,
      channels: ["email"]
    }]
  },

  methods: {
    // @overide FetchMixin
    fetchRequest() {
      return this.$sdk.contracts.requesters.index({ contractRid: this.contractRid })
    },

    // @overide FetchMixin
    onFetchSuccess({ data }) {
      this.requesters = data

      this.recipients = data.map(requester => ({
        personId: requester.personId,
        channels: requester.personId === this.currentRequester.personId
          ? ["email"]
          : []
      }))
    },

    handleShowEdit({ requester }) {
      this.$emit("show-requester-edit", { requester })
    },

    getRecipientsRequester(recipient) {
      return this.requesters.find(requester => requester.personId === recipient.personId)
    },

    async submitRequestersNotification() {

      try {
        this.submitting = true

        const recipientsClone = _.snakeizeKeys(_.cloneDeep(this.reorganizedRecipients))

        const params = {
          recipients: recipientsClone.filter(recipient => recipient.channels.length > 0)
        }

        await this.$sdk.tickets.requesterNotifications.create({
          ticketId: this.ticketId,
          params
        })

        this.$notifications.info(this.$t(".notifications.submit.success"))
        this.$emit("create")
      }
      catch (err) {
        console.error(err)

        this.$notifications.error(this.$t(".notifications.submit.error"))
        this.$emit("error")
      }
      finally {
        this.submitting = false
      }
    }
  }
}

</script>
