// Base
import Base from "./Base"

// Models
import SupplierBusinessHour from "@/models/supplier/supplier-business-hour"

class SupplierBusinessHourRequests extends Base {
  static get selector() {
    return "suppliers.businessHours"
  }

  index({ supplierId, params = {}, options = {} } = {}) {
    this.verifyData("index", { supplierId })

    return this.action({
      method:  "post",
      url:     `/suppliers/${supplierId}/business_hours/search`,
      params,
      options: {
        model: SupplierBusinessHour,

        ...options
      }
    })
  }
}

export default SupplierBusinessHourRequests
