<style lang="scss" scoped>

.ticket {
  --ticket-grid-gap: 24px;
  --view-inline-padding: 16px;

  padding-block: 24px;
  gap: var(--ticket-grid-gap);
  align-content: flex-start;

  .divider {
    height: 1px;
    background-color: $gray-light-2;
  }

  .top {
    min-height: 21px;
  }

  .go-back-text {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
  }

  .go-back-icon {
    font-size: 12px;
  }

  .attempts-loader {
    font-size: 6rem;
    opacity: 0.4;
    padding-block-start: 64px;

    i {
      animation-name: spinning;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-timing-function: ease-out;
    }

    @keyframes spinning {
      0% { transform: rotate(0turn); }
      100% { transform: rotate(1turn); }
    }
  }
}

</style>


<template lang="pug">

.ticket.grid(ref="container")

  //- XXX: workaround problema em produção de demora a gerar o atendimento
  //- https://github.com/caiena/movida-gac/issues/395
  template(v-if="!attemptsSuccessful")
    .attempts-loader.flex.center
      i.fa-duotone.fa-spinner-third

  template(v-else)
    .grid.gap-small
      .top.flex.vertical-center
        transition-fade-slide(v-bind="pageTransitionProps")
          app-button(
            v-if="ticket.isStateDraft && goBackDetails",
            :to="{ name: goBackDetails.previousRouteName, params: { id: ticket.id } }",
            theme="link",
            :key="goBackDetails.previousRouteName"
          )
            .flex.vertical-center.gap-tiny
              i.fal.fa-arrow-left.go-back-icon
              span.go-back-text {{ $t(goBackDetails.buttonTextI18n) }}

      ticket-header(
        :skeleton="initializing",
        :ticket="ticket",
        :show-type="showSelectedType"
      )

    .divider

    router-view(
      v-slot="{ Component, route }",
      :ticket-id="ticketId",
      :ticket="ticket",
      :vehicle="vehicle",
      :parent-loading="fetching",
      @update-ticket="handleTicketUpdate",
    )
      transition-fade-slide(v-bind="pageTransitionProps")
        component(:is="Component", :key="route.path")

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import TicketHeader from "../_components/ticket-header"

// Transitions
import TransitionFadeSlide from "@/transitions/transition-fade-slide"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Views
import LoadedView from "@/views/loaded-view.vue"

const goBackMap = new Map([
  [
    "tickets-draft.service",
    {
      previousRouteName: "tickets-draft.type",
      buttonTextI18n:    ".go-back-to.type"
    }
  ],
  [
    "tickets-draft.supplier",
    {
      previousRouteName: "tickets-draft.service",
      buttonTextI18n:    ".go-back-to.service"
    }
  ],
  [
    "tickets-draft.confirmation",
    {
      previousRouteName: "tickets-draft.supplier",
      buttonTextI18n:    ".go-back-to.supplier"
    }
  ]
])

export default {
  name: "TicketsDraft",

  components: {
    TicketHeader,
    AppButton,

    TransitionFadeSlide
  },

  mixins: [FetchMixin],

  extends: LoadedView,

  data() {
    return {
      i18nScope:            "tickets-draft",
      routeName:            "tickets-draft",
      ticketId:             "",
      //
      ticket:               {},
      isTransitionReversed: false,
      //
      // XXX: workaround problema em produção de demora a gerar o atendimento
      // https://github.com/caiena/movida-gac/issues/395
      extraAttempts:        10,
      attemptsSuccessful:   false
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeName = to.name
      vm.extraAttempts = 10
    })
  },

  beforeRouteUpdate(to, from, next) {
    this.isTransitionReversed = to.meta.step > from.meta.step

    this.routeName = to.name
    this.scrollContainerTop()

    next()
  },

  mounted() {
    this.scrollContainerTop()
  },

  computed: {
    showSelectedType() {
      return !this.ticket?.isTypeDraft
    },

    vehicle() {
      return this.ticket?.contract?.vehicle
    },

    isTypeSelectionRoute() {
      return this.$route.name === "tickets-draft.type"
    },

    goBackDetails() {
      if (!goBackMap.has(this.$route.name)) return null

      return goBackMap.get(this.$route.name)
    },

    pageTransitionProps() {
      return {
        reverse:     this.isTransitionReversed,
        duration:    "300ms",
        translation: "20px"
      }
    }
  },

  methods: {
    // @override LoadedView
    parseRoute() {
      this.ticketId = this.$route.params.id
    },

    scrollContainerTop() {
      if (!this.$refs.container) return

      const container = this.$refs.container.$el || this.$refs.container

      container.scrollIntoView()
    },

    handleTicketUpdate() {
      this.fetch()
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.show({ ticketId: this.$route.params.id })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.ticket = data
      this.attemptsSuccessful = true
    },

    // @override FetchMixin
    afterFetchError() {
      if (this.fetchError?.error?.status) this.$notifications.clear()
    },

    // XXX: workaround problema em produção de demora a gerar o atendimento
    // https://github.com/caiena/movida-gac/issues/395

    // @override FetchMixin
    onFetchError(err) {
      const error = err.error || err

      if (error.status === 404 && this.extraAttempts) {
        setTimeout(() => {
          this.extraAttempts--
          this.fetch()
        }, 3000)
        return
      }

      this.handleError(err)
    },

    handleError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.fetchError =  err
      this.erred = true

      // Caso seja uma _view_, comunica o erro trocando o componente pela tela de erro
      if (!this.hideViewError && !!this.hasViewError && this.hasViewError(this.fetchError)) {
        this.appError = this.fetchError
      }

      // Caso não haja status é erro de rede
      if (_.blank(error.status)) {
        this.appError = this.fetchError
      }

      console.error(error)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>
