<template lang="pug">

supplier-appointment-options(
  :modelValue="booking.supplier?.id",
  :suppliers-available="[{ supplier: booking.supplier }]",
  :disabled="disabled"
  hide-radio
)
  template(#bottom="{ disabled: optionDisabled }")
    supplier-appointment-scheduler(
      v-if="initialSetupEnded"
      :disabled="optionDisabled",
      :errors="errors",
      :supplier-id="booking.supplier.id"
      v-model:date="form.date",
      v-model:time="form.time",
    )

</template>


<script>

// Components
import SupplierAppointmentOptions from "../options"
import SupplierAppointmentScheduler from "../scheduler"

export default {
  name: "SupplierAppointmentEdit",

  components: {
    SupplierAppointmentOptions,
    SupplierAppointmentScheduler
  },

  props: {
    booking:  { type: Object },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.supplier-appointment-edit",
      form:      {
        supplier: "",
        date:     "",
        time:     ""
      },
      errors:            {},
      initialSetupEnded: false
    }
  },

  computed: {
    hasErrors() {
      const errors = Object.entries(this.errors)

      return errors.length > 0
        && errors.some(([key, value]) => Array.isArray(value) && value.length > 0)
    }
  },

  mounted() {
    this.setSavedSupplierDataToForm()
  },

  methods: {
    async setSavedSupplierDataToForm() {
      const [date, time] = moment(this.booking.at).format("YYYY-MM-DD HH:mm").split(" ")

      this.form.supplier = this.booking.supplier?.id
      this.form.date = date

      await this.$nextTick()
      this.form.time = time

      this.initialSetupEnded = true
    },

    validate() {
      this.errors = {}

      if (!this.form.date) {
        this.errors.date = [this.$t(".errors.date")]
      }

      if (!this.form.time) {
        this.errors.time = [this.$t(".errors.time")]
      }
    },

    getParamsFromForm() {
      this.validate()

      if (this.hasErrors) {
        return null
      }

      const date = moment(`${this.form.date} ${this.form.time}`, "YYYY-MM-DD hh:mm")

      const { score, distance } = this.booking

      return {
        supplier_id: this.form.supplier,
        at:          date.toISOString(),
        score,
        distance
      }
    }
  }
}

</script>
