<style lang="scss" scoped>

.modal-tickets-table {
  .summary {
    padding: 16px 24px;
    border-bottom: 1px solid $gray-light-2;
    gap: 48px;
  }

  .table {
    padding: 16px 24px 24px;
  }

  .supplier-input {
    min-width: 290px;
  }

  .submit {
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid currentColor;
    color: $orange;
    padding: 0;
    width: 40px;
    aspect-ratio: 1;

    &:not([disabled], :disabled):hover {
      color: $orange-dark;
      cursor: pointer;
    }

    &[disabled],
    &:disabled {
      cursor: not-allowed;
      color: $gray-light;
    }
  }
}

</style>


<template lang="pug">

.modal-tickets-table

  .summary.flex.vertical-center
    .summary-item
      modal-tickets-table-vehicle(:vehicle="vehicle")

    .summary-item
      license-plate(:value="vehicle.licensePlate")

    .summary-item

    //- form.summary-item.flex.gap-regular(@submit.prevent="handleSubmit")
    //-   input-field.supplier-input(
    //-     hide-label,
    //-     :placeholder="$t('.fields.supplier')",
    //-     v-model="supplier",
    //-   )

    //-   button.submit.flex.center.vertical-center
    //-     i.fas.fa-magnifying-glass

  .table
    tickets-table(
      :skeleton="!submitted && submitting",
      :tickets="tickets",
      :loading="submitted && submitting",
      :pagination="pagination",
      :disabled-pagination="submitting || singlePage",
      configuration="extended"
      show-pagination
      @page-change="handlePageChange"
    )

</template>


<script>

// Components
import TicketsTable from "@/components/tickets-table"
import LicensePlate from "@/components/license-plate/license-plate.vue"
import InputField from "@/components/input-field/input-field.vue"

import ModalTicketsTableVehicle from "./vehicle"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "ModalTicketsTable",

  components: {
    TicketsTable,
    LicensePlate,
    InputField,

    ModalTicketsTableVehicle
  },

  mixins: [FormMixin],

  props: {
    vehicle: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope:  "component.modal-tickets-table",
      tickets:    [],
      supplier:   "",
      pagination: null,
      page:       null
    }
  },

  computed: {
    singlePage() {
      if (!this.pagination) return false
      return this.pagination.total <= this.pagination.perPage
    }
  },

  mounted() {
    this.handleSubmit()
  },

  methods: {
    handleSubmit() {
      this.submit()
    },

    handlePageChange(page) {
      this.page = page

      this.submit()
    },

    // @overide FormMixin
    submitRequest() {
      const params = {}
      if (this.page > 1) params.page = this.page

      return this.$sdk.vehicles.tickets.index({ vehicleId: this.vehicle.id, params })
    },

    // @overide FormMixin
    onSubmitSuccess({ data, headers }) {
      const { "x-per-page": perPage, "x-page": page, "x-total": total } = headers

      this.pagination = {
        perPage: Number(perPage),
        page:    Number(page),
        total:   Number(total)
      }

      this.tickets = data
    }

  }
}

</script>
