<style lang="scss" scoped>

.modal-ticket-cancellation {
  p {
    margin: 0px;
  }

  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $gray-light-2;
    }
  }

  .small-padding {
    display: inline-block;
    padding-inline-end: 0.5ch;
  }
}

</style>


<template lang="pug">

.modal-ticket-cancellation
  .section
    p.text
      span.small-padding {{ $t('.text.first') }}
      id-tag(v-if="ticket?.remoteId", :value="ticket.remoteId")
      span {{ $t('.text.second') }}

  .section
    textarea-field(
      mandatory,
      name="cancellationReason",
      id="cancellationReason",
      v-model="resource.cancellationReason",
      :maxlength="400",
      :label="$t('.fields.cancellationReason.label')",
      :placeholder="$t('.fields.cancellationReason.placeholder')",
      :errors="errors.cancellationReason"
    )

  .section.flex.end
    app-button(
      :disabled="submitting"
      @click="submit"
    ) {{ $t(".button.cancel") }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import IdTag from "@/components/id-tag/id-tag.vue"
import TextareaField from "@/components/textarea-field/textarea-field.vue"

// Models
import Base from "@/models/base/base"

// Mixins
import FormMixin from "@/mixins/form-mixin"

class TicketCancellationForm extends Base {
  static get attrs() {
    return ["cancellationReason"]
  }

  static get constraints() {
    return {
      cancellationReason: { presence: true }
    }
  }
}

export default {
  name: "ModalTicketCancellation",

  components: {
    AppButton,
    IdTag,
    TextareaField
  },

  props: {
    ticket: { type: Object }
  },

  mixins: [FormMixin],

  emits: ["cancel"],

  data() {
    return {
      i18nScope: "components.modal-ticket-cancellation",
      resource:  new TicketCancellationForm()
    }
  },

  methods: {
    // @override DirtyableMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override FormMixin
    submitRequest() {
      const params = this.serializeAttributes()

      return this.$sdk.tickets.cancellation.show({
        ticketId: this.ticket.id,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      const successMessage = this.$t(`.notifications.submit.success`, { ticketId: this.ticket.remoteId })

      this.$notifications.info(successMessage)
      this.$emit("cancel", { successMessage })
    },

    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      console.error(this.submitError)
      this.chooseErrorToastMessage(error)

      if (error.originalError) this.setFormErrors(error.originalError)
    },

    chooseErrorToastMessage(error) {
      let errorTranslationTitle = "generic"

      if (this.isErrorFromInvalidServiceOrderState(error)) {
        errorTranslationTitle = "service-order-state"
      }

      else if (this.isErrorFromInvalidTicketState(error)) {
        errorTranslationTitle = "ticket-state"
      }

      this.$notifications.error(this.$t(`.notifications.submit.error.${errorTranslationTitle}`))
    },

    isErrorFromInvalidServiceOrderState(error) {
      //
      // XXX: Capturar caso de erro apresentado em https://github.com/caiena/movida-gac/issues/414#issuecomment-1382088561
      //
      return _.chain(error)
        .get("originalError.response.data.remote_ticket")
        .flatMap(remoteTicket => _.get(remoteTicket, "errors.errors"))
        .flatMap(errorType => _.values(errorType))
        .filter(errors => _.find(errors, errorList => _.get(errorList, "errors")))
        .flatten()
        .map(errorList => _.get(errorList, "errors.errors"))
        .some(errorItem => {
          const relevantErrorList = _.get(errorItem, "service_order_state")

          return relevantErrorList
            && _.some(relevantErrorList, errorDetail => errorDetail.error === "invalid")
        })
        .value()
    },

    isErrorFromInvalidTicketState(error) {
      //
      // XXX: Capturar caso de erro apresentado em https://github.com/caiena/movida-gac/issues/418#issue-1536869970
      //
      return _.chain(error)
        .get("originalError.response.data.ticket")
        .some(ticketError => ticketError.error === "invalid_state")
        .value()
    }
  }
}

</script>
