// Base
import Base from "./Base"

// Models
import Params from "@/models/params/params"

class ParamsRequests extends Base {
  static get selector() {
    return "params"
  }

  index({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     `/params`,
      params,
      options: {
        model: Params,

        ...options
      }
    })
  }

  update({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/params`,
      params,
      options: {
        model: Params,
        ...options
      }
    })
  }
}

export default ParamsRequests
