<style lang="scss" scoped>

.tag-inline {
  font-size: 12px;
}

.color-gray {
  color: $gray-dark;
}

.identifier-icon {
  width: 20px;
  aspect-ratio: 1;
}

</style>


<template lang="pug">

data-box(
  :skeleton="skeleton",
  category-icon-classes="fas fa-user",
  :category-name="$t('.category-name')",
  :title="customer.name",
  :details="customerDetails"
  :information-missing="!customer?.id"
)
  template(#categoryDecoration)
    tag-inline.tag-inline(
      v-if="customer.isPerson",
      shape="small-pill"
    ) {{ $t('.main-conductor-tag')}}

  template(#detail-0="{ content, mask, iconClasses, tooltip }")
    .identifier-icon.text-center
      i.color-gray(:class="iconClasses", v-tooltip="tooltip")

    app-copy-wrapper(:text="content", :mask="mask")

</template>


<script>

// Components
import DataBox from "@/components/data-box"
import TagInline from "@/components/tag-inline"
import AppCopyWrapper from "@/components/app-copy-wrapper"

export default {
  name: "DataBoxCustomer",

  components: {
    DataBox,
    TagInline,
    AppCopyWrapper
  },

  props: {
    customer: { type: Object },
    skeleton: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.data-box-customer"
    }
  },

  computed: {
    customerDetails() {
      if (!this.customer?.name) return []

      const { phone, email, identifier, address } = this.customer

      const identifierMask = this.generateIdentifierMask(identifier)
      const identifierIcon = this.customer.isPerson ? "user" : "building"
      const phoneMask = this.generatePhoneMask(phone)

      return [
        {
          content:     identifier,
          iconClasses: `fal fa-${identifierIcon}`,
          tooltip:     this.$t(`.tooltip.identifier.${this.customer.kindPerson}`),
          mask:        identifierMask
        },
        {
          content:     phone,
          iconClasses: "fal fa-phone",
          tooltip:     this.$t(".tooltip.phone"),
          mask:        phoneMask
        },
        {
          content:     email,
          iconClasses: "fal fa-envelope",
          tooltip:     this.$t(".tooltip.email")
        },
        {
          content:     address.fullFormat,
          iconClasses: "fal fa-map-location-dot",
          tooltip:     this.$t(".tooltip.address")
        }
      ]
    }
  },

  methods: {
    generateIdentifierMask(identifier) {
      if (!identifier) return ""

      const cleanIdentifier = identifier.replace(/\D/g, "")

      switch (cleanIdentifier.length) {
        case 14:
          return "##.###.###/####-##"
        case 11:
          return "###.###.###-##"
        default:
          return ""
      }
    },

    generatePhoneMask(phone) {
      if (!phone) return ""

      const cleanPhone = phone.replace(/\D/g, "")

      switch (cleanPhone.length) {
        case 11:
          return "(##) #####-####"
        case 10:
          return "(##) ####-####"
        default:
          return ""
      }
    }
  }
}

</script>
