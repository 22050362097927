<style lang="scss" scoped>

.appointment-summary {
  .header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  i {
    color: $gray;
  }

  p {
    margin: 0;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  .content {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }
}

</style>


<template lang="pug">

.appointment-summary.grid.gap-big
  template(v-if="initializing")
    .line.grid.gap-tiny
      loading-lines(:height="16.25")

      loading-lines(:height="23")

    .line.grid.gap-tiny
      loading-lines(:height="16.25")

      loading-lines(:height="23")

  template(v-else)
    .line
      .header.flex.gap-tiny.vertical-center
        .icon.flex.center.vertical-center
          i.fas.fa-calendar-alt

        span.grow {{ $t('.date') }}

        app-button.font-small(
          theme="link",
          @click="!disabled && $emit('edit-schedule')"
          :disabled="disabled",
        ) {{ $t('.edit') }}


      p.content {{ formattedSchedule || "-" }}

    .line
      .header.flex.gap-tiny.vertical-center
        .icon.flex.center.vertical-center
          i.fas.fa-user-hard-hat

        span.grow {{ $t('.supplier') }}

        app-button.font-small(
          theme="link",
          @click="!disabled && $emit('edit-supplier')"
          :disabled="disabled",
        ) {{ $t('.edit') }}

      p.content {{ supplier?.presentName || "-" }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "AppointmentSummary",

  components: {
    AppButton,
    LoadingLines
  },

  mixins: [FetchMixin],

  props: {
    ticketId: { type: [String, Number] },
    disabled: { type: Boolean, default: false }
  },

  emits: [
    "edit-schedule",
    "edit-supplier",
    "show"
  ],

  data() {
    return {
      i18nScope: "components.appointment-summary",
      booking:   null
    }
  },

  computed: {
    supplier() {
      return this.booking?.supplier || {}
    },

    formattedSchedule() {
      if (!this.booking?.at) return ""

      return [
        this.$l("date.formats.weekDay", this.booking.at),
        this.$l("time.formats.dateAndTime", this.booking.at)
      ].join(", ")
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.booking.show({
        ticketId: this.ticketId
      })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.booking = data
      this.$emit("show", { booking: data })
    }
  }
}

</script>
