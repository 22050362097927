<style lang="scss" scoped>

.tabs-selector {
  border-bottom: 1px solid $gray-light;
  padding-block: 21px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 4px;
    background-color: $primary;
    bottom: 0;
    left: var(--tab-left);
    width: var(--tab-width);
    transition: all 0.2s ease-out;
  }

  .tab {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 16px;

    &.selected {
      color: $primary;
    }

    &:not(.selected) {
      cursor: pointer;
    }
  }
}

</style>


<template lang="pug">

.tabs-selector.flex.gap-big(:style="cssVars")
  button.tab(
    ref="buttons"
    v-for="tab of tabs",
    :class="{ selected: modelValue === tab.value }"
    @click="modelValue !== tab.value && $emit('update:modelValue', tab.value)"
  ) {{ tab.label }}

</template>


<script>

export default {
  name: "TabsSelector",

  emits: ["update:modelValue"],

  props: {
    modelValue: { type: [String, Number] },
    tabs:       { type: Array, default: () => [] }
  },

  data() {
    return {
      tabLeft:  0,
      tabWidth: 0
    }
  },

  computed: {
    cssVars() {
      return {
        "--tab-left":  `${this.tabLeft}px`,
        "--tab-width": `${this.tabWidth}px`
      }
    }
  },

  mounted() {
    this.updateCssVars()
  },

  watch: {
    modelValue: "updateCssVars"
  },

  methods: {
    updateCssVars() {
      if (!this.$refs.buttons) return

      const index = this.tabs.findIndex(tab => tab.value === this.modelValue)
      if (index < 0) return

      const { clientWidth: width, offsetLeft: left } = this.$refs.buttons[index]

      this.tabLeft = left
      this.tabWidth = width
    }
  }
}

</script>
