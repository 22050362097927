import i18n from "./i18n"

const VuePlugin = {
  install({ config }, options) {
    i18n.init(options)

    // adiciona propriedade `$i18n` (getter) nas instâncias Vue
    Object.defineProperty(config.globalProperties, "$i18n", {
      get() { return i18n }
    })

    config.globalProperties.$t = function vue$t(key, tOptions = {}) {
      // lazy lookup de tradução baseando-se no escopo padrão definido
      // em `this.i18nScope` - que deve ser definido no `data()`
      // de um componente!
      if (key && key.startsWith(".") && this.i18nScope) {
        tOptions.scope = this.i18nScope
        // eslint-disable-next-line no-param-reassign
        key = key.replace(/^\./, "")
      }

      const translation = i18n.t(key, tOptions)
      return translation
    }

    config.globalProperties.$l = i18n.l.bind(i18n)
    config.globalProperties.$asCurrency = i18n.toCurrency.bind(i18n)
    config.globalProperties.$asDate = i18n.strftime.bind(i18n)
    config.globalProperties.$asHumanSize = i18n.toHumanSize.bind(i18n)
    config.globalProperties.$asNumber = i18n.toNumber.bind(i18n)
    config.globalProperties.$asPercentage = i18n.toPercentage.bind(i18n)
    config.globalProperties.$fromNow = i18n.fromNow.bind(i18n)
    config.globalProperties.$toSentence = i18n.toSentence.bind(i18n)
  }
}

export default VuePlugin
