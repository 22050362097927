<style lang="scss" scoped>

.contract-summary {
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 24px;
}

</style>


<template lang="pug">

.contract-summary.grid.vertical-start
  contract-summary-header.grid-row(:skeleton="skeleton", :contract="contract")
  data-box-vehicle(:skeleton="skeleton", :vehicle="vehicleDetails")
  data-box-customer(:skeleton="skeleton", :customer="customerDetails")

</template>


<script>

// Components
import ContractSummaryHeader from "./header"
import DataBoxCustomer from "@/components/data-box/customer"
import DataBoxVehicle from "@/components/data-box/vehicle"


export default {
  name: "ContractSummary",

  components: {
    ContractSummaryHeader,
    DataBoxCustomer,
    DataBoxVehicle
  },

  props: {
    skeleton: { type: Boolean, default: false },
    contract: { type: Object }
  },

  computed: {
    vehicleDetails() {
      return this.contract?.vehicle || {}
    },

    customerDetails() {
      return this.contract?.customer || {}
    }
  }
}

</script>
