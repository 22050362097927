// 3rd Party
import { VueReCaptcha } from "vue-recaptcha-v3"

// Libs
import conf from "@/lib/conf"

function getPluginOptions() {
  const siteKey = _.get(conf, "recaptcha.siteKey")

  if (_.blank(siteKey)) return null

  return {
    siteKey,
    loaderOptions: {
      autoHideBadge: true
    }
  }
}

export default function initialize(app) {
  const pluginOptions = getPluginOptions()

  if (_.present(pluginOptions)) {
    app.use(VueReCaptcha, pluginOptions)
  }
}

export { VueReCaptcha, getPluginOptions }
