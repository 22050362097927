import { parseModelJsErrors } from "@/lib/parse-errors"

// Realiza validação de um Model, permitindo ser aninhado,baseado no
// parâmetro `nestedMap` de mapeamento, que segue o formato:
// ```
// // informa que os modelos aninhados maintenance, contacts.emails e contacts.phones devem ser considerados
// {
//   maintenance: null
//   contacts: {
//     emails: null,
//     phones: null
//   }
// }
// ```
async function validate(resource, nestedMap) {
  let errors = {}

  if (_.blank(resource) || resource.$markedForDestruction) return errors

  await resource.$validate()
  errors = parseModelJsErrors(resource.$errors)

  if (_.present(nestedMap)) {
    for (const [attribute, value] of Object.entries(nestedMap)) {
      const nestedAttribute = resource[attribute]

      if (Array.isArray(nestedAttribute)) {
        errors[`${attribute}Attributes`] = {}

        for (const nestedResource of nestedAttribute) {
          // XXX Não podemos usar o index, pois isso impacta na `key` do `v-for`
          let position = nestedResource.id || nestedResource.tempId
          errors[`${attribute}Attributes`][position] = await validate(nestedResource, value)
        }
      }
      else if (nestedAttribute) {
        errors[`${attribute}Attributes`] = await validate(nestedAttribute, value)
      }
    }
  }

  return _.deleteBlanks(errors)
}

export default validate
