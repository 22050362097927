<style lang="scss" scoped>

.data-box {
  --data-box-spacing-small: 8px;
  --data-box-font-size-small: 0.875em;
  --data-box-border: 1px solid #{$gray-light-2};

  background-color: $white;
  font-family: $secondary-font;
  border: var(--data-box-border);
  border-radius: 8px;
  padding: 16px;
  align-self: flex-start;

  p {
    margin: 0;
  }

  .header {
    font-family: $secondary-font;

    &.with-details {
      padding-bottom: 16px;
      border-bottom: var(--data-box-border);
    }

    .category {
      font-size: var(--data-box-font-size-small);

      i {
        color: $gray;
        width: 16px;
        aspect-ratio: 1;
      }
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .title {
      padding-inline-end: 6px;
    }
  }

  .details {
    font-style: normal;

    &.two-columns {
      grid-template-columns: 1fr 1fr;
    }
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    font-size: var(--data-box-font-size-small);
    line-height: 1.4;
    color: $gray-dark;
    flex-shrink: 0;
    align-self: first baseline;
  }

  .footer {
    font-style: italic;
    font-size: 0.875rem;
    color: $gray;
  }

  .info-missing {
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 400;
  }

  .fas.fa-diamond {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .small-text-bold {
    font-size: .75rem;
    font-weight: 600;
  }

  &.skeleton {
    &>:nth-child(1) {
      gap: 10px;
    }
  }
}

</style>


<template lang="pug">

.data-box.grid.gap-regular.skeleton(v-if="skeleton")
  .header.with-details.grid.gap-regular
    loading-lines(:height="14" :min="20" :max="25")
    loading-lines(:height="30")

  .details.grid.gap-small
    loading-lines(:lines="2", icon)
    loading-lines(:lines="2", icon)

.data-box.grid.gap-regular(v-else)
  .header.flex.space-between.gap-regular(:class="{'with-details': details?.length}")
    .left.grid.gap-small
      p.category.flex.vertical-center.gap-tiny
        i(:class="categoryIconClasses")
        span {{ categoryName }}

        slot(name="categoryDecoration")

      h3(v-if="title")
        span(:class="{ title: hasTitleDecoration }") {{ title }}
        slot(name="titleDecoration")

    .right
      slot(name="headerExtra")

  p.info-missing(v-if="informationMissing") {{ informationMissingText }}

  template(v-else-if="details?.length")
    .details.grid.gap-small(:class="{'two-columns': twoColumns}")
      template(v-for="(detail, i) of details")
        .detail.flex.gap-small(v-if="!detail.hidden")
          slot(:name="`detail-${i}`", v-bind="{ ...detail }")
            .icon.text-center
              i(
                :class="detail.iconClasses"
                v-tooltip="detail.tooltip"
              )

            p(v-mask="detail.mask || ''") {{ detail.content }}

    slot(name="footer")

</template>


<script>

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

export default {
  name: "DataBox",

  components: {
    LoadingLines
  },

  props: {
    skeleton:            { type: Boolean, default: false },
    categoryIconClasses: { type: String },
    categoryName:        { type: String },
    title:               { type: String },
    details:             { type: Array },
    twoColumns:          { type: Boolean, default: false },
    informationMissing:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.data-box"
    }
  },

  computed: {
    hasTitleDecoration() {
      return this.$slots.titleDecoration && this.$slots.titleDecoration().length > 0
    },

    informationMissingText() {
      return this.$t(".information-missing", { category: this.categoryName })
    }
  }
}

</script>
