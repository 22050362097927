import initializeNewRelic from "./new-relic"
import initializeAbility from "./ability"
import initializeAuth from "./auth"
import initializeConfirmation from "./confirmation"
import initializeDirectives from "./directives"
import initializeEvents from "./events"
import initializeFlatpickr from "./flatpickr"
import initializeGoBack from "./go-back"
import initializeGoogleAnalytics from "./google-analytics"
import initializeGoogleTagManager from "./google-tag-manager"
import initializeI18n from "./i18n"
import initializeLoading from "./loading"
import initializeModels from "./models"
import initializeMoment from "./moment"
import initializeNotifications from "./notifications"
import initializeParams from "./params"
import initializeRecaptcha from "./recaptcha"
import initializeRouter from "./router"
import initializeSdk from "./sdk"
import initializeSentry from "./sentry"
import initializeDuration from "./duration"
import initializeValidators from "./validators"
import iniiializeGoogleMaps from "./google-maps"

function initialize(app) {
  initializeNewRelic()
  initializeAbility(app)
  initializeAuth(app)
  initializeConfirmation(app)
  initializeDirectives(app)
  initializeEvents(app)
  initializeFlatpickr()
  initializeGoBack(app)
  initializeGoogleAnalytics(app)
  initializeGoogleTagManager(app)
  initializeI18n(app)
  initializeLoading(app)
  initializeModels(app)
  initializeMoment()
  initializeNotifications(app)
  initializeParams(app)
  initializeRecaptcha(app)
  initializeRouter(app)
  initializeSdk(app)
  initializeSentry(app)
  initializeDuration(app)
  initializeValidators()
  iniiializeGoogleMaps(app)
}

export default initialize
