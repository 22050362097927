// Base
import Base from "./Base"

// Models
import Issue from "@/models/issue/issue"

class TicketIssueRequests extends Base {
  static get selector() {
    return "tickets.issues"
  }

  index({ ticketId, params = {}, options = {} } = {}) {
    this.verifyData("index", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/issues`,
      params,
      options: {
        model: Issue,

        ...options
      }
    })
  }

  create({ ticketId, params = {}, options = {} } = {}) {
    this.verifyData("create", { ticketId })

    return this.action({
      method:  "post",
      url:     `/tickets/${ticketId}/issues`,
      params,
      options: {
        model: Issue,

        ...options
      }
    })
  }
}

export default TicketIssueRequests
