<style lang="scss" scoped>

.service {
  gap: 54px;
  grid-template-columns: 1fr 330px;

  .main {
    align-content: start;
  }

  .title {
    font-size: 24px;
  }

  .aside {
    align-content: start;
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light-2;
  }
}

</style>


<template lang="pug">

.service.grid
  .main.grid.gap-big.gap-big
    h2.title {{ $t('.title') }}

    tabs-selector(:tabs="tabs", v-model="selectedTab")

    transition-fade-slide(:reverse="selectedTab === tabTypes[1]")
      service-form-by-symptom(
        v-if="selectedTab === 'symptom'",
        :key="'symptom'",
        :ticket-id="ticketId",
        :disabled="leaving",
        @create="handleCreate",
      )
      service-form(
        v-else-if="selectedTab === 'service'",
        :showPartsAndSymptoms="ticket.type !== 'Tickets::Withdrawal'", 
        :key="'service'",
        :ticket-id="ticketId",
        :disabled="leaving"
        @create="handleCreate"
      )

  .aside.grid.gap-big
    service-cart(
      initially-open,
      ref="cart",
      :ticket-id="ticketId",
      @complete="goToProviderSelection"
    )

    .divider

    auxiliar-buttons(
      :ticket="ticket",
      @cancellation="handleCancellation",
      @leaving-start="leaving = true",
      @leaving-end="leaving = false"
    )

</template>


<script>

// Components
import ServiceCart from "@/components/service-cart"

import ServiceForm from "./_components/service-form"
import ServiceFormBySymptom from "./_components/service-form-by-symptom"
import AuxiliarButtons from "../../_components/auxiliar-buttons"
import TabsSelector from "../../_components/tabs-selector"

// Transitions
import TransitionFadeSlide from "@/transitions/transition-fade-slide"

export default {
  name: "TicketsDraftService",

  components: {
    ServiceCart,

    ServiceForm,
    ServiceFormBySymptom,
    AuxiliarButtons,
    TabsSelector,

    TransitionFadeSlide
  },

  props: {
    ticketId: { type: [String, Number] },
    vehicle:  { type: Object, default: () => ({}) },
    ticket:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope:   "tickets-draft-service",
      tabTypes:    ["symptom", "service"],
      selectedTab: "symptom",
      leaving:     false
    }
  },

  computed: {
    tabs() {
      return this.tabTypes.map(type => {

        if (this.ticket.type === "Tickets::Withdrawal" && type === "service") {
            return { value: type, label: this.$t(`.tab.${type}`) }
        }

        return { value: type, label: this.$t(`.tab.${type}`) }
      })
    }
  },

  // Vamos verificando quando o ticket.type é alterado
  watch: {
    "ticket.type": {
      immediate: true,
      handler() {
        if (this.ticket.type === "Tickets::Withdrawal") {
          
          this.selectedTab = "service"

          // Remove a outra tab
          this.tabTypes = this.tabTypes.filter(type => type === "service")
        } else {
          this.selectedTab = "symptom"
          this.tabTypes = ["symptom", "service"]
        }
      }
    }
  },

  methods: {
    handleCreate(issue) {
      this.$notifications.info(this.$t(".notifications.success", { issue: issue.issueName }))

      if (!this.$refs.cart) return
      this.$refs.cart.fetch()
    },

    goToProviderSelection() {
      this.$router.push({ name: "tickets-draft.supplier", params: { id: this.ticketId } })
    },

    async handleCancellation({ successMessage }) {
      await this.$router.push({ name: "tickets-show", param: { id: this.ticketId } })
      this.$notifications.info(successMessage)
    }
  }
}

</script>
