<style lang="scss" scoped>

.messages-history-item {
  --message-padding-block: 16px;
  --message-lines-gap: 6px;

  grid-template-columns: var(--messages-history-template-columns);
  gap: var(--messages-history-gap);

  p {
    margin: 0;
  }

  .padded-block {
    padding-block: var(--message-padding-block);
  }

  .createdAt {
    font-size: 14px;

    p:first-child {
      font-weight: 400;
    }
  }

  .body {
    font-size: 14px;
  }

  .content {
    border-radius: 8px;
    padding: 16px;
    background-color: $gray-light-3;

    &.bg-purple-light {
      background-color: $purple-light-2;
    }
  }

  .font-secondary {
    font-family: $secondary-font;
    font-weight: 400;
  }

  .color-gray {
    color: $gray-2;
  }

  .color-gray-light {
    color: $gray-3;
  }

  .color-purple {
    color: $purple-2;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .client-interaction {
    font-style: italic;
  }

  .truncated-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .button-content {
    font-size: 12px;
    font-weight: 400;
    color: $gray;
  }
}

</style>


<template lang="pug">

.messages-history-item.grid(:style="cssVars")
  .createdAt.padded-block
    p(v-if="dateFromNow") {{ dateFromNow }}
    p(v-if="dateFormatted") {{ dateFormatted }}
    p(v-if="timeFormatted") {{ timeFormatted }}

  .body.padded-block.grid.gap-small
    p.flex.gap-tiny.vertical-center
      span {{ $t('.registered-by', { name: message.user.name }) }}

      template(v-if="message.customerInteraction")
        i.fas.fa-diamond.decoration
        span.client-interaction {{ $t(".client-interaction") }}

    .content.grid.gap-small.start
      p(ref="paragraph", :class="textClasses") {{ message.message }}

      app-button(
        v-if="truncatable"
        theme="link",
        @click="textTruncated = !textTruncated"
      )
        span.button-content.flex.gap-small
          i.fas(:class="`fa-chevrons-${textTruncated ? 'down' : 'up'}`")
          span {{ $t(`.button.toggle.${textTruncated? 'close' : 'open'}`) }}


</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"

export default {
  name: "MessagesHistoryItem",

  components: {
    AppButton
  },

  props: {
    columnSizes: { type: String, default: "1fr" },
    gap:         { type: String, default: "0px" },
    message:     { type: Object }
  },

  data() {
    return {
      i18nScope:     "components.messages-history-item",
      truncatable:   true,
      textTruncated: true
    }
  },

  computed: {
    cssVars() {
      return {
        "--messages-history-template-columns": this.columnSizes,
        "--messages-history-gap":              this.gap
      }
    },

    textClasses() {
      return {
        "bg-purple-light": this.message.customerInteraction,
        "truncated-text":  this.truncatable && this.textTruncated
      }
    },

    dateFromNow() {
      return this.message.createdAt ? this.$i18n.fromNow(this.message.createdAt) : ""
    },

    dateFormatted() {
      return this.message.createdAt
        ? this.$l("date.formats.default", this.message.createdAt)
        : ""
    },

    timeFormatted() {
      return this.message.createdAt
        ? this.$l("time.formats.hourMinutesAndSeconds", this.message.createdAt)
        : ""
    },

    iconClasses() {
      return [
        this.message.customerInteraction ? "fa-user" : "fa-user-headset",
        { "color-purple": this.message.customerInteraction }
      ]
    }
  },

  mounted() {
    this.enableTrucationIfNeeded()
  },

  methods: {
    enableTrucationIfNeeded() {
      const el = this.$refs.paragraph.el
        ? this.$refs.paragraph.el
        : this.$refs.paragraph


      this.truncatable = el.scrollHeight > el.clientHeight
    }
  }
}

</script>
