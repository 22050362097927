<!--
<doc lang="markdown">

Seletor _dropdown_ de opções

_Props_:
options -> No formato [{ label: 'Text', value: 1 }]
modelValue -> Se optionAsValue for ativo usa o formato { label: 'Text', value: 1 }. Caso contrário apenas `value` 1
optionAsValue -> Define se `v-model` é a _option_ inteira, ou apenas o `modelValue`
defaultFilter -> filtro padrão por label
searchable -> `input` para filtrar

Como usar:
dado:
```javascript
data() {
  return {
    selectValue: 2
    options: [
      { label: 'Value 1', value: 1 },
      { label: 'Value 2', value: 2 },
    ]

  }
}
```

```pug
  select-field(
    name="model[attr]",
    :loading="submitting",
    :disabled="fetching",
    placeholder="Selecione uma opção",
    :errors="['não pode ficar em branco']",
    v-model="selectValue",
    :options="options",
    searchable
  )
```

USANDO `optionAsValue` Caso queira toda a option
```javascript
data() {
  return {
    selectValue: { label: 'Value 1', value: 1 }
    options: [
      { label: 'Value 1', value: 1 },
      { label: 'Value 2', value: 2 },
    ]

  }
}
```

```pug
  select-field(
    value-as-option,
    name="model[attr]",
    :loading="submitting",
    :disabled="fetching",
    placeholder="Selecione uma opção",
    :errors="['não pode ficar em branco']",
    v-model="selectValue",
    :options="options",
    searchable
  )
```

</doc>
-->


<style lang="scss" scoped>

.select-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $error;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .tooltip {
    margin-left: 8px;
  }

  .info-icon {
    font-size: 14px;
    cursor: pointer;
  }

  .select-search-content {
    display: flex;
    flex-direction: column;

    &.left {
      align-items: flex-end;
    }

    &.right {
      align-items: flex-start;
    }

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 26px;
      border-radius: 50px;
      transition: all 100ms linear;
    }

    .select-field-input {
      &:not(.open) {
        width: 100%;
      }

      &.open {
        min-width: 100%;
      }

      .input {
        font-size: 0px;

        .input-button {
          cursor: pointer;
          position: relative;
          background-color: $white;
          background-image: none;
          border-radius: 4px;
          border: 1px solid $gray-3;
          border-style:solid;
          color: $gray;
          display: inline-block;
          font-size: 16px;
          min-height: 40px;
          line-height: 36px;
          outline: none;
          padding: 1px 13px;
          padding-right: 68px;
          width: 100%;
          text-align: left;
          font-weight: 400;

          &:hover {
            border-color: $orange;

            .arrow-icon {
              color: $primary;
            }
          }

          &.open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-width: 2px;
            padding: 0 12px;
            padding-right: 67px;

            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            z-index: 1001;
          }

          &:focus,
          &.focus {
            border-style:solid;
            outline: none;
            border-color: $orange;
          }

          &.disabled {
            background-color: $gray-light-2;
            border-color: $gray-light;
            color: $gray;
            cursor: not-allowed;

            .value {
              color: $gray-2;
            }

            .arrow-icon {
              color: $gray-light;
            }

            &:hover {
              .arrow-icon {
                color: $gray-light;
              }
            }
          }

          &.error {
            border-color: $error;
          }

          &.loading {
            background-color: $gray-light-2;
            border-color: $gray-light;
            color: $gray;
            cursor: progress;

            input {
              cursor: progress;
            }

            .arrow-icon {
              color: $gray-light;
            }

            &:hover {
              .arrow-icon {
                color: $gray-light;
              }
            }
          }

          .value,
          .placeholder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }

          .placeholder {
            font-weight: 300;
            color: $gray-3;
          }

          .clear-button {
            position: absolute;
            appearance: none;
            border: none;
            background: transparent;
            cursor: pointer;
            width: 24px;
            height: 38px;
            right: 36px;
            top: calc(50% - 19px);
            font-size: 16px;
            padding: 0;
            text-align: center;
            transition: transform .2s ease;
            color: $gray-dark;

            &.open {
              right: 35px;
              height: 36px;
              top: calc(50% - 18px);
            }

            &:hover {
              color: $primary;

              .remove-icon-content {
                background: $gray-light-2;
              }
            }
          }

          .arrow-icon {
            position: absolute;
            width: 24px;
            height: 38px;
            right: 6px;
            top: calc(50% - 19px);
            font-size: 12px;
            text-align: center;
            transition: transform .2s ease;
            color: $gray-dark;

            &.open {
              height: 34px;
              color: $primary;
              top: calc(50% - 17px);
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .menu-wrapper {
      position: relative;
      min-width: 100%;

      .menu-content {
        width: var(--select-field-width);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        position: fixed;
        top: var(--menu-content-top);
        left: var(--menu-content-left);
        background: $white;
        border-radius: 5px;
        border: 1px solid $gray-3;
        z-index: 1000;
        padding: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .input-wrapper {
          padding: 8px;
          border-bottom: 1px solid $gray-3;
          background: $white;
          top: 0;
          position: relative;

          &.hidden {
            opacity: 0;

            &, & > * {
              height: 0;
              border: 0;
              padding: 0;
              z-index: -1;
            }
          }

          .input-inner {
            font-size: 16px;
            height: 40px;
            width: 100%;
            border: 1px solid $gray-3;
            border-radius: 4px;
            padding-left: 8px;
            padding-right: 35px;
          }

          .search-icon {
            position: absolute;
            top: 21px;
            right: 20px;
            color: $gray-2;
          }
        }

        .options-wrapper {
          max-height: min(200px, var(--options-max-height));
          overflow-y: auto;

          .item {
            list-style: none;

            &:last-child {
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &.option {
              height: 40px;
              padding: 0 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid $gray-light-2;
              cursor: pointer;

              .remove-icon-content {
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 50px;
                color: $gray-2;
                transition: all 100ms linear;
              }

              &.active {
                color: $primary;
                background: $gray-light-3;
              }

              &.focus {
                background: $gray-light-3;
                color: $primary-dark;

                .remove-icon-content {
                  background: $gray-light-2;
                  color: $primary;
                }
              }
            }
          }

          .options-content {
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .error-message {
      display: block;
      color: $error;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
    }
  }
}

</style>


<template lang="pug">

.select-field(:data-testid="name")
  .flex.vertical-baseline
    label.label-content.flex.vertical-center(
      v-if="!hideLabel",
      :class="{ error: hasErrors, focus: isOpen }",
      :for="inputId"
    )
      span {{ labelText }}
      template(v-if="optional")
        span {{ $t('form.optional') }}

      template(v-else-if="mandatory")
        i.mandatory-icon.fas.fa-circle(v-tooltip.top="$t('form.mandatory.tooltip')")

      template(v-if="info")
        i.info-icon.far.fa-info-circle(
          v-tooltip.top="infoTooltip",
          @click="$emit('info-click')"
        )

  .select-search-content(
    v-click-away="onClickOutside",
    :class="{ [growthSideClass]: true }"
  )
    .select-field-input(:class="{ open: isOpen }")
      .input
        .input-button(
          ref="button",
          :class="{ disabled, focus: isOpen, open: isOpen, error: hasErrors, loading }",
          :tabindex="disabled ? -1 : 0",
          @click="toggleMenu",
          @keydown.down.prevent="",
          @keydown.enter.prevent="",
          @keydown.space.prevent="toggleMenu",
          @keyup.down="openMenu",
          @keyup.enter="toggleMenu"
        )
          template(v-if="hasValue")
            slot(name="selected", :props="{ selected: selectedOption }")
              app-span.value(:value="valueLabel", crop)

          template(v-else)
            slot(name="placeholder")
              template(v-if="placeholder")
                span.placeholder {{ placeholder }}

          template(v-if="showClear && !_disabled")
            button.clear-button.flex.center.vertical-center(
              tabindex="-1",
              type="button",
              :class="{ open: isOpen }",
              @click.stop="clear"
            )
              .remove-icon-content
                i.far.fa-times

          .arrow-icon.flex.center.vertical-center(v-if="!disabled", :class="{ open: isOpen }")
            i.fas.fa-chevron-down

    .menu-wrapper(
      v-observe-visibility="menuWrapperVisibilityChange",
      ref="menuWrapper"
    )
      .menu-content(
        v-if="isOpen",
        v-show="showWrapper",
        :style="menuContentVars",
        data-testid="menuContent"
      )
        .input-wrapper(:class="{ disabled, focus: isOpen, error: hasErrors, loading, hidden: !searchable }")
          input.input-inner(
            v-mask="mask",
            autocomplete="disabled",
            ref="input",
            type="text",
            :disabled="_disabled",
            :maxlength="maxlength",
            :value="search",
            @focus="focus = true",
            @input="onSearch",
            @keydown.down="e => e.preventDefault()",
            @keydown.enter.prevent="",
            @keydown.tab="closeMenu",
            @keydown.up="e => e.preventDefault()",
            @keyup.down="onKeyDown",
            @keyup.enter="onSelectByEnter",
            @keyup.esc="onKeyEsc",
            @keyup.up="onKeyUp"
          )
          i.search-icon.far.fa-search

        .options-wrapper
          slot(name="menuContentHeader")

          slot(v-if="fetching", name="fetching")
            li.item.option {{ $t('.fetching') }}

          ul.options-content(
            v-else,
            ref="optionsContent",
            @mouseleave="focusedOption = null",
            data-testid="optionsContent"
          )
            li.item.listed-option(
              v-for="(option, index) in _options", :key="option.value",
              :aria-select-option="`option-${index}`",
              :class="defaultOptionClass(option, index)",
              @click="onSelectByClick(option)",
              @mousemove="focusedOption = index"
            )
              slot(name="option", :props="optionProps(option, index)")
                span {{ option.label }}
                .remove-icon-content(v-if="isActiveOption(option)")
                  i.far.fa-times

          slot(name="menuContentFooter")

    span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

// Vue
import { nextTick } from "vue"

// Modules
import { i18n } from "@/modules/i18n"

// Mixins
import FieldMixin from "@/mixins/field-mixin"

// Components
import AppSpan from "@/components/app-span/app-span.vue"

export default {
  name: "SelectField",

  emits: ["search", "open", "close", "inputSearch", "update:modelValue", "info-clock"],

  components: {
    AppSpan
  },

  mixins: [FieldMixin],

  props: {
    defaultFilter:  { type: Boolean, default: true },
    disabled:       { type: Boolean, default: false },
    fetching:       { type: Boolean, default: false },
    limit:          { type: Number, default: null },
    mask:           { type: [String, Object], default: null },
    maxlength:      { type: Number, default: null },
    menuGrowthSide: { type: String, default: "right", validator: side => ["left", "right"].includes(side) },
    menuWidth:      { type: Number, default: null },
    optionAsValue:  { type: Boolean, default: false },
    options:        { type: [Array], default: () => [] },
    searchable:     { type: Boolean, deafult: false },
    showTooltip:    { type: Boolean, default: false },
    closeOnSelect:  { type: Boolean, default: true  },
    hideSelected:   { type: Boolean, default: false },

    // @override Field Mixin
    placeholder: { type: String, default: () => i18n.t("components.select-field.placeholder") }
  },

  data() {
    return {
      i18nScope: "components.select-field",

      focus:         false,
      focusedOption: null,
      isCropped:     false,
      isOpen:        false,
      search:        null,

      showWrapper:     false,
      menuWrapperRect: {}
    }
  },

  computed: {
    _disabled() {
      return this.disabled || this.loading
    },

    menuContentVars() {
      const { x, y, width } = this.menuWrapperRect

      const fieldWidth = this.hasWidth ? this.menuWidth : width

      const left = this.menuGrowthSide === "right" ? x : x - this.menuWidth + width

      const documentHeight = document.documentElement.clientHeight

      return {
        "--menu-content-top":   `${y}px`,
        "--menu-content-left":  `${left}px`,
        "--select-field-width": `${fieldWidth}px`,
        "--options-max-height": `${documentHeight - y}px`
      }
    },

    selectedOption() {
      if (_.blank(this.modelValue)) return null

      if (this.optionAsValue) return this.modelValue

      return this.options.find(option => option.value === this.modelValue)
    },

    valueLabel() {
      if (_.blank(this.modelValue)) return null

      if (this.optionAsValue) return this.modelValue.label

      const selectedOption = this.options.find(option => option.value === this.modelValue)

      return selectedOption.label
    },

    parsedOptions() {
      if (_.blank(this.options) || this.options[0] instanceof Object) return this.options

      return this.options.map(option => ({ label: option, value: option }))
    },

    defaultFilterOptions() {
      if (_.blank(this.search)) return this.parsedOptions

      return this.parsedOptions.filter(({ label }) => {
        // XXX Estamos usando `normalize` e a regex para a busca sem comparação de acentos
        // https://stackoverflow.com/a/37511463
        const formattedOption = String(label).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        const formattedSearch = this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        return formattedOption.indexOf(formattedSearch) >= 0
      })
    },

    _options() {
      let options = this.defaultFilter ? this.defaultFilterOptions : this.options

      if (this.hideSelected) options = this.options.filter(({ value }) => value !== this.modelValue)

      const { length } = options

      if (this.limit && length > this.limit) {
        let limitedOptions = [...options]
        limitedOptions.splice(this.limit, length - 1)

        return limitedOptions
      }

      return options
    },

    hasOptionSlot() {
      return !!this.$slots.option
    },

    hasValue() {
      return _.present(this.modelValue)
    },

    hasWidth() {
      return _.present(this.menuWidth)
    },

    growthSideClass() {
      return this.hasWidth ? this.menuGrowthSide : "none"
    }
  },

  mounted() {
    document.addEventListener("scroll", this.getMenuWrapperPosition, true)
    window.addEventListener("resize", this.getMenuWrapperPosition, true)
  },

  beforeUnmount() {
    document.removeEventListener("scroll", this.getMenuWrapperPosition, true)
    window.removeEventListener("resize", this.getMenuWrapperPosition, true)
  },

  watch: {
    search() {
      this.$emit("inputSearch", this.search)
    },

    focusedOption() {
      let content = this.$refs.optionsContent

      if (!content) return

      let optionElement = content.querySelector(`[aria-select-option="option-${this.focusedOption}"]`)
      if (_.blank(optionElement)) return

      optionElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
    }
  },

  methods: {
    getMenuWrapperPosition() {
      const ref = this.$refs.menuWrapper
      this.menuWrapperRect = ref.getBoundingClientRect()
    },

    menuWrapperVisibilityChange(visible) {
      this.showWrapper = visible
    },

    clear() {
      if (this._disabled) return

      this.$emit("update:modelValue", null)
    },

    isActiveOption(option) {
      if (this.optionAsValue) return _.get(this.modelValue, "value") === option.value

      return this.modelValue === option.value
    },

    optionProps(option, index) {
      return {
        option,
        index,
        focus:  this.focusedOption === index,
        active: this.isActiveOption(option)
      }
    },

    defaultOptionClass(option, index) {
      if (this.hasOptionSlot) return {}

      return {
        option: true,
        focus:  this.focusedOption === index,
        active: this.isActiveOption(option)
      }
    },

    onSearch(event) {
      if (!this.searchable) return

      if (!this.isOpen) this.openMenu()

      this.search = event.target.value
      this.focusedOption = null

      this.$emit("search", { term: this.search, el: event.target })
    },

    toggleMenu() {
      if (this.isOpen) this.closeMenu()
      else this.openMenu()
    },

    async openMenu() {
      if (this._disabled) return

      this.getMenuWrapperPosition()

      // Quem fecha o input é o onBlur
      if (this.isOpen) return

      this.isOpen = true
      this.$emit("open")

      await nextTick()
      this.$refs.input.focus()
    },

    closeMenu({ focus = true } = {}) {
      if (focus && _.present(this.$refs.button)) this.$refs.button.focus()

      this.search = null

      this.isOpen = false
      this.focusedOption = null

      this.$emit("close")
    },

    onClickOutside({ target }) {
      if (!this.isOpen) return

      // XXX detecta se o click outside vem de um elementoc om o mesmo id
      //     para tratar caso de elementos de slot
      const elementHasSameId = [target, target.parentElement]
        .some(el => el && el.dataset.selectId === this.inputId)

      if (elementHasSameId) return

      this.closeMenu({ focus: false })
    },

    onSelectByClick(option) {
      if (this._disabled) return

      const modelValue = this.optionAsValue ? this.modelValue.value : this.modelValue

      if (_.blank(this.modelValue) || modelValue !== option.value) {
        const value = this.optionAsValue ? option : option.value
        this.$emit("update:modelValue", value)
      }
      else {
        this.$emit("update:modelValue", null)
      }

      if (this.closeOnSelect) this.closeMenu()
    },

    onSelectByEnter() {
      if (this._disabled) return

      if (_.present(this.focusedOption)) {
        const option = this._options[this.focusedOption]

        const value = this.optionAsValue ? option : option.value
        this.$emit("update:modelValue", value)
      }

      if (this.closeOnSelect) this.closeMenu()
    },

    onKeyEsc() {
      this.closeMenu()
    },

    onKeyUp() {
      let totalIndexPosition = this._options.length - 1

      this.focusedOption = (this.focusedOption === 0 || _.blank(this.focusedOption))
        ? totalIndexPosition
        : this.focusedOption - 1

      return false
    },

    onKeyDown() {
      if (!this.isOpen) this.openMenu()
      let totalIndexPosition = this._options.length - 1

      this.focusedOption = (this.focusedOption === totalIndexPosition || _.blank(this.focusedOption))
        ? 0
        : this.focusedOption + 1

      return false
    }
  }
}

</script>
