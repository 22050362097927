import { i18n } from "@/modules/i18n"

export function durationFromDate(dateISOString, finalDateString = "") {
  const initial = finalDateString ? moment(finalDateString) : moment()

  return moment.duration(initial.diff(moment(dateISOString)))
}

export function durationYearsMonths(dateISOString) {
  const duration = durationFromDate(dateISOString)

  let text = []

  const years = duration.years()
  const months = duration.months()

  if (years) {
    text.push(i18n.t("lib.duration.date.year", { count: years }))
  }

  if (months >= 1 || (!years && months === 0)) {
    text.push(i18n.t("lib.duration.date.month", { count: months }))
  }

  return text.join(i18n.t("lib.duration.date.connector"))
}
