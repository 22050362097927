// 3rd Party
import store from "store/dist/store.modern"

// Libs
import conf from "@/lib/conf"

// Modules
import { events } from "@/modules/events"

// Models
import User from "@/models/user/user"

function setToStore(key, value) {
  if (_.isNil(value)) return store.remove(key)

  const encodedValue = window.btoa(JSON.stringify(value))
  return store.set(key, encodedValue)
}

function getFromStore(key) {
  const encodedValue = store.get(key)

  if (_.blank(encodedValue)) return null

  const decodedValue = _.blank(encodedValue) ? null : JSON.parse(window.atob(encodedValue))
  return decodedValue
}

let data = {
  get token() { return getFromStore("token") },
  set token(value) { return setToStore("token", value) },

  get expiresAt() { return getFromStore("expiresAt") },
  set expiresAt(value) { return setToStore("expiresAt", value) },

  get user() { return getFromStore("user") },
  set user(value) { return setToStore("user", value) },

  get roles() { return getFromStore("roles") || [] },
  set roles(value) { return setToStore("roles", value) }
}

/**
 * Singleton para controle de autenticação.
 *
 * Eventos:
 *   - @auth:expire
 *       Usuário é desautenticado quando token é inválido ou expirou
 *
 *   - @auth:login(credentials)
 *       Usuário acaba de se autenticar na aplicação
 *
 *   - @auth:logout
 *       Usuário acaba de se desautenticar da aplicação
 *
 *   - @auth:isAuthenticated
 *       Retorna situação de autenticação do usuário
 *
 * @type {Object}
 */
const auth = {
  // Authorization: Basic ${clientCredentials}
  clientCredentials: window.btoa(`${_.get(conf, "client.id")}:${_.get(conf, "client.secret")}`),

  user: null,

  clear() {
    Object.keys(data).forEach(key => {
      store.remove(key)
    })

    this.user = null
  },

  /**
   * Limpa dados de usuário com token expirado ou inválido.
   */
  expire() {
    this.clear()

    // Dispara evento para possíveis observers
    events.emit("auth:expired")
  },

  /**
   * Autentica o usuário na aplicação e registra a token.
   *
   * @param {String} token     Chave de acesso do usuário
   * @param {String} expiresAt Prazo de validade da chave de acesso
   * @param {String} scope     Lista de papéis do usuário separados por espaço
   * @param {Object} user      Dados descritivos do usuário autenticado (ex: e-mail)
   */
  login({ token, expiresAt, scope, user }) {
    data.token = token
    data.expiresAt = expiresAt
    data.user = user

    // "role:admin role:user role:fleetmanager" -> ['admin', 'user', 'fleetmanager']
    const roles = scope
      .split(" ")
      .filter(entry => entry.startsWith("role:"))
      .map(entry => entry.split(":")[1])
    data.roles = roles

    // Dispara evento para possíveis observers apenas quando os dados do usuário estão disponíveis
    events.emit("auth:login", {
      ...data,

      user
    })
  },

  /**
   * Desautentica o usuário atualmente autenticado na aplicação
   */
  logout() {
    this.clear()

    // Dispara evento para possíveis observers
    events.emit("auth:logout")
  },

  /**
   * Verifica se usuário está autenticado e com seus dados disponíveis
   */
  isAuthenticated() {
    // XXX: Verifica todos os dados necessários para evitar problemas no caso de algum valor ser removido de localStorage
    const isAuthenticated = _.present(data.token) && _.present(data.user) && _.present(data.roles)
    if (!isAuthenticated) this.clear()

    return isAuthenticated
  },

  ensureEncoded() {
    if (getFromStore("encoded") === true) return

    this.clear()
    setToStore("encoded", true)
  },

  getToken() {
    return data.token
  },

  setUser(user) {
    data.user = user
  },

  getUser() {
    if (_.blank(this.user) && _.present(data.user)) this.user = new User(data.user)

    return this.user
  },

  getRoles() {
    return data.roles
  }
}

export default auth
