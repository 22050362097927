// Modules
import { events } from "@/modules/events"

/**
 *  Singleton para controle de notificações da aplicação, disparando os eventos
 * necessários.
 *
 * IMPORTANTE utilize o componente `notifications.vue` para integrar com a UI
 *
 * @type {Object}
 */
const notifications = options => ({
  /**
   *   Remove todas as notificações existentes.
   */
  clear() {
    events.emit("notifications:clear")
  },

  /**
   *  Adiciona uma notificação com level "error" - útil para informar falhas
   * em algum processamento (ex: salvar um cadastro)
   *
   * @param  {String}  message conteúdo da notificação
   * @param  {Boolean} dismiss requer remoção automática. Default: true.
   */
  error(message, dismiss = _.get(options, "error.dismiss", true)) {
    events.emit("notifications:error", { message, dismiss })
  },

  /**
   *  Adiciona uma notificação com level "info" - útil para informar sucesso
   * de algum processamento (ex: salvar um cadastro)
   *
   * @param  {String}  message conteúdo da notificação
   * @param  {Boolean} dismiss requer remoção automática. Default: true.
   */
  info(message, dismiss = _.get(options, "info.dismiss", true)) {
    events.emit("notifications:info", { message, dismiss })
  },

  /**
   *  Adiciona uma notificação com level "warn" - útil para informar alguma
   * "condição adversa" (ex: falha em API de terceiros)
   *
   * @param  {String}  message conteúdo da notificação
   * @param  {Boolean} dismiss requer remoção automática. Default: true.
   */
  warn(message, dismiss = _.get(options, "warn.dismiss", true)) {
    events.emit("notifications:warning", { message, dismiss })
  }
})

export default notifications
