<style lang="scss" scoped>

.channels-selector {
  .option {
    input {
      position: absolute;
      top: -9999vh;
      left: -9999vw;
    }

    &.disabled {
      opacity: 0.6;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        cursor: not-allowed !important;
      }
    }
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  p {
    margin: 0;
  }
}

</style>


<template lang="pug">

.channels-selector.flex.gap-small.vertical-center
  label.option(
    v-for="option of options",
    :for="generateId(option.value)"
    :class="{ disabled }"
  )
    input(
      type="checkbox",
      :id="generateId(option.value)",
      :value="option.value",
      :disabled="disabled"
      v-model="model"
    )

    toggle-tag(
      radius="4px",
      :selected="model.includes(option.value)"
    )
      .flex.gap-tiny.vertical-center
        .icon
          i(:class="option.iconClass")

        p {{ option.label }}

</template>


<script>

// Components
import ToggleTag from "@/components/toggle-tag"

export default {
  name: "ChannelsSelector",

  components: {
    ToggleTag
  },

  emits: ["update:modelValue"],

  props: {
    modelValue: { type: Array },
    disabled:   { type: Boolean, default: false },
    id:         { type: [String, Number], default: "" }
  },

  data() {
    return {
      i18nScope:  "components.channels-selector",
      rawOptions: [
        {
          value:     "email",
          iconClass: "fas fa-envelope"
        },
        {
          value:     "whatsapp",
          iconClass: "fab fa-whatsapp"
        }
      ]
    }
  },

  computed: {
    options() {
      return this.rawOptions.map(({ value, iconClass }) => ({
        value,
        label: this.$t(`.${value}`),
        iconClass
      }))
    },

    model: {
      get() {
        return this.modelValue
      },

      set(val) {
        this.$emit("update:modelValue", val)
      }
    }
  },

  methods: {
    generateId(value) {
      const idArray = ["channel"]

      if (this.id) idArray.push(this.id)

      idArray.push(value)

      return idArray.join("-")
    }
  }
}

</script>
