import svgGradeCMM from "./grade-CMM.svg"
import svgGradeA from "./grade-A.svg"
import svgGradeB from "./grade-B.svg"
import svgGradeC from "./grade-C.svg"
import svgGradeD from "./grade-D.svg"
import svgGradePhone from "./grade-phone.svg"

export const gradeMap = new Map([
  ["CMM", svgGradeCMM],
  ["A", svgGradeA],
  ["B", svgGradeB],
  ["C", svgGradeC],
  ["D", svgGradeD],
  ["MOVIDAAPP", svgGradePhone]
])
