// Models
import Base from "../base/base"

class ContactPhone extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "value",
      "kind",
      "ddi"
    ])
  }
}

export default ContactPhone
