<style lang="scss" scoped>

.kilometer-checker {
  p {
    margin: 0;
  }

  .heading {
    font-weight: 500;
  }

  .label {
    font-size: 14px;
    gap: 7px;
  }

  .sparkle {
    color: $orange;
    font-size: 4px;
  }

  .inputs {
    .submit {
      appearance: none;
      background: transparent;
      border: none;
      padding: 0;
      color: $primary;
      width: 24px;
      aspect-ratio: 1;
      cursor: pointer;

      &:disabled,
      &[disabled],
      &.fetching {
        cursor: not-allowed;
        color: $gray-dark;
      }

      &.fetching {
        animation-name: rotation;
        animation-duration: 800ms;
        animation-iteration-count: infinite;

        @keyframes rotation {
          100% { transform: rotate(1turn) }
        }
      }
    }
  }
}

</style>


<template lang="pug">

.kilometer-checker.grid.gap-small
  p.heading.flex.gap-tiny {{ $t('.header') }}
    span.sparkle.flex.vertical-center
      i.fas.fa-circle

  .label.flex.vertical-center
    i.fal.fa-gauge

    p {{ $t('.label', { count: currentKilometrage }) }}

  .inputs.flex.space-between.gap-small
    input-field(
      hideLabel,
      name="kilometrage",
      suffix="km"
      v-model="model",
      :placeholder="$t('.placeholder')",
      :mask="{ mask:'#.##9', options: { reverse: true, onKeyPress: setCaretToEnd } }",
      :maxlength="7",
      :disabled="fetching || disabled"
    )

    button.submit.flex.vertical-center.center(
      @click="handleCheck",
      :class="{ fetching }"
      :disabled="fetching || disabled",
      v-tooltip="$t('.check-maintenance')"
    )
      i.far(:class="fetching ? 'fa-spinner' : 'fa-sync'")

  kilometrage-feedback(v-if="feedbackState", :state="feedbackState")

</template>


<script>

// Components
import InputField from "@/components/input-field/input-field.vue"
import KilometrageFeedback from "@/components/kilometrage-feedback"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "KilometerChecker",

  components: {
    InputField,
    KilometrageFeedback
  },

  emits: ["update:modelValue"],

  mixins: [FetchMixin],

  props: {
    current:    { type: Number, default: 0 },
    modelValue: { type: String, default: "" },
    vehicleId:  { type: [String, Number], default: "" },
    disabled:   { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:   "components.kilometer-checker",
      result:      {},
      invalid:     false,
      nonExistant: false,

      // @override FetchMixin
      autofetch: false
    }
  },

  computed: {
    model: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit("update:modelValue", value)
      }
    },

    currentKilometrage() {
      if (!this.current) return 0

      return this.$asNumber(this.current, { precision: 0 })
    },

    feedbackState() {
      if (this.fetching) return "loading"

      if (this.invalid) return "invalid"

      if (this.nonExistant) return "non-existant"

      if (this.result.maintenanceRequired) return "required"

      if (_.present(this.result.maintenanceRequired)) return "not-required"

      return ""
    }
  },

  methods: {
    setCaretToEnd(value, event, currentField, options) {
      setTimeout(
        () => { currentField.setSelectionRange(value.length, value.length) },
        10
      )
    },

    /**
     * @param {{noFetch: boolean}} options noFetch: if true, only checks is valid
     * @returns {boolean} returns validation check
     * */
    handleCheck({ noFetch } = { noFetch: false }) {
      const isValid = this.validate()

      if (!isValid) {
        this.invalid = true
      }
      else {
        this.invalid = false

        if (!noFetch) this.fetch()
        else this.checkIfHasNextMaintenance()
      }

      return isValid
    },

    validate() {
      return Boolean(this.modelValue)
    },

    // @overide FetchMixin
    fetchRequest() {
      return this.$sdk.vehicles.km.show({
        vehicleId: this.vehicleId,
        params:    {
          km: this.modelValue.replace(/\D/g, "")
        }
      })
    },

    // @overide FetchMixin
    onFetchSuccess({ data }) {
      this.result = data

      this.checkIfHasNextMaintenance()
    },

    // @overide FetchMixin
    onFetchError({ error }) {
      this.nonExistant = true
      console.warn(error)
    },

    checkIfHasNextMaintenance() {
      this.nonExistant = !Boolean(this.result.nextMaintenance)
    }
  }
}

</script>
