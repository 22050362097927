<style lang="scss" scoped>
  .font-small {
    font-size: 14px;
  }

  .font-smaller {
    font-size: 12px;
  }
</style>


<template lang="pug">

data-box(
  :skeleton="skeleton",
  category-icon-classes="fas fa-user",
  :category-name="$t('.category-name')",
  :title="requester?.name",
  :details="requesterDetails"
  :information-missing="!requester?.id"
)
  template(#headerExtra, v-if="!hideNotifications")
    app-button(theme="link", @click="openModal")
      .flex.vertical-center.gap-small
        span.font-small {{ $t('.notification') }}
        i.fal.fa-angle-down.font-smaller

app-modal(
  v-if="!hideNotifications",
  avoid-close-on-click-outside,
  close-button,
  :heading="$t(`.modal.title.${currentStep}`)",
  :width="modalWidth",
  :show="modalShown",
  @close="closeModal"
)
  //- modal-requester-notifications(
  //-   v-if="modalShown && currentStep === 'index'",
  //-   @next="currentStep = 'create'"
  //- )

  modal-requester-notifications-create(
    v-if="modalShown && currentStep === possibleSteps.create",
    :contract-rid="contractRid",
    :ticket-id="ticketId",
    :current-requester="requester"
    @back="closeModal",
    @create="handleCreateSuccess"
    @show-requester-edit="openEditForm"
    @show-requester-create="currentStep = possibleSteps.requesterCreate"
  )

  requester-form-edit(
    v-if="modalShown && currentStep === possibleSteps.requesterEdit",
    :requester="requesterForEdit",
    @cancel="leaveRequesterEditStep",
    @update="handleRequesterUpdate"
  )

  requester-form-new(
    v-if="modalShown && currentStep === possibleSteps.requesterCreate",
    :contract-rid="contractRid",
    :submit-button-text="$t('.modal.button.requester-create-submit')"
    @cancel="currentStep = possibleSteps.create",
    @create="handleRequesterCreate"
  )

</template>


<script>

// Components
import DataBox from "@/components/data-box"
import AppButton from "@/components/app-button/app-button.vue"
import AppModal from "@/components/app-modal"
import RequesterFormEdit from "@/components/requester-form-edit"
import RequesterFormNew from "@/components/requester-form-new"

import ModalRequesterNotifications from "../modal-requester-notifications"
import ModalRequesterNotificationsCreate from "../modal-requester-notifications/create"

const requesterNotificationSteps = {
  create:          "create",
  requesterEdit:   "requester-edit",
  requesterCreate: "requester-create"
}

export default {
  name: "DataBoxRequester",

  components: {
    DataBox,
    AppButton,
    AppModal,
    RequesterFormEdit,
    RequesterFormNew,

    ModalRequesterNotifications,
    ModalRequesterNotificationsCreate
  },

  props: {
    skeleton:          { type: Boolean, default: false },
    requester:         { type: Object },
    contractId:        { type: [String, Number] },
    ticketId:          { type: [String, Number] },
    hideNotifications: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:        "components.data-box-requester",
      modalShown:       false,
      currentStep:      requesterNotificationSteps.create,
      requesterForEdit: null,
      possibleSteps:    requesterNotificationSteps
    }
  },

  computed: {
    requesterDetails() {
      if (!this.requester?.name) return []

      const { cpf, phone, email  } = this.requester

      return [
        {
          content:     cpf,
          iconClasses: "fal fa-user",
          tooltip:     this.$t(".tooltip.identifier"),
          mask:        "###.###.###-##"
        },
        {
          content:     phone,
          iconClasses: "fal fa-phone",
          tooltip:     this.$t(".tooltip.phone"),
          mask:        this.generatePhoneMask(phone)
        },
        {
          content:     email,
          iconClasses: "fal fa-envelope",
          tooltip:     this.$t(".tooltip.email")
        }
      ]
    },

    contractRid() {
      return this.contractId
        ? btoa(`vetor:${this.contractId}`)
        : ""
    },

    modalWidth() {
      if ([
        requesterNotificationSteps.requesterEdit,
        requesterNotificationSteps.requesterCreate
      ].includes(this.currentStep)) return 486

      return 805
    }
  },

  methods: {
    generatePhoneMask(phone) {
      if (!phone) return ""

      const cleanPhone = phone.replace(/\D/g, "")

      switch (cleanPhone.length) {
        case 11:
          return "(##) #####-####"
        case 10:
          return "(##) ####-####"
        default:
          return ""
      }
    },

    openModal() {
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
      this.requesterForEdit = null
      this.currentStep = requesterNotificationSteps.create
    },

    openEditForm({ requester }) {
      this.requesterForEdit = requester
      this.currentStep = requesterNotificationSteps.requesterEdit
    },

    leaveRequesterEditStep() {
      this.requesterForEdit = null
      this.currentStep = requesterNotificationSteps.create
    },

    handleRequesterUpdate({ requester }) {
      this.$notifications.info(this.$t(".requester.update", { requester: requester.name }))

      this.leaveRequesterEditStep()
    },

    handleRequesterCreate({ requester }) {
      this.$notifications.info(this.$t(".requester.create", { requester: requester.name }))

      this.currentStep = requesterNotificationSteps.create
    },

    handleCreateSuccess() {
      this.closeModal()
    }
  }
}

</script>
