<style lang="scss" scoped>

.ticket-show-header {
  .review {
  font-family: $secondary-font;
  font-size: 30px;
  font-weight: 500;
  }

  .id {
    font-size: 24px;
  }

  .override-tag-inline {
    padding: 6px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-family: $secondary-font;
  }

  .open-at {
    margin: 0;
    font-size: 14px;
    color: $gray-dark;

    .fa-diamond {
      font-size: 4px;
      color: $gray-3;
    }
  }
}

</style>


<template lang="pug">

.ticket-show-header
  .grid.gap-tiny(v-if="skeleton")
    loading-lines(:height="32")
    loading-lines(:height="16.5")

  template(v-else)
    h1.flex.gap-small.vertical-center
      span.review {{ $t(".review") }}

      id-tag.id(v-if="ticket?.remoteId", :value="ticket.remoteId", hide-hashtag)

      tag-inline-ticket-state.override-tag-inline(:ticket="ticket")

      tag-inline-business-unit.override-tag-inline(
        v-if="ticket?.contract?.businessUnit"
        :unit="ticket.contract.businessUnit"
      )

    p.open-at.flex.vertical-center.gap-tiny
      small(v-if="userOpenend") {{ userOpenend }}
      i.fas.fa-diamond(v-if="dateTimeOpenend")
      small(v-if="dateTimeOpenend") {{ dateTimeOpenend }}

</template>


<script>

// Components
import IdTag from "@/components/id-tag/id-tag.vue"
import TagInlineBusinessUnit from "@/components/tag-inline/business-unit"
import TagInlineTicketState from "@/components/tag-inline/ticket-state"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

export default {
  name: "TicketShowHeader",

  components: {
    IdTag,
    TagInlineBusinessUnit,
    TagInlineTicketState,
    LoadingLines
  },

  props: {
    skeleton: { type: Boolean, default: false },
    ticket:   { type: Object }
  },

  data() {
    return {
      i18nScope: "components.ticket-show-header"
    }
  },

  computed: {
    userOpenend() {
      if (!this.ticket?.user?.name) return ""

      const phraseArray = [
        this.$t(".opened-by", { user: this.ticket.user.name }),
        this.$fromNow(this.ticket.openingDate)
      ]

      return phraseArray.join(" ")
    },

    dateTimeOpenend() {
      if (!this.ticket?.openingDate) return ""

      return this.$l("time.formats.short", this.ticket.openingDate)
    }
  }
}

</script>
