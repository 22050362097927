// Base
import Base from "./Base"

// Models
import BookingEvent from "@/models/booking/booking-event"

class TicketBookingEventRequests extends Base {
  static get selector() {
    return "tickets.bookings.events"
  }

  index({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("index", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/booking_events`,
      params,
      options: {
        model: BookingEvent,

        ...options
      }
    })
  }
}

export default TicketBookingEventRequests
