<style lang="scss">

.transition-fade-slide {
  &-right,
  &-left {
    &-enter-active,
    &-leave-active {
      transition: all var(--transition-duration) ease-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  &-right-enter-from,
  &-left-leave-to {
    transform: translateX(calc(0px - var(--transition-translation)));
  }

  &-left-enter-from,
  &-right-leave-to {
    transform: translateX(var(--transition-translation));
  }
}

</style>


<template lang="pug">

span(:style="cssVars")
  transition(
    :name="`transition-fade-slide-${reverse ? 'left' : 'right'}`",
    mode="out-in"
  )
    slot

</template>


<script>
export default {
  name: "TransitionFadeSlide",

  props: {
    reverse:     { type: Boolean,  default: false   },
    duration:    { type: String, default: "200ms" },
    translation: { type: String, default: "8px" }
  },

  computed: {
    cssVars() {
      return {
        "--transition-duration":    this.duration,
        "--transition-translation": this.translation
      }
    }
  }
}
</script>
