import { ability, abilityPlugin  } from "@/modules/ability"

export default function initialize(app) {
  app.use(abilityPlugin)

  configure()
}

function configure() {
  ability.init()
}

export { abilityPlugin, configure }
