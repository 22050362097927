// Models
import { Model } from "@caiena/model"
import models from "@/models"

const ModelsPlugin = {
  install({ config }) {
    // providing a lookup for relations
    _.assign(Model, {
      $lookupModel(nameOrPath) {
        return _.get(models, nameOrPath)
      }
    })

    config.globalProperties.$models = models
  }
}

export default ModelsPlugin
