<style lang="scss" scoped>

.requester-edit{
  .footer {
    border-top: 1px solid $gray-light;
  }
}

.padding-regular {
  padding: 16px
}

.checkbox {
  padding-top: 16px;
  border-top: 1px solid $gray-light-2;

  &-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline: 16px;
  }
}

</style>


<template lang="pug">

.requester-edit
  .body.padding-regular.grid.gap-regular(v-if="resource.cpf")
    fake-input(
      :label="cpfTranslatedLabel",
      :value="resource.cpf"
      mask="000.000.000-00"
    )

    input-field(
      name="requester[name]",
      v-model="resource.name",
      :placeholder="$t('.fields.name.placeholder')",
      :errors="errors.name",
      :disabled="submitting",
      :maxlength="100"
    )

    input-field(
      name="requester[phone]",
      v-model="resource.phone",
      :mask="phoneMask",
      :placeholder="$t('.fields.phone.placeholder')",,
      :errors="errors.phone",
      :disabled="submitting",
    )

    input-field(
      name="requester[email]",
      v-model="resource.email",
      :placeholder="$t('.fields.email.placeholder')",
      :errors="errors.email",
      :disabled="submitting",
      :maxlength="100"
    )

    .checkbox(v-if="showCheckbox")
      checkbox-field(
        hide-label,
        name="requester[driver]",
        v-model="resource.driver",
        :errors="errors.driver",
        :disabled="checkboxDisabled || submitting",
      )
        template(#optionText="{ props: { inputId } }")
          label(:for="inputId")
            span.checkbox-text {{ $t('.fields.driver.label') }}

  .footer.padding-regular
    .flex.space-between
      app-button(
        theme="gray",
        outline,
        :disabled="submitting",
        @click="$emit('cancel')"
      )
        .flex.gap-small
          span {{ $t('.btn.cancel') }}

      app-button(
        :disabled="!canSubmit || submitting",
        @click="submit",
      ) {{ $t('.btn.save') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import InputField from "@/components/input-field/input-field.vue"
import CheckboxField from "@/components/checkbox-field/checkbox-field.vue"
import FakeInput from "@/components/fake-input"

// Models
import Requester from "@/models/requester/requester"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "RequesterFormEdit",

  components: {
    AppButton,
    InputField,
    CheckboxField,
    FakeInput
  },

  mixins: [FormMixin],

  props: {
    requester:    { type: Object, default: () => ({}) },
    showCheckbox: { type: Boolean, default: false }
  },

  emits: ["cancel", "update"],

  data() {
    return {
      i18nScope: "components.requester-form-edit",
      resource:  {}
    }
  },

  computed: {
    phoneMask() {
      return this.resource.phone?.length === 15
        ? "(00) 00000-0000"
        : "(00) 0000-00009"
    },

    checkboxDisabled() {
      return this.requester.driver
    },

    canSubmit() {
      const { cpf, name, phone, email } = this.resource
      return [cpf, name, phone, email].every(Boolean)
    },

    cpfTranslatedLabel() {
      return Requester.$tAttr("cpf")
    }
  },

  mounted() {
    this.resource = this.cloneRequester(this.requester)
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    cloneRequester(requester) {
      return new Requester({
        ..._.cloneDeep(requester)
      })
    },

    serializeAttributes() {
      return this.resource.$serialize()
    },

    async resetData() {
      await this.$nextTick()
      Object.assign(this.$data, this.$options.data.apply(this))
    },

    formatData(formData) {
      let phone = formData.phone.replace(/\D/g, "")
      phone = `${phone.substring(0, 2)}-${phone.substring(2)}`

      formData.phone = phone
      formData.cpf = this.requester.cpf

      if (!_.present(formData.driver)) { formData.driver = false }

      return formData
    },

    // @override FormMixin
    submitRequest() {
      const params = this.formatData(this.serializeAttributes())

      return this.$sdk.requesters.update({
        requesterId: this.requester.id,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("update", { requester: data })
    }
  }
}

</script>
