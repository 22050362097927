<style lang="scss" scoped>

.modal-sinister-attacher {
  p {
    margin: 0;
  }

  .wrapper {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $gray-light-2;
    }
  }

  .thumb {
    padding: 16px;
    background-image: var(--background-image);
    background-color: $yellow-light-2;
    border-radius: 8px;
  }

  .gray-box {
    padding: 8px;
    border-radius: 8px;
    background-color: $gray-light-4;
  }

  .third-party {
  font-family: $secondary-font;
  font-weight: 500;
  }

  .mandatory {
    font-size: 4px;
    color: $orange;
  }

  .description {
    .title {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 500;
    }

    .details {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .reminder {
    font-size: 12px;
    font-weight: 400;
  }
}

</style>


<template lang="pug">

.modal-sinister-attacher
  .wrapper
    .grid.gap-small
      .thumb.flex.center(:style="thumbCssStyle")
        img(:src="orangeCarCrashImg", :alt="$t('.asssets.orange-car-crash.alt')")

      .reminder.flex.gap-small.vertical-center
        i.fal.fa-envelope
        p {{ $t('.reminder') }}

  .wrapper.description.grid.gap-small
    .grid.gap-tiny
      label.title.flex.vertical-center.gap-tiny(for="sinisterDescription")
        p {{ $t('.fields.description.label') }}
        i.mandatory.fas.fa-circle

      p.details {{ $t('.fields.description.details') }}

      textarea-field(
        hide-label,
        name="sinisterDescription",
        id="sinisterDescription",
        v-model="resource.description"
        :placeholder="$t('.fields.description.placeholder')",
        :errors="errors.description"
      )

    .gray-box.flex.space-between.gap-regular
      label.third-party.grow(for="sinisterThirdParty") {{ $t(".fields.third-party.label") }}
      toggle-field(
        hide-value-text,
        name="sinisterThirdParty"
        id="sinisterThirdParty"
        v-model="resource.thirdParty"
      )

  .wrapper
    .flex.gap-regular.space-between
      app-button(
        outline,
        theme="gray",
        :disabled="submitting",
        @click="$emit('close')"
      ) {{ $t(".footer.cancel") }}

      app-button(
        :disabled="submitting",
        @click="submit"
      ) {{ $t(".footer.register") }}

</template>


<script>

// Assets
import orangeCarCrashImg from "@/assets/images/sinister/orange-car-crash.png"
import wavesImg from "@/assets/images/white-waves.png"

// Components
import TextareaField from "@/components/textarea-field/textarea-field.vue"
import AppButton from "@/components/app-button/app-button.vue"
import ToggleField from "@/components/toggle-field/toggle-field.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Models
import Base from "@/models/base/base"

class SinisterAttacher extends Base {
  static get attrs() {
    return ["thirdParty", "description"]
  }

  static get constraints() {
    return {
      description: {
        presence: true
      }
    }
  }
}

export default {
  name: "ModalSinisterAttacher",

  components: {
    TextareaField,
    AppButton,
    ToggleField
  },

  mixins: [FormMixin],

  emits: ["close", "create"],

  props: {
    ticketId: { type: [String, Number] },
    sinister: { type: Object }
  },

  data() {
    return {
      i18nScope: "components.modal-sinister-attacher",
      orangeCarCrashImg,
      //
      resource:  new SinisterAttacher()
    }
  },

  computed: {
    thumbCssStyle() {
      return {
        "--background-image": `url(${wavesImg})`
      }
    }
  },

  async mounted() {
    if (!this.sinister) return

    const { thirdParty, description } = this.sinister

    this.resource.thirdParty = thirdParty === true
    this.resource.description = description
  },

  methods: {
    // @override DirtyableMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override FormMixin
    submitRequest() {
      const params = this.serializeAttributes()

      return this.$sdk.tickets.sinister.create({
        ticketId: this.ticketId,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("create", { ...this.resource })

      const successMessage = this.$t(
        `.notifications.submit.success.${this.sinister ? "update" : "create"}`
      )

      this.$notifications.info(successMessage)
    }
  }
}

</script>
