<style lang="scss" scoped>

.person-search {
  p {
    margin: 0;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .footer {
    border-top: 1px solid $gray-light-2;
  }
}

</style>


<template lang="pug">

form.person-search(@submit.prevent="submit")
  .wrapper
    input-field.grow(
      name="person[cpf]",
      v-model="resource.cpf",
      mask="000.000.000-00",
      :label="$t('.fields.cpf.label')",
      :placeholder="$t('.fields.cpf.placeholder')",
      :errors="errors.cpf",
      :disabled="submitting",
    )

  .footer.wrapper.flex.space-between
    app-button(
      outline,
      theme="gray",
      :disabled="submitting",
      @click="$emit('cancel')"
    ) {{ $t('.button.cancel') }}

    app-button(
      :disabled="submitting",
      type="submit"
    ) {{ $t('.button.search') }}

</template>


<script>

// Components
import InputField from "@/components/input-field/input-field.vue"
import AppButton from "@/components/app-button/app-button.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Models
import Person from "@/models/person/person"

export default {
  name: "PersonSearch",

  components: {
    InputField,
    AppButton
  },

  emits: ["show", "no-show", "cancel"],

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "components.person-search",
      resource:  new Person()
    }
  },

  methods: {
    // @override DirtyableMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    unmaskCpf(cpf) {
      return cpf.replace(/\D/g, "")
    },

    // @override FormMixin
    submitRequest() {
      const attributes = this.serializeAttributes()
      const cpf = this.unmaskCpf(attributes.cpf)
      return this.$sdk.people.show({ cpf  })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("show", data)
    },

    // @override FormMixin
    onSubmitError(error) {
      const { statusCode } = error

      if (statusCode === 404) {
        return this.$emit("no-show", { cpf: this.unmaskCpf(this.resource.cpf) })
      }

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>
