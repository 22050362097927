<style lang="scss" scoped>

.loading {
  height: 4px;
  display: block;
  width: 100%;
  border-radius: 2px;
  background-clip: padding-box;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: $primary;

  .indeterminate {
    background-color: $primary-light;

    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
    }
  }
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

</style>


<template lang="pug">

transition(name="fade")
  .loading(v-show="active")
    .indeterminate

</template>


<script>

// Modules
import { loading } from "@/modules/loading"

export default {
  name: "Loading",

  emits: [],

  data() {
    return {
      i18nScope: "components.loading",

      active: false
    }
  },

  created() {
    this.$events.on(["loading:start", "loading:stop"], this.refresh)
  },

  beforeUnmount() {
    this.$events.off(["loading:start", "loading:stop"], this.refresh)
  },

  methods: {
    refresh() {
      this.active = loading.active
    }
  }
}

</script>
