<style lang="scss" scoped>

.requester-selector {
  --requester-selector-padding: 16px;
  --requester-selector-gap: 16px;
  --requester-selector-gap-small: 8px;

  gap: var(--requester-selector-gap);

  p {
    margin: 0;
  }

  .header {
    font-size: 1rem;

    small {
      font-size: 14px;
    }
  }

  .heading {
    font-weight: 500;
  }

  .check {
    font-size: 12px;
    width: 16px;
    aspect-ratio: 1;
  }

  .sparkle {
    color: $orange;
    font-size: 4px;
  }

  .fold {
    margin-left: auto;
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 24px;
    aspect-ratio: 1;
    font-size: 1rem;
    cursor: pointer;
  }

  .requester-skeleton {
    border: 1px solid $gray-light-2;
    border-radius: 8px;
    padding: var(--requester-selector-padding);
    gap: var(--requester-selector-gap-small);
  }

  .content {
    gap: var(--requester-selector-gap-small);

    &.transparent {
      opacity: 0.6;
    }
  }

  .empty-list {
    padding: 16px;
    display: grid;
    justify-items: center;
    background-color: $gray-light-4;
    border-radius: 4px;

    p {
      text-align: center;
      font-style: italic;
      color: $gray;
    }
  }

  .add-button {
    margin-block-start: 8px;
  }
}

</style>


<template lang="pug">

.requester-selector.grid
  header.header.flex
    .title
      p.heading.flex.gap-tiny {{ $t('.header') }}
        span.sparkle.flex.vertical-center
          i.fas.fa-circle

      small.inline-flex.gap-tiny(v-show="!showRequesters && !!chosenRequester?.name")
        .check.flex.center.vertical-center
          i.far.fa-check

        span {{ chosenRequester.name }}

    button.fold.self-vertical-start(
      @click="showRequesters = !showRequesters",
      v-show="!initializing",
    )
      i.fal(:class="`fa-angle-${showRequesters ? 'up' : 'down'}`")

  .content.grid.skeleton(v-if="initializing")
    .requester-skeleton(v-for="number of 2", :key="number")
      loading-lines(icon)

  template(v-else)
    .content.grid(v-show="showRequesters", :class="{ transparent: fetching }")
      .empty-list.gap-small(v-if="!requesters.length")
        img(:src="emptyUser", :alt="$t('.empty-list.alt')")
        p {{ $t('.empty-list.text') }}

      template(v-else)
        requester-selector-option(
          v-for="requester of requesters",
          :key="requester.cpf",
          :requester="requester",
          :selected="isSelected(requester.id)",
          :transparent="fetching",
          :tag="isSelected(requester.id) ? 'div' : 'label'",
          :for="`requester-${requester.id}`",
          :disabled="disabled",
          @edit="openModal('edit')"
        )
          template(#left)
            radio-field.radio(
              hide-value-text,
              name="newTickets[requesterId]",
              v-model="model",
              :value="String(requester.id)",
              :id="`requester-${requester.id}`",
              :disabled="disabled"
            )

      app-button.add-button(
        size="small",
        theme="link",
        @click="openModal('create')"
      ) {{ $t('.new-requester') }}

  app-modal(
    avoid-close-on-click-outside,
    close-button
    :heading="$t(`.form.heading.${formType}`)"
    :width="690",
    :show="modalShown"
    @close="closeModal"
  )
    template(#default)
      requester-form-new(
        v-if="formType === 'create'"
        :contract-rid="contractRid",
        :show-checkbox="enableDriverAssingment",
        @cancel="closeModal",
        @create="handleRequesterCreate",
      )

      requester-form-edit(
        v-if="formType === 'edit'"
        :requester="chosenRequester",
        :show-checkbox="enableDriverAssingment",
        @cancel="closeModal",
        @update="handleRequesterUpdate",
      )

</template>


<script>

// Assets
import emptyUser from "@/assets/images/list-illustrations/empty-user.svg"

// Components
import RadioField from "@/components/radio-field/radio-field.vue"
import AppButton from "@/components/app-button/app-button.vue"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import AppModal from "@/components/app-modal"
import RequesterFormEdit from "@/components/requester-form-edit"
import RequesterFormNew from "@/components/requester-form-new"

import RequesterSelectorOption from "./option"
import MaintenanceKilometerChecker from "../maintenance-kilometer-checker"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "RequesterSelector",

  components: {
    RadioField,
    AppButton,
    LoadingLines,
    AppModal,
    RequesterFormEdit,
    RequesterFormNew,

    RequesterSelectorOption,
    MaintenanceKilometerChecker
  },

  mixins: [FetchMixin],

  props: {
    modelValue:             { type: String },
    contractRid:            { type: String },
    enableDriverAssingment: { type: Boolean, default: false },
    disabled:               { type: Boolean, default: false }
  },

  emits: [
    "update:modelValue",
    "fetching", // começa a busca por solicitantes
    "index", // requisição de solicitantes finalizada com sucesso
    "requester-create", // sinalizar o create para outros componentes se atualizarem
    "requester-update" // sinalizar o update para outros componentes se atualizarem
  ],

  data() {
    return {
      i18nScope:      "components.requester-selector",
      emptyUser,
      showRequesters: true,
      requesters:     [],
      //
      modalShown:     false,
      formType:       ""
    }
  },

  computed: {
    model: {
      get() { return this.modelValue },
      set(val) { this.$emit("update:modelValue", val) }
    },

    chosenRequester() {
      if (!this.modelValue) return {}

      // eslint-disable-next-line eqeqeq
      return this.requesters.find(requester => requester.id == this.modelValue) || {}
    }
  },

  watch: {
    contractRid() {
      this.fetch()
    },

    fetching(to) {
      if (to) this.$emit("fetching")
    }
  },

  methods: {
    openModal(formType) {
      if (!["create", "edit"].includes(formType)) throw new Error("Modal type not existant")

      this.modalShown = true
      this.formType = formType
    },

    closeModal() {
      this.modalShown = false
      this.formType = ""
    },

    isSelected(id) {
      return this.modelValue === String(id)
    },

    handleRequesterCreate({ requester }) {
      this.$notifications.info(this.$t(".requester.create", { requester: requester.name }))

      if (requester.driver) {
        this.$notifications.info(this.$t(".driver.create", { driver: requester.name }))
      }

      this.$emit("requester-create", { id: requester.personId })
      this.fetch()
      this.closeModal()
    },

    handleRequesterUpdate({ requester }) {
      this.$notifications.info(this.$t(".requester.update", { requester: requester.name }))

      if (requester.driver) {

        const turnedIntoDriver = !this.chosenRequester.driver
        this.$notifications.info(
          this.$t(
            `.driver.${turnedIntoDriver ? "create" : "update"}`,
            { driver: requester.name }
          )
        )
      }

      this.$emit("requester-update", { id: requester.personId })
      this.fetch()
      this.closeModal()
    },

    // @overide FetchMixin
    fetchRequest() {
      return this.$sdk.contracts.requesters.index({ contractRid: this.contractRid })
    },

    // @overide FetchMixin
    onFetchSuccess({ data }) {
      this.requesters = data

      this.$emit("index", { requesters: data })
    },

    // XXX: Método a ser usado pelo componente pai para atualizar a lista
    publicDataUpdateMethod(id) {
      // eslint-disable-next-line eqeqeq
      const dataPresent = this.requesters.some(requester => requester.personId == id)

      if (id && !dataPresent) return

      this.fetch()
    }
  }
}

</script>
