// Base
import Base from "./Base"

import SupplierInstruction from "@/models/supplier/supplier-instruction"

class TicketSupplierInstructionRequests extends Base {
  static get selector() {
    return "tickets.supplierInstruction"
  }

  show({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("show", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/supplier_instruction`,
      params,
      options: {
        model: SupplierInstruction,

        ...options
      }
    })
  }
}

export default TicketSupplierInstructionRequests
