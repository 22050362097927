<style lang="scss" scoped>

.ticket-starter {
  --ticket-starter-padding: 16px;
  --ticket-starter-gap: 16px;
  --ticket-starter-gap-small: 8px;

  padding: var(--ticket-starter-padding);
  border: 1px solid $gray-light-2;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  gap: var(--ticket-starter-gap);

  p {
    margin: 0;
  }

  .heading {
    font-weight: 500;
  }

  .sparkle {
    color: $orange;
    font-size: 4px;
  }

  .footer {
    --footer-border: 1px solid #{$gray-light-2};

    border-top: var(--footer-border);
    padding-top: var(--ticket-starter-padding);
    gap: var(--ticket-starter-gap);

    & > .bottom-padding {
      padding-bottom: var(--ticket-starter-padding);
    }

    & > .bottom-border {
      border-bottom: var(--footer-border);
    }
  }

  .create{
    gap: var(--ticket-starter-gap-small);
  }

  .channel {
    gap: var(--ticket-starter-gap-small);

    .channel-option {
      display: inline-block;
      font-size: 14px;
      padding-inline: 3px;
      font-family: $secondary-font;
      font-weight: 400;
    }

    .fa-diamond {
      font-size: 4px;
      line-height: 4px;
      color: $gray-3;
    }
  }
}

</style>


<template lang="pug">

.ticket-starter.grid.gap-regular
  requester-selector(
    ref="requesters",
    v-model="resource.requesterId"
    :contract="contract",
    :contract-rid="contractRid",
    :enable-driver-assingment="contract.isGTF",
    :disabled="fieldsDisabled",
    @fetching="fetchingRequesters = true"
    @index="handleRequesterIndex"
    @requester-create="$emit('list-update')",
    @requester-update="$emit('list-update')",
  )

  maintenance-kilometer-checker.bottom-padding.bottom-border(
    ref="kilometrage",
    :vehicle-id="contract?.vehicle?.id || ''",
    :current="contract?.vehicle?.km || 0",
    :disabled="fieldsDisabled",
    v-model="newKilometrage"
  )

  .channel.grid.bottom-padding
    p.heading.flex.gap-tiny {{ $t('.channel.header') }}
      span.sparkle.flex.vertical-center
        i.fas.fa-circle

    .channel-options.flex.space-between.vertical-center
      template(v-for="(option, i) of channelOptions", :key="option")
        radio-field.radio(
          hide-value-text,
          v-model="resource.channel",
          :name="option",
          :value="option",
          :id="`channel-${option}`",
          :disabled="fieldsDisabled"
        )
          template(#optionText="{ props: { inputId } }")
            label.channel-option(:for="inputId") {{ $t(`.radio.${option}`) }}

        i.fas.fa-diamond(v-if="channelOptions.length - 1 !== i", :key="i")

  slot(name="beforeCta")

  app-button(
    full-width,
    :disabled="missingFormData || fieldsDisabled",
    @click="handleNewTicket"
  )
    .create.flex.vertical-center
      p {{ $t('.new-ticket') }}

      i.fas.fa-plus

</template>


<script>

// Modules
import { auth } from "@/modules/auth"

// Components
import AppButton from "@/components/app-button/app-button.vue"
import RadioField from "@/components/radio-field/radio-field.vue"
import MaintenanceKilometerChecker from "../maintenance-kilometer-checker"
import RequesterSelector from "../requester-selector"

const channelOptions = ["phone", "chat", "email"]

export default {
  name: "TicketStarter",

  components: {
    AppButton,
    RadioField,
    MaintenanceKilometerChecker,
    RequesterSelector
  },

  props: {
    contract:            { type: Object },
    existingDraftTicket: { type: Object },
    contractRid:         { type: String },
    disabled:            { type: Boolean, default: false }
  },

  emits: ["list-update", "create"],

  data() {
    return {
      i18nScope:      "components.ticket-starter",
      //
      newKilometrage: "",
      channelOptions,
      //
      resource:       {
        requesterId: "",
        currentKm:   0,
        channel:     channelOptions[0]
      },
      //
      submitting:         false,
      fetchingRequesters: false,
      user:               auth.getUser()
    }
  },

  computed: {
    missingFormData() {
      const { requesterId, channel, currentKm } = this.resource

      return ![requesterId, channel, currentKm].every(Boolean)
    },

    fieldsDisabled() {
      return [
        this.disabled,
        this.submitting,
        this.fetchingRequesters
      ].some(Boolean)
    }
  },

  watch: {
    newKilometrage(to) {
      this.resource.currentKm = to ? Number(to.replace(/\D/g, "")) : 0
    },

    /* eslint-disable-next-line func-names */
    "contract.id": function () {
      this.resetForm()
    }
  },

  methods: {
    handleRequesterIndex({ requesters }) {
      this.fetchingRequesters = false

      if (!requesters.length) {
        this.resource.requesterId = ""
        return
      }

      if (this.resource.requesterId) return

      this.resource.requesterId = String(requesters[0].id)
    },

    async resetForm() {
      this.resource = {
        requesterId: "",
        currentKm:   0,
        channel:     channelOptions[0]
      }

      this.newKilometrage = ""
    },

    formatFormData() {
      const { requesterId, channel, currentKm } = this.resource

      const params = _.snakeizeKeys({
        channel,
        currentKm,
        userId:      this.user.id,
        requesterId: Number(requesterId),
        contractId:  Number(this.contract.id)
      })

      return params
    },

    async handleNewTicket() {
      if (!this.$refs.kilometrage) return

      const validKilometrage = this.$refs.kilometrage.handleCheck({ noFetch: true })

      if (!validKilometrage) return

      let cancellationRanError = false

      if (this.existingDraftTicket) cancellationRanError = await this.cancelExistingDraft()

      if (!cancellationRanError) await this.submit()
    },

    async cancelExistingDraft() {
      let encounteredError = false

      try {
        this.submitting = true

        const automaticCancellationReason = this.$t(".automatic-cancellation-reason")

        const params = _.snakeizeKeys({
          cancellationReason: automaticCancellationReason
        })

        await this.$sdk.tickets.cancellation.show({
          ticketId: this.existingDraftTicket.id,
          params
        })
      }
      catch (err) {
        encounteredError = true
        console.error(err)
        this.$notifications.error(this.$t(".notifications.submit.error.cancellation"))
      }
      finally {
        this.submitting = false
      }

      return encounteredError
    },

    async submit() {
      try {
        this.submitting = true

        const { data } = await this.$sdk.tickets.create({ params: this.formatFormData() })

        this.$emit("create", data)
      }
      catch (err) {
        console.error(err)
        this.$notifications.error(this.$t(".notifications.submit.error.create"))
      }
      finally {
        this.submitting = false
      }
    },

    // XXX: Método a ser usado pelo componente pai para atualizar a lista do filho
    publicDataUpdateMethod(id) {
      if (!this.$refs.requesters?.publicDataUpdateMethod) return

      this.$refs.requesters.publicDataUpdateMethod(id)
    }
  }
}

</script>
