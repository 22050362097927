<style lang="scss" scoped>

.drivers-table {
  .outside {
    font-size: 14px;
  }

  .summary {
    font-weight: 400;
  }

  .counter {
    font-family: $primary-font;
    font-weight: 300;
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3;
  }

  .fa-steering-wheel {
    font-size: 12px;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  .transparent {
    opacity: 0.4;
  }

  .no-margin-bottom :deep(.footer) {
    margin-bottom: 0;
  }

  .empty {
    background-color: $gray-light-3;
    padding: 12px;
    border-top: 1px solid $gray-light-2;
    border-bottom: 1px solid $gray-light-2;

    p {
      margin: 0;
      color: $gray;
      font-size: 14px;
      font-style: italic;
    }
  }
}

</style>


<template lang="pug">

.drivers-table.grid.gap-regular
  slot(name="outside", v-bind="{ count: resource.length }")
    .outside.flex.space-between
      .summary.flex.vertical-center.gap-tiny
        span.icon.flex.vertical-center.center
          i.fas.fa-steering-wheel

        span {{$t('.title')}}

        template(v-if="resource.length")
          i.fas.fa-diamond

          span.counter {{$t('.counter', { count: this.resource.length })}}

      app-button.font-small(
        v-if="contractRid"
        @click="openModalCreate",
        theme="link",
        :disabled="!creatable"
      ) {{$t('.new')}}

  app-table.no-margin-bottom(
    :column-sizes="columnSizes",
    :cols-count="colsCount",
    :empty="listIsEmpty",
    :initializing="initializing"
    :class="{ transparent: fetching && !initializing }"
  )
    template(#header)
      drivers-table-header(:column-sizes="columnSizes", :hide-edit="!contractRid")

    template(#rows, v-if="!listIsEmpty")
      drivers-table-row(
        v-for="(driver, i) of resource",
        :key="i",
        :driver="driver",
        :column-sizes="columnSizes",
        :editable="editable",
        :hide-edit="!contractRid",
        @edit="openModalEdit"
      )

    template(#empty)
      .empty.grid.center.gap-small
        img(:src="emptyUser" :alt="$t('.empty-icon')")
        p {{ $t(".counter.zero") }}

  app-modal(
    avoid-close-on-click-outside,
    close-button
    :heading="$t(`.form.heading.${formType}`)"
    :width="690",
    :show="modalShown"
    @close="closeModal"
  )
    template(#default)
      driver-form-new(
        v-if="formType === 'create'",
        :contract-rid="contractRid",
        @close="closeModal"
        @create="handleCreate"
      )

      driver-form-edit(
        v-if="formType === 'edit'",
        :contract-rid="contractRid"
        :driver="selectedForEdit"
        @close="closeModal"
        @update="handleUpdate"
      )

</template>


<script>

// Assets
import emptyUser from "@/assets/images/list-illustrations/empty-user.svg"

// Components
import AppButton from "@/components/app-button/app-button.vue"
import AppTable from "@/components/app-table/app-table.vue"
import AppModal from "@/components/app-modal"

import DriversTableHeader from "./header"
import DriversTableRow from "./row"
import DriverFormEdit from "@/views/contract/_components/driver-form-edit"
import DriverFormNew from "@/views/contract/_components/driver-form-new"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "DriversTable",

  components: {
    AppButton,
    AppTable,
    AppModal,
    DriversTableHeader,
    DriversTableRow,
    DriverFormEdit,
    DriverFormNew
  },

  mixins: [FetchMixin],

  props: {
    contractRid:      { type: String },
    contractId:       { type: [Number, String] },
    creatable:        { type: Boolean, default: true },
    editable:         { type: Boolean, default: true },
    hideCreateButton: { type: Boolean, default: true }
  },

  emits: [
    "driver-create", // sinaliza o create de condutor
    "driver-update" // sinaliza o update de condutor
  ],

  data() {
    return {
      i18nScope:       "components.drivers-table",
      emptyUser,
      //
      modalShown:      false,
      formType:        "",
      //
      resource:        [],
      selectedForEdit: {}
    }
  },

  computed: {
    columnSizes() {
      // XXX: expeços afetam colsCount
      let columns = "minmax(0,1fr) 134px 143px minmax(0,1fr)"

      if (this.contractRid) columns += " 48px"

      return columns
    },

    colsCount() {
      return this.columnSizes.split(" ").length
    },

    listIsEmpty() {
      return this.resource.length === 0
    },

    encodedContractId() {
      if (!this.contractId) return ""

      return btoa(`vetor:${this.contractId}`)
    }
  },

  watch: {
    contractRid() {
      this.restartFetch()
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.contracts.drivers.index({
        contractRid: this.contractRid || this.encodedContractId
      })
    },

    openModal(formType) {
      if (!["create", "edit"].includes(formType)) throw new Error("Modal type not existant")

      this.modalShown = true
      this.formType = formType
    },

    closeModal() {
      this.selectedForEdit = {}
      this.modalShown = false
      this.formType = ""
    },

    openModalCreate() {
      if (!this.creatable) return

      this.openModal("create")
    },

    openModalEdit({ driver }) {
      if (!this.editable) return

      this.selectedForEdit = driver
      this.openModal("edit")
    },

    restartFetch() {
      this.initialized = false
      this.fetch()
    },

    handleCreate({ driver }) {
      this.$notifications.info(this.$t(".driver.create", { driver: driver.name }))
      this.$notifications.info(this.$t(".requester.create", { requester: driver.name }))

      this.$emit("driver-create", { id: driver.id })
      this.fetch()
      this.modalShown = false
    },

    handleUpdate({ driver }) {
      this.$notifications.info(this.$t(".driver.update", { driver: driver.name }))
      this.$notifications.info(this.$t(".requester.update", { requester: driver.name }))

      this.$emit("driver-update", { id: driver.id })
      this.fetch()
      this.closeModal()
    },

    // XXX: Método a ser usado pelo componente pai para atualizar a lista
    publicDataUpdateMethod(id) {
      // eslint-disable-next-line eqeqeq
      const dataPresent = this.resource.some(driver => driver.id == id)

      if (id && !dataPresent) return

      this.fetch()
    }
  }
}

</script>
