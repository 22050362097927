// Views
import Tickets from "@/views/tickets/tickets.vue"
import TicketsDraft from "@/views/tickets/draft"
import TicketsDraftType from "@/views/tickets/draft/type"
import TicketsDraftService from "@/views/tickets/draft/service"
import TicketsDraftSupplier from "@/views/tickets/draft/supplier"
import TicketsDraftConfirmation from "@/views/tickets/draft/confirmation"
import TicketsEnd from "@/views/tickets/end"
import TicketsShow from "@/views/tickets/show"
import TicketsReschedule from "@/views/tickets/reschedule"
import TicketsRescheduleSupplier from "@/views/tickets/reschedule/supplier"
import TicketsRescheduleConfirmation from "@/views/tickets/reschedule/confirmation"

export default [
  {
    path:      "/tickets",
    name:      "tickets",
    component: Tickets,
    meta:      {
      auth: true
    }
  },
  {
    path:      "/tickets/:id/draft",
    name:      "tickets-draft",
    component: TicketsDraft,
    meta:      {
      auth: true
    },
    redirect: { name: "tickets-draft.type" },
    children: [
      {
        path:      "",
        name:      "tickets-draft.type",
        component: TicketsDraftType,
        meta:      { step: 1 }
      },
      {
        path:      "service",
        name:      "tickets-draft.service",
        component: TicketsDraftService,
        meta:      { step: 2 }
      },
      {
        path:      "supplier",
        name:      "tickets-draft.supplier",
        component: TicketsDraftSupplier,
        meta:      { step: 3 }
      },
      {
        path:      "confirmation",
        name:      "tickets-draft.confirmation",
        component: TicketsDraftConfirmation,
        meta:      { step: 4 }
      }
    ]
  },
  {
    path:      "/tickets/:id/end",
    name:      "tickets-end",
    component: TicketsEnd,
    meta:      {
      auth: true
    }
  },
  {
    path:      "/tickets/:id",
    name:      "tickets-show",
    component: TicketsShow,
    meta:      {
      auth: true
    }
  },
  {
    path:      "/tickets/:id/reschedule",
    name:      "tickets-reschedule",
    component: TicketsReschedule,
    meta:      {
      auth: true
    },
    redirect: { name: "tickets-reschedule.supplier" },
    children: [
      {
        path:      "",
        name:      "tickets-reschedule.supplier",
        component: TicketsRescheduleSupplier,
        meta:      { step: 1 }
      },
      {
        path:      "confirmation",
        name:      "tickets-reschedule.confirmation",
        component: TicketsRescheduleConfirmation,
        meta:      { step: 2 }
      }
    ]
  }
]
