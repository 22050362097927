import Base from "../base/base"

class Contract extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "status",
      "businessUnit",
      "remoteId",
      "dealEndAt"
    ])
  }

  get dealEndPresent() {
    return _.present(this.dealEndAt)
  }

  get isGTF() {
    return this.businessUnit === "gtf"
  }

  get isMensal() {
    return ["rac_mensal", "mensal_flex"].includes(this.businessUnit)
  }

  static get enums() {
    return {
      status: {
        canceled: "canceled",
        open:     "open",
        closed:   "closed",
        done:     "done"
      },

      businessUnit: {
        rac_eventual: "rac_eventual",
        rac_mensal:   "rac_mensal",
        mensal_flex:  "mensal_flex",
        zkm:          "zkm",
        gtf:          "gtf"
      }
    }
  }

  static get relations() {
    return {
      customer:   { type: "hasOne", model: "Customer" },
      vehicle:    { type: "hasOne", model: "Vehicle" },
      mainDriver: { type: "hasOne", model: "Customer" }
    }
  }
}

export default Contract
