// Modules
import { i18n } from "@/modules/i18n"

// Assets
import iconsMap from "@/assets/images/ticket"

import Base from "../base/base"

class Ticket extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "scheduledAt",
      "type",
      "state",
      "remoteId",
      "openingDate",
      "reason",
      "currentKm",
      "remoteRequester",
      "requesterId",
      "userId",
      "contractId",
      "channel",
      "scheduleState",
      "canceledAt",
      "cancellationReason"
    ])
  }

  static get enums() {
    return {
      type: {
        "Tickets::Draft":                 "Tickets::Draft",
        "Tickets::PreventiveMaintenance": "Tickets::PreventiveMaintenance",
        "Tickets::Repair":                "Tickets::Repair",
        "Tickets::Electric":              "Tickets::Electric",
        "Tickets::AutoBodyAndPaint":      "Tickets::AutoBodyAndPaint",
        "Tickets::Lamp":                  "Tickets::Lamp",
        "Tickets::TiresAndWheels":        "Tickets::TiresAndWheels",
        "Tickets::Glass":                 "Tickets::Glass",
        "Tickets::Locksmith":             "Tickets::Locksmith",
        "Tickets::Tapestry":              "Tickets::Tapestry",
        "Tickets::Armoring":              "Tickets::Armoring",
        "Tickets::Assistance":            "Tickets::Assistance",
        "Tickets::Withdrawal":            "Tickets::Withdrawal",
        "Tickets::Documentation":         "Tickets::Documentation"
      },
      state: {
        draft:    "draft",
        open:     "open",
        ongoing:  "ongoing",
        done:     "done",
        canceled: "canceled"
      },
      scheduleState: {
        pre_booked: "pre_booked",
        booked:     "booked"
      }
    }
  }

  static get relations() {
    return {
      requester: { type: "hasOne", model: "Requester" },
      supplier:  { type: "hasOne", model: "Supplier" },
      contract:  { type: "hasOne", model: "Contract" },
      user:      { type: "hasOne", model: "User" }
    }
  }

  get disabledTypes() {
    const initiallyDisabled = [
      "Tickets::Assistance",
      // "Tickets::Withdrawal",
      "Tickets::Documentation"
    ]

    const notEnabledForMensalContracts = [
      "Tickets::AutoBodyAndPaint",
      "Tickets::Glass",
      "Tickets::Locksmith",
      "Tickets::Tapestry",
      "Tickets::Armoring"
    ]

    return this.contract.isMensal
      ? [...initiallyDisabled, ...notEnabledForMensalContracts]
      : initiallyDisabled
  }

  get typeOrReason() {
    return this.type && !this.isTypeDraft
      ? i18n.t(`models.ticket.enums.type.${this.type}`)
      : this.reason
  }

  get isTypeDraft() {
    return this.type === "Tickets::Draft"
  }

  get isStateDraft() {
    return this.state === "draft"
  }

  static getTypeSubtitle(type) {
    return i18n.t(`models.ticket.subtitle.${type}`) || ""
  }

  static getTypeDescription(type) {
    return i18n.t(`models.ticket.description.${type}`) || ""
  }

  static getTypeIcon(type) {
    return iconsMap.has(type)
      ? iconsMap.get(type)
      : ""
  }

  get processedState() {
    if (this.state !== "open") return this.state

    return this.scheduleState || "booked"
  }

  get processedStateTranslated() {
    const correctEnum = this.state === "open" ? "scheduleState" : "state"

    return i18n.t(`models.ticket.enums.${correctEnum}.${this.processedState}`) || ""
  }

  get isPrebooked() {
    return this.processedState === "pre_booked"
  }

  get canBeNotified() {
    return ["open", "ongoing"].includes(this.state)
  }

  get canBeRescheduled() {
    return ["open"].includes(this.state)
  }
}

export default Ticket
