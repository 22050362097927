<style lang="scss" scoped>

.target-item {
  p {
    margin: 0;
  }

  .field {
    flex-basis: 179px;
  }

  .icon-button {
    appearance: none;
    background-color: transparent;
    border: none;
    width: 24px;
    aspect-ratio: 1;
    font-size: 24px;
    padding: 0;
    margin-top: calc(17px + 8px); //compensação label de select (font + margin)
    position: relative;

    :where(.fas, .fal) {
      position: absolute;
      top: 0;
      left: 0;
    }

    .fas {
      opacity: 0;
      background-color: white;
    }

    &.primary {
      color: $primary;
    }

    &:disabled,
    &[disabled],
    &.disabled {
      color: $gray-3;
    }

    &:not(.disabled, :disabled, [disabled]):hover {
      cursor: pointer;

      .fas {
        opacity: 1;
      }
    }

    &:focus-visible {
      outline: 2px solid $orange;
      outline-offset: 2px;
    }
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

.target-item
  .flex.vertical-bottom.gap-regular
    select-field.field(
      name="service-position",
      v-model="target.position",
      :options="positionOptions",
      :label="$t('.fields.position.label')"
      :placeholder="$t('.fields.position.placeholder')",
      :disabled="disabled"
    )

    select-field.field(
      name="service-location",
      hide-label
      v-model="target.location",
      :options="locationOptions",
      :placeholder="$t('.fields.location.placeholder')",
      :disabled="disabled"
    )

    button.icon-button.self-vertical-center(
      v-tooltip="$t('.tooltip.remove')",
      @click="emitRemove",
      :disabled="_cannotRemove"
    )
      i.fal.fa-times-circle
      i.fas.fa-times-circle

    button.icon-button.primary.self-vertical-center(
      v-if="canAdd"
      v-tooltip="$t('.tooltip.add')",
      @click="emitAdd",
      :disabled="_cannotAdd"
    )
      i.fal.fa-plus-circle
      i.fas.fa-plus-circle

  p.error(v-if="errorMessage") {{ errorMessage }}

</template>


<script>

// Components
import SelectField from "@/components/select-field/select-field.vue"

export default {
  name: "ServiceTargetsFieldsItem",

  props: {
    target:     { type: Object, default: () => ({}) },
    disabled:   { type: Boolean, default: false },
    parentList: { type: Array },
    canAdd:     { type: Boolean, default: false },
    index:      { tupe: Number },
    options:    { type: Array, default: () => [] },
    errors:     { type: Object }
  },

  emits: ["add", "remove"],

  components: {
    SelectField
  },

  data() {
    return {
      i18nScope: "components.service-targets-fields-item"
    }
  },

  computed: {
    positionOptions() {
      const possibleCombinations =  this.target.location
        ? this.options.filter(({ location }) => this.target.location === location.value)
        : this.options

      const possibleOptionsSet = new Set(possibleCombinations.map(({ position }) => position.value))

      const optionsReduced = [...possibleOptionsSet].map(val => this.options.find(({ position }) => position.value === val).position)

      return optionsReduced
    },

    locationOptions() {
      const possibleCombinations =  this.target.position
        ? this.options.filter(({ position }) => this.target.position === position.value)
        : this.options

      const possibleOptionsSet = new Set(possibleCombinations.map(({ location }) => location.value))

      const optionsReduced = [...possibleOptionsSet].map(val => this.options.find(({ location }) => location.value === val).location)

      return optionsReduced
    },

    _cannotAdd() {
      const bothFieldsFilled = [this.target.position, this.target.location].every(Boolean)

      return this.disabled || !this.canAdd || !bothFieldsFilled
    },

    _cannotRemove() {
      return this.parentList.length <= 1
    },

    errorMessage() {
      if (!(typeof this.errors === "object")) return ""
      const keyValues = Object.values(this.errors)

      if (!keyValues.length) return ""
      return keyValues[0][0]
    }
  },

  methods: {
    emitAdd() {
      if (this._cannotAdd) return

      this.$emit("add")
    },

    emitRemove() {
      if (this._cannotRemove) return

      this.$emit("remove", this.index)
    }
  }
}

</script>
