<style lang="scss" scoped>

.driver-header {
  grid-template-columns: var(--driver-template-columns);
  gap: var(--driver-gap);
  //
  border-radius: 8px 8px 0 0;
  background-color: $gray-light-3;
  font-family: $secondary-font;

  p {
    margin: 0;
  }

  .cell {
    padding: 8px;
    font-size: 0.875rem;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }
}

</style>


<template lang="pug">

.driver-header.grid.vertical-center(:style="cssVars")
  .cell
    p {{$t('.name')}}

  .cell
    p {{$t('.cpf')}}

  .cell
    p {{$t('.phone')}}

  .cell
    p {{$t('.email')}}

  .cell(v-if="!hideEdit")
    i.fas.fa-diamond.decoration

</template>


<script>

export default {
  name: "DriversTableHeader",

  props: {
    columnSizes: { type: String, default: "1fr" },
    gap:         { type: String, default: "0px" },
    hideEdit:    { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.drivers-table-header"
    }
  },

  computed: {
    cssVars() {
      return {
        "--driver-template-columns": this.columnSizes,
        "--driver-gap":              this.gap
      }
    }
  }
}

</script>
