// 3rd Party
// ref https://flatpickr.js.org/localization/
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt"

function initialize() {
  flatpickr.localize(Portuguese)
}

export default initialize
