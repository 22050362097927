<!--
<doc lang="markdown">

Campo de _input_ padrão

Como usar:
```pug
input-field(
  v-model="resource.username",
  autocomplete="username",
  id="username",
  name="login[username]",
  :disabled="submitting || fetching",
  :error="hasError",
  :errors="errors.username",
  :label="$t('.fields.username.label')",
  :placeholder="$t('.fields.username.placeholder')",
  data-testid="usernameInput"
)
```

</doc>
-->

<style lang="scss" scoped>

.input-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    --icons-gap: 16px;

    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .input-inner {
      --icon-padding-left-adjustment:var(--input-field-inline-padding);
      --icon-padding-right-adjustment:var(--input-field-inline-padding);

      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: var(--input-field-radius);
      border: 1px solid $gray-3;
      color: $gray;
      display: inline-block;
      font-size: inherit;
      height: var(--input-field-height);
      line-height: var(--input-field-height);
      outline: none;
      padding-block: 1px;
      width: 100%;
      //
      padding-left: var(--icon-padding-left-adjustment);
      padding-right: calc(
        var(--icon-padding-right-adjustment) +
        var(--second-icon-padding-right-adjustment, 0px)
      );

      &.icon-right {
        --icon-padding-right-adjustment: calc(
          var(--input-field-inline-padding) +
          var(--input-field-icon-size, 0.875em) +
          var(--input-field-icon-spacing, 8px)
        );
      }

      &.clear-icon {
        --second-icon-padding-right-adjustment: calc(
          var(--input-field-icon-size, 0.875em)
        );

        cursor: pointer;

        &.icon-right {
          --second-icon-padding-right-adjustment: calc(
            var(--input-field-icon-size, 0.875em) +
            calc(var(--icons-gap) * 2)
          );
        }
      }

      &.icon-left {
        --icon-padding-left-adjustment: calc(
          var(--input-field-inline-padding) +
          var(--input-field-icon-size, 0.875em) +
          var(--input-field-icon-spacing, 8px)
        );
      }

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding-left: calc(var(--icon-padding-left-adjustment) - 1px);
        padding-right: calc(var(--icon-padding-right-adjustment) - 1px);
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-3;
        opacity: 1;
      }

      &.disabled {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray;
        cursor: progress;
      }

      &.error {
        border-color: $red;
      }
    }

    .suffix {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      color: $gray;
      top: 12px;
      right: 0;
      display: block;
      padding-inline: var(--input-field-icon-spacing, 8px) var(--input-field-inline-padding);
      user-select: none;
      pointer-events: none;
    }

    .icons {
      position: absolute;
      font-size: var(--input-field-icon-size, 0.875em);
      top: calc(var(--input-field-height) / 2);
      transform: translateY(-50%);
      color: $gray-dark-2;
      gap: var(--icons-gap);

      &.right {
        right: var(--input-field-inline-padding);
      }

      &.left {
        left: var(--input-field-inline-padding);
      }

      .clickable {
        cursor: pointer;
      }

      .divider {
        min-width: 1px;
        min-height: 1px;
        background-color: $gray-light;
      }
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

.input-field(:style="cssVars")
  label.label-content.flex.vertical-center(
    v-if="!hideLabel",
    :class="{ error: hasErrors, focus }",
    :for="inputId"
  )
    span {{ labelText }}
    template(v-if="optional")
      span {{ $t('form.optional') }}

    template(v-else-if="mandatory")
      i.mandatory-icon.fas.fa-circle(
        v-tooltip.top="$t('form.mandatory.tooltip')"
      )

    template(v-if="info")
      i.info-icon.far.fa-info-circle(
        v-tooltip.top="infoTooltip",
        @click="$emit('info-click')"
      )

  .input-wrapper
    input.input-inner(
      v-mask="mask",
      ref="input",
      :autocomplete="autocompleteValue",
      :autofocus="autofocus",
      :class="inputClasses",
      :disabled="disabled || loading",
      :id="inputId",
      :maxlength="maxlength",
      :name="name",
      :placeholder="placeholder",
      :readonly="readonly",
      :style="inputInnerStyle",
      :type="type",
      :value="modelValue",
      @blur="onBlur",
      @focus="onFocus",
      @input="onInput"
    )

    template(v-if="suffix")
      span.suffix(ref="suffix") {{ suffix }}

    .icons.left
      i(
        v-if="iconLeft",
        v-tooltip.bottom="tooltipLeft",
        :class="{ [iconLeft]: true, left: true, clickable: clickableIcon }",
        @click="$emit('left-icon-click')"
      )

    .icons.right.inline-flex(v-if="iconRight || clearIcon")
      i.far.fa-times.clickable(
        v-if="clearIcon",
        @click="$emit('update:modelValue', '')"
      )

      .divider(v-if="iconRight && clearIcon")

      i(
        v-if="iconRight",
        v-tooltip.bottom="tooltipRight",
        :class="{ [iconRight]: true, clickable: clickableIcon }",
        @click="$emit('right-icon-click')"
      )

  template(v-if="hasMessageErrors")
    span.error-message {{ errors[0] }}

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field-mixin"

export default {
  name: "InputField",

  emits: ["info-click", "left-icon-click", "right-icon-click"],

  mixins: [FieldMixin],

  props: {
    autocomplete:  { type: [Boolean, String], default: false },
    clickableIcon: { type: Boolean, default: false },
    height:        { type: Number, default: 40 },
    iconLeft:      { type: String, default: null },
    iconRight:     { type: String, default: null },
    clearIcon:     { type: Boolean, default: false },
    mask:          { type: [String, Object], default: null },
    maxlength:     { type: Number, default: null },
    suffix:        { type: String, default: null },
    tooltipLeft:   { type: String, default: null },
    tooltipRight:  { type: String, default: null },
    type:          { type: String, default: "text" },
    borderRadius:  { type: Number, default: 4 },
    inlinePadding: { type: Number, default: 13 }
  },

  data() {
    return {
      i18nScope: "components.input-field",

      isMounted: false
    }
  },

  computed: {
    cssVars() {
      return {
        "--input-field-height":         `${this.height}px`,
        "--input-field-radius":         `${this.borderRadius}px`,
        "--input-field-inline-padding": `${this.inlinePadding}px`
      }
    },

    inputClasses() {
      return {
        loading:      this.loading,
        disabled:     this.disabled,
        error:        this.hasErrors,
        "icon-left":  this.iconLeft,
        "icon-right": this.iconRight,
        "clear-icon": this.clearIcon
      }
    },

    inputInnerStyle() {
      if (!this.isMounted) return {}

      const style = {}

      if (this.suffix && !!this.$refs.suffix) {
        style.paddingRight = `${this.$refs.suffix.offsetWidth}px`
      }

      return style
    },

    autocompleteValue() {
      if (!this.autocomplete) return "off"
      return typeof this.autocomplete === "string" ? this.autocomplete : ""
    }
  },

  mounted() {
    this.isMounted = true
  },

  methods: {
    onInput(event) {
      if (this.disabled) return

      let value = null
      if (event.target.value !== "") value = event.target.value

      this.$emit("update:modelValue", value)
    }
  }
}

</script>
