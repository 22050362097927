// 3rd Party
import axios from "axios"
import qs from "qs"

// Modules
import { auth } from "@/modules/auth"

class Http {
  constructor(options) {
    const defaultOptions = { authorizationHeader: "Authorization" }

    const opt = { ...defaultOptions, ...options }

    // XXX Se for diferente de 'false' deve ser setado como `true` (default)
    const useAuthorizationToken = opt.useAuthorizationToken !== false

    this.data = {
      authorizationHeader: opt.authorizationHeader,
      locale:              opt.locale,
      useAuthorizationToken
    }

    this.router = axios.create({
      baseURL: opt.baseURL,

      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: "brackets" })
      }
    })

    this.router.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
    this.router.defaults.headers.common[this.data.authorizationHeader] = `Basic ${opt.clientCredentials}`

    this.router.interceptors.request.use(
      config => {
        if (this.data.locale) config.headers["Accept-Language"] = this.data.locale

        const authorizationToken = auth.getToken()
        if (this.data.useAuthorizationToken && _.present(authorizationToken)) {
          config.headers[this.data.authorizationHeader] = `Bearer ${authorizationToken}`
        }

        return config
      }
    )

    this.get    = this.router.get
    this.post   = this.router.post
    this.put    = this.router.put
    this.patch  = this.router.patch
    this.delete = this.router.delete

    this.CancelToken = axios.CancelToken
    this.isCancel = axios.isCancel
  }

  setData(data) {
    this.data = { ...this.data, ...data }
  }
}

export default Http
