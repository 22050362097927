// Base
import Base from "./Base"

// Models
import Person from "@/models/person/person"

class PeopleRequests extends Base {
  static get selector() {
    return "people"
  }

  show({ cpf, params = {}, options = {} } = {}) {
    this.verifyData("show", { cpf })

    return this.action({
      method:  "get",
      url:     `/people/${cpf}`,
      params,
      options: {
        model: Person,

        ...options
      }
    })
  }
}

export default PeopleRequests
