<style lang="scss" scoped>

.modal-requester-notifications-contact{
  background-color: $gray-light-4;
  border-radius: 8px;
  padding: 8px;

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .name {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .qualification {
    font-size: 12px;
    font-weight: 400;
  }

  .contactedBy {
    font-size: 14px;
  }

  .tag {
    font-size: 12px;

    i {
      font-size: 16px;
      color: $gray;
    }
  }
}

</style>


<template lang="pug">

.modal-requester-notifications-contact.grid.gap-small
  template(v-if="skeleton")
    .grid.gap-tiny
      loading-lines(:height="22")
      loading-lines(:height="15", :min="23", :max="45")
    loading-lines(:height="21")

  template(v-else)
    .header
      .name {{ contact.requester?.name }}

      .flex.gap-tiny.vertical-center
        template(v-for="(qualification, i) of qualificationsArray")
          i.fas.fa-diamond.decoration(v-if="i > 0")
          span.qualification {{ qualification }}

    .contactedBy.grid.gap-small
      .flex.gap-tiny(v-if="contactedByWhatsapp")
        tag-inline
          .tag.flex.vertical-center.gap-tiny
            i.fab.fa-whatsapp
            span {{ $t(`.tag.whatsapp`) }}

        span {{ $t('.for') }}
        strong(v-mask="phoneMask") {{ contact?.requester?.phone }}

      .flex.gap-tiny(v-if="contactedByEmail")
        tag-inline
          .tag.flex.vertical-center.gap-tiny
            i.fas.fa-envelope
            span {{ $t(`.tag.email`) }}

        span {{ $t('.for') }}
        strong {{ contact?.requester?.email }}

</template>


<script>

// Components
import TagInline from "@/components/tag-inline"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

export default {
  name: "ModalRequesterNotificationsContact",

  components: {
    TagInline,
    LoadingLines
  },

  props: {
    skeleton: { type: Boolean, default: false },
    contact:  { type: Object }
  },

  data() {
    return {
      i18nScope: "components.modal-requester-notifications-contact"
    }
  },

  computed: {
    qualificationsArray() {
      const result = []

      if (this.contact?.requester?.driver) result.push(this.$t(".qualification.driver"))

      return result
    },

    contactedByWhatsapp() {
      return this.contact?.channels?.includes("whatsapp")
    },

    contactedByEmail() {
      return this.contact?.channels?.includes("email")
    },

    phoneMask() {
      if (!this.contact?.requester?.phone) return ""

      switch (this.contact?.requester.phone.replace(/\D/g, "").length) {
        case 10:
          return "(##) ####-####"
        case 11:
          return "(##) #####-####"
        default:
          return ""
      }
    }
  }
}

</script>
