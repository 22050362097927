<style lang="scss" scoped>

.checklist-modal {
  p {
    margin: 0;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .section {
    grid-template-columns: 40px 1fr;
  }

  .icon {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color:  #fff6d9;
  }

  .heading {
    font-family: $secondary-font;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 16px;
  }

  .checkbox-wrapper {
    padding-left: 8px;
    padding-top: 2px;
  }

  .checkbox-label {
    font-size: 14px;
    font-weight: 400;
  }

  .descriptions {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .list-decorator {
    width: 16px;
    font-size: 2px;
  }

  .footer {
    border-top: 1px solid $gray-light;
  }
}

</style>


<template lang="pug">

.checklist-modal
  .wrapper.grid.gap-big
    .section.grid.gap-regular(v-for="(section, i) of checklist")
      .aside
        .icon.flex.vertical-center.center
          i(:class="section.iconClass")

      .content
        p.heading {{ section.heading }}

        .checkboxes.grid.gap-regular
          checkbox-field.vertical-start(
            v-for="(item, idx) of section.items"
            hide-label,
            :name="`section-${i}-item-${idx}`"
            v-model="item.value"
          )
            template(#optionText="{ props: { inputId } }")
              label.checkbox-wrapper(:for="inputId")
                span.checkbox-label {{ item.label }}

                ul.descriptions(v-if="item.descriptions?.length")
                  li.flex(v-for="description of item.descriptions")
                    .list-decorator.flex.vertical-center.center
                      i.fas.fa-circle

                    span {{ description }}

  .footer.wrapper.flex.space-between
    app-button(
      theme="gray",
      outline,
      @click="$emit('close')"
    ) {{ $t(".button.cancel") }}

    app-button(
      v-if="isStageLocal"
      @click="selectAll"
    )
      .flex.vertical-center.gap-small
        span {{ $t(".button.select-all") }}

    app-button(
      :disabled="!isAllTrue",
      @click="isAllTrue && $emit('complete')"
    )
      .flex.vertical-center.gap-small
        span {{ $t(".button.schedule") }}
        i.fas.fa-check

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import CheckboxField from "@/components/checkbox-field/checkbox-field.vue"

// Models
import Ticket from "@/models/ticket/ticket"

// Libs
import conf from "@/lib/conf"

// Models
import Contract from "@/models/contract/contract"

const {
  zkm,
  gtf
} = Contract.$enums.businessUnit.all

export default {
  name: "ChecklistModal",

  components: {
    AppButton,
    CheckboxField
  },

  props: {
    ticket:  { type: Object },
    booking: { type: Object }
  },

  data() {
    return {
      i18nScope: "components.checklist-modal",
      checklist: [],
      listOptionsByType: new Map([
        ['zkm', new Map([
          ['multiservice', [[], ["type-id", null, "schedule"]]], 
          ['dealership', [[], ["schedule", null]]]
        ])],
        ['gtf', new Map([
          ['multiservice', [[], ["type-id", null, "schedule"]]], 
          ['dealership', [[], ["schedule", null]]]
        ])],
        ["default", new Map([
          ['multiservice', [[], ["type-id", null, "schedule"]]], 
          ['dealership', [[], ["schedule", null]]]
        ])]
      ])
    };
  },

  computed: {
    isAllTrue() {
      return this.checklist.every(section => section.items.every(item => item.value))
    },

    chosenScheduleType() {
      if (this.booking?.supplier?.dealership) {
        return 'dealership'
      }

      return 'multiservice'
    }, 

    chosenListType() {
      return this.listOptionsByType.has(this.ticket?.contract?.businessUnit)
        ? this.ticket.contract.businessUnit
        : "default"
    },

    typeTranslated() {
      return this.ticket?.type && !this.ticket.isTypeDraft
        ? Ticket.$tEnum("type", this.ticket.type)
        : ""
    },

    formattedSchedule() {
      if (!this.booking?.at) return ""

      return [
        this.$l("date.formats.weekDay", this.booking.at),
        this.$l("time.formats.dateAndTime", this.booking.at)
      ].join(", ")
    },

    isStageLocal() {
      return _.get(conf, "stage") === "local"
    },

    allTranslateOptions() {
      return {
        dateAndTime:   this.booking?.at ? this.$l("time.formats.dateAndTime", this.booking.at) : "",
        ticketId:      this.ticket.remoteId,
        establishment: this.$t([
          ".description",
          "establishment",
          this.booking?.supplier?.dealership ? "dealership" : "multiservice"
        ].join("."))
      }
    }
  },

  mounted() {
    this.generateListByContractType()
  },

  methods: {
    generateListByContractType() {
      this.checklist = Object.values(this.$t(`.checklist.${this.chosenScheduleType}.${this.chosenListType}`))
        .map((section, index) => ({
          ...section,
          iconClass: index ? "fas fa-megaphone" : "fas fa-circle-info",
          items:     this.generateListItemsFromRawObject(section.items, index)
        }))
    },

    generateListItemsFromRawObject(itemsObject, sectionIndex) {

      let listOptions = this.listOptionsByType.get(this.chosenListType)
      listOptions = listOptions.get(this.chosenScheduleType)

      return Object.values(itemsObject).map((_, idx) => {
        const listItem = { value: false }

        listItem.label = this.$t(
          [
            ".checklist",
            "dealership",
            this.chosenListType,
            sectionIndex,
            "items",
            idx
          ].join("."),
          this.allTranslateOptions
        )

        const descriptionIndex = listOptions && listOptions[sectionIndex][idx]

        if (descriptionIndex) {
          const descriptions = this.getDescriptionsByType(descriptionIndex)
          if (descriptions.length) { listItem.descriptions = descriptions }
        }

        return listItem
      })
    },

    getDescriptionsByType(descriptionType) {
      const descriptions = []

      if (descriptionType === "type-id") {
        const ticketInfo = this.ticket.remoteId && this.typeTranslated
          ? this.$t(".description.type-id", {
            typeName: this.typeTranslated,
            remoteId: this.ticket.remoteId
          })
          : ""

        if (ticketInfo) descriptions.push(ticketInfo)
      }

      if (descriptionType === "schedule") {
        [
          this.formattedSchedule,
          this.booking?.supplier?.presentName,
          this.booking?.supplier?.address?.fullFormat
        ].filter(Boolean).forEach(text => descriptions.push(text))
      }

      return descriptions
    },

    selectAll() {
      if (!this.isStageLocal) return

      this.checklist.forEach(section => {
        section.items = section.items.map(item => ({ ...item, value: true }))
      })
    }
  }
}

</script>
