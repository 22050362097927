<style lang="scss" scoped>

.messages-history-header {
  grid-template-columns: var(--messages-history-template-columns);
  gap: var(--messages-history-gap);
  font-size: 14px;
  min-height: 24px;

  .fused-columns {
    grid-column: 1 / -1;
  }

  .header-button {
    font-size: 14px;
  }

  .font-secondary {
    font-family: $secondary-font;
    font-weight: 400;
  }
}

</style>


<template lang="pug">

.messages-history-header.grid(:style="cssVars")
  .fused-columns.flex.end
    app-button(theme="link", @click="$emit('add')")
      .flex.vertical-center.gap-tiny.header-button.font-secondary
        i.fas.fa-plus

        span {{ $t('.add-message') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"

export default {
  name: "MessagesHistoryHeader",

  components: {
    AppButton
  },

  props: {
    columnSizes: { type: String, default: "1fr" },
    gap:         { type: String, default: "0px" }
  },

  emits: ["add"],

  data() {
    return {
      i18nScope: "components.messages-history-header"
    }
  },

  computed: {
    cssVars() {
      return {
        "--messages-history-template-columns": this.columnSizes,
        "--messages-history-gap":              this.gap
      }
    }
  }
}

</script>
