// regex capturado do repositorio do validate.js
// https://github.com/ansman/validate.js/blob/master/validate.js#L1064
//
// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

export function emailList(value, options, key, attrs) {
  let opts = _.merge({}, this.options, options)

  const separator = opts.separator || ","

  const emailArray = value
    .split(separator)
    .map(email => email.trim())
    .filter(Boolean)

  const allEmailValid = emailArray.every(email => Boolean(emailRegex.exec(email)))

  if (allEmailValid) return

  return opts.message || this.message || `invalid email`
}
