const FieldMixin = {
  emits: ["update:modelValue", "focus", "blur"],

  props: {
    autofocus:   { type: Boolean, default: false },
    disabled:    { type: Boolean, default: false },
    error:       { type: Boolean, default: false },
    errors:      { type: Array, default: () => [] },
    hideLabel:   { type: Boolean, default: false },
    id:          { type: String, default: null },
    info:        { type: Boolean, default: false },
    label:       { type: String, default: null },
    loading:     { type: Boolean, default: false },
    mandatory:   { type: Boolean, default: false },
    modelValue:  { default: null },
    name:        { type: String, required: true },
    optional:    { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    readonly:    { type: Boolean, default: false }
  },

  data() {
    return {
      focus: false
    }
  },

  computed: {
    attrName() {
      let match = /\[([^\]]+)\]$/.exec(this.name)
      return match ? _.camelize(match[1]) : null
    },

    // TODO Permit nested models like model[nested_model][attribute].
    // Is expected to return 'nested_model' insted of 'model'
    modelName() {
      let match = /^([^[]+)\[/.exec(this.name)
      return match ? match[1] : null
    },

    inputId() {
      if (this.id) return this.id

      return _.snakeize(this.name)
    },

    labelText() {
      return this.label
        ? this.label
        : this.$t(`models.${this.modelName}.attributes.${this.attrName}`)
    },

    hasErrors() {
      return this.error || _.present(this.errors)
    },

    hasMessageErrors() {
      return _.present(this.errors)
    },

    showClear() {
      if (this.disabled || this.readonly) return false
      return _.present(this.modelValue)
    }
  },

  methods: {
    clear() {
      this.$emit("update:modelValue", null)
    },

    onFocus() {
      if (this.loading || this.disabled) return

      this.focus = true
      this.$emit("focus")
    },

    onBlur() {
      this.focus = false
      this.$emit("blur")
    }
  }
}

export default FieldMixin
