import GoogleMapsLoader from "./google-maps"

const GoogleMapsPlugin = {
  installed: false,

  install({ config }, { key }) {
    if (GoogleMapsPlugin.installed) return
    GoogleMapsPlugin.installed = true

    GoogleMapsLoader.key = key

    const googleMaps = {
      load: ({ language, libraries } = {}) => GoogleMapsLoader.load({ language, libraries })
    }

    config.globalProperties.$googleMaps = googleMaps
  }
}

export default GoogleMapsPlugin
