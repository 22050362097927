<style lang="scss" scoped>

.issues-summary {
  .issues-table {
    --issues-table-border: 1px solid #{$gray-light};
    border: var(--issues-table-border);
    border-radius: 4px;
    overflow: hidden;
  }

  .row {
    border-top: var(--issues-table-border);
  }

  .count {
    font-size: 14px;
  }

  .cart {
    font-family: $secondary-font;
    font-weight: 400;
  }

  .fa-cart-shopping {
    font-size: 12px;
    color: $gray;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }
}

</style>


<template lang="pug">

.issues-summary.grid.gap-small
  .outside(v-if="!initializing && !hideOutside")
    slot(name="outside")
      .flex.space-between
        .count.flex.gap-tiny.vertical-center
          i.fas.fa-cart-shopping

          span.cart {{ $t(".cart") }}

          template(v-if="issues.length")
            i.fas.fa-diamond

            span.amount {{ $t(".issues-amount", { count: issues.length }) }}

        app-button.font-small(
          v-if="!hideButton"
          theme="link",
          :disabled="disabled",
          @click="!disabled && $emit('edit')"
        ) {{ $t('.edit') }}

  app-table(
    skeleton-gap="12px"
    :column-sizes="columnSizes",
    :cols-count="colsCount",
    :initializing="initializing",
    :class="{ dimmed: loading, 'issues-table': !initializing }"
    :empty="false"
    :footer="false"
  )
    template(#header)
      issues-summary-header(:column-sizes="columnSizes")

    template(#rows, v-if="issues?.length")
      issues-summary-row.row(
        v-for="(issue, i) of issues",
        :key="i",
        :column-sizes="columnSizes",
        :issue="issue"
      )

    template(#rows, v-else)
      issues-summary-row.row(
        :column-sizes="columnSizes",
        :issue="emptyIssue"
      )

</template>


<script>

// Components
import AppTable from "@/components/app-table/app-table.vue"
import AppButton from "@/components/app-button/app-button.vue"
import IssuesSummaryHeader from "./header"
import IssuesSummaryRow from "./row"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import Issue from "@/models/issue/issue"
import Service from "@/models/service/service"

export default {
  name: "IssuesSummary",

  components: {
    AppTable,
    AppButton,
    IssuesSummaryHeader,
    IssuesSummaryRow
  },

  mixins: [FetchMixin],

  props: {
    ticketId:    { type: [String, Number] },
    disabled:    { type: Boolean, default: false },
    hideOutside: { type: Boolean, default: false },
    hideButton:  { type: Boolean, default: false }
  },

  emits: ["edit", "index"],

  data() {
    return {
      i18nScope:   "components.issues-summary",
      columnSizes: "36px 1fr 1fr",
      issues:      [],
      loading:     false
    }
  },

  computed: {
    colsCount() {
      return this.columnSizes.split(" ").length
    },

    emptyIssue() {
      return new Issue({
        service: new Service({
          name: this.$t(".empty-issue")
        })
      })
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.issues.index({ ticketId: this.ticketId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.issues = data
      this.$emit("index", { issues: data })
    }
  }
}

</script>
