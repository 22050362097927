<style lang="scss" scoped>

.service-form-by-symptom {
  .divider {
    min-height: 1px;
    min-width: 1px;

    background-color: $gray-light-2;
  }

  .add-service-button {
    gap: 9px;
  }

  .grid.columns-2 {
    grid-template-columns: 1fr 1fr;

    & > * {
      min-width: 1px;
    }
  }
}

</style>


<template lang="pug">

.service-form-by-symptom.grid.gap-big
  .grid.grid.columns-2.gap-big
    service-fetch-select.grow(
      name="issue[partId]",
      kind="parts"
      v-model="resource.partId",
      :placeholder="$t('.fields.part.placeholder')",
      :errors="errors.partId"
      :ticket-id="ticketId"
      :fetch-params="availabilityParams"
      :disabled="fieldsDisabled"
      @fetching="fetchingOptions = true"
      @fetched="fetchingOptions = false"
      @select-content="e => selectedPart = e"
    )

    service-fetch-select.grow(
      name="issue[symptomId]",
      kind="symptoms"
      v-model="resource.symptomId",
      dependent,
      :dependency="resource.partId"
      :placeholder="$t('.fields.symptom.placeholder')",
      :errors="errors.symptomId"
      :ticket-id="ticketId"
      :fetch-params="availabilityParams"
      :disabled="fieldsDisabled"
      @fetching="fetchingOptions = true"
      @fetched="fetchingOptions = false"
    )

  service-targets-fields(
    v-if="showTargetFields"
    v-model:targets="resource.serviceTargets",
    :disabled="fieldsDisabled",
    :part="selectedPart",
    :errors="errors.serviceTargetsAttributes"
  )

  .divider

  service-fetch-select.grow(
    mandatory,
    name="issue[serviceId]",
    kind="services"
    v-model="resource.serviceId",
    dependent,
    :dependency="resource.symptomId"
    :placeholder="$t('.fields.service.placeholder')",
    :errors="errors.serviceId"
    :ticket-id="ticketId"
    :fetch-params="availabilityParams"
    :disabled="fieldsDisabled"
    @fetching="fetchingOptions = true"
    @fetched="fetchingOptions = false"
  )

  textarea-field(
    name="issue[description]",
    v-model="resource.description",
    :disabled="fieldsDisabled"
    :maxlength="10000"
    :placeholder="$t('.fields.description.placeholder')",
  )

  .flex.end
    app-button(:disabled="fieldsDisabled", @click="handleSubmit")
      .add-service-button.inline-flex.vertical-center
        i.fas.fa-cart-arrow-down

        span {{ $t('.fields.add-button') }}

</template>


<script>

// Components
import TextareaField from "@/components/textarea-field/textarea-field.vue"
import AppButton from "@/components/app-button/app-button.vue"
import ServiceTargetsFields from "../service-targets-fields"
import ServiceFetchSelect from "../service-fetch-select/service-fetch-select.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Models
import Issue from "@/models/issue/issue"
import ServiceTarget from "@/models/service/service-target"

export default {
  name: "ServiceFormBySymptom",

  components: {
    TextareaField,
    AppButton,
    ServiceTargetsFields,
    ServiceFetchSelect
  },

  props: {
    ticketId: { type: [Number, String] },
    disabled: { type: Boolean, default: false }
  },

  emits: ["create"],

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "components.service-form-by-symptom",
      resource:  new Issue({
        serviceId:      "",
        partId:         "",
        symptomId:      "",
        description:    "",
        serviceTargets: [
          new ServiceTarget({ position: "", location: "" })
        ]
      }),
      //
      fetchingOptions:  false,
      selectedPart:     null,
      nestedAttributes: {
        serviceTargets: null
      }
    }
  },

  computed: {
    fieldsDisabled() {
      return [
        this.disabled,
        this.fetchingOptions,
        this.submitting
      ].some(Boolean)
    },

    showTargetFields() {
      return this.resource.symptomId
        && (this.selectedPart?.hasPositionsAndLocations)
    },

    availabilityParams() {
      const { serviceId, partId, symptomId } = this.resource

      const params = {}
      if (serviceId) params.service_id = serviceId
      if (partId) params.part_id = partId
      if (symptomId) params.symptom_id = symptomId

      return params
    }
  },

  methods: {
    handleSubmit() {
      this.submit()
    },

    serializeAttributes() {
      const serviceTargets = this.resource.serviceTargets
        .map(target => target.$serialize())
        .filter(target => Object.values(target).every(Boolean))

      return {
        ...this.resource.$serialize(),
        type:                       "Issues::Service",
        service_targets_attributes: serviceTargets
      }
    },

    // @override FormMixin
    submitRequest() {
      const params = this.serializeAttributes()

      return this.$sdk.tickets.issues.create({ ticketId: this.ticketId, params  })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("create", data)
      this.clearForm()
    },

    clearForm() {
      this.resource =   new Issue({
        serviceId:      "",
        partId:         "",
        symptomId:      "",
        description:    "",
        serviceTargets: [
          new ServiceTarget({ position: "", location: "" })
        ]
      })
    }
  }
}

</script>
