<style scoped lang="scss">

.sidebar-icon {
  width: 24px;
  aspect-ratio: 1;
  font-size: 16px;
}

</style>


<template lang="pug">

.sidebar-icon.flex.center.no-shrink.vertical-center(v-tooltip="tooltip")
  i(:class="iconClass")

</template>


<script>

export default {
  name: "SidebarIcon",

  props: {
    icon:    { type: String, default: null },
    active:  { type: Boolean, default: false },
    tooltip: { type: String, default: null }
  },

  computed: {
    iconClass() {
      let type = this.active ? "fas" : "fal"

      return {
        [type]:      true,
        [this.icon]: true
      }
    }
  }
}

</script>
