import { i18n } from "@caiena/i18n"

// Libs
import { parseServerErrors, i18nError } from "@/lib/parse-errors"

i18n.fromNow = function i18nFromNow(value) {
  return moment(value).fromNow()
}

i18n.errify = parseServerErrors
i18n.error = i18nError

export default i18n
