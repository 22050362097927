<style lang="scss" scoped>

.type-grid {
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  grid-auto-rows: minmax(200px, auto);
}

</style>


<template lang="pug">

.type-grid.grid.gap-regular
  template(v-for="option of ticketOptions", :key="option.title")
    ticket-type-card(
      :loading="parentLoading || submitting || feedbackState === 'loading'",
      :content="option",
      :disabled="isDisabled(option.type)",
      :selected="option.type === ticket.type",
      @click="ticketSelect(option)"
    )
      template(#default, v-if="isTypeMaintenance(option.type) && feedbackState")
        .feedback.grid.vertical-bottom
          kilometrage-feedback.inline-flex(big, :state="feedbackState")

</template>


<script>

// Components
import KilometrageFeedback from "@/components/kilometrage-feedback"
import TicketTypeCard from "./_components/ticket-type-card"

// Models
import Ticket from "@/models/ticket/ticket"

export default {
  name: "TicketsDraftType",

  components: {
    KilometrageFeedback,
    TicketTypeCard
  },

  props: {
    ticketId:      { type: [String, Number] },
    ticket:        { type: Object, default: () => ({}) },
    vehicle:       { type: Object, default: () => ({}) },
    parentLoading: { type: Boolean, default: false }
  },

  emits: ["update-ticket"],

  data() {
    return {
      i18nScope:          "tickets-draft-type",
      submitting:         false,
      feedbackState:      "",
      maintenanceDetails: {}
    }
  },

  computed: {
    ticketOptions() {
      return [...Ticket.$enums.type.values]
        .slice(1)
        .map(key => ({
          type:        key,
          title:       Ticket.$tEnum("type", key),
          subtitle:    Ticket.getTypeSubtitle(key),
          description: Ticket.getTypeDescription(key),
          icon:        Ticket.getTypeIcon(key)
        }))
    },

    isWithoutNextMaintenance() {
      return !this.maintenanceDetails?.nextMaintenance
    }
  },

  mounted() {
    if (!this.vehicle.id) return
    this.checkMaintenance()
  },

  watch: {
    "vehicle.id": {
      handler(to) {
        if (typeof to === "number") this.checkMaintenance()
      }
    }
  },

  methods: {
    isDisabled(type) {
      return this.isWithinDisabledTypes(type)
        || this.isDisabledMaintenanceType(type)
    },

    isWithinDisabledTypes(type) {
      return this.ticket.disabledTypes?.length
        && this.ticket.disabledTypes.includes(type)
    },

    isDisabledMaintenanceType(type) {
      return this.isTypeMaintenance(type)
        && (this.feedbackState === "non-existant" || this.isWithoutNextMaintenance)
    },

    async ticketSelect(option) {
      try {
        this.submitting = true

        const params = { type: option.type }

        const { data } =  await this.$sdk.tickets.reason.update({ ticketId: this.ticketId, params })

        this.$emit("update-ticket", { ticket: data })

        this.$router.push({ name: "tickets-draft.service", params: { id: this.ticketId } })
      }
      catch (err) {
        console.error(err)

        this.$notifications.error(this.$t(".notifications.submit.error"))
      }
      finally {
        this.submitting = false
      }
    },

    async checkMaintenance() {
      try {
        this.feedbackState = "loading"

        const response = await this.$sdk.vehicles.km.show({
          vehicleId: this.vehicle.id,
          params:    { km: this.ticket.currentKm }
        })

        this.maintenanceDetails = response.data

        this.setFeedbackFromResponseData(response)

      }
      catch (e) {
        console.error(e)
        this.feedbackState = "non-existant"
      }
      finally {
        if (this.feedbackState === "loading") this.feedbackState = ""
      }
    },

    isTypeMaintenance(type) {
      return type === "Tickets::PreventiveMaintenance"
    },

    setFeedbackFromResponseData(response) {
      const { status, data } = response

      if (status !== 200 || !data.nextMaintenance) this.feedbackState = "non-existant"

      else if (data.maintenanceRequired) this.feedbackState = "required"

      else if (_.present(data.maintenanceRequired)) this.feedbackState = "not-required"
    }
  }
}

</script>
