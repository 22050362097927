import allDay from "./ticket-24h.svg"
import bodyShop from "./ticket-body_shop.svg"
import corrective from "./ticket-corrective.svg"
import withdrawal from "./ticket-withdrawal.svg"
import documentation from "./ticket-documentation.svg"
import electric from "./ticket-electric.svg"
import lights from "./ticket-lights.svg"
import locksmith from "./ticket-locksmith.svg"
import revision from "./ticket-revision.svg"
import shielding from "./ticket-shielding.svg"
import tapestry from "./ticket-tapestry.svg"
import tires from "./ticket-tires.svg"
import windows from "./ticket-windows.svg"

export default new Map([
  ["Tickets::PreventiveMaintenance", revision],
  ["Tickets::Repair", corrective],
  ["Tickets::Electric", electric],
  ["Tickets::AutoBodyAndPaint", bodyShop],
  ["Tickets::Lamp", lights],
  ["Tickets::TiresAndWheels", tires],
  ["Tickets::Glass", windows],
  ["Tickets::Locksmith", locksmith],
  ["Tickets::Tapestry", tapestry],
  ["Tickets::Armoring", shielding],
  ["Tickets::Assistance", allDay],
  ["Tickets::Withdrawal", withdrawal],
  ["Tickets::Documentation", documentation]
])
