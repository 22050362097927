<!--
<doc lang="markdown">

Exibe programaticamente um modal de confirmação binário simples por meio de this.$confirm

Como usar:
```js
const isConfirmed = await this.$confirm({
  heading: this.$t('.heading'),
  message: this.$t('.message'),

  width: 720,

  actions: {
    confirm: this.$t('.confirm'),
    cancel:  this.$t('.cancel')
  }
})
```

_Props_:
width:           Largura do modal                         [null]
heading:         Define o texto de título do cabeçalho    ['']
message:         Define o texto do corpo                  [obrigatório]
actions.confirm: Texto do botão de confirmação do rodapé  [i18n.t('btn.confirm')]
actions.cancel:  Texto do botão de cancelamento do rodapé [i18n.t('btn.cancel')]

</doc>
-->
<style lang="scss" scoped>

.modal-body {
  padding: 16px 24px;
}

</style>


<template lang="pug">

.confirmation-modal
  app-modal(
    avoid-close-on-click-outside,
    footer,
    :width="width",
    :heading="heading",
    :show="show",
    :cancel-label="actions.cancel",
    :confirm-label="actions.confirm",
    @cancel="confirm(false)",
    @confirm="confirm(true)",
    @hidden="$emit('close')"
  )
    .modal-body
      span {{ message }}

</template>


<script>

// Modules
import { i18n } from "@/modules/i18n"

// Components
import AppModal from "@/components/app-modal"

export default {
  name: "ConfirmationModal",

  emits: ["close"],

  components: {
    AppModal
  },

  props: {
    show:    { type: Boolean, default: true },
    width:   { type: Number, default: 540 },
    heading: { type: String, default: "" },
    message: { type: String, required: true },
    confirm: { type: Function, required: true },
    actions: {
      type:    Object,
      default: () => ({
        confirm: i18n.t("btn.confirm"),
        cancel:  i18n.t("btn.cancel")
      })
    }
  }

  // XXX: O componente é destruído automaticamente pelo plugin ConfirmationPlugin
}

</script>
