// Vue
import { createVNode, render } from "vue"

// Components
import ConfirmationModal from "./confirmation-modal/confirmation-modal.vue"

const ConfirmationPlugin = {
  install(app) {
    function destroy(container) {
      render(null, container)
      document.body.removeChild(container)
    }

    async function confirm(propsData = {}) {
      let vNode

      const promise = new Promise(resolve => {
        const container = document.createElement("div")
        document.body.appendChild(container)

        vNode = createVNode(ConfirmationModal, {
          ...propsData,
          confirm: resolve,
          onClose: () => { destroy(container) }
        })
        vNode.appContext = app._context

        render(vNode, container)
      })
        .finally(() => {
          vNode.component.props.show = false
        })

      return promise
    }

    app.config.globalProperties.$confirm = confirm
  }
}

export default ConfirmationPlugin
