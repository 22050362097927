import isObject from "./is-object"

// Identifica se o valor é um objeto ou um array de objetos e transforma as chaves em camelCase.
// Opcionalmente, instancia os objetos com o construtor dado
function normalize(data, { camelize = true, constructor = null } = {}) {
  if (isObject(data)) {
    if (!constructor) return camelize ? _.camelizeKeys(data) : data
    return new constructor(camelize ? _.camelizeKeys(data) : data)
  }

  if (Array.isArray(data)) return data.map(element => normalize(element, { constructor }))

  return data
}

export default normalize
