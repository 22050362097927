// 3rd Party
import mitt from "mitt"

const emitter = mitt()
const events = {
  emit: emitter.emit,
  all:  emitter.all
}

const { on, off } = emitter

events.on = function $on(type, handler) {
  if (!Array.isArray(type)) on(type, handler)
  else type.forEach(event => on(event, handler))
}

events.off = function $off(type, handler) {
  if (!Array.isArray(type)) off(type, handler)
  else type.forEach(event => off(event, handler))
}

events.once = function $once(type, handler) {
  const onceHandler = (...args) => {
    handler(...args)
    off(type, onceHandler)
  }

  if (!Array.isArray(type)) on(type, onceHandler)
  else type.forEach(event => on(event, onceHandler))
}

export default events
