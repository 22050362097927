<!--
<doc lang="markdown">

Exibe um código, prefixado por #

_Props_: value, hideHashtag

Como usar:
```pug
  id-tag(value="25061987")
```

Ou passando valor por prop:
```
  id-tag(:value="myCodeValue", hide-hashtag)
```

</doc>
-->

<style lang="scss" scoped>

.id-tag {
  font-family: $primary-monospace-font;
  display: inline-block;
  color: $purple;
  font-weight: 400;
}

</style>


<template lang="pug">

.id-tag
  .content.flex.vertical-center
    template(v-if="!hideHashtag")
      app-span.text(value="#")

    slot
      app-span.text(:value="value", :crop="crop")

</template>


<script>

// Components
import AppSpan from "@/components/app-span/app-span.vue"

export default {
  name: "IdTag",

  emits: [],

  components: {
    AppSpan
  },

  props: {
    value:       { type: [String, Number], default: null },
    hideHashtag: { type: Boolean, default: false },
    crop:        { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.id-tag"
    }
  }
}

</script>
