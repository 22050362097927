<style scoped lang="scss">

.paginator {
  .loading {
    cursor: progress;
    width: 400px;
    margin: 0 auto;
  }

  .content {
    &.disabled {
      cursor: not-allowed;
      color: $gray-light;

      .button {
        cursor: not-allowed;

        &.current {
          background-color: $gray-light-2
        }

        &:not(:disabled) {
          cursor: not-allowed;
        }

        &.ellipsis {
          color: $gray-light;
        }
      }
    }

    .button {
      $paginator-gutter: 12px;

      border: none;
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: $gray-dark;
      background-color: transparent;
      padding: 4px;
      margin: 0 $paginator-gutter;
      min-width: 25px;
      position: relative;

      &:not(:disabled) {
        cursor: pointer;

        .navigation {
          color: $primary;
        }
      }

      &:disabled:not(.ellipsis) {
        color: $gray-2;
      }

      &:first-child {
        margin: 0 $paginator-gutter 0 0;
      }

      &:last-child {
        margin: 0 0 0 $paginator-gutter;
      }

      &.current {
        border-radius: 4px;
        background-color: #FFF0E2;
        color: $primary;
      }

      &:focus {
        color: $primary-light;
      }

      .navigation {
        .next,
        .previous {
          font-weight: 400;
        }

        .next {
          margin-right: 4px;
        }

        .previous {
          margin-left: 4px;
        }

        .icon {
          font-size: 12px;
        }
      }
    }

    .page-input {
      width: 100px;
    }
  }
}

</style>


<template lang="pug">

.paginator
  .loading(v-if="loading")
    loading-lines(:min="100", :max="100")

  .content.center.flex(
    v-else-if="showSinglePage",
    :class="{ disabled }"
  )
    button.button(
      v-if="showNavigation",
      type="button",
      :disabled="!hasPreviousPage || disabled",
      @click="onPreviousPage"
    )
      .navigation.flex.vertical-center
        i.icon.far.fa-chevron-left
        span.previous {{ $t('.previous') }}

    template(v-if="pages <= block")
      button.button(
        v-for="i in pages",
        type="button",
        :class="{ current: modelValue === i }",
        :disabled="disabled",
        :key="`page-${i}`",
        @click="onChangePage(i)"
      ) {{ i }}

    template(v-else)
      template(v-if="currentIsOnLeft")
        template(v-for="i in pages")
          button.button(
            v-if="i <= block - 2",
            type="button",
            :class="{ current: modelValue === i }",
            :disabled="disabled",
            :key="`page-${i}`",
            @click="onChangePage(i)",
          ) {{ i }}

        button.button.ellipsis(type="button", disabled) ...

        button.button(
          type="button",
          :disabled="disabled",
          @click="onChangePage(pages)"
        ) {{ pages }}

      template(v-if="currentIsOnRight")
        button.button(
          type="button",
          :disabled="disabled",
          @click="onChangePage(first)"
        ) {{ first }}

        button.button.ellipsis(type="button", disabled) ...

        template(v-for="i in pages")
          button.button(
            v-if="i > pages - block + 2",
            type="button",
            :class="{ current: modelValue === i }",
            :disabled="disabled",
            :key="`page-${i}`",
            @click="onChangePage(i)",
          ) {{ i }}

      template(v-if="currentIsOnCenter")
        button.button(
          type="button",
          :disabled="disabled",
          @click="onChangePage(first)"
        ) {{ first }}

        button.button.ellipsis(type="button", disabled) ...

        button.button(
          v-for="i in leftValues",
          type="button",
          :disabled="disabled",
          :key="`page-${i}`",
          @click="onChangePage(i)",
        ) {{ i }}

        button.button.current(
          type="button",
          :disabled="disabled"
        ) {{ modelValue }}

        button.button(
          v-for="i in rightValues",
          type="button",
          :key="`page-${i}`",
          :disabled="disabled",
          @click="onChangePage(i)",
        ) {{ i }}

        button.button.ellipsis(type="button", disabled) ...

        button.button(
          type="button",
          :disabled="disabled",
          @click="onChangePage(pages)"
        ) {{ pages }}

    button.button(
      v-if="showNavigation",
      type="button",
      :disabled="!hasNextPage || disabled",
      @click="onNextPage"
    )
      .navigation.flex.vertical-center
        span.next {{ $t('.next') }}
        i.icon.far.fa-chevron-right

    form(
      v-if="withInput"
      @submit.prevent="onInputSubmit",
      method="get",
      data-testid="form"
    )
      input-field.page-input(
        v-model="page",
        clickable-icon,
        hide-label,
        icon-right="fas fa-chevron-right",
        mask="#",
        name="paginator[page]",
        :height="32",
        :maxlength="inputMaxLength",
        @right-icon-click="onInputSubmit",
      )

</template>


<script>

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import InputField from "@/components/input-field/input-field.vue"

export default {
  name: "Paginator",

  emits: ["update:modelValue"],

  components: {
    LoadingLines,
    InputField
  },

  props: {
    modelValue:     { type: Number, default: 1 },
    perPage:        { type: Number, default: 0 },
    total:          { type: Number, default: 0 },
    block:          { type: Number, default: 5 },
    hideSinglePage: { type: Boolean, default: true },
    showNavigation: { type: Boolean, default: true },
    loading:        { type: Boolean, default: false },
    disabled:       { type: Boolean, default: false },
    withInput:      { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:      "components.paginator",
      first:          1,
      pageInputValue: null
    }
  },

  computed: {
    showSinglePage() {
      return !(this.pages <= 1 && this.hideSinglePage)
    },

    center() {
      return (this.block + 1) / 2
    },

    neighborsLength() {
      return (this.block - 4 - 1) / 2
    },

    leftValues() {
      return Array.from({ length: this.neighborsLength }, (val, key) => key + this.modelValue - this.neighborsLength)
    },

    rightValues() {
      return Array.from({ length: this.neighborsLength }, (val, key) => key + this.modelValue + 1)
    },

    currentIsOnLeft() {
      return this.modelValue <= this.center
    },

    currentIsOnRight() {
      return this.modelValue > this.pages - this.center
    },

    currentIsOnCenter() {
      return !this.currentIsOnLeft && !this.currentIsOnRight
    },

    hasPreviousPage() {
      return this.modelValue > this.first
    },

    hasNextPage() {
      return this.modelValue < this.pages
    },

    pages() {
      let numberOfPages = Number(this.total) / Number(this.perPage)
      let mod = this.total % this.perPage

      return Math.floor(mod === 0 ? numberOfPages : numberOfPages + 1)
    },

    inputMaxLength() {
      return String(this.pages).length
    },

    page: {
      get() { return +this.pageInputValue },

      set(value) { this.pageInputValue = Math.min(this.pages, Math.max(1, +value)) }
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.pageInputValue = this.modelValue
      }
    }
  },

  methods: {
    onChangePage(val) {
      if (!this.disabled && this.modelValue !== val) {
        this.$emit("update:modelValue", val)
      }
    },

    onPreviousPage() {
      if (this.hasPreviousPage) {
        this.onChangePage(Math.min(this.modelValue - 1, this.pages))
      }
    },

    onNextPage() {
      if (this.hasNextPage) {
        this.onChangePage(Math.max(this.modelValue + 1, this.first))
      }
    },

    onInputSubmit() {
      this.onChangePage(this.page)
    }
  }
}

</script>
