// Libs
import conf from "@/lib/conf"

import { newRelicBrowserInit } from "./init"

function getPluginOptions() {
  const { accountId,
    trustKey,
    agentId,
    licenseKey,
    applicationId } = _.get(conf, "newRelic")

  if (![
    accountId,
    trustKey,
    agentId,
    licenseKey,
    applicationId
  ].every(Boolean)) return null

  return {
    accountId,
    trustKey,
    agentId,
    licenseKey,
    applicationId
  }
}

function initialize() {
  const pluginOptions = getPluginOptions()

  if (_.present(pluginOptions)) {
    newRelicBrowserInit(pluginOptions)
  }
}

export default initialize
