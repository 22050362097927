<style lang="scss" scoped>

.app-table-skeleton {
  display: grid;
  gap: var(--grid-gap);

  .row {
    display: grid;
    grid-template-columns: var(--grid-columns);
    gap: var(--grid-gap);
  }
}

</style>


<template lang="pug">

.app-table-skeleton(:style="styles")
  .row(v-for="row in rowsCount", :key="`app-table-skeleton-row-${row}`")
    slot(name="cols")
      .column(v-for="col in colsCount", :key="`app-table-skeleton-col-${row}-${col}`")
        loading-lines(:min="100", :max="100", :height="20")

</template>


<script>

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

export default {
  name: "AppTableSkeleton",

  emits: [],

  components: {
    LoadingLines
  },

  props: {
    rowsCount:   { type: Number, default: 5 },
    gridGap:     { type: String },
    colsCount:   { type: Number, required: true },
    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "components.app-table.app-table-skeleton"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes,
        "--grid-gap":     this.gridGap || "24px"
      }
    }
  }
}

</script>
