<style lang="scss" scoped>

.driver-edit{
  .footer {
    border-top: 1px solid $gray-light;
  }
}

.padding-regular {
  padding: 16px
}

</style>


<template lang="pug">

.driver-edit
  .body.padding-regular.grid.gap-regular(v-if="resource.cpf")
    fake-input(
      :label="cpfTranslatedLabel",
      :value="resource.cpf"
      mask="000.000.000-00"
    )

    input-field(
      name="driver[name]",
      v-model="resource.name",
      :placeholder="$t('.fields.name.placeholder')",
      :errors="errors.name",
      :disabled="submitting",
      :maxlength="100"
    )

    input-field(
      name="driver[phone]",
      v-model="resource.phone",
      :mask="phoneMask",
      :placeholder="$t('.fields.phone.placeholder')",
      :errors="errors.phone",
      :disabled="submitting",
    )

    input-field(
      name="driver[email]",
      v-model="resource.email",
      :placeholder="$t('.fields.email.placeholder')",
      :errors="errors.email",
      :disabled="submitting",
      :maxlength="100"
    )

  .footer.padding-regular
    .flex.end
      //- app-button(
      //-   :key="`app-modal-footer-cancel-${uuid()}`",
      //-   theme="neutral",
      //-   :disabled="submitting"
      //- )
      //-   .flex.gap-small
      //-     span
      //-       i.fas.fa-trash
      //-     span {{ $t('.btn.delete') }}

      app-button(
        @click="submit",
        :disabled="!canSubmit || submitting",
      ) {{ $t('.btn.save') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import InputField from "@/components/input-field/input-field.vue"
import FakeInput from "@/components/fake-input"

// Models
import Driver from "@/models/driver/driver"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "DriverFormEdit",

  components: {
    AppButton,
    InputField,
    FakeInput
  },

  mixins: [FormMixin],

  props: {
    contractRid: { type: String },
    driver:      { type: Object, default: () => ({}) }
  },

  emits: ["close", "update"],

  data() {
    return {
      i18nScope: "components.driver-form-edit",
      resource:  {}
    }
  },

  computed: {
    phoneMask() {
      return this.resource.phone?.length === 15
        ? "(00) 00000-0000"
        : "(00) 0000-00009"
    },

    canSubmit() {
      const { cpf, name, phone, email } = this.resource
      return [cpf, name, phone, email].every(Boolean)
    },

    cpfTranslatedLabel() {
      return Driver.$tAttr("cpf")
    }
  },

  mounted() {
    this.resource = this.cloneDriver()
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    cloneDriver() {
      return new Driver(this.driver.$serialize())
    },

    emitClose() {
      this.$emit("close")
    },

    serializeAttributes() {
      return this.resource.$serialize()
    },

    formatData(formData) {
      let phone = formData.phone.replace(/\D/g, "")
      phone = `${phone.substring(0, 2)}-${phone.substring(2)}`

      formData.phone = phone
      formData.cpf = this.driver.cpf

      return formData
    },

    // @override FormMixin
    submitRequest() {
      const params = this.formatData(this.serializeAttributes())

      return this.$sdk.contracts.drivers.update({
        contractRid: this.contractRid,
        driverRid:   this.driver.rid,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("update", { driver: data })
    }
  }
}

</script>
