<style lang="scss" scoped>

.login {
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.view {
    background-color: $gray-light-3;
    max-width: unset;
  }

  .card {
    padding: 32px;
    background: $white;
    width: 400px;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    position: relative;

    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .vertical-absolute {
      position: absolute;
      transform: translateY(-100%);
      top: 0px;

      .logo-container {
        position: relative;
        padding-bottom: 12px;

        .stamp {
          position: absolute;
          top: 4px;
          left: 80px;
        }

        .logo {
          width: 112px;
        }
      }

      .welcome {
        position: relative;
        font-family: $secondary-font;
        text-align: center;
        width: 100vw;
        padding-bottom: 30px;

        &__title {
          font-weight: 500;
          font-size: 38px;
          color: $gray-dark;
          margin-bottom: 8px;
        }

        &__description {
          margin-block: 0;
        }
      }

      .arrow-up {
        $border-length: 19px;
        top: 0;
        width: 0;
        height: 0;
        border-left: $border-length solid transparent;
        border-right: $border-length solid transparent;
        border-bottom: $border-length solid $white;
      }
    }

    .form {
      width: 100%;

      .fields {
        margin-bottom: 40px;

        &.error {
          margin-bottom: 20px;
        }

        .username {
          margin-bottom: 16px;
        }

        .error-message {
          font-size: 14px;
          color: $error;
          margin-top: 20px;
          font-weight: normal;
          text-align: center;
          background: #ffefef;
          padding: 8px;
          border-radius: 4px;
        }
      }

      .icon {
        margin-right: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

.login
  .card
    .vertical-absolute.flex.column-direction.vertical-center
      .logo-container
        stage-stamp.stamp
        img.logo(:src="logo")
      .welcome
        h1.welcome__title {{ $t('.welcome.title') }}
        p.welcome__description {{ $t('.welcome.description') }}
      .arrow-up

    form.form(
      ref="form",
      method="post",
      action="/login",
      @submit.prevent="verifyRecaptchaAndSubmit"
    )
      .fields(:class="{ error }")
        input-field.username(
          v-model="resource.username",
          autocomplete="username",
          name="login[username]",
          :disabled="submitting || fetching",
          :error="hasError",
          :errors="errors.username",
          :placeholder="$t('.fields.username.placeholder')",
          :label="$t('.fields.username.label')",
          data-testid="usernameInput"
        )

        input-field.password(
          v-model="resource.password",
          autocomplete="current-password",
          name="login[password]",
          clickable-icon,
          :type="passwordType",
          :disabled="submitting || fetching",
          :error="hasError",
          :errors="errors.password",
          :icon-right="passwordIcon",
          :placeholder="$t('.fields.password.placeholder')",
          :label="$t('.fields.password.label')",
          @right-icon-click="togglePassword",
          data-testid="passwordInput"
        )

        span.error-message.flex(v-if="hasError")
          .icon.error
            i.fas.fa-times-circle
          | {{ error }}

      app-button.button(
        type="submit",
        full-width,
        :loading="submitting || fetching",
        data-testid="submitButton"
      )
        i.far.fa-sign-in.icon
        span {{ $t('.btn.submit') }}
</template>


<script>

// Vue
import { nextTick } from "vue"

// Libs
import conf from "@/lib/conf"

// Assets
import logo from "@/assets/images/logo-movida.svg"

// Components
import InputField from "@/components/input-field/input-field.vue"
import AppButton from "@/components/app-button/app-button.vue"
import StageStamp from "@/components/stage-stamp/stage-stamp.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import Base from "@/models/base/base"

const RECAPTCHA_SITE_KEY = _.get(conf, "recaptcha.siteKey")

export class Login extends Base {
  static get attrs() {
    return ["username", "password", "recaptchaToken"]
  }

  static get constraints() {
    return {
      username: { presence: true },
      password: { presence: true },

      recaptchaToken(value, attrs, attrName, options, constraints) {
        if (_.blank(RECAPTCHA_SITE_KEY)) return {}

        return { presence: true }
      }
    }
  }
}

export default {
  name: "Login",

  emits: [],

  components: {
    InputField,
    AppButton,
    StageStamp
  },

  mixins: [FormMixin, FetchMixin],

  data() {
    return {
      i18nScope: "login",

      passwordType: "password",
      logo,

      error: null,

      retries: 0,

      resource: new Login(),

      guardFormOnLeave: false,

      authData:  null,
      autofetch: false
    }
  },

  computed: {
    afterLoginRoute() {
      let redirect = _.get(this.$route, "query.redirect")
      return _.present(redirect) ? { path: redirect } : { path: "/" }
    },

    passwordIcon() {
      return this.passwordType === "password"
        ? "fal fa-eye-slash"
        : "fal fa-eye"
    },

    hasError() {
      return _.present(this.error)
    },

    recaptchaEnabled() {
      // XXX: não dá pra usar _.present(function)!
      return this.$recaptchaLoaded !== undefined
    }
  },

  created() {
    if (this.recaptchaEnabled) this.showRecaptchaBadge()
  },

  unmounted() {
    if (this.recaptchaEnabled) this.hideRecaptchaBadge()
  },

  methods: {
    async showRecaptchaBadge() {
      await this.$recaptchaLoaded()

      const recaptcha = this.$recaptchaInstance._rawValue
      if (recaptcha) recaptcha.showBadge()
    },

    async hideRecaptchaBadge() {
      await this.$recaptchaLoaded()

      const recaptcha = this.$recaptchaInstance._rawValue
      if (recaptcha) recaptcha.hideBadge()
    },

    togglePassword() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    },

    async verifyRecaptchaAndSubmit() {
      this.error = ""

      if (this.recaptchaEnabled) {
        try {
          await this.$recaptchaLoaded()
          this.resource.recaptchaToken = await this.$recaptcha("login")
        }
        catch {
          const message = this.$t(".notifications.submit.errors.server")
          this.error = message
          this.$notifications.error(message)
          return
        }
      }

      this.submit()
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.auth.login({ params: this.resource.$serialize() })
    },

    // @override Form mixin
    afterSubmitStart() {
      // XXX Requisições de login e /me de modo atômico
      this.$loading.start()
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      const { data } = response

      this.authData = {
        token:     data.accessToken,
        scope:     data.scope,
        expiresAt: data.expiresAt
      }

      this.$notifications.clear()

      this.fetch()
    },

    getErrorMessage(err) {
      let errorKey = _.get(err, "originalError.response.data.error")

      let label

      switch (errorKey) {
        case "invalid_recaptcha":
          label = "server"
          break

        case "invalid_client":
          label = "client.authorization"
          break

        default:
          break
      }

      if (_.blank(label)) {
        if (err.statusCode >= 500) label = "server"
        else {
          switch (err.statusCode) {
            case 401:
            case 403:
              label = "client.authorization"
              break

            default:
              label = "client.authentication"
              break
          }
        }
      }

      return this.$t(`.notifications.submit.errors.${label}`)
    },

    // @override Form mixin
    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      console.error(this.submitError)
      this.error = this.getErrorMessage(error)
      this.$notifications.error(this.error)
      this.$loading.stop()
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$sdk.user.me({ token: this.authData.token })
    },

    // @override Fetch mixin
    async onFetchError(err) {
      if (this.retries >= 2) {
        console.error(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))

        this.retries = 0

        this.$loading.stop()
      }
      else {
        this.retries += 1
        await nextTick()

        this.fetch()
      }
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      // aguarda a construção das permissões
      this.$events.on("ability:build", async () => {
        this.$router.replace(this.afterLoginRoute)

        this.$loading.stop()
      })

      this.$auth.login({
        ...this.authData,

        user: data
      })
    }
  }
}

</script>
