// Base
import Base from "./Base"

// Models
import Issue from "@/models/issue/issue"

class IssueRequests extends Base {
  static get selector() {
    return "issues"
  }

  delete({ issueId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { issueId })

    return this.action({
      method:  "delete",
      url:     `/issues/${issueId}`,
      params,
      options: {
        model: Issue,

        ...options
      }
    })
  }
}

export default IssueRequests
