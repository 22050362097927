<!--
<doc lang="markdown">

Exibe um modal

Como usar:
```pug
app-modal(
  avoid-close-on-click-outside,
  footer,
  :cancel-label="this.actions.cancel",
  :confirm-label="this.actions.confirm",
  :heading="heading",
  :width="width",
  @cancel="confirm(false)",
  @confirm="confirm(true)"
)
  .modal-body
    span {{ message }}
```

_Props_:
footer:                   Define se o rodapé padrão deve ser exibido na ausência do slot "footer" [false]
submitting:               Informa ao componente quando o aplicativo está enviando os dados        [false]
fetching:                 Informa ao componente quando o aplicativo está carregando os dados      [false]
closeButton:              Define a presença de um botão com ícone para fechar o modal             [false]
heading:                  Define o texto (e a presença) de título do cabeçalho                    ['']
transition:               Transição do modal                                                      ['modal']
avoidCloseOnClickOutside: Evita o fechamento do modal ao clicar fora dele                         [false]
maxHeight:                Define se o modal deve cobrir a tela inteira verticalmente              [false]
isView:                   Define se o modal deve ter a mesma largura das demais views             [false]
width:                    Largura do modal                                                        [null]
confirmButton:            Define se o rodapé padrão possui botão de confirmação                   [true]
disabledConfirmButton:    Desabilita o botão de confirmação                                       [false]
disabledCancelButton:     Desabilita o botão de cancelamento                                      [false]
confirmLabel:             Texto do botão de confirmação do rodapé                                 [i18n.t('btn.submit.label')]
confirmLabelLoading:      Texto do botão de confirmação do rodapé em estado de carregamento       [i18n.t('btn.submit.loading')]
cancelLabel:              Texto do botão de cancelamento do rodapé                                [i18n.t('btn.cancel')]
theme:                    Tema do modal                                                           ['default']

_Eventos_:
confirm: Ao clicar no botão de confirmação do rodapé padrão
cancel:  Ao clicar no botão de cancelamento do rodapé padrão
open:    Ao iniciar a criação do modal
opened:  Ao finalizar a criação do modal
close:   Ao iniciar a destruição do componente
closed:  Ao finalizar a destruição do componente

_Slots_:
default: O corpo do modal
header:  Cabeçalho com padrão
footer:  Rodapé com padrão

</doc>
-->

<style lang="scss" scoped>

.modal-body {
  padding: 24px;
}

.app-modal {
  $modal-z-index: 400 !default;
  position: fixed;
  z-index: $modal-z-index;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($gray-dark, 0.7);

  .modal-wrapper {
    margin: 0 auto;
    z-index: $modal-z-index + 1;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 94%;
    // Altura total menos margin
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 40px);
    margin: 20px auto;
    margin-top: 40px;
    overflow: auto;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba($black, 0.33);

    &.full-height {
      height: 100%;
    }

    &.full-height,
    &.view {
      height: calc(100vh - 80px);
    }

    &.view {
      max-width: 1200px;
    }
  }

  .actions {
    position: absolute;
    z-index: $modal-z-index + 2;
    right: 24px;
    top: 16px;

    .action,
    :deep(.action) {
      border: 0;
      outline: 0;
      padding: 0;
      margin: 0;
      width: 24px;
      height: 24px;
      font-size: 16px;
      color: $gray-dark;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & + .action {
        margin-left: 16px;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .modal-header {
    color: $orange;
    margin-top: 0;
    padding: 16px 24px;
    border-bottom: 1px solid $gray-light;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 16px 24px;

    &.with-border {
      border-top: 1px solid $gray-light;
    }
  }

  .modal-heading {
    font-size: 18px;
    font-weight: 400;
    color: $gray-dark;
    font-family: $secondary-font;
  }

  .modal-photo {
    padding: 16px;

    .action {
      background-color: $white;
      margin: 8px;
      border-radius: 50%;
    }
  }

  .modal-content {
    overflow: auto;
    flex-grow: 1;
  }
}

</style>


<template lang="pug">

teleport(to="#modals")
  transition(
    appear,
    name="modal",
    @after-enter="afterEnter",
    @after-leave="afterLeave",
    @before-enter="beforeEnter"
  )
    .app-modal(
      v-if="show",
      ref="modal"
    )
      .modal-wrapper
        .modal-container(
          v-click-away="onClickOutside",
          :class="{ [`modal-${theme}`]: true, 'full-height': maxHeight, view: isView }",
          :style="style"
        )
          .actions.flex
            slot(name="actions", :props="{ close }")
              button.action(
                v-if="closeButton",
                type="button",
                @click="close"
              )
                i.fal.fa-times

          slot(name="header")
            header.modal-header(v-if="hasHeaderSlot || heading")
              h1.modal-heading {{ heading }}

          .modal-content(v-if="hasDefaultSlot")
            slot

          slot(name="footer")
            .modal-footer(
              v-if="footer",
              :class="{ 'with-border': hasDefaultSlot }"
            )
              app-button(
                outline,
                theme="gray",
                :disabled="submitting || disabledCancelButton",
                :key="`app-modal-footer-cancel-${uuid()}`",
                :loading="fetching",
                @click="cancel"
              ) {{ cancelLabel }}

              app-button(
                v-if="confirmButton",
                :disabled="fetching || disabledConfirmButton",
                :loading="submitting",
                @click="confirm"
              )
                span {{ confirmLabel }}
                template(#loading)
                  span {{ confirmLabelLoading }}

</template>


<script>

// 3rd Party
import { v4 as uuid } from "uuid"

// Modules
import { i18n } from "@/modules/i18n"

// Libs
import freezeScrolling from "@/lib/freeze-scrolling"

// Components
import AppButton from "@/components/app-button/app-button.vue"

const themeValidator = value => ["default", "photo"].includes(value.toLowerCase())

export default {
  name: "AppModal",

  emits: ["close", "closed", "hidden", "open", "opened", "cancel", "confirm"],

  components: {
    AppButton
  },

  props: {
    show:                     { type: Boolean, default: false },
    footer:                   { type: Boolean, default: false },
    submitting:               { type: Boolean, default: false },
    fetching:                 { type: Boolean, default: false },
    closeButton:              { type: Boolean, default: false },
    heading:                  { type: String, default: "" },
    transition:               { type: String, default: "modal" },
    avoidCloseOnClickOutside: { type: Boolean, default: false },
    maxHeight:                { type: Boolean, default: false },
    isView:                   { type: Boolean, default: false },
    width:                    { type: Number, default: null },
    confirmButton:            { type: Boolean, default: true },
    disabledConfirmButton:    { type: Boolean, default: false },
    disabledCancelButton:     { type: Boolean, default: false },
    confirmLabel:             { type: String, default: () => i18n.t("btn.submit.label") },
    confirmLabelLoading:      { type: String, default: () => i18n.t("btn.submit.loading") },
    cancelLabel:              { type: String, default: () => i18n.t("btn.cancel") },
    theme:                    {
      type:      String,
      validator: value => themeValidator(value),
      default:   "default"
    }
  },

  data() {
    return {
      i18nScope: "components.app-modal",
      uuid
    }
  },

  computed: {
    hasDefaultSlot() {
      return _.present(this.$slots.default)
    },

    hasHeaderSlot() {
      return _.present(this.$slots.header)
    },

    parentScrollableElem() {
      return document.body
    },

    style() {
      if (!this.width) return
      return {
        width:       `${this.width}px`,
        "max-width": `100vw`
      }
    }
  },

  unmounted() {
    this.cleanup()
  },

  watch: {
    show() {
      if (this.show) return

      this.cleanup()
      this.$emit("closed")
    }
  },

  methods: {
    onClickOutside() {
      if (this.avoidCloseOnClickOutside) return

      this.close()
    },

    beforeEnter() {
      // Já tenta congelar o scroll aqui, pois usa `position: fixed` e isso causa um "flick"
      // nos dispositivos móveis caso seja feito apenas no afterEnter()
      this.stopScrolling()
      this.$emit("open")
    },

    afterEnter() {
      // XXX garantindo que o scroll esteja congelado.
      if (this.isScrolling()) this.stopScrolling()
      this.focus()
      this.$emit("opened")
    },

    afterLeave() {
      this.$emit("hidden")
    },

    focus() {
      let autofocusEl = this.$refs.modal.querySelector("[autofocus]")
      if (autofocusEl) autofocusEl.focus()
    },

    cancel() {
      this.$emit("cancel")
      this.close()
    },

    cleanup() {
      this.startScrolling()
    },

    close() {
      if (this.submitting) return

      this.$emit("close")
    },

    confirm() {
      if (this.submitting) return

      this.$emit("confirm")
    },

    isScrolling() {
      return !freezeScrolling.isFrozen(this.parentScrollableElem)
    },

    startScrolling() {
      // "religa" scroll no body (conteúdo abaixo do overlay).
      freezeScrolling.release(this.parentScrollableElem)
    },

    stopScrolling() {
      // evita scroll no body (conteúdo abaixo do overlay) enquanto o overlay
      // estiver aberto.
      freezeScrolling.freeze(this.parentScrollableElem)
    }
  }
}

</script>
