<style lang="scss" scoped>

.issues-summary-header {
  background-color: $gray-light-4;
  grid-template-columns: var(--issues-summary-template-columns);
  font-size: 14px;

  .cell {
    padding: 6px 8px;

    p {
      font-family: $secondary-font;
      font-weight: 400;
    }
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    color: $gray-2;
  }

  p {
    margin: 0;
  }
}

</style>


<template lang="pug">

.issues-summary-header.grid(:style="cssStyles")
  .cell
    .icon.flex.center.vertical-center
      i.far.fa-wrench

  .cell
    p {{ $t('.service') }}

  .cell
    p {{ $t('.parts-symptoms') }}

</template>


<script>

export default {
  name: "IssueSummaryHeader",

  props: {
    columnSizes: { type: String, default: "1fr" }
  },

  data() {
    return {
      i18nScope: "components.issues-summary-header"
    }
  },

  computed: {
    cssStyles() {
      return {
        "--issues-summary-template-columns": this.columnSizes
      }
    }
  }
}

</script>
