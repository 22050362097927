import Base from "../base/base"

class Driver extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "cpf",
      "phone",
      "email",
      "remoteId",
      "contractRid"
    ])
  }

  static get constraints() {
    return {
      name:  { presence: true },
      cpf:   { presence: true, cpf: true },
      phone: { presence: true, phone: true },
      email: { presence: true, email: true }
    }
  }
}

export default Driver
