import { Model, mixin } from "@caiena/model"
import EnumOptions from "../mixins/enum_options"
import Record from "../mixins/record"

class Base extends mixin(Model, [EnumOptions, Record]) {
  $serialize(options, nestedAttributes) {
    let serialized = super.$serialize(options)

    // Transforma atributos aninhados em chaves `<key>_attributes`, e.g.:
    // nestedAttributes: {
    //   address: null,  <-- objeto
    //   contacts: {     <-- array
    //     emails: null, <-- array
    //     phones: null  <-- array
    //   }
    // }
    // serializa:
    // {
    //   address_attributes: { ... },
    //   contacts_attributes = [
    //     {
    //       emails_attributes: [{ ... }],
    //       phones_attributes: [{ ... }]
    //     }
    //   ]
    // }
    if (_.present(nestedAttributes)) {
      _.forEach(nestedAttributes, (value, key) => {
        let nestedParams
        const nestedResource = this[key]

        if (_.present(nestedResource)) {
          nestedParams = Array.isArray(nestedResource)
            ? nestedResource.map(resource => resource.$serialize(options, value))
            : this[key].$serialize(options, value)

          serialized[`${key}_attributes`] = nestedParams
        }
      })
    }

    return _.snakeizeKeys(serialized)
  }
}

export default Base
