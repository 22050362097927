// Modules
import { events } from "@/modules/events"

// Modules
import { auth } from "@/modules/auth"

// Roles
import roles from "./roles"

class Ability {
  constructor() {
    this.roles = []
  }

  init() {
    events.on("auth:login", () => this.build())
    events.on(["auth:logout", "auth:expired"], () => this.clear())

    this.build()
  }

  build({ user = auth.getUser(), roleNames = auth.getRoles(), options = {} } = {}) {
    this.roles = roleNames
      .map(roleName => roles[roleName])
      .filter(role => !!role)
      .map(role => role.build(user, options))

    events.emit("ability:build")
  }

  clear() {
    this.roles = []
  }

  can(...args) {
    return this.roles.some(ability => ability.can(...args))
  }

  cannot(...args) {
    return this.roles.every(ability => ability.cannot(...args))
  }
}

const instance = new Ability()

export default instance
