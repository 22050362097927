// 3rd Party
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

// Libs
import conf from "@/lib/conf"

// Modules
import router from "@/modules/router"

function getPluginOptions() {
  const dsn = _.get(conf, "sentry.dsn")

  if (_.blank(dsn)) return null

  const apiURL = _.get(conf, "api.url")
  const stage = _.get(conf, "stage")

  return {
    dsn,
    logErrors:   true,
    environment: stage,

    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),

        tracingOrigins: [apiURL]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  }
}

function initialize(app) {
  const pluginOptions = getPluginOptions()

  if (_.present(pluginOptions)) {
    Sentry.init({ ...pluginOptions, app })
  }
}

export default initialize
