<style lang="scss" scoped>

.modal-supplier-notifications-create {
  --supplier-notifications-border: 1px solid #{$gray-light-2};

  p {
    margin: 0px;
  }

  .wrapper {
    padding: 16px 24px;
  }

  .footer {
    border-block-start : var(--supplier-notifications-border);
  }
}

</style>


<template lang="pug">

.modal-supplier-notifications-create
  .wrapper
    .grid.gap-regular
      p
        //- span {{ $t('.description.email') }}
        //- br
        //- span {{ $t('.description.separator') }}
        span {{ $t('.description.registered') }}

      input-field(
        name="supplierNotification[email]",
        disabled,
        :modelValue="resource.email",
        :label="$t('.fields.email.label')",
        :placeholder="$t('.fields.email.placeholder')",
        :errors="errors.email",
        :disabled="submitting"
      )

  .footer.wrapper.flex.space-between
    app-button(
      outline,
      theme="gray",
      :disabled="submitting"
      @click="$emit('back')"
    ) {{ $t('.button.cancel') }}

    app-button(@click="submit", :disabled="submitting") {{ $t('.button.resend') }}

</template>


<script>

// Models
import Base from "@/models/base/base"

// Components
import AppButton from "@/components/app-button/app-button.vue"
import InputField from "@/components/input-field/input-field.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"

class SupplierNotification extends Base {
  static get attrs() {
    return ["email"]
  }

  static get constraints() {
    return {
      email: {
        presence: true,
        emailList(value) { return Boolean(value) }
      }
    }
  }
}

export default {
  name: "ModalSupplierNotificationsCreate",

  components: {
    AppButton,
    InputField
  },

  mixins: [FormMixin],

  emits: ["back", "create"],

  props: {
    currentEmail: { type: String },
    ticketId:     { type: [Number, String] }
  },

  data() {
    return {
      i18nScope: "components.modal-supplier-notifications-create",

      resource: new SupplierNotification()
    }
  },

  mounted() {
    if (this.currentEmail) {
      this.resource.email = this.currentEmail
    }
  },

  methods: {
    // @override DirtyableMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override FormMixin
    submitRequest() {
      const serializedAttributes = this.serializeAttributes()

      const params = this.formatDataToParams(serializedAttributes)

      return this.$sdk.tickets.supplierNotifications.create({
        ticketId: this.ticketId,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$notifications.info(this.$t(".notifications.submit.success"))
      this.$emit("create", data)
    },

    formatDataToParams(resource) {
      const { email: emails } = resource

      const email = emails
        .split(",")
        .map(em => em.trim())
        .filter(Boolean)
        .join(",")

      return {
        ...resource,
        email
      }
    }
  }
}

</script>
