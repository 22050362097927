<style lang="scss" scoped>

.tickets-table-row-state-icon {
  $icon-colors: (
    'green': $green,
    'green-darker': $green-darker,
    'gray-light':$gray-light,
    'orange': $orange,
    'red': $red,
    'yellow': $yellow,
  );

  @each $name, $color in $icon-colors {
    .text-#{$name} {
      color: $color;
    }
  }
}

</style>


<template lang="pug">

.tickets-table-row-state-icon.flex.center
  i.fas.font-size-regular(:class="stateIconClasses", v-tooltip="stateTooltipText")

</template>


<script>

const stateClassMap = new Map([
  ["none", ["fa-circle-question", "text-gray-light"]],
  ["draft", ["fa-pencil", "text-gray-light"]],
  ["pre_booked", ["fa-calendar-circle-exclamation", "text-yellow"]],
  ["booked", ["fa-calendar-check", "text-green"]],
  ["ongoing", ["fa-screwdriver-wrench", "text-green"]],
  ["done", ["fa-check", "text-green"]],
  ["canceled", ["fa-circle-x", "text-red"]]
])

export default {
  name: "TicketsTableRowStateIcon",

  props: {
    ticket: { type: Object }
  },

  data() {
    return {
      i18nScope: "components.tickets-table-row-state-icon"
    }
  },

  computed: {
    stateIconClasses() {
      return stateClassMap.has(this.ticket.processedState)
        ? stateClassMap.get(this.ticket.processedState)
        : stateClassMap.get("none")
    },

    stateTooltipText() {
      if (!stateClassMap.has(this.ticket.processedState)) return this.$t(".unknown-state")

      const stateTranslated = this.ticket.processedStateTranslated.toLowerCase()
      return this.$t(".state", { state: stateTranslated })
    }
  }
}

</script>
