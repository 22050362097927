<style lang="scss" scoped>

.side-buttons {
  .font-small {
    font-size: 14px;
  }

  .button-text {
    width: 100%;
    text-align: start;
  }

  .color-black {
    color: $gray;

      &.disabled {
        color: $gray-light;
      }

      &:not(.disabled):hover {
        color: $orange;
      }
  }
}

</style>


<template lang="pug">

.side-buttons.grid.gap-regular
  app-button.font-small(
    v-if="showBookingReschedule",
    theme="link",
    :disabled="disabled"
    :to="{ name: 'tickets-reschedule', params: { id: ticket.id } }"
  )
    span.button-text {{ $t(".button.reschedule") }}

  app-button.font-small(
    v-if="showBookingConfirmation",
    theme="link",
    :disabled="disabled"
    @click="openModal('booking-confirmation')"
  )
    span.button-text {{ $t(".button.confirm-booking") }}

  app-button.font-small.color-black(
    :class="{ disabled }"
    theme="link",
    :disabled="disabled"
    @click="openModal('cancellation')"
  )
    span.button-text {{ $t(".button.cancel") }}

app-modal(
  close-button,
  :heading="$t(`.modal.title.${selectedModal}`)",
  :width="566",
  :show="modalShown",
  @close="closeModal"
)
  modal-ticket-cancellation(
    v-if="selectedModal === 'cancellation'"
    :ticket="ticket",
    @cancel="handleCancel"
  )

  modal-booking-confirmation(
    v-if="selectedModal === 'booking-confirmation'"
    :ticket="ticket",
    @start-reschedule="goToReschedule",
    @confirm="handleBookingConfirmation"
  )

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import AppModal from "@/components/app-modal"
import ModalTicketCancellation from "@/components/modal-ticket-cancellation"
import ModalBookingConfirmation from "@/components/modal-booking-confirmation"

export default {
  name: "SideButtons",

  components: {
    AppButton,
    AppModal,
    ModalTicketCancellation,
    ModalBookingConfirmation
  },

  props: {
    ticket:                  { type: Object },
    disabled:                { type: Boolean, default: false },
    showBookingConfirmation: { type: Boolean, default: false },
    showBookingReschedule:   { type: Boolean, default: false }
  },

  emits: ["cancellation", "booking-confirmation"],

  data() {
    return {
      i18nScope:              "components.side-buttons",
      cancellationModalShown: false,
      modalShown:             false,
      selectedModal:          ""
    }
  },

  methods: {
    handleCancel() {
      this.closeModal()
      this.$emit("cancellation")
    },

    handleBookingConfirmation() {
      this.closeModal()
      this.$emit("booking-confirmation")
    },

    openModal(modalKind) {
      const permittedModalKinds = ["cancellation", "booking-confirmation"]

      if (!permittedModalKinds.includes(modalKind)) throw new Error("Modal kind not permitted")

      this.selectedModal = modalKind
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
      this.selectedModal = ""
    },

    goToReschedule() {
      this.$router.push({ name: "tickets-reschedule", params: { id: this.ticket.id } })
    }
  }
}

</script>
