<style lang="scss" scoped>

.requester-new{
  .footer {
    border-top: 1px solid $gray-light;
  }
}

.padding-regular {
  padding: 16px
}

.checkbox{
  padding-top: 16px;
  border-top: 1px solid $gray-light-2;

  &-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline: 16px;
  }
}

.go-back {
  color: $gray-dark !important;

  &:hover {
    color: $orange !important;

    &:active {
      color: $orange-dark !important;
    }
  }
}

</style>


<template lang="pug">

.requester-new
  person-search(
    v-if="!cpfSearched",
    @cancel="$emit('cancel')"
    @show="handlePersonShow"
    @no-show="handleNoShow"
  )

  .body.padding-regular.grid.gap-regular(v-else)
    fake-input(
      :label="cpfTranslatedLabel",
      :value="resource.cpf"
      mask="000.000.000-00"
    )

    input-field(
      name="requester[name]",
      v-model="resource.name",
      :placeholder="$t('.fields.name.placeholder')",
      :errors="errors.name",
      :disabled="submitting",
      :maxlength="100"
    )

    input-field(
      name="requester[phone]",
      v-model="resource.phone",
      :mask="phoneMask",
      :placeholder="$t('.fields.phone.placeholder')",,
      :errors="errors.phone",
      :disabled="submitting",
    )

    input-field(
      name="requester[email]",
      v-model="resource.email",
      :placeholder="$t('.fields.email.placeholder')",
      :errors="errors.email",
      :disabled="submitting",
      :maxlength="100"
    )

    .checkbox(v-if="showCheckbox")
      checkbox-field(
        hide-label,
        name="requester[driver]",
        v-model="resource.driver",
        :errors="errors.driver",
        :disabled="submitting",
      )
        template(#optionText="{ props: { inputId } }")
          label(:for="inputId")
            span.checkbox-text {{ $t('.fields.driver.label') }}

  .footer.padding-regular(v-if="cpfSearched")
    .flex.space-between
      app-button.go-back(
        outline,
        theme="link",
        :disabled="submitting",
        @click="resetCpf"
      )
        .flex.gap-small
          span
            i.fas.fa-arrow-left
          span {{ $t('.btn.go-back') }}

      app-button(
        @click="submit",
        :disabled="!canSubmit || submitting",
      ) {{ submitButtonText || $t('.btn.add') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import InputField from "@/components/input-field/input-field.vue"
import CheckboxField from "@/components/checkbox-field/checkbox-field.vue"
import PersonSearch from "@/components/person-search"
import FakeInput from "@/components/fake-input"

// Models
import Requester from "@/models/requester/requester"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "RequesterFormNew",

  components: {
    AppButton,
    InputField,
    CheckboxField,
    PersonSearch,
    FakeInput
  },

  mixins: [FormMixin],

  props: {
    contractRid:      { type: String },
    showCheckbox:     { type: Boolean, default: false },
    submitButtonText: { type: String }
  },

  emits: ["create", "cancel"],

  data() {
    return {
      i18nScope:   "components.requester-form-new",
      resource:    new Requester(),
      cpfSearched: false
    }
  },

  computed: {
    phoneMask() {
      return this.resource.phone?.length === 15
        ? "(00) 00000-0000"
        : "(00) 0000-00009"
    },

    canSubmit() {
      const { cpf, name, phone, email } = this.resource
      return [cpf, name, phone, email].every(Boolean)
    },

    cpfTranslatedLabel() {
      return Requester.$tAttr("cpf")
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    handlePersonShow(person) {
      Object.assign(this.resource, {
        ...person.driverDetails,
        driver: this.showCheckbox // padrão `true` se o campo aparecer
      })
      this.cpfSearched = true
    },

    handleNoShow({ cpf }) {
      this.resource.cpf = cpf
      this.cpfSearched = true
    },

    resetCpf() {
      this.cpfSearched = false
      this.resetData()
    },

    // @override DirtyableMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    async resetData() {
      await this.$nextTick()
      Object.assign(this.$data, this.$options.data.apply(this))
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    formatData(formData) {
      let phone = formData.phone.replace(/\D/g, "")
      phone = `${phone.substring(0, 2)}-${phone.substring(2)}`

      const cpf = formData.cpf.replace(/\D/g, "")

      formData.phone = phone
      formData.cpf = cpf

      if (!_.present(formData.driver)) { formData.driver = false }

      return formData
    },

    // @override FormMixin
    submitRequest() {
      const params = this.formatData(this.serializeAttributes())

      return this.$sdk.contracts.requesters.create({
        contractRid: this.contractRid,
        params
      })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      this.$emit("create", { requester: data })
      this.resetData()
    }
  }
}

</script>
