<template lang="pug">

form.flex.column-direction.gap-big(@submit.prevent="handleSearch")
  input-field(
    name="search-item",
    hide-label,
    v-model="searchTerm",
    :icon-left="iconLeft",
    :icon-right="iconRight",
    :clear-icon="clearIconShown"
    :clickable-icon="!!iconRight"
    :maxlength="fieldCharLimit",
    :placeholder="$t('.input.placeholder')",
    :border-radius="30"
    :inline-padding="24"
    :height="inputHeight"
    :disabled="submitting"
    @right-icon-click="handleSearch"
  )

  app-button.self-vertical-center(
    v-if="withButton"
    type="submit",
    :disabled="isSearchTermEmpty || submitting",
  ) {{ $t('.button') }}

</template>


<script>

// Components
import InputField from "@/components/input-field/input-field.vue"
import AppButton from "@/components/app-button/app-button.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "ContractSearch",

  components: {
    InputField,
    AppButton
  },

  mixins: [FormMixin],

  props: {
    initialValue:  { type: String, default: "" },
    withButton:    { type: Boolean, default: false },
    inputHeight:   { type: Number, default: 56 },
    iconLeft:      { type: String, default: "" },
    iconRight:     { type: String, default: "" },
    withClearIcon: { type: Boolean, default: false }
  },

  emits: ["error", "success"],

  data() {
    return {
      i18nScope:      "components.contract-search",
      searchTerm:     "",
      fieldCharLimit: 8 // XXX-XXXX ou XXXXXXXX
    }
  },

  mounted() {
    this.searchTerm = this.initialValue
  },

  computed: {
    isSearchTermEmpty() {
      return !this.searchTerm
    },

    clearIconShown() {
      return this.withClearIcon && !this.isSearchTermEmpty
    }
  },

  methods: {
    handleSearch() {
      if (this.isSearchTermEmpty || this.submitting) return

      this.submit()
    },

    // @override FormMixin
    submitRequest() {
      return this.$sdk.contracts.index({ params: { q: this.searchTerm } })
    },

    // @override FormMixin
    onSubmitSuccess({ data }) {
      if (!data.length) {
        this.$emit("error", { term: this.searchTerm, vehicle: false })
        return
      }

      this.$emit("success", { contract: data[0] })
    },

    // @override FormMixin
    onSubmitError({ error }){
      this.$emit("error", { term: this.searchTerm, vehicle: false })

      const { response } = error.originalError

      if (response.data && response.data.data.length > 0) {
        const data = response.data.data[0];

        // Verificar se 'data' possui a propriedade 'error'
        if (data.error) {
          const error = data.error;
          // Verificar se 'error' possui a propriedade 'vehicle'
          if (error.vehicle) {
            this.$emit("error", { term: this.searchTerm, vehicle: true })

            //dispara que o vehicle existe
          }
        }
      }
    }
  }
}

</script>
