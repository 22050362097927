// Modules
import { i18n } from "@/modules/i18n"

// Models
import Base from "../base/base"

// Assets
import { gradeMap } from "@/assets/images/supplier-grade"

class Supplier extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "classification",
      "remoteId",
      "cnpj",
      "name",
      "tradingName",
      "serviceOrderEmail",
      "serviceOrderContactName",
      "cellphone",
      "phone",
      "dealership",
      "movidaApp"
    ])
  }

  static get relations() {
    return {
      address:  { type: "hasOne", model: "Address" },
      sectors:  { type: "hasMany", model: "Sector" },
      contacts: { type: "hasMany", model: "Contact" }
    }
  }

  static get enums() {
    return {
      classification: {
        CMM: "CMM",
        A:   "A",
        B:   "B",
        C:   "C",
        D:   "D"
      }
    }
  }

  get email() {
    return this.serviceOrderEmail
  }

  get gradeIcon() {
    if (!gradeMap.has(this.classification)) return null

    return {
      src: gradeMap.get(this.classification),
      alt: i18n.t(`models.supplier.icon-alt.${this.classification}`)
    }
  }

  get movidaAppLink() {
    return {
      src: gradeMap.get('MOVIDAAPP'),
      alt: "Movida App"
    }
  }

  get presentName() {
    return this.tradingName || this.name
  }
}

export default Supplier
