import Base from "../base/base"

class Message extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "authorName",
      "message",
      "customerInteraction"
    ])
  }

  static get relations() {
    return {
      user: { type: "hasOne", model: "User" }
    }
  }

  static get constraints() {
    return {
      message: { presence: true }
    }
  }
}

export default Message
