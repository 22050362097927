<!--
<doc lang="markdown">

Exibe um checkbox

Como usar:
```pug
checkbox-field(
  name="model[prop]",
  label="Primário",
  v-model="resource.value"
)
```

</doc>
-->

<style lang="scss" scoped>

.checkbox-field {
  .field-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $error;
    }
  }

  .loading-lines {
    width: 20px;
    height: 20px;
  }

  .checkbox {
    display: flex;
    align-items: center;

    .checkbox-input {
      flex-shrink: 0;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: $gray-light-4;
      border: 1px solid $gray-3;
      margin: 4px 0;
      transition: all .1s;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        border-color: $orange;
      }

      &:active {
        background-color: $orange-light;
      }

      &.disabled,
      &.loading {
        background-color: $gray-light-4;
        border-color: $gray-3;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &:checked,
      &.indeterminate {
        background-color: $orange;
        border-color: $orange;
        position: relative;

        &:after {
          font-size: 14px;
          line-height: 14px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $white;
        }

        &:focus,
        &:active {
          border-color: $orange-dark;
        }

        &:active {
          background-color: $orange-dark;
        }

        &.disabled,
        &.loading {
          background-color: $gray-light-2;
          border-color: $gray-light;
        }
      }

      &:checked:not(.indeterminate) {
        &:after {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 400;
          content: '\f00c';
        }
      }

      &.indeterminate {
        &:after {
          font-weight: 900;
          content: '—';
        }
      }
    }
  }

  .checkbox-label {
    padding-left: 8px;
    margin-right: 8px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    &.disabled {
      cursor: not-allowed;
    }

    &.loading {
      cursor: progress;
    }

    &.disabled,
    &.loading {
      color: $gray-2;
    }

    &.error {
      color: $error;
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

.checkbox-field.flex.vertical-center
  label.field-label(v-if="!hideLabel && !!label", :class="{ error: hasErrors }", :for="inputId")
    span {{ label }}
    span(v-if="optional") {{ $t('form.optional') }}

  loading-lines(v-if="loading", :min="100", :max="100")
  .checkbox(v-else, :class="{ error: hasErrors }")
    input.checkbox-input(
      type="checkbox",
      :checked="modelValue",
      :class="{ disabled, loading, indeterminate }",
      :disabled="disabled || loading",
      :id="inputId",
      :name="name",
      @click="onClick"
    )

  slot(name="optionText", :props="{ inputId, disabled, error: hasErrors }")
    template(v-if="!hideValueText")
      label.checkbox-label(
        :class="{ disabled, loading, error: hasErrors }",
        :for="inputId"
      )
        span {{ optionText }}

  span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field-mixin"

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

export default {
  name: "CheckboxField",

  emits: [],

  components: {
    LoadingLines
  },

  mixins: [FieldMixin],

  props: {
    hideValueText: { type: Boolean, default: false },
    indeterminate: { type: Boolean, default: false },
    loading:       { type: Boolean, default: false },
    valueText:     { type: String, default: null },

    // @override Field Mixin
    modelValue: { type: Boolean, default: false }
  },

  computed: {
    optionText() {
      return this.valueText
        ? this.valueText
        : this.$t(`models.${this.modelName}.attributes.${this.attrName}`)
    }
  },

  methods: {
    onClick(e) {
      if (this.disabled) return
      if (this.indeterminate) e.preventDefault()

      this.$emit("update:modelValue", !this.modelValue)
    }
  }
}

</script>
