// Modules
import { i18n } from "@/modules/i18n"

// Libs
import { durationFromDate, durationYearsMonths } from "@/lib/duration"

// Models
import Base from "../base/base"

class Vehicle extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "year",
      "fipeId",
      "licensePlate",
      "purchasedAt",
      "km",
      "categoryName",
      "manufacturerName",
      "modelName",
      "versionName",
      "fuelName",
      "colorName"
    ])
  }

  get purchasedAtFormatted() {
    return i18n.l("date.formats.monthShort", this.purchasedAt)
  }

  get purchasedAtPhrase() {
    return i18n.t("models.vehicle.bought-in-format", { date: this.purchasedAtFormatted })
  }

  get usagePhrase() {
    return durationYearsMonths(this.purchasedAt) + i18n.t("models.vehicle.usage-suffix")
  }

  get name() {
    if (this.versionName) return this.versionName

    const name = []

    if (this.modelName) name.push(this.modelName)
    if (this.fuelName) name.push(this.fuelName)

    return name.join(" ")
  }

  get isLessThan1YearOld() {
    return durationFromDate(this.purchasedAt).asYears() < 1
  }

  get categorySimplified() {
    const relevantCategoriesSet = new Set(["Operacional", "Premium", "Super Vip", "Vip"])

    if (!this.categoryName) return ""

    return relevantCategoriesSet.has(this.categoryName)
      ? this.categoryName
      : "Other"
  }

  get isVeryImportant() {
    return ["Premium", "Super Vip"].includes(this.categoryName)
  }
}

export default Vehicle
