// Libs
import conf from "@/lib/conf"

// Modules
import { googleMapsPlugin } from "@/modules/google-maps"

function getPluginOptions() {
  const key = _.get(conf, "googleMaps.key")

  if (_.blank(key)) return null

  return { key }
}

export default function initialize(app) {
  const pluginOptions = getPluginOptions()

  if (_.present(pluginOptions)) {
    app.use(googleMapsPlugin, pluginOptions)
  }
}

export { googleMapsPlugin }



