<style scoped lang="scss">

.vehicle-manufacturer-icon {
  width: var(--size);
  height: var(--size);
  text-align: left;
}

</style>


<template lang="pug">

img.vehicle-manufacturer-icon(:src="icon", :style="cssVars", data-testid="manufacturerIcon")

</template>


<script>

// Assets
import icons from "@/assets/images/manufacturers"

export default {
  name: "VehicleManufacturerIcon",

  emits: [],

  props: {
    value: { type: String, default: null },
    size:  { type: [String, Number], default: 24 }
  },

  computed: {
    cssVars() {
      return {
        "--size": `${this.size}px`
      }
    },

    icon() {
      if (_.blank(this.value)) return icons.others

      let iconKey = _.find(Object.keys(icons), key => _.canonic(this.value).includes(key))

      if (_.present(iconKey)) return icons[iconKey]

      return icons.others
    }
  }
}

</script>
