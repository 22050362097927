import { goBackPlugin } from "@/modules/go-back"

function getPluginOptions() {
  return {
    defaultRoute: { path: "/" }
  }
}

export default function initialize(app) {
  const pluginOptions = getPluginOptions()

  app.use(goBackPlugin, pluginOptions)
}

export { goBackPlugin, getPluginOptions }
