// Base
import Base from "./Base"

// Models
import Driver from "@/models/driver/driver"

class ContractDriverRequests extends Base {
  static get selector() {
    return "contracts.drivers"
  }

  index({ contractRid, params = {}, options = {} } = {}) {
    this.verifyData("index", { contractRid })

    return this.action({
      method:  "get",
      url:     `/contracts/${contractRid}/drivers`,
      params,
      options: {
        model: Driver,

        ...options
      }
    })
  }

  create({ contractRid, params = {}, options = {} } = {}) {
    this.verifyData("create", { contractRid })

    return this.action({
      method:  "post",
      url:     `/contracts/${contractRid}/drivers`,
      params,
      options: {
        model: Driver,

        ...options
      }
    })
  }

  update({ contractRid, driverRid, params = {}, options = {} } = {}) {
    this.verifyData("update", { contractRid, driverRid })

    return this.action({
      method:  "patch",
      url:     `/contracts/${contractRid}/drivers/${driverRid}`,
      params,
      options: {
        model: Driver,

        ...options
      }
    })
  }

  destroy({ contractRid, driverRid, params = {}, options = {} } = {}) {
    this.verifyData("destroy", { contractRid, driverRid })

    return this.action({
      method:  "delete",
      url:     `/contracts/${contractRid}/drivers/${driverRid}`,
      params,
      options: {
        model: Driver,

        ...options
      }
    })
  }
}

export default ContractDriverRequests
