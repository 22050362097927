<style lang="scss" scoped>

.ticket-contract-header {
  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
  }

  .id {
    font-size: 20px;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
    color: $gray;
  }

  .decoration {
    font-size: 4px;
    color: $gray-3;
  }

  .small-text {
    color: $gray-dark-2;
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
  }

  .due {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 8px;
    background-color: $green;
    //
    font-family: $secondary-font;
    font-weight: 500;
    color: $white;

    &[data-status="expired"] {
      background-color: $gray-2;
    }
  }

  p {
    margin: 0;
  }
}

</style>


<template lang="pug">

.ticket-contract-header.grid.gap-small
  template(v-if="skeleton")
    loading-lines(:height="27.5")
    loading-lines(:height="16.25")

  template(v-else)
    .flex.gap-small.vertical-center
      h2.title {{ $t(".title", { businessUnit: businessUnitTranslated }) }}
      id-tag.id(:value="contract.id")
      span.due(
        :data-status="contract.status === 'open' ? 'valid' : 'expired'",
        v-tooltip="$t('.tooltip.status')"
      ) {{ contract.status === 'open' ? $t('.status.positive') : $t('.status.negative') }}

    .flex.gap-regular.vertical-center
      p.small-text.flex.gap-tiny.vertical-center
        .icon.flex.vertical-center.center
          i.fas.fa-file-contract
        span {{ createdAtFormatted }}
        i.fa.fa-diamond.decoration
        span {{ $i18n.fromNow(contract.createdAt) }}

      p.small-text.flex.vertical-center.gap-tiny(v-if="contractDurationInMonths > 0")
        .icon.flex.vertical-center.center
          i.fas.fa-calendar
        span {{ $t('.duration', { count: contractDurationInMonths }) }}
        i.fa.fa-diamond.decoration
        span {{ timeRemaining }}

</template>


<script>

// Components
import IdTag from "@/components/id-tag/id-tag.vue"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

// Models
import Contract from "@/models/contract/contract"

export default {
  name: "TicketContractHeader",

  components: {
    IdTag,
    LoadingLines
  },

  props: {
    skeleton: { type: Boolean, default: false },
    contract: { type: Object }
  },

  data() {
    return {
      i18nScope: "components.ticket-contract-header"
    }
  },

  computed: {
    businessUnitTranslated() {
      if (!this.contract?.businessUnit) return ""

      return Contract.$tEnum("business-unit", this.contract.businessUnit)
    },

    createdAtFormatted() {
      const formattedDate = this.$l("date.formats.default", this.contract.createdAt)
      return this.$t(".start-format", { date: formattedDate })
    },

    contractDurationInMonths() {
      if (!this.contract.dealEndPresent) return 0

      return Math.round(this.$duration(this.contract.createdAt, this.contract.dealEndAt).asMonths())
    },

    timeRemaining() {
      if (!this.contract.dealEndPresent) return ""
      const remainingMonths = Math.abs(this.$duration(this.contract.dealEndAt).asMonths())

      if (remainingMonths < 0) return this.$t(".remaining.expired")

      return remainingMonths === 1
        ? this.$t(".remaining.valid.one", { count: Math.round(remainingMonths) })
        : this.$t(".remaining.valid.other", { count: Math.round(remainingMonths) })
    }
  }
}

</script>
