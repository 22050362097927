<style lang="scss" scoped>

.requester {
  --requester-padding: 16px;
  --requester-gap-small: 8px;

  border: 1px solid $gray-light-2;
  border-radius: 8px;
  padding: var(--requester-padding);
  gap: var(--requester-gap-small);

  &.selected {
    border-color: $orange;

    &.disabled {
      border-color: $gray-light;
    }
  }

  &:not(.selected, .disabled):hover {
    background-color: $gray-light-4;
  }

  .grid-template {
    grid-template-columns: var(--requester-grid-template-columns);
  }

  p {
    margin: 0;
  }

  .person {
    small {
      color: $gray;

      i {
        font-size: 10px;
      }

      span {
        font-family: $primary-font;
        font-size: 12px;
      }
    }
  }

  .details,
  .detail {
    gap: var(--requester-gap-small);
  }

  .detail {
    min-width: 0;
  }

  .icon {
    width: 20px;
    aspect-ratio: 1;
    flex-shrink: 0;
  }

  .edit {
    align-self: start;
    justify-self: end;
    width: 24px;
    aspect-ratio: 1;
    font-size: 16px;
    padding: 0;
    color: $orange;
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;

    &:hover {
      color: $orange-2;
    }
  }

  .transparent {
    opacity: 0.3;
  }
}



</style>


<template lang="pug">

component.requester.grid(
  :is="tag",
  :class="{ selected, transparent, disabled }",
  :style="cssStyles"
)
  .requester-header.grid.grid-template
    slot(name="left")

    .person.grid.vertical-center
      p {{ requester.name }}

      small.flex.vertical-center.gap-tiny(v-if="requester.driver")
        i.fal.fa-steering-wheel

        span {{ $t(".driver") }}

    button.edit.flex.center.vertical-center(
      v-show="selected",
      @click="emitEdit"
    )
      i.far.fa-pencil

  .requester-body.grid.grid-template(v-show="selected")
    .details.grid(:style="{'grid-column-start': leftSlotUsed ? 2 : 1}")
      .detail.flex
        .icon
          i.fal.fa-id-card

        p(v-mask="'###.###.###-##'") {{ requester.cpf }}

      .detail.flex
        .icon
          i.fal.fa-phone

        p(
          v-if="maskedValuesShown",
          v-mask="selectPhoneMaskOnLength(requester.phone)",
        ) {{ requester.phone }}

      .detail.flex
        .icon
          i.fal.fa-envelope

        app-span(crop, :value="requester.email")

</template>


<script>

import AppSpan from "@/components/app-span/app-span.vue"

export default {
  name: "RequesterSelectorOption",

  components: {
    AppSpan
  },

  props: {
    requester:   { type: Object, default: () => ({}) },
    tag:         { type: String, default: "div" },
    selected:    { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    leftColumn:  { type: String, default: "36px" },
    rightColumn: { type: String, default: "32px" },
    disabled:    { type: Boolean, default: false }
  },

  emits: ["edit"],

  data() {
    return {
      i18nScope:         "components.requester-selector-option",
      maskedValuesShown: true
    }
  },

  computed: {
    leftSlotUsed() {
      return !!this.$slots.left && !!this.$slots.left()[0]
    },

    cssStyles() {
      const gridColumns = [
        "1fr",
        this.rightColumn
      ]

      if (this.leftSlotUsed) gridColumns.unshift(this.leftColumn)

      return { "--requester-grid-template-columns": gridColumns.join(" ") }
    }
  },

  watch: {
    requester() {
      this.resetMaskedValues()
    }
  },

  methods: {
    selectPhoneMaskOnLength(phone) {
      if (!phone) return ""

      const cleanPhone = phone.replace(/\D/g, "")

      switch (cleanPhone.length) {
        case 10:
          return "(##) ####-####"
        case 11:
          return "(##) #####-####"
        default:
          return ""
      }
    },

    emitEdit() {
      this.$emit("edit", this.requester)
    },

    // XXX: Rerenderiza valores mascarados, para atualizá-los
    async resetMaskedValues() {
      this.maskedValuesShown = false
      await this.$nextTick()
      this.maskedValuesShown = true
    }
  }
}

</script>
