<style lang="scss" scoped>

.tickets-reschedule-supplier {
  grid-template-columns: 1fr 330px;
  gap: 57px;

  .change-supplier {
    height: 24px;
    justify-self: flex-start;
  }

  .content-start {
    align-content: flex-start;
  }

  .border-wrapper {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    padding: 24px;
  }

  .divider {
    min-height: 1px;
    min-width: 1px;
    background-color: $gray-light-2;
  }
}

</style>


<template lang="pug">

.tickets-reschedule-supplier.grid
  .main
    supplier-appointment(
      ref="supplierAppointment"
      :ticket-id="ticketId"
      :parent-loading="parentLoading"
      :show-unconfirmed="startedEditing"
      :disabled="parentLoading",
      @update="continueToConfirmation"
    )

  .aside.grid.gap-big.content-start
    service-cart(
      without-delete,
      initially-open,
      :ticket-id="ticketId"
    )
      template(#footer="{ disabled: cartDisabled }")
        app-button(
          full-width,
          :disabled="cartDisabled",
          @click="updateAppointment"
        )
          .flex.center.vertical-center.gap-small
            span {{ $t(".cart-button") }}
            i.fas.fa-arrow-right

    .divider

    auxiliar-buttons(
      hide-save,
      hide-sinister,
      :ticket="ticket",
      @cancellation="handleCancellation"
    )

</template>


<script>

// Components
import ServiceCart from "@/components/service-cart"
import AppButton from "@/components/app-button/app-button.vue"

import AuxiliarButtons from "../../_components/auxiliar-buttons"
import SupplierAppointment from "../../_components/supplier-appointment"

export default {
  name: "TicketsRescheduleSupplier",

  components: {
    ServiceCart,
    AppButton,

    AuxiliarButtons,
    SupplierAppointment
  },

  props: {
    ticketId:       { type: [String, Number] },
    ticket:         { type: Object, default: () => ({}) },
    parentLoading:  { type: Boolean, default: false },
    startedEditing: { type: Boolean, default: false }
  },

  emits: ["supplier-updated"],

  data() {
    return {
      i18nScope: "tickets-reschedule-supplier"
    }
  },

  methods: {
    updateAppointment() {
      if (!this.$refs.supplierAppointment?.updateAppointment) return

      this.$refs.supplierAppointment.updateAppointment()
    },

    continueToConfirmation() {
      this.$emit("supplier-updated")

      this.$router.push({
        name:   "tickets-reschedule.confirmation",
        params: { id: this.ticketId }
      })
    },

    async handleCancellation({ successMessage }) {
      await this.$router.push({ name: "tickets-show", param: { id: this.ticketId } })
      this.$notifications.info(successMessage)
    }
  }
}

</script>
