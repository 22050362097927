<style lang="scss" scoped>

.service-cart {
  --service-cart-border: 1px solid #{$gray-light-2};

  p {
    margin: 0;
  }

  .header {
    font-size: 16px;

    .skeleton-little {
      margin-top: 2px;
    }

    .amount {
      gap: 5px;

      .fa-cart-shopping {
        font-size: 12px;
      }

      p {
        font-size: 14px;
      }
    }

    .expand {
      appearance: none;
      background: transparent;
      border: none;
      padding: 0;
      width: 24px;
      aspect-ratio: 1;
      transition: transform 0.2s ease;

      &:hover {
        cursor: pointer;
      }

      &.open {
        transform: rotate(0.5turn);
      }
    }
  }

  .summary {
    h2 {
      font-size: 16px;
      font-family: $secondary-font;
      font-weight: 500;
    }
  }

  .skeleton,
  .list,
  .empty {
    margin-top: 8px;
  }

  .skeleton:deep(.loading-line) {
    padding: 16px;
  }

  .list {
    border-radius: 8px;
    border: var(--service-cart-border);
  }

  .issue {
    &:not(:last-child) {
      border-bottom: var(--service-cart-border);
    }
  }

  .empty {
    padding: 24px;
    border-radius: 8px;
    background-color: $gray-light-4;
    font-size: 14px;
  }

  .footer {
    --service-cart-footer-spacing: 24px;

    margin-top: var(--service-cart-footer-spacing);
    padding-top: var(--service-cart-footer-spacing);
    border-top: var(--service-cart-border);
  }

  .transparent {
    opacity: 0.4;
  }

  .color-purple {
    color: $purple;
  }
}

</style>


<template lang="pug">

.service-cart
  header.header.flex.space-between
    .summary
      h2 {{ $t('.title') }}

      .skeleton-little(v-if="!initialized")
        loading-lines(:height="17", :min="90", :max="92")

      .amount.flex.vertical-center(v-else, :class="{ transparent: isRemoving }")
        i.fa-cart-shopping(:class="issues.length ? 'fas color-purple' : 'fal'")
        p {{ $t('.amount', { count: issues.length }) }}

    button.expand.flex.center.vertical-center.self-vertical-start(
      :class="{ open: contentOpen }"
      @click="contentOpen = !contentOpen"
    )
      i.fas.fa-angle-down

  template(v-if="contentOpen")
    .skeleton(v-if="!initialized")
      loading-lines(:lines="3",:min="90", :max="100")

    .list(v-else-if="issues.length", :class="{ transparent: isRemoving }")
      .issue(v-for="issue of issues")
        service-cart-item(
          :withoutDelete="withoutDelete"
          :issue="issue",
          :disabled="disabled"
          @deleting="handleIssueRemoving"
          @delete="handleIssueRemove"
          @not-deleted="handleIssueNotRemoved"
        )

    .empty.grid.center.gap-small(v-else)
      img(:src="emptyCart", :alt="$t('.list-empty.icon-alt')")
      p {{ $t(".list-empty.text") }}

  footer.footer(v-if="!hideFooter")
    slot(name="footer", v-bind="{ disabled: footerDisabled }")
      app-button(
        full-width,
        :disabled="footerDisabled",
        @click="handleSubmitClick",
      )
        .flex.center.vertical-center.gap-small
          span {{ $t('.supplier-selection') }}
          i.fas.fa-arrow-right

</template>


<script>

// Assets
import emptyCart from "@/assets/images/list-illustrations/empty-cart.svg"

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import AppButton from "@/components/app-button/app-button.vue"
import ServiceCartItem from "./item"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "ServiceCart",

  components: {
    LoadingLines,
    AppButton,
    ServiceCartItem
  },

  mixins: [FetchMixin],

  props: {
    ticketId:      { type: [String, Number] },
    initiallyOpen: { type: Boolean, default: false },
    withoutDelete: { type: Boolean, default: false },
    hideFooter:    { type: Boolean, default: false },
    disabled:      { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:           "components.service-cart",
      emptyCart,
      contentOpen:         false,
      //
      autofetch:           false,
      issues:              [],
      //
      itemStartedRemoving: false
    }
  },

  mounted() {
    if (this.initiallyOpen) this.contentOpen = true
    if (this.ticketId) this.fetch()
  },

  computed: {
    footerDisabled() {
      return [
        this.disabled,
        !this.issues.length,
        this.itemStartedRemoving
      ].some(Boolean)
    },

    isRemoving() {
      return (this.initialized && this.fetching) || this.itemStartedRemoving
    }
  },

  methods: {
    handleIssueRemove() {
      this.fetch()
      this.itemStartedRemoving = false
    },

    handleIssueRemoving() {
      this.itemStartedRemoving = true
    },

    handleIssueNotRemoved() {
      this.itemStartedRemoving = false
    },

    fetchRequest() {
      return this.$sdk.tickets.issues.index({ ticketId: this.ticketId })
    },

    onFetchSuccess({ data }) {
      this.issues = data
    },

    handleSubmitClick() {
      this.$emit("complete")
    }
  }
}

</script>
