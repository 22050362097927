<style lang="scss" scoped>

.tickets-reschedule {
  padding-block: 24px;
  align-content: flex-start;

  .divider {
    height: 1px;
    background-color: $gray-light-2;
  }

  .top {
    min-height: 21px;
  }

  .go-back-text {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
  }

  .go-back-icon {
    font-size: 12px;
  }
}

</style>


<template lang="pug">

.tickets-reschedule.grid.gap-big
  .grid.gap-small
    .top.flex.vertical-center
      transition-fade-slide(v-bind="pageTransitionProps")
        app-button(
          v-if="goBackDetails",
          theme="link",
          :key="goBackDetails.previousRouteName"
          @click="goBackAction(goBackDetails)"
        )
          .flex.vertical-center.gap-tiny
            i.fal.fa-arrow-left.go-back-icon
            span.go-back-text {{ $t(goBackDetails.buttonTextI18n) }}

    ticket-header(
      :skeleton="initializing",
      :ticket="ticket",
      show-type,
      :header-title="$t('.title')"
    )

  .divider

  router-view(
    v-slot="{ Component, route }",
    :ticket-id="ticketId",
    :ticket="ticket",
    :parent-loading="fetching || deleting",
    :started-editing="startedNewBooking",
    @supplier-updated="handleTicketUpdate",
  )
    transition-fade-slide(v-bind="pageTransitionProps")
      component(:is="Component", :key="route.path")

</template>


<script>

// Components
import TicketHeader from "../_components/ticket-header"
import AppButton from "@/components/app-button/app-button.vue"

// Transitions
import TransitionFadeSlide from "@/transitions/transition-fade-slide"

// Views
import LoadedView from "@/views/loaded-view.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

const goBackMap = new Map([
  [
    "tickets-reschedule.supplier",
    {
      previousRouteName: "tickets-show",
      buttonTextI18n:    ".go-back-to.show",
      eraseBookings:     true
    }
  ],
  [
    "tickets-reschedule.confirmation",
    {
      previousRouteName: "tickets-reschedule.supplier",
      buttonTextI18n:    ".go-back-to.supplier"
    }
  ]
])

export default {
  name: "TicketsReschedule",

  components: {
    TicketHeader,
    AppButton,

    TransitionFadeSlide
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope:            "tickets-reschedule",
      routeName:            "tickets-reschedule",
      ticketId:             "",
      //
      ticket:               {},
      isTransitionReversed: false,
      startedNewBooking:    false,
      deleting:             false
    }
  },

  computed: {
    goBackDetails() {
      if (!goBackMap.has(this.$route.name)) return null

      return goBackMap.get(this.$route.name)
    },

    pageTransitionProps() {
      return {
        reverse:     this.isTransitionReversed,
        duration:    "300ms",
        translation: "20px"
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeName = to.name
    })
  },

  beforeRouteUpdate(to, from, next) {
    this.isTransitionReversed = to.meta.step > from.meta.step

    this.routeName = to.name
    this.scrollContainerTop()

    next()
  },

  mounted() {
    this.scrollContainerTop()
  },

  methods: {
    handleTicketUpdate() {
      this.startedNewBooking = true
    },

    // @override LoadedView
    parseRoute() {
      this.ticketId = this.$route.params.id
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.show({ ticketId: this.ticketId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.ticket = data
    },

    scrollContainerTop() {
      if (!this.$refs.container) return

      const container = this.$refs.container.$el || this.$refs.container

      container.scrollIntoView()
    },

    goBackAction(details) {
      if (!details.eraseBookings) {
        this.$router.push({
          name:   details.previousRouteName,
          params: { id: this.ticketId }
        })

        return
      }

      this.eraseUnconfirmedBookingsAndLeave(details)
    },

    async eraseUnconfirmedBookingsAndLeave(details) {
      try {
        this.deleting = true
        await this.$sdk.tickets.booking.delete({ ticketId: this.ticketId })
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.deleting = false
        this.$router.push({
          name:   details.previousRouteName,
          params: { id: this.ticketId }
        })
      }
    }
  }
}
</script>
