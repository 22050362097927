// Base
import Base from "./Base"

// Models
import Requester from "@/models/requester/requester"

class ContractRequesterRequests extends Base {
  static get selector() {
    return "contracts.requesters"
  }

  index({ contractRid, params = {}, options = {} } = {}) {
    this.verifyData("index", { contractRid })

    return this.action({
      method:  "get",
      url:     `/contracts/${contractRid}/requesters`,
      params,
      options: {
        model: Requester,

        ...options
      }
    })
  }

  create({ contractRid, params = {}, options = {} } = {}) {
    this.verifyData("create", { contractRid })

    return this.action({
      method:  "post",
      url:     `/contracts/${contractRid}/requesters`,
      params,
      options: {
        model: Requester,

        ...options
      }
    })
  }
}

export default ContractRequesterRequests
