// Base
import Base from "./Base"

// Models
import Ticket from "@/models/ticket/ticket"

class TicketReasonRequests extends Base {
  static get selector() {
    return "tickets.reason"
  }

  update({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("update", { ticketId })

    return this.action({
      method:  "patch",
      url:     `/tickets/${ticketId}/reason`,
      params,
      options: {
        model: Ticket,

        ...options
      }
    })
  }
}

export default TicketReasonRequests
