<style lang="scss" scoped>

.ticket-row {
  --ticket-row-border: 1px solid #{$gray-light-2};

  grid-template-columns: var(--ticket-template-columns);
  gap: var(--ticket-gap);
  border-top: var(--ticket-row-border);
  font-family: $secondary-font;
  font-weight: 400;


  &.darkend {
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      background-color: $gray-light-3;
      inset: 0;
      z-index: -1;
    }

    &>* {
    opacity: 0.5;
    }
  }

  &:last-of-type {
    border-bottom: var(--ticket-row-border);
  }

  p {
    margin: 0;
  }

  .cell {
    padding: 12px;
    font-size: 14px;

    &.clickable{
      &:hover {
        color: $orange;
        cursor: pointer;
      }

      ::selection {
        background-color: transparent;
      }
    }
  }

  .tag-inline-block{
    display: inline-block;
  }

  .text-small {
    font-size: 12px;
  }

  .not-available {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    color: $gray;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3
  }

  .primary-font {
    font-family: $primary-font;
    color: $gray;
  }

  .gap-smaller {
    column-gap: 4px;
  }

  .text-max {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-break: anywhere;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .font-size-regular {
    font-size: 1rem;
  }
}

.popover-button {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: $primary-font;
  font-weight: 300;
  padding: 4px 8px;
  transition: color 0.1s ease-out;
  cursor: not-allowed;

  &:not(:disabled, [disabled]) {
    cursor: pointer;

    &:hover {
      color: $orange;

      &:active {
        color: $orange-dark;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray-light-2;
  }
}

</style>


<template lang="pug">

.ticket-row.grid.vertical-center(
  :style="cssVars",
  :class="{ darkend: ticketIsFromOutsideGac }"
)
  .cell
    tickets-table-row-state-icon.font-size-regular(:ticket="ticket")

  .cell(v-if="extendedConfiguration")
    p {{ ticket.processedStateTranslated }}

  .cell
    p.text-max(
      v-if="(smallConfiguration || extendedConfiguration) && ticket.typeOrReason"
    ) {{ ticket.typeOrReason }}

    id-tag(:value="ticket.remoteId", v-tooltip="$t('.column.identifier')")

    p.flex.vertical-center.gap-smaller.wrap(v-if="ticket.openingDate")
      span {{ formatDate(ticket.openingDate).date }}
      i.fas.fa-diamond
      span {{ formatDate(ticket.openingDate).time }}

  .cell(v-if="!smallConfiguration")
    p.text-max(v-if="!extendedConfiguration && ticket.typeOrReason") {{ ticket.typeOrReason }}

    p.flex.vertical-center.gap-smaller.wrap.primary-font(v-if="ticket.scheduledAt")
      span {{ formatDate(ticket.scheduledAt).date }}

      i.fas.fa-diamond

      span {{ formatDate(ticket.scheduledAt).time }}

  .cell(v-if="!smallConfiguration && !extendedConfiguration")
    template(v-if="vehicle.licensePlate")
      p.text-max {{ vehicle.name }}

      .vehicle-details.flex.wrap.gap-tiny
        license-plate(:value="vehicle.licensePlate", small)

        tag-inline-vehicle-category.text-small(
          v-if="vehicle.categorySimplified"
          :category="vehicle.categorySimplified"
        )

    p.not-available(v-else) {{ $t('.not-available') }}

  .cell
    v-dropdown(
      v-if="ticket.supplier?.presentName"
      :triggers="['hover']",
      :popper-triggers="['hover']"
      :show-group="`ticket-${ticket.remoteId}`"
    )
      p.text-max {{ ticket.supplier.presentName }}

      p.text-max.primary-font {{ ticket.supplier?.address?.cityAndState }}

      template(#popper="{ shown }")
        details-popover(:copy-enable="shown", :details="supplierPopoverDetails")

    p.not-available(v-else) {{ $t('.not-available') }}

  .cell
    p.text-max(
      v-if="ticket.contract?.customer?.name",
      v-tooltip="$t('.column.client')"
    ) {{ customerName }}

    p.text-max.primary-font(
      v-if="ticket.remoteRequester",
      v-tooltip="$t('.column.requester')"
    ) {{ remoteRequesterName }}

  .cell
    tag-inline-business-unit.tag-inline-block.text-small(
      v-if="ticket.contract?.businessUnit",
      :unit="ticket.contract.businessUnit"
    )

  .cell.self-center
    tag-inline.text-small(
      v-if="userFormatted"
      weight="bold"
      shape="pill"
      v-tooltip="userFormatted"
    ) {{ userInitials }}
    p(v-else) -

  .cell.self-center(v-if="!extendedConfiguration")
    v-dropdown(
      v-if="!ticketIsFromOutsideGac"
      placement="bottom-end",
      :triggers="['click']",
      :popperTriggers="['click']"
    )
      app-icon-button

      template(#popper)
        .grid
          button.popover-button.flex.vertical-center.gap-small(
            v-for="button of popoverButtonsData"
            @click="button.action",
            :disabled="button.disabled"
          )
            i(:class="button.iconClass")
            span {{ button.label }}

</template>


<script>

// Components
import TagInline from "@/components/tag-inline"
import TagInlineBusinessUnit from "@/components/tag-inline/business-unit"
import TagInlineVehicleCategory from "@/components/tag-inline/vehicle-category"
import AppButton from "@/components/app-button/app-button.vue"
import IdTag from "@/components/id-tag/id-tag.vue"
import LicensePlate from "@/components/license-plate/license-plate.vue"
import AppSpan from "@/components/app-span/app-span.vue"
import DetailsPopover from "@/components/details-popover"
import AppIconButton from "@/components/app-icon-button"

import TicketsTableRowStateIcon from "./state-icon"

const configurationTypes = ["default", "small", "extended"]

export default {
  name: "TicketsTableRow",

  props: {
    ticket:        { type: Object, default: () => ({}) },
    columnSizes:   { type: String, default: "1fr" },
    gap:           { type: String, default: "0px" },
    small:         { type: Boolean, default: false },
    configuration: {
      type:    String,
      default: "default",
      validator(val) { return configurationTypes.includes(val) }
    }
  },

  components: {
    TagInline,
    TagInlineBusinessUnit,
    TagInlineVehicleCategory,
    AppButton,
    IdTag,
    LicensePlate,
    AppSpan,
    DetailsPopover,
    AppIconButton,

    TicketsTableRowStateIcon
  },

  data() {
    return {
      i18nScope: "components.tickets-table-row"
    }
  },

  computed: {
    smallConfiguration() { return this.configuration === "small" },

    extendedConfiguration() { return this.configuration === "extended" },

    cssVars() {
      return {
        "--ticket-template-columns": this.columnSizes,
        "--ticket-gap":              this.gap
      }
    },

    vehicle() {
      return this.ticket.contract?.vehicle || {}
    },

    userFormatted() {
      if (!this.ticket.user?.name) return ""
      return _.firstLettersCapital(this.ticket.user.name)
    },

    customerName() {
      if (!this.ticket.contract?.customer?.name) return ""

      return _.firstLettersCapital(this.ticket.contract.customer.name)
    },

    remoteRequesterName() {
      if (!this.ticket.remoteRequester) return ""

      return _.firstLettersCapital(this.ticket.remoteRequester)
    },

    userInitials() {
      if (!this.userFormatted) return ""
      const names = this.userFormatted.split(/\s/)

      const firstAndLast = [names[0], _.last(names)]

      return firstAndLast.map(name => name[0]).join("")
    },

    supplierPopoverDetails() {
      const { cnpj, phone, email, address } = this.ticket.supplier

      const phoneMask = () => {
        if (!phone) return ""

        const cleanPhone = phone.replace(/\D/g, "")

        switch (cleanPhone.length) {
          case 11:
            return "(##) #####-####"
          case 10:
            return "(##) ####-####"
          default:
            return ""
        }
      }

      return [
        { value: this.ticket?.supplier?.presentName },
        {
          value:     cnpj,
          mask:      "##.###.###/####-#",
          iconClass: "fa-building",
          copy:      true
        },
        {
          value:     phone,
          mask:      phoneMask(),
          iconClass: "fa-phone",
          copy:      true
        },
        {
          value:     email,
          iconClass: "fa-envelope",
          copy:      true
        },
        {
          value:     address?.fullFormat,
          iconClass: "fa-location-dot",
          copy:      true
        }
      ]
    },

    popoverButtonsData() {
      return [
        {
          action:    this.goToTicket,
          disabled:  this.ticket.isStateDraft,
          iconClass: "fal fa-eye",
          label:     this.$t(".popover.view")
        },
        {
          action:    this.emitBookingConfirmation,
          disabled:  !this.ticket.isPrebooked,
          iconClass: "fal fa-check",
          label:     this.$t(".popover.booking-confirmation")
        },
        {
          action:    this.goToTicketDraft,
          disabled:  !this.ticket.isStateDraft,
          iconClass: "fal fa-pencil",
          label:     this.$t(".popover.continue-draft")
        },
        {
          action:    this.goToTicketReschedule,
          disabled:  !this.ticket.canBeRescheduled,
          iconClass: "fal fa-calendar",
          label:     this.$t(".popover.reschedule")
        },
        {
          action:    this.emitCancellation,
          disabled:  this.ticket.processedState === "canceled",
          iconClass: "fal fa-calendar-xmark",
          label:     this.$t(".popover.cancellation")
        }
      ]
    },

    ticketIsFromOutsideGac() {
      return !this.ticket.id
    }
  },

  methods: {
    formatDate(dateIsoString) {
      const date = this.$l("date.formats.default", dateIsoString)
      const time = this.$l("time.formats.hourAndMinutes", dateIsoString)

      return { date, time }
    },

    goToTicket() {
      if (this.ticket.isStateDraft) return

      this.$router.push({ name: "tickets-show", params: { id: this.ticket.id } })
    },

    goToTicketDraft() {
      if (!this.ticket.isStateDraft) return

      this.$router.push({ name: "tickets-draft", params: { id: this.ticket.id } })
    },

    goToTicketReschedule() {
      if (!this.ticket.canBeRescheduled) return

      this.$router.push({ name: "tickets-reschedule", params: { id: this.ticket.id } })
    },

    emitCancellation() {
      this.$emit("cancellation", { ticket: this.ticket })
    },

    emitBookingConfirmation() {
      this.$emit("booking-confirmation", { ticket: this.ticket })
    }
  }
}

</script>
