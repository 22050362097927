// Models
import Base from "../base/base"

class VehicleMaintenance extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "lastMaintenance",
      "lastMaintenanceKm",
      "km",
      "maintenanceRequired",
      "nextMaintenance"
    ])
  }
}

export default VehicleMaintenance
