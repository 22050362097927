// Vue
import { createApp } from "vue"

// Globals
import "@/globals"

// Base styles
import "@/assets/styles/styles.scss"

// Service worker
import "./registerServiceWorker"

// App
import { version } from "../package"
import initialize from "./initializers"
import App from "./app.vue"

globalThis.VERSION = version

const app = createApp(App)

initialize(app)

app.mount("#app")
