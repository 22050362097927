// Modules
import { auth } from "@/modules/auth"

// Views
import Login from "@/views/login/login.vue"
import Logout from "@/views/logout/logout.vue"
import Search from "@/views/search/search.vue"
import Contract from "@/views/contract/contract.vue"
import Params from "@/views/params/params.vue"

// Routes
import ticketsRoutes from "./tickets"

// Components
import NotFound from "@/components/errors/not-found/not-found.vue"

const routes = [
  ...ticketsRoutes,

  {
    path:      "/login",
    name:      "login",
    component: Login,
    meta:      {
      layout: "blank"
    }
  },
  {
    path:      "/logout",
    name:      "logout",
    component: Logout,
    meta:      {
      auth: true
    }
  },
  {
    path:     "/",
    name:     "root",
    redirect: () => (auth.isAuthenticated() ? "/search" : "/login")
  },
  {
    path:      "/search",
    name:      "search",
    component: Search,
    meta:      {
      auth: true
    }
  },
  {
    path:      "/params",
    name:      "params",
    component: Params,
    meta:      {
      auth: true
    }
  },
  {
    path:      "/contract/:rid",
    name:      "contract",
    component: Contract,
    meta:      {
      auth: true
    }
  },

  {
    path:      "/:pathMatch(.*)*",
    name:      "notFound",
    component: NotFound
  }
]

export default routes
