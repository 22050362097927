// Base
import Base from "./Base"

// Models
import Message from "@/models/message/message"

class TicketMessageRequests extends Base {
  static get selector() {
    return "tickets.messages"
  }

  index({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("index", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/messages`,
      params,
      options: {
        model: Message,

        ...options
      }
    })
  }

  create({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("create", { ticketId })

    return this.action({
      method:  "post",
      url:     `/tickets/${ticketId}/messages`,
      params,
      options: {
        model: Message,

        ...options
      }
    })
  }
}

export default TicketMessageRequests
