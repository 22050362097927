import { i18nPlugin, i18n } from "@/modules/i18n"

// XXX: dados construídos por `yarn run build:i18n`
import translations from "@/assets/translations.json"

const allTranslations = _.merge({}, i18n.translations, translations)

function getPluginOptions(options) {
  return {
    locales:       ["pt-BR"/* , 'en-US' */],
    defaultLocale: "pt-BR",
    translations:  allTranslations,

    ...options
  }
}

export default function initialize(app, options) {
  const pluginOptions = getPluginOptions(options)

  app.use(i18nPlugin, pluginOptions)
}

export { i18nPlugin, getPluginOptions }
