<style lang="scss" scoped>

.messages-history {
  padding-inline: 20px;
}

</style>


<template lang="pug">

.messages-history
  template(v-if="initializing")
    loading-lines.grid.gap-regular(:lines="4", :min="40", :max="45")

  template(v-else)
    messages-history-header(
      :column-sizes="columnSizes",
      :gap="gap",
      @add="modalShown = true"
    )

    template(v-if="messages.length")
      messages-history-item(
        v-for="message of messages",
        :style="{ opacity: fetching ? 0.7 : 1}"
        :column-sizes="columnSizes",
        :gap="gap",
        :message="message"
      )

    p.empty(v-else) {{ $t(".empty") }}

    paginator(
      v-if="pagination && messages.length",
      :per-page="pagination.perPage",
      :total="pagination.total",
      :hide-single-page="false",
      :disabled="fetching",
      :modelValue="pagination.page",
      @update:modelValue="handlePageUpdate"
    )

    app-modal(
      close-button,
      avoid-close-on-click-outside,
      :heading="$t(`.modal.title`)",
      :width="566",
      :show="modalShown",
      @close="closeModal"
    )
      messages-history-create-modal(
        :ticket-id="ticketId",
        :ticket-remote-id="ticketRemoteId",
        @close="closeModal",
        @create="handleCreate"
      )

</template>


<script>

// Components
import MessagesHistoryHeader from "./header"
import MessagesHistoryItem from "./item"
import MessagesHistoryCreateModal from "./create-modal"

import AppModal from "@/components/app-modal"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import Paginator from "@/components/paginator/paginator.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "MessagesHistory",

  components: {
    MessagesHistoryHeader,
    MessagesHistoryItem,
    MessagesHistoryCreateModal,

    AppModal,
    LoadingLines,
    Paginator
  },

  props: {
    ticketId:        { type: [String, Number] },
    ticketRemoteId:  { type: [String, Number] },
    initialMessages: { type: Array }
  },

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope:    "components.messages-history",
      columnSizes:  "74px 1fr",
      gap:          "32px",
      messages:     [],
      autofetch:    true,
      modalShown:   false,
      //
      pagination:   null,
      tempNextPage: null
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      const params = {}

      if (this.tempNextPage) params.page = this.tempNextPage

      return this.$sdk.tickets.messages.index({ ticketId: this.ticketId, params })
    },

    // @overide FetchMixin
    onFetchSuccess({ data, headers }) {
      this.updatePagination(headers)

      this.messages = data
      this.tempNextPage = null

      this.$emit("index", { messages: data })
    },

    updatePagination(headers) {
      const { "x-per-page": perPage, "x-page": page, "x-total": total } = headers

      this.pagination = {
        perPage: Number(perPage),
        page:    Number(page),
        total:   Number(total)
      }
    },

    closeModal() {
      this.modalShown = false
    },

    handleCreate() {
      this.fetch()
      this.closeModal()
    },

    handlePageUpdate(page) {
      console.log({ page })

      this.tempNextPage = page <= 1
        ? null
        : page

      this.fetch()
    }
  }
}

</script>
