import { auth, authPlugin  } from "@/modules/auth"

export default function initialize(app) {
  app.use(authPlugin)

  configure()
}

function configure() {
  auth.ensureEncoded()
}

export { authPlugin, configure }
