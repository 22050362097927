<style lang="scss" scoped>

.logout {
  height: 100vh;

  .user {
    margin-bottom: 24px;

    .text {
      display: block;
    }
  }
}

</style>


<template lang="pug">

.logout.flex.vertical-center.center
  .info
    .user
      span.text id: {{ user.id }}
      span.text name: {{ user.name }}
      span.text username: {{ user.username }}
      span.text email: {{ user.email }}
      span.text roles: {{ roles }}

    app-button.button(
      theme="link",
      :disabled="submitting",
      @click="logout"
    )
      span {{ $t('.btn.logout') }}

</template>


<script>

// Components
import AppButton from "@/components/app-button/app-button.vue"
import AppModal from "@/components/app-modal"

// Modules
import { auth } from "@/modules/auth"

export default {
  name: "Logout",

  emits: [],

  components: {
    AppButton,
    AppModal
  },

  data() {
    return {
      i18nScope: "logout",

      submitting: false,

      user:  auth.getUser(),
      roles: auth.getRoles(),

      showModal: false
    }
  },

  methods: {
    async logout() {
      if (this.submitting || !await this.showConfirmation()) return

      this.submitting = true

      await this.$sdk.auth.logout().catch(() => {})
      this.$auth.logout()

      this.submitting = false

      this.$router.push({ name: "login" })
    },

    showConfirmation() {
      return this.$confirm({
        heading: this.$t(".confirmation.heading"),
        message: this.$t(".confirmation.message")
      })
    }
  }
}

</script>
