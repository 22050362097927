// Base
import Base from "./Base"

// Models
import Contract from "@/models/contract/contract"

class ContractRequests extends Base {
  static get selector() {
    return "contracts"
  }

  index({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/contracts/search`,
      params,
      options: {
        model: Contract,

        ...options
      }
    })
  }

  show({ contractRid, params = {}, options = {} } = {}) {
    this.verifyData("show", { contractRid })

    return this.action({
      method:  "get",
      url:     `/contracts/${contractRid}`,
      params,
      options: {
        model: Contract,

        ...options
      }
    })
  }
}

export default ContractRequests
