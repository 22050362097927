<style lang="scss" scoped>

.navbar {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  background-color: $white;
  position: relative;
  z-index: 1;
  width: 100%;
  top: 0;
  height: $navbar-height;

  .left-menu {
    position: relative;
    margin-left: 24px;

    .stamp {
      position: absolute;
      top: 2px;
      left: 50px;
    }

    .button {
      .logo {
        width: 77px;
        height: 23px;
      }

      .divider {
        height: 16px;
        width: 1px;
        background-color: $gray-light;
        margin: 0 24px;
      }

      .gac-name {
        min-width: 140px;
        margin-left: 8px;
        font-family: $secondary-font;
        color: $gray-dark;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  .right-menu {
    border-left: 1px solid $gray-light;
  }
}

</style>


<template lang="pug">

nav.navbar
  .flex.space-between.full-height
    .left-menu.flex
      stage-stamp.stamp

      app-button.button(theme="link", :to="{ path: '/' }")
        img.logo(:src="logo")

        .divider

        .flex.vertical-center
          gac-icon.gac
          span.gac-name.preserve-whitespace {{ $t('.gac') }}

    .right-menu.flex.vertical-center
      logout-button

</template>


<script>

// Assets
import logo from "@/assets/images/logo-movida.svg"

// Components
import AppButton from "@/components/app-button/app-button.vue"
import StageStamp from "@/components/stage-stamp/stage-stamp.vue"
import LogoutButton from "@/components/logout-button/logout-button.vue"
import GacIcon from "@/components/icons/gac-icon/gac-icon.vue"

export default {
  name: "Navbar",

  emits: [],

  components: {
    AppButton,
    StageStamp,
    LogoutButton,
    GacIcon
  },

  data() {
    return {
      i18nScope: "components.navbar",

      logo
    }
  }
}

</script>
