import Base from "./base/base"
import User from "./user/user"
import Contract from "./contract/contract"
import Customer from "./customer/customer"
import Vehicle from "./vehicle/vehicle"
import VehicleMaintenance from "./vehicle/vehicle-maintenance"
import Requester from "./requester/requester"
import Ticket from "./ticket/ticket"
import Part from "./part/part"
import Service from "./service/service"
import ServiceTarget from "./service/service-target"
import Sector from "./sector/sector"
import Symptom from "./symptom/symptom"
import Supplier from "./supplier/supplier"
import SupplierAvailable from "./supplier/supplier-available"
import SupplierBusinessHour from "./supplier/supplier-business-hour"
import SupplierRecommendation from "./supplier/supplier-recommendation"
import SupplierInstruction from "./supplier/supplier-instruction"
import Driver from "./driver/driver"
import Person from "./person/person"
import Address from "./address/address"
import Issue from "./issue/issue"
import Maintenance from "./maintenance/maintenance"
import Contact from "./contact/contact"
import ContactEmail from "./contact/contact-email"
import ContactPhone from "./contact/contact-phone"
import Booking from "./booking/booking"
import BookingEvent from "./booking/booking-event"
import Message from "./message/message"
import Params from "./params/params"

const models = {
  Base,
  User,
  Contract,
  Customer,
  Vehicle,
  VehicleMaintenance,
  Requester,
  Ticket,
  Part,
  Service,
  ServiceTarget,
  Sector,
  Symptom,
  Supplier,
  SupplierAvailable,
  SupplierBusinessHour,
  SupplierRecommendation,
  SupplierInstruction,
  Driver,
  Person,
  Address,
  Issue,
  Maintenance,
  Contact,
  ContactEmail,
  ContactPhone,
  Booking,
  BookingEvent,
  Message,
  Params
}

Base.$models = models

export default models
