import Base from "../base/base"

class SupplierAvailable extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "distance",
      "score",
      "at"
    ])
  }

  static get relations() {
    return {
      supplier: { type: "hasOne", model: "Supplier" }
    }
  }
}

export default SupplierAvailable
