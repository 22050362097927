<style lang="scss" scoped>

.tickets-search {
  --tickets-search-fixed-field-width: 269px;
  --tickets-search-fixed-field-width-small: 160px;

  .field-fixed {
    width: var(--tickets-search-fixed-field-width)
  }

  .field-fixed-small {
    width: var(--tickets-search-fixed-field-width-small)
  }

  .submit {
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid currentColor;
    color: $orange;
    padding: 0;
    width: 40px;
    aspect-ratio: 1;

    &:not([disabled], :disabled):hover {
      color: $orange-dark;
      cursor: pointer;
    }

    &[disabled],
    &:disabled {
      cursor: not-allowed;
      color: $gray-light;
    }
  }

  .footer {
    min-height: 22px;

    &-button-content {
      font-size: 14px;
      color: $gray-dark;

      &.disabled {
        color: $gray-2
      }

      &:not(.disabled):hover {
        color: $gray-dark-2;
      }
    }
  }
}

</style>


<template lang="pug">

form.tickets-search.grid.gap-small(@submit.prevent="handleSubmit")
  .fields.flex.vertical-bottom.gap-regular
    input-field.grow(
      hide-label,
      name="ticket[q]",
      icon-left="fal fa-magnifying-glass",
      v-model="form.q",
      :maxlength="termCharLimit",
      :placeholder="$t('.fields.q.placeholder')",
      :inline-padding="12",
      :height="40",
      :disabled="disabled"
    )

    date-field.field-fixed-small(
      name="ticket[openingAfter]",
      v-model="form.openingAfter",
      :disabled="disabled",
      v-if="datesRendered"
    )

    date-field.field-fixed-small(
      name="ticket[openingBefore]",
      v-model="form.openingBefore",
      :disabled="disabled",
      v-if="datesRendered"
    )

    select-field.field-fixed-small(
      name="ticket[timeframe]",
      v-model="form.timeframe",
      :options="timeframeOptions",
      :disabled="disabled",
      :label="$t('.fields.timeframe.label')"
    )

    button.submit.flex.center.vertical-center(
      :disabled="disabled",
    )
      i.fas.fa-magnifying-glass

  .fields.flex.vertical-bottom.gap-regular
    multiple-select-field.field-fixed(
      name="ticket[businessUnit]",
      v-model="form.businessUnit",
      :options="businessUnitOptions",
      :disabled="disabled",
      :placeholder="$t('.fields.business-unit.placeholder')",
    )

    multiple-select-field.field-fixed(
      name="ticket[state]",
      v-model="form.state",
      :options="stateOptions",
      :disabled="disabled",
      :placeholder="$t('.fields.state.placeholder')",
    )

    multiple-select-field.field-fixed(
      name="ticket[scheduleState]",
      v-model="form.scheduleState",
      :options="scheduleStateOptions",
      :disabled="disabled",
      :placeholder="$t('.fields.schedule-state.placeholder')",
    )

    multiple-select-field.field-fixed(
      name="ticket[vehicleCategory]",
      v-model="form.vehicleCategory",
      :options="vehicleCategoryOptions",
      :disabled="disabled",
      :label="$t('.fields.vehicle-category.label')",
      :placeholder="$t('.fields.vehicle-category.placeholder')",
    )

  .footer
    app-button(
        v-if="hasFilter",
        theme="link",
        @click="clearForm",
        :disabled="disabled"
      )
      .footer-button-content.flex.vertical-center.gap-tiny(:class="{ disabled }")
        i.far.fa-times
        span {{ $t('.form-reset') }}

</template>


<script>

// Components
import InputField from "@/components/input-field/input-field.vue"
import MultipleSelectField from "@/components/multiple-select-field"
import AppButton from "@/components/app-button/app-button.vue"
import DateField from "@/components/date-field/date-field.vue"
import SelectField from "@/components/select-field/select-field.vue"

// Models
import Contract from "@/models/contract/contract"
import Ticket from "@/models/ticket/ticket"

const amountOfDaysOptions = [14, 30, 90, 180, 365, 720]
const vehicleCategoryRawOptions = ["Operacional", "Premium", "Super Vip", "Vip"]

export default {
  name: "TicketsSearch",

  components: {
    InputField,
    MultipleSelectField,
    AppButton,
    DateField,
    SelectField
  },

  emits: ["search"],

  props: {
    queryData: { type: Object },
    disabled:  { type: Boolean, default: false }
  },

  data() {
    const timeframeOptions = amountOfDaysOptions.map(amount => ({
      value: amount,
      label: moment.duration(amount, "days").locale("pt").humanize({ d: 31 })
    }))

    return {
      i18nScope:     "components.tickets-search",
      termCharLimit: 18,
      timeframeOptions,
      //
      form:          {
        q:               "",
        businessUnit:    [],
        openingAfter:    "",
        openingBefore:   "",
        state:           [],
        scheduleState:   [],
        timeframe:       "",
        vehicleCategory: []
      },
      //
      datesRendered: true
    }
  },

  computed: {
    stateOptions() {
      return Ticket.$enums.state.values
        .map(state => ({
          value: state,
          label: Ticket.$tEnum("state", state)
        }))
    },

    scheduleStateOptions() {
      return Ticket.$enums.scheduleState.values
        .map(scheduleState => ({
          value: scheduleState,
          label: Ticket.$tEnum("scheduleState", scheduleState)
        }))
    },

    businessUnitOptions() {
      return Contract.$enums.businessUnit.values
        .map(businessUnit => ({
          value: businessUnit,
          label: Contract.$tEnum("business-unit", businessUnit)
        }))
    },

    vehicleCategoryOptions() {
      return vehicleCategoryRawOptions
        .map(option => ({
          value: option,
          label: option
        }))
    },

    hasFilter() {
      return Object
        .entries(this.queryData)
        .some(([key, value]) => {
          if (!Object.keys(this.form).includes(key)) return false

          if (key === "timeframe" && value === 30) return false

          return Array.isArray(value)
            ? Boolean(value.length)
            : Boolean(value)
        })
    }
  },

  mounted() {
    this.updateFormByQueryData()
  },

  watch: {
    queryData() {
      this.updateFormByQueryData()
    }
  },

  methods: {
    handleSubmit() {
      const query = this.formatQuery()

      this.$emit("search", query)
    },

    formatQuery() {
      const query = {}

      Object.entries(this.form).forEach(([key, value]) => {
        if (!value || (Array.isArray(value) && !value.length)) return

        query[_.snakeCase(key)] = value
      })

      return query
    },

    updateFormByQueryData() {
      if (!this.queryData) return

      const formArrayTypeKeys = ["businessUnit", "scheduleState", "state", "vehicleCategory"]

      let needsToSearchAgain = false

      Object.keys(this.form).forEach(key => {
        const correspondingValueFromQuery = this.queryData[_.snakeCase(key)]

        if (!correspondingValueFromQuery) {
          this.form[key] = formArrayTypeKeys.includes(key) ? [] : ""
          return
        }

        if (key === "timeframe") {
          const valueAsNumber = Number(correspondingValueFromQuery)

          needsToSearchAgain = !amountOfDaysOptions.includes(valueAsNumber)

          this.form[key] = needsToSearchAgain
            ? amountOfDaysOptions[1]
            : valueAsNumber
          return
        }

        this.form[key] = formArrayTypeKeys.includes(key)
          ? _.cloneDeep(correspondingValueFromQuery)
          : correspondingValueFromQuery
      })

      if (needsToSearchAgain) {
        this.handleSubmit()
        return
      }

      this.rerenderDates()
    },

    clearForm() {
      this.form = {
        q:               "",
        businessUnit:    [],
        openingAfter:    "",
        openingBefore:   "",
        state:           [],
        scheduleState:   [],
        timeframe:       "",
        vehicleCategory: []
      }

      this.handleSubmit()
    },

    async rerenderDates() {
      this.datesRendered = false

      await this.$nextTick()
      this.datesRendered = true
    }
  }
}

</script>
