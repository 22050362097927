// Libs
import deepSort from "@/lib/functions/deep-sort"

const DirtyableMixin = {
  data() {
    return {
      initialSerializedAttributes: null,

      // Para formulários onde a verificação não é obrigatória caso não haja dados iniciais,
      // e.g. formulários que falharam no carregamento dos dados
      optionalDirtyCheck: false
    }
  },

  methods: {
    serializeAttributes() {
      throw new Error("Dirtyable Mixin - #serializeAttributes() - Not implemented yet")
    },

    // Analisa se formulário foi modificado baseado no estado inicial e final
    // dos dados serializados em `serializedAttributes` e `initialSerializedAttributes`
    checkDirty() {
      if (this.initialSerializedAttributes === null) {
        if (this.optionalDirtyCheck) return false
        throw new Error("Dirtyable Mixin - #initialSerializedAttributes - Not set")
      }

      const serializedAttributes = this.serializeAttributes()
      return !_.isEqual(deepSort(serializedAttributes), deepSort(this.initialSerializedAttributes))
    },

    askDirtyLeaveConfirmation() {
      return this.$confirm({
        heading: this.$t("mixins.form-mixin.confirmPageChange.heading"),
        message: this.$t("mixins.form-mixin.confirmPageChange.message"),

        actions: {
          confirm: this.$t("mixins.form-mixin.confirmPageChange.confirm"),
          cancel:  this.$t("mixins.form-mixin.confirmPageChange.cancel")
        }
      })
    },

    canLeaveForm() {
      return !this.checkDirty() || this.askDirtyLeaveConfirmation()
    }
  }
}

export default DirtyableMixin
