<style scoped lang="scss">

.default-layout {
  --view-height: calc(100vh - var(--navbar-height, 55px));
  --view-inline-padding: 10px;

  .default-container {
    height: var(--view-height);
    width: 100%;
  }

  .default-route {
    overflow-y: auto;
    width: 100%;
  }

  .view {
    width: min(1200px, 100%);
    padding-inline: var(--view-inline-padding);
    margin-inline: auto;
  }
}

</style>


<template lang="pug">

.default-layout
  navbar

  .default-container.flex
    sidebar

    template(v-if="hasError")
      component(:is="errorComponent")

    .default-route.grid(v-else, ref="routerView")
      router-view.view(@request-scroll-top="scrollToTop")

</template>


<script>

// Store
import viewStore from "@/store/view"

// Components
import Navbar from "@/components/navbar/navbar.vue"
import Sidebar from "@/components/sidebar/sidebar.vue"
import NotFound from "@/components/errors/not-found/not-found.vue"
import ServerError from "@/components/errors/server-error/server-error.vue"

export default {
  name: "DefaultLayout",

  components: {
    Navbar,
    Sidebar
  },

  computed: {
    hasError() {
      return viewStore.hasError
    },

    errorComponent() {
      if (!this.hasError) return null

      if (viewStore.appError.status === "notFound") return NotFound
      return ServerError
    }
  },

  watch: {
    $route() {
      viewStore.appError = null
    }
  },

  methods: {
    scrollToTop() {
      if (!this.$refs?.routerView) return

      this.$refs.routerView.scroll({ top: 0 })
    }
  }
}

</script>
