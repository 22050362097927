<style lang="scss" scoped>

.ticket-type-card {
  --ticket-type-font-color: #{$gray-dark};
  --ticket-type-border-color: #{$gray-light};
  --ticket-type-bg-color: transparent;
  --ticket-type-icon-filter: unset;

  border: 1px solid var(--ticket-type-border-color);
  border-radius: 8px;
  padding: 16px;
  align-content: start;
  background-color: var(--ticket-type-bg-color);
  text-align: start;
  grid-template-rows: auto auto 1fr;

  &.disabled {
    --ticket-type-font-color: #{$gray};
    --ticket-type-bg-color: #{$gray-light-3};
    --ticket-type-icon-filter: grayscale(1);

    cursor: not-allowed;
  }

  &.loading {
    opacity: 0.5;
  }

  &:not(.disabled, .loading) {
    &:hover {
      --ticket-type-border-color: #{$orange};

      &:active {
        --ticket-type-bg-color: #{$orange + '22'};
      }
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px white, 0 0 0 3px $orange;
  }

  p {
    margin: 0;
  }

  .icon {
    filter: var(--ticket-type-icon-filter);
  }

  .header {
    gap: 12px;
  }

  .title {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
    color: var(--ticket-type-font-color);
  }

  .subtitle {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--ticket-type-font-color);
  }

  .description {
    font-size: 0.75rem;
    color: var(--ticket-type-font-color);
  }

  .selected-icon {
    font-size: 20px;
    color: $orange-dark;
  }
}

</style>


<template lang="pug">

button.ticket-type-card.grid.gap-small(
  :tabindex="disabled ? -1 : 0",
  :class="{ disabled, loading }",
  @click="handleClick"
)
  header.header.flex
    img.icon.no-shrink.self-vertical-start(:src="content.icon")

    .head
      h3.title {{ content.title }}
      small.subtitle {{ content.subtitle }}

  p.description {{ content.description }}

  slot

  .selected-icon.flex.end.vertical-bottom(v-if="selected")
    i.fal.fa-check(v-tooltip="$t('.selected')")

</template>


<script>

export default {
  name: "TicketTypeCard",

  emits: ["click"],

  props: {
    content:  { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    loading:  { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.ticket-type-card"
    }
  },

  methods: {
    handleClick() {
      if (this.disabled || this.loading) return

      this.$emit("click")
    }
  }
}

</script>
