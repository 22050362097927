<!--
<doc lang="markdown">

Exibe um elemento clicável

_Props_: outline, disabled, loading, icon, size, fullWidth, to, border e theme
Nenhuma _prop_ é obrigatória

Como usar:
```pug
app-button(
  :outline="false",
  :disabled="disabled",
  :loading="loading",
  icon="far fa-cog",
  size="small",
  fullWidth,
  to="{ name: 'home' }",
  border="rounded",
  theme="primary"
) Button text
```

</doc>
-->

<style lang="scss" scoped>

// TODO: verificar quais cores são necessárias pelo design system e adicionar como parâmetros
@mixin button($color, $background-color, $hover-color, $active-color) {
  min-width: 100px;
  background-color: $background-color;
  color: $color;
  border-color: $background-color;
  transition: .1s;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay: 0s;

  &:hover:not(.disabled) {
    background-color: $hover-color;
    border-color: $hover-color;
  }

  &:active:not(.disabled) {
    background-color: $active-color;
    border-color: $active-color;
  }

  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    color: $color;
    border-color: lighten($background-color, 20%);
    background-color: lighten($background-color, 20%);
    cursor: not-allowed;
  }

  &.outline {
    color: $background-color;
    border-color: $background-color;
    background-color: transparent;

    &:hover {
      color: darken($background-color, 5.1%);
      border-color: darken($background-color, 5.1%);
      background-color: $gray-light-3;
    }

    &:active {
      color: adjust-hue(darken($background-color, 8.7%), -5%);
      border-color: adjust-hue(darken($background-color, 8.7%), -5%);
      background-color: $gray-light-2;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      color: $gray-light;
      border-color: $gray-light;
      cursor: not-allowed;
      background-color: $white;
    }
  }
}

.app-button {
  margin-bottom: 0;
  display: inline-block;
  height: 40px;
  color: $orange;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .3px;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid $orange;
  padding: 0 18px;

  &:hover,
  &:focus {
    outline: 0;
  }

  &.full-width {
    width: 100%;
  }

  &.small-size {
    min-width: initial;
    height: 32px;
    font-size: 14px;
    padding: 0 8px;
  }

  &.primary {
    @include button($white, $orange, $orange-2, $orange-dark);
  }

  &.gray {
    @include button($white, $gray-dark, $gray-3, $gray);
  }

  &.neutral {
    border: none;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);
    color: $gray-dark-2;

    &:hover:not(.disabled) {
      color: $orange;
      background: $gray-light-3;
    }

    &.disabled {
      cursor: not-allowed;
      color: $gray-light;
    }
  }

  &.link {
    border: none;
    padding: 0;
    color: $orange;
    height: unset;
    line-height: unset;
    font-weight: 500;

    &:hover {
      color: $orange-dark;
    }

    &.disabled {
      color: $gray-light;
      cursor: not-allowed;
    }
  }

  &.round {
    border-radius: 20px;
  }

  &.rounded {
    border-radius: 5px;
  }

  &.square {
    border-radius: 0;
  }

  .content {
    font-family: $secondary-font;
    height: 100%;
    display: flex;
    align-items: center;

    &.center {
      justify-content: center;
    }
  }
}

</style>


<template lang="pug">

component.app-button(
  v-bind="isRoute ? linkProps : buttonProps",
  :is="componentType",
  :class="buttonClass",
  :disabled="disabled || loading",
  @click="onClick"
)
  .content(:class="{ ['space-between']: icon, center: !icon }")
    slot(v-if="loading && hasLoadingSlot", name="loading")
    slot(v-else)
    i.icon(v-if="icon", :class="icon")

</template>


<script>

const themeValidator = value => ["primary", "link", "gray", "neutral"].includes(value.toLowerCase())
const borderValidator = value => ["round", "rounded", "square"].includes(value.toLowerCase())

export default {
  name: "AppButton",

  emits: ["click"],

  props: {
    outline:        { type: Boolean, default: false },
    disabled:       { type: Boolean, default: false },
    loading:        { type: Boolean, default: false },
    icon:           { type: String, default: null },
    size:           { type: String, default: "normal" },
    fullWidth:      { type: Boolean, default: false },
    to:             { type: Object, default: () => ({}) },
    preventDefault: { type: Boolean, default: false },
    border:         {
      type:      String,
      validator: value => borderValidator(value),
      default:   "rounded"
    },
    theme: {
      type:      String,
      validator: value => themeValidator(value),
      default:   "primary"
    },
    type: { type: String, default: "button" }
  },


  data() {
    return {
      i18nScope: "components.app-button"
    }
  },

  computed: {
    componentType() {
      return this.isRoute ? "router-link" : "button"
    },

    linkProps() {
      return {
        to: this.to
      }
    },

    buttonProps() {
      return {
        type:     this.type,
        disabled: this.disabled || this.loading
      }
    },

    isRoute() {
      return _.present(this.to) && !this.disabled && !this.loading
    },

    buttonClass() {
      return {
        outline:      this.outline,
        "full-width": this.fullWidth,
        disabled:     this.disabled || this.loading,

        [`${this.theme}`]:     true,
        [`${this.border}`]:    true,
        [`${this.size}-size`]: this.size
      }
    },

    hasLoadingSlot() {
      return !!this.$slots.loading
    }
  },

  methods: {
    onClick(clickEvent) {
      if (this.preventDefault) clickEvent.preventDefault()
      if (this.disabled || this.loading) return
      this.$emit("click", clickEvent)
    }
  }
}

</script>
