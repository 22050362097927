<style lang="scss" scoped>

button {
  appearance: none;
  border-radius: 50%;
  width: var(--app-icon-button-width);
  aspect-ratio: 1;
  border: none;
  background: transparent;
  transition: all 200ms ease;
  color: $gray-dark-2;

  &:not(:disabled) {
    &:hover {
      background-color: $gray-light-3;
      color: $orange;
      cursor: pointer;
    }
  }

  &:disabled {
    color: $gray-2;
    cursor: not-allowed;
  }
}

</style>


<template lang="pug">

button(
  :style="cssVars"
  :type="type"
  :disabled="disabled",
  @click="$emit('click')"
)
  i(:class="iconClasses")

</template>


<script>

export default {
  name: "AppIconButton",

  emits: ["click"],

  props: {
    disabled:    { type: Boolean, default: false },
    iconClasses: { type: String, default: "fas fa-ellipsis" },
    width:       { type: String, default: "32px" },
    type:        {
      type:    String,
      default: "button",
      validator(val) {
        return ["button", "submit"].includes(val)
      }
    }
  },

  computed: {
    cssVars() {
      return {
        "--app-icon-button-width": this.width
      }
    }
  }
}

</script>
