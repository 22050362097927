<style lang="scss" scoped>

.vehicle-tickets {
  .summary {
    font-weight: 400;
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }

  .footer {
    background-color: $white;
    padding-block: 8px;
    border-block: 1px solid $gray-light-2;
  }
}

</style>


<template lang="pug">

.vehicle-tickets
  tickets-table(
    :tickets="tickets",
    :amount-shown="sampleAmount",
    :skeleton="initializing",
    :loading="initialized && fetching"
    configuration="small",
    @cancellation="handleTicketCancellation",
    @booking-confirmation="handleBookingConfirmation"
  )
    template(#outside)
      .on-top.flex.space-between
        .summary.flex.vertical-center.gap-tiny
          span.icon.flex.vertical-center.center
            i.fas.fa-headset

          span {{$t('.title')}}

        app-button.font-small(
          v-if="tickets.length > sampleAmount",
          @click="handleShowAllTickets",
          theme="link",
        ) {{ $t('.show-all') }}

    template(#lastRow)
      .footer.flex.center.vertical-baseline.gap-small(v-if="tickets.length > sampleAmount")
        app-button.font-small(
          @click="handleShowAllTickets",
          theme="link",
        ) {{ $t('.show-all') }}

</template>


<script>

// Components
import TicketsTable from "@/components/tickets-table"
import AppButton from "@/components/app-button/app-button.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "VehicleTickets",

  components: {
    TicketsTable,
    AppButton
  },

  mixins: [FetchMixin],

  emits: [
    "see-all",              // Botão "Ver todos"
    "index",                // Index de atendimento
    "cancellation",         // Cancelamento de um atendimento
    "booking-confirmation", // Confirmaçao de um atendimento
    "fetch-start",          // Início da requisição Index
    "fetch-end"            // Fim da requisição Index
  ],

  props: {
    vehicleId: { type: [String, Number] }
  },

  data() {
    return {
      i18nScope:    "components.vehicle-tickets",
      tickets:      [],
      pagination:   {},
      //
      autofetch:    false,
      sampleAmount: 10
    }
  },

  watch: {
    vehicleId(to) {
      this.handleFetch(to)
    },

    fetching(to) {
      if (to) this.$emit("fetch-start")
    }
  },

  mounted() {
    this.handleFetch(this.vehicleId)
  },

  methods: {
    handleFetch(vehicleId) {
      if (vehicleId) this.fetch()
    },

    handleTicketCancellation(event) {
      this.$emit("cancellation", event)
      this.fetch()
    },

    handleBookingConfirmation(event) {
      this.$emit("booking-confirmation", event)
      this.fetch()
    },

    // @overide FetchMixin
    fetchRequest() {
      return this.$sdk.vehicles.tickets.index({ vehicleId: this.vehicleId })
    },

    // @overide FetchMixin
    onFetchSuccess({ data }) {
      this.tickets = data
      this.$emit("index", { tickets: data })
    },

    // @overide FetchMixin
    afterFetch() {
      this.$emit("fetch-end")
    },

    handleShowAllTickets() {
      this.$emit("see-all")
    }
  }
}

</script>
