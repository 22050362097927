import Http from "./Http"
import requests from "./requests"

class SdkClient {
  constructor(options) {
    this.http = new Http(options)

    this.requests = Object.keys(requests)
      .map(className => requests[className])
      // Ordena para que uma subrota não sobrescreva a rota pai
      .sort((a, b) => {
        if (a.selector < b.selector) return -1
        if (a.selector > b.selector) return 1
        return 0
      })
      .reduce((allRequests, RequestClass) => {
        _.set(allRequests, RequestClass.selector, new RequestClass(this.http))
        return allRequests
      }, {})
  }

  setLocale(locale) {
    this.http.setData({ locale })
  }

  setAuthorizationToken(token) {
    this.http.setData({ authorizationToken: token })
  }

  addInterceptorRequest(func, errorFunc) {
    this.http.router.interceptors.request.use(func, errorFunc)
  }

  addInterceptorResponse(func, errorFunc) {
    this.http.router.interceptors.response.use(func, errorFunc)
  }
}

export default SdkClient
