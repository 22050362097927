<!--
<doc lang="markdown">

Texto simples com limite de tamanho e reticências

Como usar:
```pug
app-span(crop, :value="'texto'")
```

</doc>
-->

<style lang="scss" scoped>

.app-span {
  &.crop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

</style>


<template lang="pug">

span.app-span(
  ref="textElement",
  :class="{ crop }",
  v-tooltip="tooltipOptions",
  v-observe-visibility="handleCropping"
)
  slot
    | {{ value }}

</template>


<script>

// Vue
import { nextTick } from "vue"

export default {
  name: "AppSpan",

  emits: [],

  props: {
    value:              { type: [String, Number], default: null },
    crop:               { type: Boolean, default: false },
    cropTooltipOptions: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "components.app-span",

      isCropped: false
    }
  },

  computed: {
    cropTooltip() {
      return this.value
    },

    tooltipOptions() {
      if (!this.crop || !this.isCropped) return null

      return {
        content:   this.value,
        placement: "top",

        ...this.cropTooltipOptions
      }
    }
  },

  watch: {
    cropTooltip: {
      immediate: true,
      handler() {
        this.handleCropping()
      }
    }
  },

  created() {
    window.addEventListener("resize", this.handleCropping)
  },

  unmounted() {
    window.removeEventListener("resize", this.handleCropping)
  },

  methods: {
    async handleCropping() {
      // XXX necessário para atualizar o layout antes de pegar a referência
      await nextTick()

      const { textElement } = this.$refs
      if (!textElement) return

      this.isCropped = textElement.scrollWidth !== textElement.clientWidth
    }
  }
}

</script>
