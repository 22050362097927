<style lang="scss" scoped>

.ticketHeader {
  font-family: $secondary-font;

  .main {
    .icon {
      object-fit: none;
      object-position: left;
      height: 32px;
    }

    .title {
      font-size: 1.875rem;
      font-weight: 500;
      gap: 0.3ch;
    }
  }

  .detail-up {
    min-height: 22px;
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light;
    border-radius: 5px;
  }

  .fipe-id {
    color: $gray-2;
    font-size: 0.9em;
  }

  .font-size-small {
    font-size: 0.75rem;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3;
  }

  .bold {
    font-weight: 500;
  }

  .italic {
    font-style: italic;
    font-family: $primary-font;
  }

  .font-large {
    font-size: 1.5rem;
  }

  .append {
    display: inline-block;
    overflow: 0;
  }

  .double-icon {
    position: relative;
    z-index: 1;
    width: 20px;
    aspect-ratio: 1;

    .golden-star {
      font-size: 12px;
      background: linear-gradient(23.13deg, #E0A21A 22.81%, #FFD737 39.85%, #FFFF90 58.12%, #FBDE74 88.36%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    .golden-star-stroke {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      //
      background: linear-gradient(186.12deg, #F1D336 35.35%, #B57A21 95.16%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
    }
  }

  .manufacturer-icon {
    min-height: 24px;
    align-self: flex-start;
  }

  .color-yellow-dark {
    color: $yellow-dark;
  }
}

</style>


<template lang="pug">

.ticketHeader.grid.gap-regular(v-if="skeleton")
  .main.gap-small
    loading-lines(:height="36", :min="35", :max="45")

  .details.flex.gap-big
    .detail.grow
      loading-lines(:lines="2",:min="80", :max="100")

    .divider

    .detail.grow
      loading-lines(:lines="2",:min="80", :max="100")

    .divider

    .detail.grow
      loading-lines(:lines="2",:min="80", :max="100")

    .detail.grow

.ticketHeader.grid.gap-regular(v-else)
  .main.flex.vertical-baseline.gap-small
    transition-horizontal-fold(custom-width="32px")
      img.icon.inline-block.self-vertical-center(v-if="showType && typeIcon", :src="typeIcon")

    h1.title.flex
      span {{ headerTitle || $t('.title') }}

      transition-horizontal-fold
        span.append(v-if="showType && typeTranslated") {{ $t('.type', { typeName: typeTranslated }) }}

    id-tag.font-large(:value="ticket.remoteId", v-tooltip="$t('.identifier.ticket')")

  .details.flex.gap-big
    .detail.flex.vertical-baseline.gap-small
      i.fal.fa-file-contract

      .contract.inline-grid.gap-tiny
        .detail-up.flex.vertical-baseline.gap-tiny.font-size-small
          span {{ $t('.contract') }}
          tag-inline(color="blue" weight="bold") {{ businessUnitTranslated }}

        .detail-down
          id-tag(:value="ticket.contract?.id", v-tooltip="$t('.identifier.contract')")

    .divider

    .detail.flex.vertical-baseline.gap-small
      i.fal.fa-user

      v-dropdown(
        :triggers="['hover']",
        :popper-triggers="['hover']"
        :show-group="`ticket-${ticket.remoteId}`"
      )
        template(#default)
          .client.inline-grid.gap-tiny
            .detail-up
              span.bold {{ customer.name }}

            .detail-down
              span {{ mainDriver.name }}

        template(#popper="{ shown }")
          details-popover(:copy-enable="shown", :details="customerPopoverDetails")

    .divider

    .detail.flex.gap-small
      .manufacturer-icon.flex.vertical-center.center
        vehicle-manufacturer-icon(
          :size="20",
          :value="vehicle.manufacturerName",
          v-tooltip="vehicle.manufacturerName"
        )

      .vehicle.inline-grid.gap-tiny
        .detail-up.flex.vertical-center.gap-small
          .double-icon.flex.center.vertical-center(v-if="vehicle.isVeryImportant")
            i.fa-sharp.fa-solid.fa-star.golden-star
            i.fas.fa-star.golden-star-stroke

          span.bold(:class="{ 'color-yellow-dark': vehicle.isVeryImportant }") {{ vehicleTitle }}

          license-plate(:value="vehicle.licensePlate", small)

          span.fipe-id {{ $t('.fipe-id', { fipeId: vehicle.fipeId }) }}

        .detail-down.flex.vertical-center.gap-small.wrap.font-size-small
          template(v-if="vehicle.categorySimplified")
            tag-inline-vehicle-category.font-size-small(
              :category="vehicle.categorySimplified",
            )

            i.fas.fa-diamond

          span {{ formattedKm }}

          i.fas.fa-diamond

          span {{ vehicle.year }}

          i.fas.fa-diamond

          tag-inline(
            v-if="vehicle.isLessThan1YearOld",
            text-colored,
            weight="bold",
            color="orange-dark",
            v-tooltip=" $t('.tag.new.tooltip')"
          ) {{ $t('.tag.new.text') }}

          span.italic {{ vehicle.usagePhrase }}

</template>


<script>

// Components
import LicensePlate from "@/components/license-plate/license-plate.vue"
import VehicleManufacturerIcon from "@/components/icons/vehicle-manufacturer-icon/vehicle-manufacturer-icon.vue"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import TagInline from "@/components/tag-inline"
import TagInlineVehicleCategory from "@/components/tag-inline/vehicle-category"
import IdTag from "@/components/id-tag/id-tag.vue"
import DetailsPopover from "@/components/details-popover"

// Transitions
import TransitionHorizontalFold from "@/transitions/transition-horizontal-fold"

// Models
import Contract from "@/models/contract/contract"
import Ticket from "@/models/ticket/ticket"

export default {
  name: "TicketHeader",

  components: {
    LicensePlate,
    VehicleManufacturerIcon,
    LoadingLines,
    TagInline,
    TagInlineVehicleCategory,
    IdTag,
    DetailsPopover,

    TransitionHorizontalFold
  },

  props: {
    skeleton:    { type: Boolean, default: false },
    ticket:      { type: Object, default: () => ({}) },
    showType:    { type: Boolean, default: false },
    headerTitle: { type: String }
  },

  data() {
    return {
      i18nScope: "components.ticket-header"
    }
  },

  computed: {
    customer() {
      return this.ticket.contract?.customer || {}
    },

    mainDriver() {
      return this.ticket.contract?.mainDriver || {}
    },

    vehicle() {
      return this.ticket.contract?.vehicle || {}
    },

    businessUnitTranslated() {
      return this.ticket.contract?.businessUnit
        ? Contract.$tEnum("business-unit", this.ticket.contract.businessUnit)
        : "-"
    },

    formattedKm() {
      let km = "-"

      if (_.present(this.ticket.currentKm)) km = this.$asNumber(this.ticket.currentKm, { precision: 0 })

      return this.$t(".km", { km })
    },

    customerPopoverDetails() {
      if (!this.customer.name) return []

      const { name, identifier, phone, email, isPerson } = this.customer

      const phoneMask = () => {
        if (!phone) return ""

        const cleanPhone = phone.replace(/\D/g, "")

        switch (cleanPhone.length) {
          case 11:
            return "(##) #####-####"
          case 10:
            return "(##) ####-####"
          default:
            return ""
        }
      }

      return [
        { value: name },
        {
          value:     identifier,
          mask:      isPerson ? "###.###.###-##" : "##.###.###/####-#",
          iconClass: isPerson ? "fa-user" : "fa-building",
          copy:      true
        },
        {
          value:     phone,
          mask:      phoneMask(),
          iconClass: "fa-phone",
          copy:      true
        },
        {
          value:     email,
          iconClass: "fa-envelope",
          copy:      true
        }
      ]
    },

    typeTranslated() {
      return this.ticket?.type && !this.ticket.isTypeDraft
        ? Ticket.$tEnum("type", this.ticket.type)
        : ""
    },

    typeIcon() {
      return this.ticket?.type && !this.ticket.isTypeDraft
        ? Ticket.getTypeIcon(this.ticket.type)
        : ""
    },

    vehicleTitle() {
      const title = [this.vehicle.name]

      if (this.vehicle.colorName) title.push(`( ${this.vehicle.colorName} )`)

      return title.join(" ")
    }
  }
}

</script>
