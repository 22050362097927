<style lang="scss" scoped>

.vehicle-data-box {
  .footer > span:not(.tag-inline) {
    font-style: italic;
    font-size: 0.875rem;
    color: $gray;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .tag-inline {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 500;
  }

  &.golden-box {
    padding: 1px;
    background: linear-gradient(180deg, #E4A81D 0%, #FED436 18.23%, #FFFD8C 50.52%, #E2B525 76.04%, #EEC530 100%);
    border-radius: 8px;

    .transparent-border {
      background: linear-gradient(180deg, #F7C75A 0%, #FFE370 1.94%, #FFFD9E 6.55%, #FFFFFF 20.38%);
      border: none;
    }
  }

  .double-icon {
    position: relative;
    z-index: 1;
    width: 20px;
    aspect-ratio: 1;

    .golden-star {
      font-size: 12px;
      background: linear-gradient(23.13deg, #E0A21A 22.81%, #FFD737 39.85%, #FFFF90 58.12%, #FBDE74 88.36%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    .golden-star-stroke {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      //
      background: linear-gradient(186.12deg, #F1D336 35.35%, #B57A21 95.16%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
    }
  }
}

</style>


<template lang="pug">

.vehicle-data-box(:class="{ 'golden-box': !skeleton && vehicle.isVeryImportant }")
  data-box(
    category-icon-classes="fas fa-car-mirrors",
    :category-name="$t('.category-name')",
    :skeleton="skeleton",
    :title="vehicleTitle",
    :details="vehicleDetails",
    :class="{ 'transparent-border': !skeleton && vehicle.isVeryImportant }",
    two-columns,
    :information-missing="!vehicle?.id"
  )
    template(#headerExtra)
      license-plate(:value="vehicle.licensePlate", v-tooltip="$t('.tooltip.plate')")

    template(#detail-0="{ content: manufacturer }")
      .flex.gap-small.vertical-center
        vehicle-manufacturer-icon(:value="manufacturer", :size="20")
        span {{ manufacturer }}

    template(#detail-2="{ content: category, iconClasses, tooltip }")
      .flex.gap-small.vertical-center
        .double-icon.flex.center.vertical-center(v-if="vehicle.isVeryImportant")
          i.fa-sharp.fa-solid.fa-star.golden-star
          i.fas.fa-star.golden-star-stroke

        i(v-else, :class="iconClasses", v-tooltip="tooltip")

        tag-inline-vehicle-category(:category="category")

    template(#footer)
      .footer.flex.vertical-center.wrap.gap-tiny
        tag-inline.tag-inline(
              v-if="vehicle.isLessThan1YearOld",
              text-colored,
              weight="bold",
              color="orange-dark",
              v-tooltip=" $t('.tag.new.tooltip')"
            ) {{ $t('.tag.new.text') }}

        span {{ vehicle.usagePhrase }}

        i.fas.fa-diamond.decoration

        span {{ vehicle.purchasedAtPhrase }}

</template>


<script>

// Components
import DataBox from "@/components/data-box"
import VehicleManufacturerIcon from "@/components/icons/vehicle-manufacturer-icon/vehicle-manufacturer-icon.vue"
import TagInline from "@/components/tag-inline"
import TagInlineVehicleCategory from "@/components/tag-inline/vehicle-category"
import LicensePlate from "@/components/license-plate/license-plate.vue"

export default {
  name: "DataBoxVehicle",

  components: {
    DataBox,
    VehicleManufacturerIcon,
    TagInline,
    TagInlineVehicleCategory,
    LicensePlate
  },

  props: {
    vehicle:  { type: Object },
    skeleton: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.data-box-vehicle"
    }
  },

  computed: {
    vehicleTitle() {
      const title = [this.vehicle.name]

      if (this.vehicle.colorName) title.push(`( ${this.vehicle.colorName} )`)

      return title.join(" ")
    },

    vehicleDetails() {
      if (!this.vehicle.id) return []

      const { manufacturerName, km, year, fipeId, fuelName } = this.vehicle

      let endKm = "-"

      if (_.present(this.vehicle.km)) endKm = this.$asNumber(km, { precision: 0 })

      const contentKm = this.$t(".km", { km: endKm })

      return [
        {
          content: manufacturerName
        },
        {
          content:     contentKm,
          iconClasses: "fal fa-gauge",
          tooltip:     this.$t(".tooltip.gauge")
        },
        {
          content:     this.vehicle.categorySimplified,
          iconClasses: "fal fa-car-side",
          tooltip:     this.$t(".tooltip.category")
        },
        {
          content:     year,
          iconClasses: "fal fa-calendar",
          tooltip:     this.$t(".tooltip.calendar")
        },
        {
          content:     this.$t(".fipe-id", { fipeId }),
          iconClasses: "fal fa-diamond",
          tooltip:     this.$t(".tooltip.fipe")
        },
        {
          content:     fuelName,
          iconClasses: "fal fa-gas-pump",
          tooltip:     this.$t(".tooltip.fuel")
        }
      ]
    }
  },

  methods: {
    generateIdentifierMask(identifier) {
      if (!identifier) return ""

      const cleanIdentifier = identifier.replace(/\D/g, "")

      switch (cleanIdentifier.length) {
        case 14:
          return "##.###.###/####-##"
        case 11:
          return "###.###.###-##"
        default:
          return ""
      }
    }
  }
}

</script>
