import Base from "../base/base"

class SupplierInstruction extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "note",
      "ticketId"
    ])
  }
}

export default SupplierInstruction
