<style scoped lang="scss">

.googlemaps-autocomplete {
  .input-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .input-inner {
      --icon-padding-left-adjustment: calc(
        var(--input-field-inline-padding) +
        var(--input-field-icon-size, 0.875em) +
        var(--input-field-icon-spacing, 8px)
      );
      --icon-padding-right-adjustment: var(--input-field-inline-padding);

      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: var(--input-field-radius);
      border: 1px solid $gray-3;
      color: $gray;
      display: inline-block;
      font-size: inherit;
      height: var(--input-field-height);
      line-height: var(--input-field-height);
      outline: none;
      padding-block: 1px;
      width: 100%;
      //
      padding-left: var(--icon-padding-left-adjustment);
      padding-right: var(--icon-padding-right-adjustment) ;

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-3;
        opacity: 1;
      }

      &.disabled {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $gray-light-2;
        border-color: $gray-light;
        color: $gray;
        cursor: progress;
      }

      &.error {
        border-color: $red;
      }
    }

    .icon-left {
      position: absolute;
      font-size: var(--input-field-icon-size, 0.875em);
      color: $gray-2;
      top: calc(var(--input-field-height) / 2);
      left: var(--input-field-inline-padding);
      transform: translateY(-50%);
    }

    .clear {
      position: absolute;

      height: 100%;
      width: 40px;
      top: 0;
      right: 0;

      font-size: 18px;
      background: transparent;

      outline: 0;
      border: 0;

      cursor: pointer;
      color: $gray-2;

      &:hover {
        color: $gray-3;
      }
    }
  }

  .initialize-error {
    font-size: 12px;
    margin-top: 4px;

    span {
      margin-right: 4px;
    }
  }
}

</style>


<template lang="pug">

.googlemaps-autocomplete(:style="cssVars")

  .input-wrapper(:class="{ disabled: error || fetchingGoogleMaps }")
    i.fas.fa-map-marker-alt.icon-left

    input.input-inner.space-icon-left(
      ref="input",
      type="text",
      v-model="text",
      :class="{ loading, disabled, error: inputError || error }",
      :placeholder="placeholder",
      :disabled="loading || disabled || error || fetchingGoogleMaps",
      @focus="$emit('focus')",
      @blur="onBlur"
      @keyup.esc="clear",
      @keypress.enter.prevent="",
    )

    template(v-if="!disabled && !loading && hasText")
      button.clear(type="button", @click="clear(true)")
        i.fas.fa-times

  .initialize-error(v-if="error")
    span {{ $t('.notifications.initializeError.message') }}
    a(@click="reload()") {{ $t('.notifications.initializeError.reload') }}

</template>


<script>

// https://developers.google.com/maps/documentation/javascript/place-data-fields
const DEFAULT_FIELDS = [
  'address_component',
  // 'adr_address',
  // 'business_status',
  // 'formatted_address',
  // 'geometry.viewport',
  // 'geometry.location',
  // 'icon',
  "name",
  // 'photos',
  "place_id",
  // 'plus_code',
  'type'
  // 'url',
  // 'utc_offset',
  // 'vicinity'
]

// https://developers.google.com/maps/documentation/javascript/places-autocomplete#add-autocomplete
const DEFAULT_TYPES = [
  "geocode",
  // 'address',
  "establishment",
  // '(regions)'
    // '(cities)'
]

const DEFAULT_COMPONENT_RESTRICTIONS = { country: "BR" }

export default {
  name: "GoogleAutocomplete",

  props: {
    modelValue:            { type: [String, Object], default: null },
    placeholder:           { type: String, default: null },
    disabled:              { type: Boolean, default: false },
    loading:               { type: Boolean, default: false },
    inputError:            { type: Boolean, default: false },
    fields:                { type: Array, default: () => DEFAULT_FIELDS },
    types:                 { type: Array, default: () => DEFAULT_TYPES },
    componentRestrictions: { type: Object, default: () => (DEFAULT_COMPONENT_RESTRICTIONS) },
    //
    height:                { type: Number, default: 40 },
    borderRadius:          { type: Number, default: 4 },
    inlinePadding:         { type: Number, default: 13 }
  },

  emits: ["blur", "focus", "update:modelValue"],

  data() {
    return {
      i18nScope:          "components.googlemaps-autocomplete",
      error:              false,
      placesAutocomplete: null,
      text:               null,
      fetchingGoogleMaps: false
    }
  },

  computed: {
    hasText() {
      return !!this.text
    },

    cssVars() {
      return {
        "--input-field-height":         `${this.height}px`,
        "--input-field-radius":         `${this.borderRadius}px`,
        "--input-field-inline-padding": `${this.inlinePadding}px`
      }
    }
  },

  watch: {
    value() {
      if (_.blank(this.modelValue)) this.text = null
    }
  },

  mounted() {
    this.initializeGoogleMaps()

    if (_.present(this.modelValue)) {
      this.setTextFromModelValue()
    }
  },

  beforeUnmount() {
    this.destroyGoogleMaps()
  },

  methods: {
    onBlur() {
      this.$emit("blur")
      this.setTextFromModelValue()
    },

    destroyGoogleMaps() {
      let node = document.querySelector(".pac-container")
      if (node && node.parentNode) node.parentNode.removeChild(node)
    },

    async initializeGoogleMaps() {
      this.fetchingGoogleMaps = true

      try {
        const google = await this.$googleMaps.load({ language: "pt-BR" })
        if (!_.get(this, "$refs.input")) return
        
        this.placesAutocomplete = new google.maps.places.Autocomplete(this.$refs.input, {
          fields:                this.fields,
          types:                 this.types,
          componentRestrictions: this.componentRestrictions
        })

        this.placesAutocomplete.addListener("place_changed", () => {
          let googlePlace = this.placesAutocomplete.getPlace()
          if (googlePlace.types.includes("locality") &&
              googlePlace.types.includes("political")
             ) {
            googlePlace = null
            
            this.$refs.input.value = "Centro, " + this.$refs.input.value
            this.text = this.$refs.input.value
            this.$refs.input.focus()
          }

          this.text = null
          
          this.onChange(googlePlace)
        })
      }
      catch (error) {
        console.error(error)
      }
      finally {
        this.fetchingGoogleMaps = false
      }
    },

    clear() {
      if (this.disabled || this.loading || !this.hasText) return

      this.text = ""
      this.onChange(null)
    },

    async onChange(googlePlace) {
      if (!googlePlace) return this.$emit("update:modelValue", null)
      this.$emit("update:modelValue", googlePlace)

      // Necessário para corrigir valor automatico gerado pelo autocomplete
      await this.$nextTick()
      this.setTextFromModelValue()
    },

    reload() {
      this.destroyGoogleMaps()
      this.initializeGoogleMaps()
    },

    setTextFromModelValue() {
      this.text =  _.get(this.modelValue, "formatted_address")
    }
  }
}

</script>
