// Base
import Base from "./Base"

// Models
import Ticket from "@/models/ticket/ticket"

class TicketRequests extends Base {
  static get selector() {
    return "tickets"
  }

  index({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/tickets/search`,
      params,
      options: {
        model: Ticket,

        ...options
      }
    })
  }

  show({ ticketId, params = {}, options = {} } = {}) {
    this.verifyData("show", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}`,
      params,
      options: {
        model: Ticket,

        ...options
      }
    })
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/tickets/draft`,
      params,
      options: {
        model: Ticket,

        ...options
      }
    })
  }
}

export default TicketRequests
