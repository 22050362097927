// load external conf from <meta> in public/index.html
// the values are defined by env vars (VUE_APP_*)
const conf = {}
const metas = document.querySelectorAll('meta[name^="conf."]')

for (let meta of metas) {
  let value = meta
    .getAttribute("content")
    .replace(/^"(.*)"$/, "$1")
    .replace(/^'(.*)'$/, "$1")

  let keys = meta
    .getAttribute("name")
    .replace("conf.", "")
    .split(".")

  let prop = keys.pop() // last element
  let path = keys

  let parent = conf

  path.forEach(piece => {
    parent[piece] = parent[piece] || {}
    parent = parent[piece]
  })

  parent[prop] = value
}

Object.freeze(conf)

export default conf
