import { i18n } from "@/modules/i18n"

import Base from "../base/base"

class Issue extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "serviceId",
      "partId",
      "symptomId",
      "purchaseIntent", 
      "type",
      "description",
      "maintenanceKmLimit"
    ])
  }

  static get relations() {
    return {
      service:        { type: "hasOne", model: "Service" },
      part:           { type: "hasOne", model: "Part" },
      symptom:        { type: "hasOne", model: "Symptom" },
      maintenance:    { type: "hasOne", model: "Maintenance" },
      serviceTargets: { type: "hasMany", model: "ServiceTarget" }
    }
  }

  static get constraints() {
    return {
      serviceId: { presence: true }
    }
  }

  get isMaintenance() {
    return Boolean(this.maintenance?.id)
  }

  get maintenanceNameTranslated() {
    if (!this.isMaintenance) return ""

    return i18n.t("models.issue.maintenance")
  }

  get issueName() {
    return this.isMaintenance
      ? this.maintenanceNameTranslated
      : this.service?.name
  }
}

export default Issue
