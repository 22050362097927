const prepositionRegex = /^d[aeiou]s?$/g

const charactersSeparators = ["-", "'"]

/** @param {string} word */
function capitalizeWordWithSeparator(word) {
  let result = word

  charactersSeparators.forEach(char => {
    if (!result.includes(char)) return

    result = result.split(char).map(_.capitalize).join(char)
  })

  return result
}

/** @param {string} text */
function firstLettersCapital(text) {
  const wordsArray = text.toLowerCase().split(" ")

  return wordsArray
    .map(word => {

      if (prepositionRegex.test(word)) return word

      const hasSeparator = charactersSeparators.some(char => word.includes(char))

      return word.length > 2 && hasSeparator
        ? capitalizeWordWithSeparator(word)
        : _.capitalize(word)
    })
    .join(" ")
}

export { firstLettersCapital }
