// Base
import Base from "./Base"

// Models
import Requester from "@/models/requester/requester"

class RequesterRequests extends Base {
  static get selector() {
    return "requesters"
  }

  show({ requesterId, params = {}, options = {} } = {}) {
    this.verifyData("show", { requesterId })

    return this.action({
      method:  "get",
      url:     `/requesters/${requesterId}`,
      params,
      options: {
        model: Requester,

        ...options
      }
    })
  }

  update({ requesterId, params = {}, options = {} } = {}) {
    this.verifyData("update", { requesterId })

    return this.action({
      method:  "patch",
      url:     `/requesters/${requesterId}`,
      params,
      options: {
        model: Requester,

        ...options
      }
    })
  }
}

export default RequesterRequests
