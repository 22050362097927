import Base from "../base/base"

class SupplierRecommendation extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "supplierType"
    ])
  }

  static get enums() {
    return {
      supplierType: {
        dealership:   "dealership",
        multiservice: "multiservice"
      }
    }
  }
}

export default SupplierRecommendation
