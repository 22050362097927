<style lang="scss" scoped>

.supplier-appointment {
  .change-supplier {
    height: 24px;
    justify-self: flex-start;
  }

  .border-wrapper {
    border: 1px solid $gray-light-2;
    border-radius: 8px;
    padding: 24px;
  }
}

</style>


<template lang="pug">

.supplier-appointment.grid.gap-regular.content-start
  template(v-if="parentLoading || initializing")
    loading-lines

    .border-wrapper
      loading-lines(:lines="3")

  template(v-else-if="isEditFormChosen")
    app-button.change-supplier(
      outline,
      size="small",
      @click="newSupplierShown = true"
    )
      .flex.vertical-center.gap-small
        i.fas.fa-pencil
        span {{ $t(".another-supplier") }}

    supplier-appointment-edit(
      ref="selectedScheduler"
      :booking="initialBooking",
      :disabled="disabled || formsLoading"
    )

  supplier-appointment-create(
    v-else,
    ref="supplierSelection",
    :ticket-id="ticketId",
    :disabled="disabled || formsLoading",
    @business-hours-loading="loadingBusinessHours = true"
    @business-hours-end="loadingBusinessHours = false"
  )

</template>


<script>

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import AppButton from "@/components/app-button/app-button.vue"
import SupplierAppointmentCreate from "./create"
import SupplierAppointmentEdit from "./edit"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "SupplierAppointment",

  components: {
    LoadingLines,
    AppButton,
    SupplierAppointmentCreate,
    SupplierAppointmentEdit
  },

  props: {
    ticketId:        { type: [String, Number], required: true },
    parentLoading:   { type: Boolean, default: false },
    showUnconfirmed: { type: Boolean, default: false },
    disabled:        { type: Boolean, default: false }
  },

  mixins: [FetchMixin],

  emits: [
    "updating",
    "update",
    "error",
    "loading-toggle"
  ],

  data() {
    return {
      i18nScope:            "components.supplier-appointment",
      newSupplierShown:     false,
      initialBooking:       null,
      loadingUpdateBooking: false,
      loadingBusinessHours: false
    }
  },

  computed: {
    /** @returns {boolean} */
    isEditFormChosen() {
      return !this.newSupplierShown && this.initialBooking
    },

    /** @returns {boolean} */
    formsLoading() {
      return [
        this.loadingUpdateBooking,
        this.loadingBusinessHours
      ].some(Boolean)
    }
  },

  watch: {
    formsLoading(to) {
      this.$emit("loading-toggle", to)
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.booking.show({
        ticketId: this.ticketId,
        params:   {
          approved: !this.showUnconfirmed
        }
      })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.initialBooking = data
    },

    async updateAppointment() {
      const params = this.isEditFormChosen
        ? this.$refs.selectedScheduler?.getParamsFromForm()
        : this.$refs.supplierSelection?.getParamsFromForm()

      if (!params) return

      try {
        this.$emit("updating")

        this.loadingUpdateBooking = true

        await this.$sdk.tickets.booking.create({ ticketId: this.ticketId, params })

        this.$emit("update")
      }
      catch (err) {
        console.error(err)
        this.$emit("error")
        this.$notifications.error(this.$t(".notifications.submit.error"))
      }
      finally {
        this.loadingUpdateBooking = false
      }
    }
  }
}

</script>
