<style lang="scss" scoped>

.appointment-data {
  background-color: $gray-light-4;
  border: 2px solid transparent;
  padding: 16px;
  border-radius: 8px;
  font-family: $secondary-font;

  p {
    margin: 0;
  }

  &.skeleton {
    border-color: $gray-light-2;
  }

  .skeleton-content {
    grid-template-columns: 32px 1fr;
  }

  .icon {
    width: 32px;
    aspect-ratio: 1;
    font-size: 24px;
  }

  .decoration {
    font-size: 0.25rem;
    line-height: 1;
    color: $gray-3;
  }

  .title {
    font-weight: 500;
  }

  .canceled {
    --appointment-data-spacing: 8px;

    font-size: 14px;
    line-height: 1.5;

    &>:nth-child(2) {
      padding-block-start: var(--appointment-data-spacing);
      margin-block-start: var(--appointment-data-spacing);
      border-block-start: 1px solid $gray-light-2;
      font-weight: 400;
    }

    .reason {
      font-size: 12px;
      margin-block-start: var(--appointment-data-spacing);
    }
  }

  $theme-colors: (
    'gray': ($gray-2, $gray-3),
    'yellow': ($yellow-dark, $yellow-light-2),
    'green': ($green, $green-light),
    'red': ($red, $red-light-2),
  );

  @each $name, $value in $theme-colors {
    .color-#{$name} {
      color: nth($value, 1);
    }

    &.border-color-#{$name}:not(.skeleton) {
      border-color: nth($value, 2);
    }
  }
}

</style>


<template lang="pug">

.appointment-data(
  :class="[ { skeleton }, ...stateClasses.wrapper ]"
)
  .skeleton-content.grid.gap-regular.vertical-center(v-if="skeleton")
    loading-lines(:min="99", :max="100", :height="32")

    .grid.gap-tiny
      loading-lines(:height="18.33")
      loading-lines(:height="18.33")

  .flex.gap-regular.vertical-center(v-else)
    .icon.flex.vertical-center.center
      i(:class="stateClasses.iconClass")

    .grid.gap-tiny.grow
      p.title(
        v-if="stateTranslated"
      ) {{ $t(".state", { state: stateTranslated }) }}

      .canceled(v-if="isCanceled")
        p {{ canceledDateAndTime }}

        template(v-if="ticket.scheduledAt")
          p {{ $t('.previously-scheduled') }}

          p.flex.gap-small.vertical-center
            span {{ bookedWeekDay }}
            i.fas.fa-diamond.decoration
            span {{ bookedDateAndTime }}

          p.reason(v-if="ticket.cancellationReason") {{ ticket.cancellationReason }}

      p.date.flex.gap-small.vertical-center(v-else)
        span {{ bookedWeekDay }}
        i.fas.fa-diamond.decoration
        span {{ bookedDateAndTime }}

</template>


<script>

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"

const stateClassMap = new Map([
  [
    "unknown",
    {
      wrapper:   ["border-color-gray"],
      iconClass: ["fal", "fa-circle-question",  "color-gray"]
    }
  ],
  [
    "draft",
    {
      wrapper:   ["border-color-gray"],
      iconClass: ["fal", "fa-pencil",  "color-gray"]
    }
  ],
  [
    "pre_booked",
    {
      wrapper:   ["border-color-yellow"],
      iconClass: ["fal", "fa-calendar-clock", "color-yellow"]
    }
  ],
  [
    "booked",
    {
      wrapper:   ["border-color-green"],
      iconClass: ["fal", "fa-calendar-check", "color-green"]
    }
  ],
  [
    "ongoing",
    {
      wrapper:   ["border-color-green"],
      iconClass: ["fal", "fa-screwdriver-wrench", "color-green"]
    }
  ],
  [
    "done",
    {
      wrapper:   ["border-color-green"],
      iconClass: ["fal", "fa-check", "color-green"]
    }
  ],
  [
    "canceled",
    {
      wrapper:   ["border-color-red"],
      iconClass: ["fal", "fa-calendar-xmark", "color-red"]
    }
  ]
])

export default {
  name: "AppointmentData",

  components: {
    LoadingLines
  },

  props: {
    skeleton: { type: Boolean, default: false },
    ticket:   { type: Object }
  },

  data() {
    return {
      i18nScope: "components.appointment-data"
    }
  },

  computed: {
    isCanceled() {
      return this.ticket.processedState === "canceled"
    },

    bookedWeekDay() {
      return this.ticket.scheduledAt
        ? this.$l("date.formats.weekDay", this.ticket.scheduledAt)
        : "-"
    },

    bookedDateAndTime() {
      return this.ticket.scheduledAt
        ? this.$l("time.formats.short", this.ticket.scheduledAt)
        : "-"
    },

    canceledDateAndTime() {
      return this.ticket.canceledAt
        ? this.$l("time.formats.short", this.ticket.canceledAt)
        : "-"
    },

    stateTranslated() {
      return this.ticket.processedStateTranslated
        ? this.ticket.processedStateTranslated.toLowerCase()
        : ""
    },

    stateClasses() {
      if (
        !this.ticket.processedState
        || !stateClassMap.has(this.ticket.processedState)
      ) return stateClassMap.get("unknown")

      return stateClassMap.get(this.ticket.processedState)
    }
  }
}

</script>
