import Base from "../base/base"

class Person extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "cpf",
      "phone",
      "email",
      "remoteId"
    ])
  }

  static get constraints() {
    return {
      cpf: { presence: true, cpf: true }
    }
  }

  static get relations() {
    return {
      address: { type: "hasOne", model: "Address" }
    }
  }

  get driverDetails() {
    return {
      name:  this.name,
      phone: this.phone,
      cpf:   this.cpf,
      email: this.email
    }
  }
}

export default Person
