// 3rd Party
import VueGtag from "vue-gtag"

// Libs
import conf from "@/lib/conf"

function getPluginOptions() {
  const id = _.get(conf, "googleAnalytics.id")

  if (_.blank(id)) return null

  return { config: { id } }
}

export default function initialize(app) {
  const pluginOptions = getPluginOptions()

  if (process.env.NODE_ENV === "production" && _.present(pluginOptions)) {
    app.use(VueGtag, pluginOptions)
  }
}

export { VueGtag, getPluginOptions }
