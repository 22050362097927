<style lang="scss" scoped>

.tickets-reschedule-confirmation {
  grid-template-columns: 1fr 330px;
  gap: 57px;

  .title {
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: 400;
  }

  .content-start {
    align-content: flex-start;
  }

  .select-channels {
    padding-block-start: 8px;
  }

  .field-header {
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
  }

  .field-icon {
    color: $gray;
    width: 16px;
    aspect-ratio: 1;
  }

  .checkbox-text {
    font-family: $secondary-font;
    font-weight: 400;
    padding-inline-start: 8px;
  }
}

</style>


<template lang="pug">

.tickets-reschedule-confirmation.grid
  .main.grid.gap-regular.content-start
    h2.title {{ $t('.title') }}

    appointment-summary(
      :ticket-id="ticketId",
      :disabled="parentLoading || ticketEndLoading",
      @edit-schedule="goToSupplierSelection",
      @edit-supplier="goToSupplierSelection",
      @show="handleAppointmentSummaryShow"
    )

    issues-summary(
      :ticket-id="ticketId",
      hide-button
    )

    .field.grid.gap-small
      .field-header.flex.gap-tiny.vertical-center
        .field-icon.flex.center.vertical-center
          i.fas.fa-message-text

        span.grow {{ $t(".fields.noteForSupplier.label") }}

      textarea-field(
        v-model="resource.noteForSupplier",
        hide-label,
        name="reschedule[noteForSupplier]",
        id="reschedule[noteForSupplier]",
        :maxlength="400"
        :placeholder="$t('.fields.noteForSupplier.placeholder')",
        :errors="errors.noteForSupplier",
        :disabled="ticketEndLoading || fetching",
      )

    .field.grid.gap-small
      .field-header.flex.gap-tiny.vertical-center
        .field-icon.flex.center.vertical-center
          i.fas.fa-message

        span.grow {{ $t(".fields.chatDescription.label") }}

      textarea-field(
        v-model="resource.chatDescription",
        hide-label,
        name="reschedule[chatDescription]",
        id="reschedule[chatDescription]"
        :placeholder="$t('.fields.chatDescription.placeholder')",
        :errors="errors.chatDescription"
        :disabled="ticketEndLoading || fetching",
        :maxlength="1_000_000_000"
      )

      checkbox-field(
        name="reschedule[customerInteraction]",
        v-model="resource.customerInteraction",
        :value-text="$t('.fields.customerInteraction.label')"
        :errors="errors.customerInteraction",
        :disabled="ticketEndLoading || fetching",
      )
        template(#optionText="{ props: { inputId } }")
          label(:for="inputId")
            span.checkbox-text {{ $t('.fields.customerInteraction.label') }}

  .aside.grid.gap-big
    service-cart(
      without-delete,
      :ticket-id="ticketId",
      :disabled="ticketEndLoading"
    )
      template(#footer="{ disabled: cartDisabled }")
        .cart-complement.grid.gap-big
          requester-contact(
            ref="requesterContact"
            without-edit,
            :skeleton="parentLoading",
            :requester="requester",
            @show-edit="openModal('edit-requester')"
          )
            //- template(#footer)
            //-   .select-channels.grid.gap-tiny
            //-     channels-selector(
            //-       v-model="resource.channels",
            //-       :disabled="cartDisabled"
            //-     )

            //-     p.error-message(v-if="errors.channels?.length") {{ errors.channels[0] }}

          .divider

          app-button(
            full-width,
            :disabled="cartDisabled",
            @click="openModal('checklist')"
          )
            .flex.center.vertical-center.gap-small
              span {{ $t('.cart-button') }}
              i.fas.fa-check

    .divider

    auxiliar-buttons(
      hide-save,
      hide-sinister,
      :ticket="ticket",
      @cancellation="handleCancellation"
    )

  app-modal(
    close-button,
    avoid-close-on-click-outside,
    :heading="$t(`.modal.title.${selectedModal}`)",
    :width="modalWidth",
    :show="modalShown",
    @close="hideModal"
  )
    template(#default)
      requester-form-edit(
        v-if="selectedModal === 'edit-requester'"
        :requester="requester",
        @cancel="hideModal",
        @update="handleRequesterUpdate"
      )

      checklist-modal(
        v-if="selectedModal === 'checklist'",
        :ticket="ticket",
        :booking="supplierBooking"
        @close="hideModal",
        @complete="handleChecklistComplete"
      )

</template>


<script>

// Components
import AppModal from "@/components/app-modal"
import AppButton from "@/components/app-button/app-button.vue"
import ServiceCart from "@/components/service-cart"
import RequesterFormEdit from "@/components/requester-form-edit"
import IssuesSummary from "@/components/issues-summary"
import TextareaField from "@/components/textarea-field/textarea-field.vue"
import CheckboxField from "@/components/checkbox-field/checkbox-field.vue"

import ChecklistModal from "../../draft/confirmation/_components/checklist-modal"
import AppointmentSummary from "../../draft/confirmation/_components/appointment-summary"
import RequesterContact from "../../_components/requester-contact"
import ChannelsSelector from "../../_components/channels-selector"
import AuxiliarButtons from "../../_components/auxiliar-buttons"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "TicketsRescheduleConfirmation",

  components: {
    AppModal,
    AppButton,
    ServiceCart,
    RequesterFormEdit,
    IssuesSummary,
    TextareaField,
    CheckboxField,

    ChecklistModal,
    AppointmentSummary,
    RequesterContact,
    ChannelsSelector,
    AuxiliarButtons
  },

  props: {
    ticketId:      { type: [String, Number] },
    ticket:        { type: Object, default: () => ({}) },
    parentLoading: { type: Boolean, default: false }
  },

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "tickets-reschedule-confirmation",
      resource:  {
        noteForSupplier:     "",
        chatDescription:     "",
        customerInteraction: false,
        channels:            ["email"]
      },
      errors:           {},
      ticketEndLoading: false,
      //
      selectedModal:    "",
      modalShown:       false,
      supplierBooking:  null
    }
  },

  computed: {
    requester() {
      return this.ticket?.requester ?? {}
    },

    hasErrors() {
      return Object.entries(this.errors).length > 0
    },

    modalWidth() {
      return this.selectedModal === "checklist"
        ? 914
        : 690
    }
  },

  methods: {
    // @overide FetchMixin
    fetchRequest() {
      return this.$sdk.tickets.supplierInstruction.show({
        ticketId: this.ticketId
      })
    },

    // @overide FetchMixin
    onFetchSuccess({ data }) {
      if (data?.note) {
        this.resource.noteForSupplier = data.note

        this.$notifications.info(this.$t(".notifications.fetch.success"))
      }
    },

    validate() {
      this.errors = {}

      if (!this.resource.channels.length) {
        this.errors.channels = [this.$t(".fields.channels.validation.presence")]
      }
    },

    openModal(type) {
      if (!["edit-requester", "checklist"].includes(type)) throw new Error("Modal type not found")

      if (type === "checklist") {
        this.validate()

        if (this.hasErrors) return
      }

      this.selectedModal = type
      this.modalShown = true
    },

    hideModal() {
      this.modalShown = false
      this.selectedModal = ""
    },

    handleRequesterUpdate({ requester }) {
      this.$emit("update")
      this.hideModal()

      this.$notifications.info(
        this.$t(".requester.update", { requester: requester.name })
      )
    },

    handleChecklistComplete() {
      this.hideModal()
      this.submitConfirmation()
    },

    async submitConfirmation() {
      this.validate()

      if (this.hasErrors) {
        if (this.$refs.requesterContact?.toggleOpening) {
          this.$refs.requesterContact.toggleOpening(false)
        }
        return
      }

      const params = this.getRecipientsParams()

      try {
        this.ticketEndLoading = true

        await this.$sdk.tickets.rebooking.create({ ticketId: this.ticketId, params })

        this.$router.push({
          name:   "tickets-end",
          params: { id: this.ticket.id },
          query:  { kind: "reschedule" }
        })
      }
      catch (err) {
        const error = err.error || err
        console.error(error)

        this.chooseErrorToastMessage(error)
      }
      finally {
        this.ticketEndLoading = false
      }
    },

    getRecipientsParams() {
      const {
        noteForSupplier,
        chatDescription,
        customerInteraction,
        channels
      } = _.cloneDeep(this.resource)

      return _.snakeizeKeys({
        noteForSupplier,
        chatDescription,
        customerInteraction,
        recipients: [
          {
            personId: this.requester.personId,
            channels
          }
        ]
      })
    },

    goToSupplierSelection() {
      this.$router.push({ name: "tickets-reschedule.supplier", params: { id: this.ticketId } })
    },

    async handleCancellation({ successMessage }) {
      await this.$router.push({ name: "tickets-show", param: { id: this.ticketId } })
      this.$notifications.info(successMessage)
    },

    chooseErrorToastMessage(error) {
      let errorTranslationTitle = "generic"

      if (this.isErrorFromMissingSapId(error)) {
        errorTranslationTitle = "missing-sap"
      }

      else if (this.isErrorFromInvalidServiceOrderState(error)) {
        errorTranslationTitle = "service-order-state"
      }

      this.$notifications.error(this.$t(`.notifications.submit.error.${errorTranslationTitle}`))
    },

    isErrorFromMissingSapId(error) {
      //
      // XXX: Capturar caso de erro apresentado em https://github.com/caiena/movida-gac/issues/424
      //
      const isThisErrorCase = _.chain(error)
        .get("originalError.response.data.remote_ticket")
        .map(remoteTicket => _.get(remoteTicket, "errors.errors"))
        .find(errorType => errorType.remote_service_order)
        .get("remote_service_order")
        .map(remoteServiceError => remoteServiceError.errors)
        .some(errorItem => typeof errorItem.message === "string" && errorItem.message.includes("`CodigoSAP` = ?"))
        .value()

      return isThisErrorCase
    },

    isErrorFromInvalidServiceOrderState(error) {
      //
      // XXX: Capturar caso de erro apresentado em https://github.com/caiena/movida-gac/issues/414#issuecomment-1382088561
      //
      const isThisErrorCase = _.chain(error)
        .get("originalError.response.data.remote_ticket")
        .flatMap(remoteTicket => _.get(remoteTicket, "errors.errors"))
        .flatMap(errorType => _.values(errorType))
        .filter(errors => _.find(errors, errorList => _.get(errorList, "errors")))
        .flatten()
        .map(errorList => _.get(errorList, "errors.errors"))
        .some(errorItem => {
          const relevantErrorList = _.get(errorItem, "service_order_state")

          return relevantErrorList
            && _.some(relevantErrorList, errorDetail => errorDetail.error === "invalid")
        })
        .value()

      return isThisErrorCase
    },

    handleAppointmentSummaryShow({ booking }) {
      this.supplierBooking = booking
    }
  }
}

</script>
