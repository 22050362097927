<style lang="scss" scoped>

.ticketHeader {
  grid-template-columns: var(--ticket-template-columns);
  gap: var(--ticket-gap);
  border-radius: 8px 8px 0 0;
  background-color: $gray-light-4;
  font-family: $secondary-font;

  p {
    margin: 0;
  }

  .cell {
    padding: 8px;
    font-size: 0.875rem;
  }

  .gray-2 {
    color: $gray-2
  }

  .fa-diamond {
    font-size: 8px;
    color: $gray-light;
  }

  .clickable {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);

        &:active {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      ::selection {
        background: transparent;
        color: inherit;
      }
    }
  }

  .color-orange {
    color: $orange;
  }
}

</style>


<template lang="pug">

.ticketHeader.grid.vertical-center(:style="cssVars")
  .cell.text-center
    i.fas.fa-diamond

  .cell(v-if="extendedConfiguration")
    p {{ $t('.header.state') }}

  .cell(
    :class="{ clickable: enableSorting, disabled }",
    @click="emitSort('opening_date')"
  )
    p {{ thirdColumnText }}

    i.fas.color-orange(
      v-if="enableSorting && sorting?.opening_date"
      :class="sortIconSelector('opening_date')"
    )

  .cell(
    v-if="!smallConfiguration",
    :class="{ clickable: enableSorting, disabled }",
    @click="emitSort('scheduled_at')",
  )
    p {{ extendedConfiguration ? $t('.header.extended.scheduled') : $t('.header.scheduled') }}

    i.fas.color-orange(
      v-if="enableSorting && sorting?.scheduled_at"
      :class="sortIconSelector('scheduled_at')"
    )

  .cell(v-if="!smallConfiguration && !extendedConfiguration")
    p {{ $t('.header.vehicle') }}

  .cell
    p {{ $t('.header.supplier') }}

  .cell
    p {{ $t('.header.client-requester') }}

  .cell.self-center
    p(v-tooltip="$t('.tooltip.un')") {{ $t('.header.un') }}

  .cell.self-center
    i.fal.fa-user.gray-2(v-tooltip="$t('.tooltip.user')")

  .cell.self-center(v-if="!extendedConfiguration")
    i.fas.fa-diamond

</template>


<script>

const configurationTypes = ["default", "small", "extended"]

export default {
  name: "TicketsTableHeader",

  props: {
    columnSizes:   { type: String, default: "1fr" },
    gap:           { type: String, default: "0px" },
    small:         { type: Boolean, default: false },
    disabled:      { type: Boolean, default: false },
    sorting:       { type: Object },
    enableSorting: { type: Boolean, default: false },
    configuration: {
      type:    String,
      default: "default",
      validator(val) { return configurationTypes.includes(val) }
    }
  },

  data() {
    return {
      i18nScope: "components.tickets-table-header"
    }
  },

  emits: ["sort"],

  computed: {
    smallConfiguration() { return this.configuration === "small" },

    extendedConfiguration() { return this.configuration === "extended" },

    cssVars() {
      return {
        "--ticket-template-columns": this.columnSizes,
        "--ticket-gap":              this.gap
      }
    },

    thirdColumnText() {
      if (this.smallConfiguration) return this.$t(".header.small.date")
      if (this.extendedConfiguration) return this.$t(".header.extended.date")

      return this.$t(".header.date")
    }
  },

  methods: {
    emitSort(property) {
      if (!this.enableSorting || this.disabled) return
      this.$emit("sort", property)
    },

    sortIconSelector(property) {
      if (!this.sorting) return []

      if (this.sorting[property] === "asc") return ["fa-long-arrow-down"]
      if (this.sorting[property] === "desc") return ["fa-long-arrow-up"]

      return []
    }
  }
}

</script>
