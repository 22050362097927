// 3rd Party
import { createGtm } from "@gtm-support/vue-gtm"

// Libs
import conf from "@/lib/conf"

// Modules
import vueRouter from "@/modules/router"

function getPluginOptions() {
  const id = _.get(conf, "googleTagManager.id")

  if (_.blank(id)) return null

  return { id, vueRouter }
}

export default function initialize(app) {
  const pluginOptions = getPluginOptions()

  if (process.env.NODE_ENV === "production" && _.present(pluginOptions)) {
    app.use(createGtm(pluginOptions))
  }
}

export { createGtm }
