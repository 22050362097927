import Base from "../base/base"

class Symptom extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description"
    ])
  }
}

export default Symptom
