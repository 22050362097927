<style lang="scss" scoped>

.tickets-table {
  .outside {
    font-size: 14px;

    &-default {
      text-align: end;
      min-height: 19px;

      p {
        margin: 0;
      }
    }
  }

  .counter {
    font-family: $primary-font;
    font-weight: 300;
  }

  .tickets-footer {
    background-color: $gray-light-4;
    padding-block: 8px;
    border-block: 1px solid $gray-light-2;
  }

  .empty {
    background-color: $gray-light-3;
    padding: 12px;
    border-top: 1px solid $gray-light-2;
    border-bottom: 1px solid $gray-light-2;

    p {
      margin: 0;
      color: $gray;
      font-size: 14px;
      font-style: italic;
    }
  }

  .font-small {
    font-size: 14px;
    font-weight: 400;
  }

  .fa-diamond {
    font-size: 4px;
    color: $gray-3;
  }

  .fa-steering-wheel {
    font-size: 12px;
  }

  .icon {
    width: 16px;
    aspect-ratio: 1;
  }

  .dimmed {
    opacity: 0.4;
  }

  &.table-empty :deep(.footer) {
    display: none;
  }
}

</style>


<template lang="pug">

.tickets-table.grid.gap-regular(:class="{ 'table-empty': !tickets.length }")
  .outside
    slot(name="outside")
      .outside-default(v-if="showPagination && pagination?.total")
        p {{ $t('.results', resultsForTranslationObject) }}

  app-table.tickets-table(
    skeleton-gap="12px"
    :column-sizes="columnSizes",
    :cols-count="colsCount",
    :empty="listIsEmpty",
    :initializing="skeleton",
    :class="{ dimmed: loading }"
  )
    template(#header)
      tickets-table-header(
        :column-sizes="columnSizes",
        :configuration="configuration",
        :disabled="loading",
        :sorting="headerSorting",
        :enable-sorting="enableSorting"
        @sort="handleSort"
      )

    template(#rows)
      tickets-table-row(
        v-for="(ticket, i) of ticketsSample",
        :key="i",
        :ticket="ticket",
        :column-sizes="columnSizes",
        :configuration="configuration",
        @cancellation="evt => openModal('cancellation', evt)"
        @booking-confirmation="evt => openModal('booking-confirmation', evt)"
      )

      slot(name="lastRow")

    template(#footer)
      slot(name="footer")
        .tickets-footer.gap-small(
          v-if="showPagination"
          :class="{ empty: !tickets.length }"
        )
          paginator(
            v-if="pagination && tickets.length && !amountShown",
            :per-page="pagination.perPage",
            :total="pagination.total",
            :hide-single-page="false",
            :disabled="disabledPagination",
            :modelValue="pagination.page",
            @update:modelValue="handlePageUpdate"
          )

    template(#empty)
      slot(name="customEmpty")
        .empty.grid.center.gap-samll
          img(:src="emptyHeadset" :alt="$t('.empty-icon')")
          p {{ $t(".counter.zero") }}

app-modal(
  close-button
  :heading="$t(`.modal.title.${selectedModalKind}`)"
  :width="566",
  :show="modalShown"
  @close="closeModal"
)
  modal-ticket-cancellation(
    v-if="selectedModalKind === 'cancellation'",
    :ticket="seletedTicket",
    @cancel="handleTicketCancellation"
  )

  modal-booking-confirmation(
    v-if="selectedModalKind === 'booking-confirmation'",
    :ticket="seletedTicket",
    @start-reschedule="goToReschedule"
    @confirm="handleBookingConfirmation"
  )

</template>


<script>

// Assets
import emptyHeadset from "@/assets/images/list-illustrations/empty-headset.svg"

// Components
import AppTable from "@/components/app-table/app-table.vue"
import AppModal from "@/components/app-modal"
import Paginator from "@/components/paginator/paginator.vue"
import ModalTicketCancellation from "@/components/modal-ticket-cancellation"
import ModalBookingConfirmation from "@/components/modal-booking-confirmation"

import TicketsTableHeader from "./header"
import TicketsTableRow from "./row"

const configurationTypes = ["default", "small", "extended"]

export default {
  name: "TicketsTable",

  components: {
    AppTable,
    AppModal,
    Paginator,
    ModalTicketCancellation,
    ModalBookingConfirmation,

    TicketsTableHeader,
    TicketsTableRow
  },

  emits: [
    "page-change",
    "sort",
    "cancellation",
    "booking-confirmation"
  ],

  props: {
    tickets:            { type: Array, default: () => [] },
    skeleton:           { type: Boolean, default: false },
    loading:            { type: Boolean, default: false },
    pagination:         { type: Object, default: null },
    disabledPagination: { type: Boolean, default: false },
    showPagination:     { type: Boolean, default: false },
    small:              { type: Boolean, default: false },
    amountShown:        { type: Number },
    headerSorting:      { type: Object },
    enableSorting:      { type: Boolean, default: false },
    configuration:      {
      type:    String,
      default: "default",
      validator(val) { return configurationTypes.includes(val) }
    }
  },

  data() {
    return {
      i18nScope:         "components.tickets-table",
      emptyHeadset,
      //
      modalShown:        false,
      seletedTicket:     "",
      selectedModalKind: ""
    }
  },

  computed: {
    smallConfiguration() { return this.configuration === "small" },

    extendedConfiguration() { return this.configuration === "extended" },

    // XXX: não usar `repeat()`, pois afeta o `colsCount`
    columnSizes() {
      if (this.smallConfiguration) return "44px 154px 1fr 1fr 121px 48px 48px"
      if (this.extendedConfiguration) return "44px 118px 265px 123px 1fr 1fr 121px 48px"
      return "44px 166px 172px 1fr 1fr 1fr 121px 48px 48px"
    },

    listIsEmpty() {
      return this.tickets.length === 0
    },

    colsCount() {
      return this.columnSizes.split(" ").length
    },

    resultsForTranslationObject() {
      if (!this.pagination.page) return { first: 0, last: 0, total: 0 }

      const { page, perPage, total } = this.pagination

      let first = (page - 1) * perPage + 1
      let last = perPage * page

      if (first > total) first = total
      if (last > total) last = total

      return { first, last, total }
    },

    ticketsSample() {
      return this.amountShown && this.amountShown
        ? this.tickets.slice(0, this.amountShown)
        : this.tickets
    }
  },

  methods: {
    handlePageUpdate(page) {
      this.$emit("page-change", page)
    },

    handleSort(property) {
      this.$emit("sort", property)
    },

    openModal(modalKind, { ticket }) {
      const permittedModalKinds = ["cancellation", "booking-confirmation"]

      if (!permittedModalKinds.includes(modalKind)) throw new Error("Modal kind not permitted")

      this.seletedTicket = ticket
      this.selectedModalKind = modalKind
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
      this.selectedModalKind = ""
      this.seletedTicket = ""
    },

    handleTicketCancellation(event) {
      this.$emit("cancellation", event)
      this.closeModal()
    },

    handleBookingConfirmation(event) {
      this.$emit("booking-confirmation", event)
      this.closeModal()
    },

    goToReschedule({ ticket }) {
      this.closeModal()
      this.$router.push({ name: "tickets-reschedule", params: { id: ticket.id } })
    }
  }
}

</script>
