// Base
import Base from "./Base"

class AuthRequests extends Base {
  static get selector() {
    return "auth"
  }

  login({ params = {}, options = {} } = {}) {
    return this.action({
      method: "post",
      url:    `/oauth/token`,
      params: { grant_type: "password", ...params },
      options
    })
  }

  logout({ options = {} } = {}) {
    return this.action({
      method: "delete",
      url:    "/oauth/token",
      options
    })
  }
}

export default AuthRequests
