<style lang="scss" scoped>

.supplier-appointment-scheduler {
  margin-top: 24px;

  .section-title {
    margin: 0;
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 300;
    color: $gray-dark;
  }

  .button-reset {
    border: none;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
  }

  .arrow {
    padding: 0;
    width: 24px;
  }

  .radio {
    display: none;
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

.supplier-appointment-scheduler.grid.gap-big
  template(v-if="skeleton")
    .grid.gap-small
      loading-lines(:height="16", :min="20", :max="25")
      loading-lines(:height="27", :min="50", :max="52")

    .grid.gap-small
      loading-lines(:height="16", :min="20", :max="25")
      loading-lines(:height="27", :min="85", :max="90")


  template(v-else)
    .grid.gap-small
      h4.section-title {{ monthAndYear }}

      .options.flex.gap-small
        transition-horizontal-fold(custom-width="24px", layout-spacing="8px")
          button.button-reset.arrow(
            type="button",
            @click="updateWeekIndex(-1)"
            v-if="ableToGoOneWeekBack",
          )
            i.far.fa-angle-left

        template(v-for="day of weekDates", :key="`date-${day.fullDate}`")
          input.radio(
            type="radio",
            v-model="modelDate",
            :id="`date-${day.fullDate}`",
            :value="day.fullDate"
            :disabled="disabled || fetching || isDateUnavailable(day.date)"
          )

          label(:for="`date-${day.fullDate}`")
            toggle-tag(
              :selected="day.fullDate === modelDate",
              :disabled="disabled || fetching || isDateTagDisabled(day.date)"
              :strike-through="!isInThePast(day.date) && isDateTagStruckThrough(day.date)"
              v-tooltip.top="isDateTagStruckThrough(day.date) ? $t('.holiday') : ''"
            ) {{ day.label }}

        button.button-reset.arrow(
          type="button",
          @click="updateWeekIndex()"
        )
          i.far.fa-angle-right

      span.error(v-if="errors?.date?.length") {{ errors.date[0] }}

    .grid.gap-small
      h4.section-title {{ $t(".field.hour") }}

      .options.flex.gap-small
        template(v-for="session of dailySessions", :key="`time-${session}`")
          input.radio(
            type="radio",
            v-model="modelTime",
            :id="`time-${session}`",
            :value="session",
            :disabled="disabled || fetching || isSessionNotAvailable(session)"
          )

          label(:for="`time-${session}`")
            toggle-tag(
              :selected="session === modelTime"
              :disabled="disabled || fetching || isSessionNotAvailable(session)"
            ) {{ session }}

      span.error(v-if="errors?.time?.length") {{ errors.time[0] }}

</template>


<script>

// Libs
import { isDateStringHoliday } from "@/lib/holidays"

// Components
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import ToggleTag from "@/components/toggle-tag"

// Trinsitions
import TransitionHorizontalFold from "@/transitions/transition-horizontal-fold"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
// eslint-disable-next-line no-unused-vars
import SupplierBusinessHour from "@/models/supplier/supplier-business-hour"

export default {
  name: "SupplierAppointmentScheduler",

  components: {
    LoadingLines,
    ToggleTag,

    TransitionHorizontalFold
  },

  mixins: [FetchMixin],

  props: {
    date:                 { type: String },
    time:                 { type: String },
    errors:               { type: Object },
    disabled:             { type: Boolean, default: false },
    supplierId:           { type: [String, Number], required: true }
  },

  emits: [
    "update:date",
    "update:time",
    "loading",
    "index"
  ],

  data() {
    return {
      i18nScope:               "component.supplier-appointment-scheduler",
      skeleton:                false,
      now:                     moment(),
      weekIndex:               0,
      firstAvailableWeekIndex: 0,
      startingHour:            8,
      amountOfSessions:        10,
      businessHours:           []
    }
  },

  computed: {
    modelDate: {
      get() { return this.date },
      set(val) { this.$emit("update:date", val) }
    },

    modelTime: {
      get() { return this.time },
      set(val) { this.$emit("update:time", val) }
    },

    /**
     *  @typedef {object} WeekDate
     *  @prop {import("moment").Moment} WeekDate.date
     *  @prop {string} WeekDate.label
     *  @prop {string} WeekDate.month
     *  @prop {string} WeekDate.year
     *  @prop {string} WeekDate.fullDate
     *  @prop {string} WeekDate.weekday
     */

    /**
     * @returns {WeekDate[]}
     */
    weekDates() {
      const relevantDaysOfTheWeek = [0, 1, 2, 3, 4, 5, 6] // sunday through saturday

      const weekIncrement = (this.weekIndex + this.firstAvailableWeekIndex) * 7

      return relevantDaysOfTheWeek.map(day => {
        const date = moment(this.now).day(day + weekIncrement)

        return {
          date,
          label:    _.capitalize(date.format("ddd DD")),
          month:    _.capitalize(date.format("MMMM")),
          year:     _.capitalize(date.format("YYYY")),
          fullDate: date.format("YYYY-MM-DD"),
          weekday:  date.locale("en").format("dddd").toLowerCase()
        }
      })
    },

    ableToGoOneWeekBack() {
      return this.weekIndex > 0
    },

    /** @returns {string} */
    monthAndYear() {
      const monthsSet = new Set(this.weekDates.map(({ month }) => month))
      const yearsSet = new Set(this.weekDates.map(({ year }) => year))

      if (monthsSet.size === 1) {
        const month = [...monthsSet][0]
        const year = [...yearsSet][0]
        return this.$t(".monthAndYear", { month, year })
      }

      if (yearsSet.size === 1) {
        const months = [...monthsSet].join("/")
        const year = [...yearsSet][0]
        return this.$t(".monthAndYear", { month: months, year })
      }

      const monthsArray = [...monthsSet]
      const yearsArray = [...yearsSet]

      return [
        this.$t(".monthAndYear", { month: monthsArray[0], year: yearsArray[0] }),
        this.$t(".monthAndYear", { month: monthsArray[1], year: yearsArray[1] })
      ].join(" / ")
    },

    /** @returns {string[]} */
    dailySessions() {
      return [...Array(this.amountOfSessions).keys()]
        .map(val => `${String(val + this.startingHour).padStart(2, "0")}:00`)
    },

    /** @returns {SupplierBusinessHour[]} */
    currentDayBusinessHours() {
      if (
        !this.businessHours
        || !this.businessHours.length
        || !this.date
      ) return []

      const day = this.weekDates.find(({ fullDate }) => fullDate === this.date)

      return day
        ? this.getBusinessHoursByWeekday(day.weekday)
        : []
    }
  },

  watch: {
    modelDate() {
      this.$emit("update:time", "")
    }
  },

  mounted() {
    this.setFirstAvailableWeekIndex()
    if (this.date && this.time) this.setInitialDateTimeValues()
  },

  methods: {
    updateWeekIndex(amountOfWeeks = 1) {
      this.resetDateAndTime()

      const newIndex = this.weekIndex + amountOfWeeks

      if (newIndex < 0) this.weekIndex = 0
      else this.weekIndex = newIndex
    },

    resetDateAndTime() {
      this.$emit("update:date", "")
      this.$emit("update:time", "")
    },

    setFirstAvailableWeekIndex() {
      let weekAvailableNotFound = true
      do {
        weekAvailableNotFound = this.weekDates.every(day => this.isWithoutSessions(day.date))
        if (weekAvailableNotFound) this.firstAvailableWeekIndex++
      } while (weekAvailableNotFound)
    },

    setInitialDateTimeValues() {
      const initialDate = moment(`${this.date} ${this.time}`, "YYYY-MM-DD hh:mm")

      if (this.isInThePast(initialDate)) {
        this.resetDateAndTime()
        return
      }

      const weeksDifferenceFromInitialDate = initialDate.week() - this.now.week()

      this.weekIndex = weeksDifferenceFromInitialDate < 0
        ? 0
        : weeksDifferenceFromInitialDate
    },

    /**
     * @param {string} weekday
     * @returns {SupplierBusinessHour[]}
     */
    getBusinessHoursByWeekday(weekday) {
      return this.businessHours.filter(timeblock => timeblock.weekday === weekday)
    },

    // @override FetchMixin
    async fetchRequest() {
      this.$emit("loading")
      return this.$sdk.suppliers.businessHours.index({ supplierId: this.supplierId })
    },

    // @override FetchMixin
    async onFetchSuccess({ data }) {
      this.$emit("index")
      this.businessHours = data
    },

    /**
     * @param {import("moment").Moment} date
     * @returns {boolean}
     */
    isInThePast(date) {
      return moment(date).isBefore(this.now)
    },

    /**
     * @param {import("moment").Moment} date
     * @returns {boolean}
     */
    dateHasBusinessHours(date) {
      const weekday = date.locale("en").format("dddd").toLowerCase()

      return this.getBusinessHoursByWeekday(weekday).length > 0
    },

    /**
     * @param {import("moment").Moment} date
     * @returns {boolean}
     */
    isWithoutSessions(date) {
      const isDateWithoutAvailableSessions = this.dailySessions.every(session => {
        const [hour] = session.split(":").map(Number)

        const sessionDate = moment(date).hour(hour).minute(0)

        return this.isInThePast(sessionDate)
      })

      return isDateWithoutAvailableSessions
    },

    /**
     * @param {string} date
     * @returns {boolean}
     */
    isDateToday(date) {
      return date && moment().isSame(date, "day")
    },

    /**
     *  @param {import("moment").Moment} date
     *  @returns {boolean}
    */
    isDateTagDisabled(date) {
      return this.isInThePast(date)
        || this.isWithoutSessions(date)
        || !this.dateHasBusinessHours(date)
    },

    /**
     *  @param {import("moment").Moment} date
     *  @returns {boolean}
    */
    isDateTagStruckThrough(date) {
      return isDateStringHoliday(date.format("YYYY-MM-DD"))
    },

    /**
     *  @param {import("moment").Moment} date
     *  @returns {boolean}
    */
    isDateUnavailable(date) {
      return this.isDateTagDisabled(date)
        || this.isDateTagStruckThrough(date)
    },

    /**
     * @param {string} time
     * @returns {boolean}
     */
    isOutsideBusinessHours(time) {
      if (!this.currentDayBusinessHours.length) return false

      const generateSampleDateFromTime = sampleTime => moment(`${this.date} ${sampleTime}:00`, "YYYY-MM-DD hh:mm:ss")

      const selectedSampleDate = generateSampleDateFromTime(time)

      const isSessionWithinOneTimeblock =  this.currentDayBusinessHours.some(timeblock => {
        const { startAt, endAt } = timeblock
        const [startDate, endDate] = [startAt, endAt].map(generateSampleDateFromTime)

        return selectedSampleDate.isSameOrAfter(startDate)
          && selectedSampleDate.isBefore(endDate)
      })
      return !isSessionWithinOneTimeblock
    },

    /**
     * @param {string} time
     * @returns {boolean}
     */
    isSessionNotAvailable(timeString) {
      const [hour] = timeString.split(":").map(Number)

      const dateWithManipulatedHour = moment(this.modelDate).hour(hour).minute(0)

      const isSessionEarlierToday = this.isDateToday(this.modelDate)
        && this.isInThePast(dateWithManipulatedHour)

      return !this.modelDate
        || isSessionEarlierToday
        || !this.dailySessions.includes(timeString)
        || this.isOutsideBusinessHours(timeString)
    }
  }
}

</script>
