<style lang="scss" scoped>

.contract {
  --main-aside-gap: 20px;
  --view-inline-padding: var(--main-aside-gap);
  --contract-gap: 24px;

  padding-block: 20px;
  align-content: start;

  &,
  .main {
    gap: var(--contract-gap);
    align-content: flex-start;
  }

  .header > * {
    width: min(520px, calc(100% - 20px));
    margin-inline: auto;
  }

  .content {
    grid-template-columns: 1fr 330px;
    gap: 56px;
  }

  .divider {
    min-height: 1px;
    min-width: 1px;
    background-color: $gray-light-2;
  }

  .transparent {
    opacity: 0.4;
  }

  :deep(.col-primary) {
    color: $primary
  }
}

</style>


<template lang="pug">

.contract.grid
  header.header
    loading-lines(v-if="initializing", :height="56", :min="99", :max="100")

    contract-search(
      v-else,
      icon-right="far fa-magnifying-glass col-primary",
      with-clear-icon,
      :initial-value="vehicleDetails.licensePlate",
      @error="handleNotFound"
      @success="handleContractFound"
    )

  .content.grid
    .main.grid
      contract-summary(
        :skeleton="initializing",
        :contract="contract",
        :class="{ transparent: fetching }"
      )

      .divider

      drivers-table(
        ref="drivers",
        :contract-rid="contractRid",
        :creatable="isDriverCreatable",
        :editable="areDriversEditable",
        @driver-create="handleRefetchingData({ origin: 'drivers' })",
        @driver-update="handleRefetchingData({ origin: 'drivers' })",
      )

      .divider

      vehicle-tickets(
        :vehicle-id="vehicleDetails.id",
        @see-all="modalShown = true",
        @index="searchDraftTicket",
        @fetch-start="vehicleTicketsLoading = true",
        @fetch-end="vehicleTicketsLoading = false"
      )

    aside.aside
      ticket-starter(
        ref="ticketStarter",
        :contract="contract",
        :contract-rid="contractRid",
        :existing-draft-ticket="firstDraftTicket",
        :disabled="vehicleTicketsLoading"
        @list-update="handleRefetchingData({ origin: 'ticketStarter' })",
        @create="goToTicketDraft"
      )
        template(#beforeCta, v-if="firstDraftTicket")
          draft-existence-warning(
            :ticket="firstDraftTicket",
            @continue="goToTicketDraft",
          )

  app-modal(
    close-button,
    :heading="$t(`.modal.title`)",
    :width="1156",
    :show="modalShown",
    @close="modalShown = false"
  )
    template(#default)
      modal-tickets-table(
        v-if="modalShown",
        :vehicle="vehicleDetails"
      )

</template>


<script>

// Views
import LoadedView from "@/views/loaded-view.vue"

// Components
import ContractSearch from "@/components/contract-search"
import LoadingLines from "@/components/loading-lines/loading-lines.vue"
import AppModal from "@/components/app-modal"
import ModalTicketsTable from "@/components/modal-tickets-table"
import DriversTable from "@/components/drivers-table"

import ContractSummary from "./_components/contract-summary"
import RequesterSelector from "./_components/requester-selector"
import VehicleTickets from "./_components/vehicle-tickets"
import TicketStarter from "./_components/ticket-starter"
import DraftExistenceWarning from "./_components/draft-existence-warning"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "Contract",

  components: {
    ContractSearch,
    LoadingLines,
    AppModal,
    ModalTicketsTable,
    DriversTable,

    ContractSummary,
    RequesterSelector,
    VehicleTickets,
    TicketStarter,
    DraftExistenceWarning
  },

  mixins: [FetchMixin],

  extends: LoadedView,

  data() {
    return {
      i18nScope: "contract",
      routeName: "contract",

      contract:              {},
      contractRid:           "",
      //
      modalShown:            false,
      firstDraftTicket:      null,
      vehicleTicketsLoading: false
    }
  },

  computed: {
    vehicleDetails() {
      return this.contract?.vehicle || {}
    },

    isDriverCreatable() {
      return this.contract.isGTF
    },

    areDriversEditable() {
      return this.contract.isGTF
    }
  },

  watch: {
    $route() {
      this.initialized = false
      this.firstDraftTicket = null
    }
  },

  methods: {
    // @override LoadedView
    parseRoute() {
      this.contractRid = this.$route.params.rid
    },

    // @override FetchMixin
    async fetchRequest() {
      return this.$sdk.contracts.show({ contractRid: this.contractRid })
    },

    // @override FetchMixin
    async onFetchSuccess({ data }) {
      this.contract = data
    },

    handleNotFound({ term }) {
      this.$router.push({ name: "search", query: { q: term } })
    },

    handleContractFound({ contract }) {
      this.$router.push({ name: "contract", params: { rid: contract.rid } })
    },

    handleRefetchingData({ origin }) {
      const refs = ["drivers", "ticketStarter"]

      refs.forEach(ref => {
        if (ref === origin) return

        this.$refs[ref].publicDataUpdateMethod()
      })

      this.fetch()
    },

    goToTicketDraft(ticket) {
      this.$router.push({ name: "tickets-draft", params: { id: ticket.id } })
    },

    searchDraftTicket({ tickets }) {
      const hasTickets = Array.isArray(tickets) && tickets.length > 0

      if (!hasTickets) return

      const draftTicket = tickets.find(ticket => ticket.isStateDraft && ticket.id)

      this.firstDraftTicket = draftTicket || null
    }
  }
}

</script>
