<style lang="scss" scoped>

.sinister-attached-indicator{
  border: 1px solid $gray-light-2;
  border-radius: 8px;
  padding: 16px;
  grid-template-columns: auto 1fr auto;

  p {
    margin: 0;
  }

  .title {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 500;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
  }

  .button {
    appearance: none;
    background-color: transparent;
    padding: 0;
    border-radius: 50%;
    border: none;
    font-size:  14px;
    width: 24px;
    aspect-ratio: 1;
    color: $orange;
    transition: color 200ms;
    cursor: pointer;

    &:is(:disabled, [disabled]) {
      color: $gray-light;
      cursor: not-allowed;
    }

    &:hover:not(:disabled, [disabled]) {
      color: $orange-dark;
    }
  }
}

</style>


<template lang="pug">

.sinister-attached-indicator.grid.gap-regular.vertical-center
  .car
    img(:src="carCrashIcon", :alt="$t('.car-icon-alt')")

  .text
    p.title {{ $t('.text.title') }}
    p.description {{ $t('.text.description') }}

  button.button(:disabled="disabled", @click="$emit('edit')")
    i.fas.fa-pencil

</template>


<script>

// Assets
import carCrashIcon from "@/assets/images/sinister/orange-car-crash-alt.png"

// Component
import AppIconButton from "@/components/app-icon-button"

export default {
  name: "SinisterAttachedIndicator",

  components: {
    AppIconButton
  },

  props: {
    disabled: { type: Boolean, default: false }
  },

  emits: ["edit"],

  data() {
    return {
      i18nScope: "components.sinister-attached-indicator",
      carCrashIcon
    }
  }
}

</script>
