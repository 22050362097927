<style lang="scss" scoped>

.tickets-draft-profider {
  grid-template-columns: 1fr 330px;
  gap: 56px;

  .main {
    align-content: flex-start;
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }

  .aside {
    align-content: flex-start;
  }

  .divider {
    min-width: 1px;
    min-height: 1px;
    background-color: $gray-light-2;
  }

  .empty {
    padding: 12px;
    background-color: $gray-light-3;
    border-radius: 8px;

    img {
      min-height: 32px;
    }

    p {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      color: $gray;
    }
  }

  p {
    margin: 0;
  }
}

</style>


<template lang="pug">

.tickets-draft-profider.grid
  .main.grid.gap-regular
    h1 {{ $t(".title") }}

    supplier-appointment(
      ref="supplierAppointment",
      show-unconfirmed,
      :ticket-id="ticketId",
      :parent-loading="parentLoading",
      :disabled="somethingIsLoading",
      @update="continueToConfirmation",
      @loading-toggle="e => supplierLoading = e"
    )

  .aside.grid.gap-big
    service-cart(
      initially-open,
      without-delete,
      :ticket-id="ticketId",
      :disabled="somethingIsLoading"
    )
      template(#footer="{ disabled: cartDisabled }")
        app-button(
          full-width,
          :disabled="cartDisabled",
          @click="updateAppointment"
        )
          .flex.center.vertical-center.gap-small
            span {{ $t(".cart-button") }}
            i.fas.fa-arrow-right

    .divider

    auxiliar-buttons(
      :ticket="ticket",
      @cancellation="handleCancellation"
      @leaving-start="leaving = true",
      @leaving-end="leaving = false"
    )

</template>


<script>

// Components
import ServiceCart from "@/components/service-cart"
import AppButton from "@/components/app-button/app-button.vue"

import AuxiliarButtons from "../../_components/auxiliar-buttons"
import SupplierAppointment from "../../_components/supplier-appointment"

export default {
  name: "TicketsDraftSupplier",

  components: {
    ServiceCart,
    AppButton,

    AuxiliarButtons,
    SupplierAppointment
  },

  props: {
    ticketId:      { type: [String, Number] },
    vehicle:       { type: Object, default: () => ({}) },
    ticket:        { type: Object, default: () => ({}) },
    parentLoading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:          "tickets-draft-supplier",
      suppliersAvailable: [],
      //
      errors:             {},
      leaving:            false,
      supplierLoading:    false
    }
  },

  computed: {
    /** @returns {boolean} */
    somethingIsLoading() {
      return [
        this.parentLoading,
        this.leaving,
        this.supplierLoading
      ].some(Boolean)
    }
  },

  methods: {
    updateAppointment() {
      if (!this.$refs.supplierAppointment?.updateAppointment) return

      this.$refs.supplierAppointment.updateAppointment()
    },

    continueToConfirmation() {
      this.$router.push({
        name:   "tickets-draft.confirmation",
        params: { id: this.ticketId }
      })
    },

    async handleCancellation({ successMessage }) {
      await this.$router.push({ name: "tickets-show", param: { id: this.ticketId } })
      this.$notifications.info(successMessage)
    }
  }
}

</script>
