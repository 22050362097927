// Base
import Base from "./Base"

import SupplierRecommendation from "@/models/supplier/supplier-recommendation"

class TicketSupplierRecommendationRequests extends Base {
  static get selector() {
    return "tickets.supplierRecommendation"
  }

  show({ ticketId,  params = {}, options = {} } = {}) {
    this.verifyData("show", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/supplier_recommendation`,
      params,
      options: {
        model: SupplierRecommendation,

        ...options
      }
    })
  }
}

export default TicketSupplierRecommendationRequests
