<style lang="scss" scoped>
.logout-button {
  &:hover {
    background-color: $bg-hover;
  }

  &:active {
    background-color: $bg-active;
  }

  .button {
    padding: 0 24px;
    height: $navbar-height;

    &__content {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .avatar {
      width: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: $gray-light-2;
      color: $white;
      display: grid;
      place-items: center;
    }

    .name {
      font-weight: 500;
      font-family: $secondary-font;
      color: $text;
    }

    &:hover {
      .name {
        color: $primary;
      }
    }
  }
}

.modal-body {
  padding: 24px;

  .user-info {
    text-align: center;
    margin-top: 16px;
  }

  .action-button {
    margin-top: 16px;

    .icon {
      margin-right: 8px;
    }
  }
}
</style>


<template lang="pug">
.logout-button.flex.vertical-center(v-if="isAuthenticated")
  app-button.button(
    theme="link",
    :disabled="submitting",
    @click="showModal = true"
  )
    .button__content
      .avatar
        i.fa-solid.fa-user
      span.name {{ user.firstName }}

app-modal(
  :avoid-close-on-click-outside="submitting",
  :close-button="!submitting",
  :show="showModal",
  :width="385",
  @close="showModal = false"
)
  .modal-body
    .user-info
      label {{ user.name }}

    .actions
      app-button.action-button(
        theme="primary",
        outline,
        :disabled="submitting",
        @click="submit",
        full-width
      )
        .flex.center.vertical-center
          i.icon.fal.fa-sign-out
          span {{ $t('.btn.logout') }}
</template>


<script>
// Modules
import { auth } from "@/modules/auth"

// Components
import AppButton from "@/components/app-button/app-button.vue"
import AppModal from "@/components/app-modal"

// Mixins
import FormMixin from "@/mixins/form-mixin"

export default {
  name: "LogoutButton",

  emits: [],

  components: {
    AppButton,
    AppModal
  },

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "components.logout-button",

      showModal: false,

      user: auth.getUser()
    }
  },

  computed: {
    isAuthenticated() {
      return auth.isAuthenticated()
    }
  },

  methods: {
    submitRequest() {
      return this.$sdk.auth.logout().catch(() => {})
    },

    onSubmitSuccess() {
      auth.logout()

      this.$router.push({ name: "login" })
    }
  }
}
</script>
