import ability from "./ability"

const AbilityPlugin = {
  install({ config }) {
    config.globalProperties.$ability = ability
    config.globalProperties.$can = ability.can.bind(ability)
    config.globalProperties.$cannot = ability.cannot.bind(ability)
  }
}

export default AbilityPlugin
