// 3rd Party
import { ObserveVisibility } from "vue-observe-visibility"

// ref https://github.com/Akryum/vue-observe-visibility/issues/219#issuecomment-893262361
const VueObserveVisibility = {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance
    ObserveVisibility.bind(el, binding, vnode)
  },
  update:    ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind
}

export default VueObserveVisibility
