// Models
import Base from "../base/base"

class Contact extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "name"
    ])
  }

  static get relations() {
    return {
      emails: { type: "hasMany", model: "ContactEmail" },
      phones: { type: "hasMany", model: "ContactEmail" }
    }
  }
}

export default Contact
