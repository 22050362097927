<!--
<doc lang="markdown">
Componente com GoogleAutocomplete para endereço
Com `value` sendo `place_id`

_Props_:
value: `place_id`
initial-text: Texto inicial caso exista um `place_id` inicil
_porps_ de um `field`

Como usar:
```pug
  address-place-field(
    name="resource[address_place_id]"
    v-model="resource.placeId",
    :initial-text="resource.addressLine",
    hide-label,
  )
```
</doc>
-->


<style lang="scss" scoped>

.input-field {
  width: 100%;

  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $gray-dark;
    transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

.input-field
  label.label-content.flex.vertical-center(v-if="!hideLabel", :for="inputId", :class="{ error: hasErrors, focus }")
    span {{ labelText }}
    span(v-if="optional") {{ $t('form.optional') }}
    i.info-icon.far.fa-info-circle(v-if="info", @click="$emit('info-click')")

    template(v-if="optional")
      span {{ $t('form.optional') }}

    template(v-else-if="mandatory")
      i.mandatory-icon.fas.fa-circle(
        v-tooltip.top="$t('form.mandatory.tooltip')"
      )

  googlemaps-autocomplete.google-autocomplete(
    :id="inputId",
    :disabled="disabled || loading",
    :input-error="hasErrors",
    :readonly="readonly",
    :name="name",
    :placeholder="placeholder",
    ref="input",
    :fields="['place_id', 'formatted_address', 'geometry.location', 'type']",
    :class="{ loading, disabled, error: hasErrors }",
    :modelValue="googlePlace",
    @update:modelValue="onChange",
    @focus="focus = true",
    @blur="focus = false"
  )

  span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

// Components
import GooglemapsAutocomplete from "../googlemaps-autocomplete"

// Mixins
import FieldMixin from "@/mixins/field-mixin"

export default {
  name: "AddressPlaceField",

  components: {
    GooglemapsAutocomplete
  },

  mixins: [FieldMixin],

  props: {
    initialText: { type: String, default: null },
    lat:         { type: Number, default: null },
    lng:         { type: Number, default: null }
  },

  emits: [
    "update:modelValue",
    "update:lat",
    "update:lng"
  ],

  data() {
    return {
      googlePlace: null,
      focus:       false
    }
  },

  computed: {
    showAutocomplete() {
      return this.autocomplete ? "" : "off"
    },

    serializedValue() {
      if (_.blank(this.modelValue)) return null

      return {
        place_id: this.modelValue
      }
    }
  },

  watch: {
    modelValue() {
      if (this.modelValue === _.get(this.googlePlace, "place_id")) return

      if (_.blank(this.modelValue)) {
        this.googlePlace = null

        this.$emit("update:lat", null)
        this.$emit("update:lng", null)
        return
      }

      this.googlePlace = {
        place_id: this.modelValue
      }
    }
  },

  created() {
    if (this.modelValue && this.initialText) {
      this.googlePlace = { place_id: this.modelValue, formatted_address: this.initialText }
    }
  },

  methods: {
    onChange(place) {
      this.googlePlace = place
      this.$emit("update:modelValue", _.get(place, "place_id") || null)

      const { lat, lng } = this.extractLatLng(place)

      this.$emit("update:lat", lat || null)
      this.$emit("update:lng", lng || null)
    },

    extractLatLng(placeObject) {
      const [lat, lng] = [
        _.get(placeObject, "geometry.location.lat"),
        _.get(placeObject, "geometry.location.lng")
      ]

      if (![lat, lng].every(Boolean)) return { lat: null, lng: null }

      return { lat: lat(), lng: lng() }
    }
  }
}

</script>
