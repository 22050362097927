<style lang="scss" scoped>

.supplier-appointment-create {
  p {
    margin: 0;
  }

  .error {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }

  .empty {
    padding: 12px;
    background-color: $gray-light-3;
    border-radius: 8px;

    img {
      min-height: 32px;
    }

    p {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      color: $gray;
    }
  }

  .suggestion {
    border: 1px solid $orange;
    border-radius: 8px;
    background-color: $orange-light-3;
    padding: 8px;

    .icon {
      width: 32px;
      aspect-ratio: 1;
      color: $orange;
      font-size: 20px;
    }

    .message {
      font-size: 12px;
    }
  }
}

</style>


<template lang="pug">

.supplier-appointment-create.grid.gap-regular
  supplier-appointment-search-form(
    :ticket-id="ticketId"
    :disabled="disabled",
    :suggestion="supplierSuggestion",
    @submitting="fetchingSuppliers = true"
    @index="handleIndex",
    @error="fetchingSuppliers = false",
  )

  span.error(v-if="errors?.supplier?.length") {{ errors.supplier[0] }}

  .suggestion.flex.gap-regular.vertical-center(v-if="supplierSuggestion && showSuggestion")
    .icon.flex.center.vertical-center
      i.fal.fa-car-garage
    p.message.grow
      span {{ $t(".suggestion.start") }}
      strong {{ $t(`.suggestion.type.${supplierSuggestion}`) }}
      span {{ $t(".suggestion.end") }}

    app-icon-button(icon-classes="fas fa-times" @click="showSuggestion = false")

  .empty.grid.gap-small.center(v-if="!hasRequested")
    img(:src="emptySupplier", :alt="$t('.empty.no-attempt.alt')")
    p.text-center {{ $t(".empty.no-attempt.text") }}

  .empty.grid.gap-small.center(v-else-if="!suppliersAvailable.length")
    img(:src="emptyMagnifying", :alt="$t('.empty.attempted.alt')")
    p.text-center {{ $t(".empty.attempted.text") }}

  supplier-appointment-options(
    v-else
    v-model="form.supplier",
    :suppliers-available="suppliersAvailable",
    :disabled="disabled"
  )
    template(#bottom="{ supplier, disabled: optionDisabled }")
      supplier-appointment-scheduler(
        v-model:date="form.date",
        v-model:time="form.time",
        :errors="errors",
        :disabled="optionDisabled || disabled",
        :supplier-id="supplier.id",
        @loading="$emit('business-hours-loading')",
        @index="$emit('business-hours-end')"
      )

</template>


<script>

// Assets
import emptySupplier from "@/assets/images/list-illustrations/empty-supplier.svg"
import emptyMagnifying from "@/assets/images/list-illustrations/empty-magnifying.svg"

// Components
import SupplierAppointmentSearchForm from "../search-form"
import SupplierAppointmentScheduler from "../scheduler"
import SupplierAppointmentOptions from "../options"
import AppIconButton from "@/components/app-icon-button"

export default {
  name: "SupplierAppointmentCreate",

  components: {
    SupplierAppointmentSearchForm,
    SupplierAppointmentScheduler,
    SupplierAppointmentOptions,
    AppIconButton
  },

  props: {
    ticketId: { type: [String, Number] },
    disabled: { type: Boolean, default: false }
  },

  emits: ["business-hours-loading", "business-hours-end"],

  data() {
    return {
      i18nScope: "components.supplier-appointment-create",
      emptySupplier,
      emptyMagnifying,
      form:      {
        supplier: "",
        date:     "",
        time:     ""
      },
      suppliersAvailable: [],
      //
      errors:             {},
      //
      fetchingSuppliers:  false,
      hasRequested:       false,
      //
      fetchingSuggestion: false,
      supplierSuggestion: "",
      showSuggestion:     true
    }
  },

  computed: {
    hasErrors() {
      const errors = Object.entries(this.errors)

      return errors.length > 0
        && errors.some(([key, value]) => Array.isArray(value) && value.length > 0)
    }
  },

  watch: {
    "form.supplier": {
      handler(to) {
        this.resetForm({ onlyDateTime: true })
        this.errors.date = []
        this.errors.time = []
      }
    }
  },

  mounted() {
    this.fetchSupplierRecommendation()
  },

  methods: {
    async fetchSupplierRecommendation() {
      try {
        this.fetchingSuggestion = true

        const { data } = await this.$sdk.tickets.supplierRecommendation.show({ ticketId: this.ticketId })

        this.supplierSuggestion = data.supplierType
      }
      catch (err) {
        console.error(err)
      }
      finally {
        this.fetchingSuggestion = false
      }
    },

    validate() {
      this.errors = {}

      if (!this.form.supplier) {
        this.errors.supplier = [this.$t(".errors.supplier")]

        return
      }

      if (!this.form.date) {
        this.errors.date = [this.$t(".errors.date")]
      }

      if (!this.form.time) {
        this.errors.time = [this.$t(".errors.time")]
      }
    },

    getParamsFromForm() {
      this.validate()

      if (this.hasErrors) {
        return null
      }

      const date = moment(`${this.form.date} ${this.form.time}`, "YYYY-MM-DD hh:mm")

      /* eslint-disable-next-line eqeqeq */
      const { score, distance } = this.suppliersAvailable.find(({ supplier }) => supplier.id == this.form.supplier)

      return {
        supplier_id: this.form.supplier,
        at:          date.toISOString(),
        score,
        distance
      }
    },

    handleIndex(suppliersAvailable) {
      this.resetForm()
      this.suppliersAvailable = suppliersAvailable
      this.fetchingSuppliers = false

      this.hasRequested = true
    },

    resetForm({ onlyDateTime = false } = {}) {
      if (!onlyDateTime) this.form.supplier = ""

      this.form.date = ""
      this.form.time = ""
    }
  }
}

</script>
