// Libs
// import normalize from "@/lib/functions/normalize"

// Base
import Base from "./Base"

// Models
import VehicleMaintenance from "@/models/vehicle/vehicle-maintenance"

// const sampleMaintenance = () => normalize({
//   maintenance_required: false
// }, { constructor: VehicleMaintenance })

class VehicleKm extends Base {
  static get selector() {
    return "vehicles.km"
  }

  show({ vehicleId, params = {}, options = {} } = {}) {
    this.verifyData("show", { vehicleId })

    return this.action({
      method:  "get",
      url:     `/vehicles/${vehicleId}/maintenance_required`,
      params,
      options: {
        model: VehicleMaintenance,

        ...options
      }
      // }).then(response => {
      //   response.data = sampleMaintenance()

      //   return response
      // }).catch(err => {
      //   err.data = sampleMaintenance()

    //   return err
    })
  }
}

export default VehicleKm
