<style lang="scss" scoped>

.not-found {
  min-height: 100%;
  width: 100%;
  background: $white;

  .img {
    margin-top: 10vh;
    margin-bottom: 32px;
    width: 70%;

    @media only screen and (min-width: 1600px) {
      width: 1200px;
    }
  }

  .button-content {
    display: block;
    width: 200px;
    padding-bottom: 20px;
  }
}

</style>


<template lang="pug">

.not-found.flex.vertical-center.column-direction(data-testid="not-found")
  img.img(:src="illustration")
  .button-content
    app-button(
      full-width,
      outline,
      size="small",
      @click="$goBack"
    ) {{ $t('.btn.goBack') }}

</template>


<script>

// Assets
import illustration from "@/assets/images/errors/404.png"

// Components
import AppButton from "@/components/app-button/app-button.vue"

export default {
  name: "NotFound",

  emits: [],

  components: {
    AppButton
  },

  data() {
    return {
      i18nScope: "components.errors.not-found",

      illustration
    }
  }
}

</script>
