// Libs
import validate from "@/lib/validate"
import { setErrorsToHasManyIndexes } from "@/lib/parse-errors"

// Mixins
import DirtyableMixin from "@/mixins/dirtyable-mixin"

const FormMixin = {
  mixins: [DirtyableMixin],

  data() {
    return {
      submitting:       false,
      errors:           {},
      nestedAttributes: null,
      resource:         null,

      submitRequestPromise: null,
      submitErred:          false,
      submitError:          {},
      submitted:            false,

      throwOnError: false,

      // Informa se o usuário deve ser avisado ao sair da página com dados alterados
      guardFormOnLeave: true
    }
  },

  // ref: https://github.com/vuejs/router/issues/454
  // beforeRouteLeave(to, from) {
  //   return this.beforeRouteLeaveGuard(to, from)
  // },

  methods: {
    cancelRunningSubmitRequest({ motive = "Submit duplicado" } = {}) {
      if (_.get(this.submitRequestPromise, "cancel")) this.submitRequestPromise.cancel(motive)
    },

    async submit(options) {
      await this.beforeSubmit(options)
      this.errors = await validate(this.resource, this.nestedAttributes)

      if (_.present(this.errors)) {
        // XXX: Para sub-formulários informarem o erro
        if (this.throwOnError) throw new Error("Validation failed:", this.errors)
        return
      }

      this.$notifications.clear()

      let currentRequestPromise

      try {
        this.cancelRunningSubmitRequest()

        currentRequestPromise = this.submitRequest()
        this.submitRequestPromise = currentRequestPromise

        this.submitting = true

        await this.afterSubmitStart(options)

        const response = await currentRequestPromise

        this.submitted = true

        await this.onSubmitSuccess(response, options)

        this.submitError = {}
        this.submitErred = false

        await this.afterSubmitSuccess(options)
      }
      catch (err) {
        await this.onSubmitError(err, options)
        await this.afterSubmitError(options)

        // XXX: Para sub-formulários informarem o erro
        if (this.throwOnError) throw err
      }
      finally {
        await this.afterSubmit(options)

        if (this.submitRequestPromise === currentRequestPromise) {
          this.submitRequestPromise = null
          this.submitting = false
        }
      }
    },

    submitRequest() {
      throw new Error("Form Mixin - #submitRequest() - Not implemented yet")
    },

    // Hooks
    beforeSubmit() {},

    afterSubmitStart() {},

    onSubmitSuccess(response, options) {
      this.$notifications.info(this.$t(".notifications.submit.success"))
    },

    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      console.error(this.submitError)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      if (error.originalError) this.setFormErrors(error.originalError)
    },

    afterSubmitSuccess() {},

    afterSubmitError() {},

    afterSubmit() {},

    setFormErrors(httpError) {
      if (_.blank(this.resource)) return

      const errData = _.get(httpError, "response.data.errors") || {}
      const model = this.resource.constructor.name
      const errors = this.$i18n.errify(errData, { model })

      this.errors = setErrorsToHasManyIndexes(errors, this.resource)
    },

    beforeRouteLeaveGuard(to, from) {
      if (
        !this.guardFormOnLeave
        || to.name === from.name
        || this.submitted        // Após envio dos dados com sucesso
        || this.fetching         // Durante carregamento
        || this.erred            // Após falha de carregamento
      ) return true

      return this.canLeaveForm()
    }
  }
}

export default FormMixin
