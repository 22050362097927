// 3rd Party
import qs from "qs"

// Vue
import { createRouter, createWebHistory } from "vue-router"

// Modules
import { auth } from "@/modules/auth"

// Routes
import routes from "./routes"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  parseQuery:     qs.parse,
  stringifyQuery: query => qs.stringify(query, { arrayFormat: "brackets" }),

  scrollBehavior(to, from, savedPosition) {
    let position = savedPosition || { top: 0 }

    return { ...position, behavior: "smooth" }
  }
})

// Como todo o app requer autenticação, registremos um navigation guard global
// ref https://next.router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach((to, from) => {
  // Se o usuário estiver autenticado e tenta acessar a rota de "login", redirecione-o para a rota "padrão" do app
  if (to.name === "login" && auth.isAuthenticated()) return { path: "/" }

  // Se a rota necessitar de autenticação (pelo atributo meta.auth), então
  // verificamos se o usuário está autenticado, redirecionando-o à página
  // de login caso necessário
  if (to.matched.some(record => record.meta.auth) && !auth.isAuthenticated()) {
    return {
      name:  "login",
      query: { redirect: to.fullPath }
    }
  }

  return true
})

// XXX: Registro da URL anterior
// ref https://github.com/vuejs/vue-router/issues/883
router.referrer = null
const _push = router.push
router.push = (...args) => {
  router.referrer = router.currentRoute._rawValue
  return _push.call(router, ...args)
}

export default router
