<!--
<doc lang="markdown">

Indicador de carregamento tipo _skeleton_

Como usar:
```pug
loading-lines(icon, :lines="3")
```

</doc>
-->

<style lang="scss" scoped>

.loading-lines {
  .shine-loading {
    background: #EFEFEF;
    background-image: linear-gradient(to right, #EFEFEF 5%, #F9F9F9 15%, #EFEFEF 40%, #EFEFEF 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    border-radius: 6px;

    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  .loading-line + .loading-line {
    margin-top: 8px;
  }

  .loading-line {
    display: flex;
  }

  .icon-load {
    width: 26px;
    margin-right: 12px;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -500px 0;
    }

    100% {
      background-position: 500px 0;
    }
  }
}

</style>


<template lang="pug">

.loading-lines(role="progressbar")
  .loading-line(v-for="line in lines", :index="line")
    .shine-loading.icon-load(v-if="icon", :style="iconStyle", data-test="icon")
    .shine-loading(:style="barStyle()", data-test="bar")

</template>


<script>

export default {
  name: "LoadingLines",

  emits: [],

  props: {
    lines:  { type: Number, default: 1 },
    height: { type: Number, default: 20 },
    min:    { type: Number, default: 25 },
    max:    { type: Number, default: 70 },
    icon:   { type: Boolean, default: false }
  },

  computed: {
    iconStyle() {
      return {
        height: `${this.height}px`
      }
    }
  },

  methods: {
    barStyle() {
      return {
        width:  `${Math.floor(Math.random() * (this.max - this.min + 1)) + this.min}%`,
        height: `${this.height}px`
      }
    }
  }
}

</script>
